import React, { useEffect, useReducer } from "react";
import ListUsers from "../../components/tables/ListUsers";
import { Button, Grid } from "@mui/material";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../../components/GenericAlert";
import Loader from "../../components/Loader";
import {
  DELETE_USER_MODAL_TYPE,
  DISABLE_USER_MODAL_TYPE,
  ERROR,
  MEDIUM,
  USERS_PERMISSION,
  USER_EDIT_MODAL_TYPE
} from "../../utils/constants";
import { deleteUser, disabledUser, getUsers } from "../../requests/usersRequests";
import CreateEditUserModal from "../../components/modals/CreateEditUserModal";
import { IModalsState } from "../../interfaces/configuration";
import SearchUsers from "../../components/usersRoles/SearchUsers";
import { IRole } from "../../interfaces/usersRoles";
import ConfirmDeleteUserModal from "../../components/modals/ConfirmDeleteUserModal";
import { usePermission } from "../../customHooks/usePermission";
import { IModifyUserInfo, TModifyModalOptions } from "../../interfaces/modal";
import { userPageReducer } from "../../reducers/userPageReducer";

const UsersLoader = () => (
  <Grid item xs={12}>
    <Loader size={MEDIUM} />
  </Grid>
);

const baseUserStates = {
  alert: {} as IAlert,
  users: [] as any[],
  count: 0,
  selectedUser: {} as any,
  openModal: {} as IModalsState,
  userToModify: {} as IModifyUserInfo,
  typeOfModal: "" as TModifyModalOptions,
  filter: "",
  page: 0,
  loading: true
};

const Users = ({ roles }: { roles: IRole[] }) => {
  const { can_write } = usePermission(USERS_PERMISSION);
  const [states, dispatch] = useReducer(userPageReducer, baseUserStates);

  const handleGetUsers = async (currentFilter: string, currentPage: number) => {
    try {
      dispatch({ type: "loading", value: true });
      const { users: userList, count: userCount } = await getUsers(currentFilter, currentPage);
      dispatch({ type: "users", value: userList });
      dispatch({ type: "count", value: userCount });
    } catch (error: any) {
      dispatch({ type: "alert", value: {
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      }});
    } finally {
      dispatch({ type: "loading", value: false });
    }
  };

  useEffect(() => {
    handleGetUsers(states.filter, states.page);
  }, [states.page, states.filter]);

  const handleUserModification = async () => {
    try {
      switch (states.typeOfModal) {
      case DELETE_USER_MODAL_TYPE: {
        await deleteUser(states.userToModify.id);
        break;
      }
      case DISABLE_USER_MODAL_TYPE: {
        await disabledUser(states.userToModify.id, states.userToModify.disabled);
        break;
      }
      default: break;
      }

      handleGetUsers(states.filter, states.page);
      dispatch({ type: "typeOfModal", value: "" });
    } catch (error: any) {
      dispatch({ type: "alert", value: {
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      }});
    }
  };

  const handleOpenModificationModal = (
    values: IModifyUserInfo,
    modalType: TModifyModalOptions
  ) => {
    dispatch({ type: "userToModify", value: values });
    dispatch({ type: "typeOfModal", value: modalType });
  };

  const handleOnCloseConfirmationModal = () => {
    dispatch({ type: "typeOfModal", value: "" });
    dispatch({ type: "userToModify", value: {} as IModifyUserInfo });
  };

  const handleOpenModal = (type: string, userInfo?: any) => {
    dispatch({ type: "selectedUser", value: type === USER_EDIT_MODAL_TYPE ? userInfo : undefined });

    dispatch({ type: "openModal", value: { type, open: true }});
  };

  const handleDeleteInvitation = async (id: number) => {
    await deleteUser(id);
    dispatch({ type: "page", value: 0 });
  };

  const handleSelectedAction = (typeOfAction: string, values?: any) => {
    switch (typeOfAction) {
    case "edit":
      // Open modal and use selected user's info
      return handleOpenModal(typeOfAction, values);
    case "delete":
      return handleOpenModificationModal(values, DELETE_USER_MODAL_TYPE);
    case "disable":
      return handleOpenModificationModal(values, DISABLE_USER_MODAL_TYPE);
    case "create":
      return handleOpenModal(typeOfAction);
    case "delete-invitation":
      return handleDeleteInvitation(values);
    default:
      return <></>;
    }
  };

  const handleModalClose = (refreshUsers?: boolean) => {
    if (refreshUsers)
      handleGetUsers(states.filter, states.page);

    dispatch({ type: "openModal", value: { type: "", open: false }});
  };

  return (
    <Grid container className="m-t-30">
      {states.openModal.open && (
        <CreateEditUserModal
          open={states.openModal.open}
          typeOfModal={states.openModal.type}
          userInfo={states.selectedUser}
          roles={roles}
          onClose={handleModalClose}
        />
      )}
      <ConfirmDeleteUserModal
        open={states.typeOfModal !== ""}
        modalType={states.typeOfModal}
        userInfo={states.userToModify}
        onClose={handleOnCloseConfirmationModal}
        onSubmit={handleUserModification}
      />
      <Grid container spacing={3}>
        <Grid item md={5} lg={3}>
          <SearchUsers handleGetUsers={(input) => dispatch({ type: "filter", value: input })} />
        </Grid>
        {can_write && (
          <Grid item xs={12} md={4} lg={1.5}>
            <Button
              className="w-100"
              variant="contained"
              onClick={() => handleSelectedAction("create")}
            >
              Añadir usuario
            </Button>
          </Grid>
        )}
      </Grid>
      {states.alert.showAlert && (
        <Grid item xs={12}>
          <GenericAlert alert={states.alert} />
        </Grid>
      )}
      {!states.loading && (
        <Grid item xs={12}>
          <ListUsers
            users={states.users}
            can_write={can_write}
            handleSelectedAction={handleSelectedAction}
            handlePageChange={(newPage) => dispatch({ type: "page", value: newPage })}
            page={states.page}
            count={states.count}
          />
        </Grid>
      )}
      {states.loading && <UsersLoader />}
    </Grid>
  );
};

export default Users;
