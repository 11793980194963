import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, Typography, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/userContext";
import { IAlert } from "../../interfaces/alert";
import { IRules, IWebhookInput } from "../../interfaces/configuration";
import { updateWebhookInfo } from "../../requests/configurationRequests";
import {
  AUTOMATIC_STATE_UPDATE,
  ERROR,
  INITIAL_CONFIGURATION_PARTIAL_PATH,
  MANUAL_STATE_UPDATE,
  MARKETPLACE,
  SELECT_MODE,
  SUCCESS,
} from "../../utils/constants";
import GenericAlert from "../GenericAlert";
import ConfirmChangesButtons from "./ConfirmChangesButtons";
import WebhookForm from "./WebhookForm";

const WebhookSettings = ({ handleNext, handleBack }: IRules) => {
  const [notificationType, setNotificationType] = useState(SELECT_MODE);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [authentication, setAuthentication] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({} as IAlert);
  const [baseInfo, setBaseInfo] = useState({} as IWebhookInput);

  const stateManagerHandler: {
    [key: string]: React.Dispatch<React.SetStateAction<string>>;
  } = {
    notificationType: setNotificationType,
    authentication: setAuthentication,
    webhookUrl: setWebhookUrl,
  };

  const { currentUser, refreshUserData } = useContext(UserContext);

  const isInitialConfig = location.pathname.includes(
    INITIAL_CONFIGURATION_PARTIAL_PATH
  );

  const isMarketplace = currentUser?.entity === MARKETPLACE;

  useEffect(() => {
    if (currentUser) {
      const baseWebhook = currentUser.webhook_info?.url || "";
      const baseNotificationType = currentUser.webhook_info?.enabled
        ? AUTOMATIC_STATE_UPDATE
        : MANUAL_STATE_UPDATE;
      const baseAuthentication =
        currentUser.webhook_info?.authentication_value || "";
      setNotificationType(baseNotificationType);
      setWebhookUrl(baseWebhook);
      setAuthentication(baseAuthentication);
      setBaseInfo({
        notificationType: baseNotificationType,
        webhookUrl: baseWebhook,
        authentication: baseAuthentication,
      });
    }
  }, [currentUser]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateWebhookInfo({
        enabled: notificationType === AUTOMATIC_STATE_UPDATE,
        authentication,
        webhookUrl,
      });
      setAlert({
        typeOfAlert: SUCCESS,
        message: "Configuración de estados guardada con éxito",
        showAlert: true,
      });
      if (handleNext) handleNext();
      refreshUserData();
      setBaseInfo({
        notificationType,
        webhookUrl: notificationType ? webhookUrl : baseInfo.webhookUrl,
        authentication: notificationType
          ? authentication
          : baseInfo.authentication,
      });
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setNotificationType(baseInfo.notificationType);
    setWebhookUrl(baseInfo.webhookUrl || "");
    setAuthentication(baseInfo.authentication || "");
  };

  const unsavedChanges =
    baseInfo.notificationType !== notificationType ||
    baseInfo.webhookUrl !== webhookUrl ||
    baseInfo.authentication !== authentication;

  return (
    <>
      <Card
        className={`br-20 ${isInitialConfig ? "bg-input" : "bg-light m-b-40"}`}
        sx={{
          padding: 3,
          width: isInitialConfig ? "560px" : undefined,
          height: isInitialConfig ? "325px" : undefined,
        }}
      >
        <CardContent className="webhookContainer" sx={{ paddingInline: 4.5 }}>
          <>
            <Typography className="f-s-24">
              Notificación de estados a la tienda
            </Typography>
            <div className="m-b-20">
              {alert.showAlert && <GenericAlert alert={alert} />}
            </div>
          </>

          <WebhookForm
            webhookInfo={{ notificationType, webhookUrl, authentication }}
            handleInfoChange={(field: string, value: string) => {
              stateManagerHandler[field](value);
            }}
            isInitialConfig={isInitialConfig}
          />

          {isInitialConfig && (
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              className="m-t-30 webhookSelect"
            >
              {isMarketplace && (
                <Grid item xs={6}>
                  <Button fullWidth variant="outlined" onClick={handleBack}>
                    Volver
                  </Button>
                </Grid>
              )}
              <Grid item xs={isMarketplace ? 6 : 12}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    notificationType === SELECT_MODE ||
                    (notificationType === AUTOMATIC_STATE_UPDATE && !webhookUrl)
                  }
                  loading={loading}
                  loadingPosition="end"
                >
                  Continuar
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      {/*
        La posición de los botones depende de si el componente se renderiza en la configuración
        inicial o en la sección de configuración, de ahi el uso de la variable
        isInitialConfig como condicional
      */}
      {!isInitialConfig && unsavedChanges && (
        <ConfirmChangesButtons
          handleConfirm={handleSubmit}
          handleCancel={handleCancel}
          confirmButtonText="Guardar cambios"
        />
      )}
    </>
  );
};

export default WebhookSettings;
