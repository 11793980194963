import { TMethods } from "../interfaces/axios";
import { GET } from "../utils/constants";
import { fetchData } from "../utils/dataProvider";

export const getIntegrationsOfMarketplace = () => {
  const payload: { url: string; method: TMethods } = {
    url: "/marketplaces_merchants/acceptedIntegrationsOfMarketplace",
    method: GET,
  };
  return fetchData(payload);
};

export const getMerchantIntegrationsFullInfo = (options?: string) => {
  const payload: { url: string; method: TMethods } = {
    url: options ? `/marketplaces_merchants/merchantsFullInfo?options=${options}`: "/marketplaces_merchants/merchantsFullInfo",
    method: GET,
  };
  return fetchData(payload);
};
