import { Box, Grid, IconButton, Typography, TextField } from "@mui/material";
import { useEffect, useContext, useState, useReducer } from "react";
import { useParams, useNavigate } from "react-router-dom";
import GenericAlert from "../../components/GenericAlert";
import { IAlert, typeOfAlerts } from "../../interfaces/alert";
import {
  DELIVERED,
  ERROR,
  GET,
  MEDIUM,
  ORDER_NOT_DELIVERED_MESSAGE,
  ORDER_NOT_OWN_MESSAGE,
  ORDER_REFUND_ERROR_MESSAGE,
  ORDER_REFUND_SUCCESS_MESSAGE,
  PARENT_PATH,
  POST,
  SUCCESS,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import "../../styles/tables.css";
import RefundInfoCard from "../../components/orders/RefundInfoCard";
import RefundPackagesTable from "../../components/tables/RefundPackagesTable";
import { UserContext } from "../../contexts/userContext";
import ConfirmChangesButtons from "../../components/configuration/ConfirmChangesButtons";
import { trimValue } from "../../utils/form";
import Loader from "../../components/Loader";
import { refundOrderReducer } from "../../reducers/refundOrderReducers";
import { IRefundOrderReducerStates } from "../../interfaces/configuration";
import { IOrderRaw, IPackageToRefund } from "../../interfaces/order";

const refundOrderStates: IRefundOrderReducerStates = {
  orderData: {} as IOrderRaw,
  packagesToRefund: [] as IPackageToRefund[],
  reason: "",
  hasConfirmed: false,
  loading: false,
};

const RefundOrderDetails = () => {
  const [{ orderData, reason, packagesToRefund, hasConfirmed, loading }, dispatch] = useReducer(
    refundOrderReducer,
    refundOrderStates);
  const [alert, setAlert] = useState({} as IAlert);

  const navigate = useNavigate();
  const { trackingNumber } = useParams();
  const { tracking_id, orderInfo, status, merchant_id, external_id, client } =
    orderData;
  const {
    selectedMarketplace,
    currentUser: { merchantId: currentMerchantId, clientId: currentClientId },
  } = useContext(UserContext);

  const isDelivered = status === DELIVERED;
  const isOwnOrder = !(
    (currentMerchantId && currentMerchantId !== merchant_id) ||
    (currentClientId && currentClientId !== client?.id)
  );
  const shouldDisableConfirmButton =
    !Object.keys(packagesToRefund).length ||
    !trimValue(reason).length ||
    hasConfirmed;

  const showAlert = (typeOfAlert: typeOfAlerts, message: string) =>
    setAlert({
      typeOfAlert,
      message,
      showAlert: true,
    });

  const getOrderByTrackingNumber = async () => {
    try {
      const response = await fetchData({
        url: `/orders/${trackingNumber}${
          selectedMarketplace && `?marketplaceId=${selectedMarketplace}`
        }`,
        method: GET,
      });
      dispatch({ type: "setOrderData", value: response });
      dispatch({type: "setLoading", value: false});
    } catch (error: any) {
      showAlert(ERROR, error);
    }
  };

  const goBack = () => navigate(PARENT_PATH);

  const handleConfirmChanges = async () => {
    dispatch({ type: "setHasConfirmed", value: true });
    try {
      await fetchData({
        method: POST,
        url: `/orders/return/${trackingNumber}${
          selectedMarketplace && `?marketplaceId=${selectedMarketplace}`
        }`,
        body: { packages: packagesToRefund, reason: trimValue(reason) },
      });
      showAlert(SUCCESS, ORDER_REFUND_SUCCESS_MESSAGE);
      setTimeout(goBack, 2000);
    } catch (error) {
      showAlert(ERROR, ORDER_REFUND_ERROR_MESSAGE);
      dispatch({type: "setHasConfirmed", value: false});
    }
  };

  useEffect(() => {
    dispatch({type: "setLoading", value: true});
    getOrderByTrackingNumber();
  }, []);

  useEffect(() => {
    if (Object.keys(orderData).length === 0) return;
    if (!isOwnOrder) {
      showAlert(ERROR, ORDER_NOT_OWN_MESSAGE);
      return;
    }
    if (!isDelivered) showAlert(ERROR, ORDER_NOT_DELIVERED_MESSAGE);
  }, [orderData]);

  return (
    <>
      {loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <Grid container flexDirection="column">
          <Grid item>
            <Box display="flex" alignItems="center">
              <IconButton onClick={goBack}>
                <span className="material-symbols-rounded text-dark">
                  keyboard_backspace
                </span>
              </IconButton>
              <Typography ml={1} className="f-s-20">
                Nueva devolución
              </Typography>
            </Box>
          </Grid>

          {alert.showAlert && (
            <Grid item xs={12} lg={6}>
              <GenericAlert alert={alert} />
            </Grid>
          )}

          {isDelivered && isOwnOrder && (
            <Grid item xs={12} lg={6}>
              <Typography className="f-s-20 m-t-20">
                N° de guía {tracking_id}
              </Typography>

              <RefundInfoCard orderInfo={orderInfo} external_id={external_id} />

              <Typography className="f-s-20 m-t-30">Paquetes</Typography>

              <RefundPackagesTable
                orderInfo={orderInfo}
                packagesToRefund={packagesToRefund}
                setPackagesToRefund={(e:any[])=> dispatch({ type: "setPackagesToRefund", value: e })}
              />

              <Typography className="f-s-20 m-t-30">
                ¿Cuál es el motivo de la devolución?
              </Typography>

              <Grid container className="m-t-20 notif-cont-box-shadow m-b-40">
                <TextField
                  multiline
                  fullWidth
                  rows={4}
                  placeholder="Por favor, ingresa en detalle el motivo de la devolución."
                  value={reason}
                  onChange={(e) =>
                    dispatch({ type: "setReason", value: e.target.value })
                  }
                />
              </Grid>

              <ConfirmChangesButtons
                handleConfirm={handleConfirmChanges}
                handleCancel={goBack}
                confirmButtonText={
                  hasConfirmed ? "Procesando..." : "Confirmar y crear envío"
                }
                cancelButtonText="Volver"
                disableConfirmButton={shouldDisableConfirmButton}
                size={4}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default RefundOrderDetails;
