import { DEVELOP, PRODUCTION, STAGING } from "../../utils/constants";
import config from "../../config/config";
import { Grid, OutlinedInput, Typography } from "@mui/material";
import ClipboardAdornment from "../ClipboardAdornment";
import { useContext } from "react";
import { UserContext } from "../../contexts/userContext";

const renderInput = (label: string, value: string | null) => (
  <Grid item xs={12} lg={6}>
    <Typography className="f-s-14 m-b-10">{label}</Typography>
    <OutlinedInput
      disabled
      fullWidth
      value={value || ""}
      size="small"
      className="bg-input"
      endAdornment={<ClipboardAdornment value={value || ""} />}
    />
  </Grid>
);

const getFullEnvName = () => {
  const envNameDict: any = {
    [DEVELOP]: "desarrollo",
    [STAGING]: "staging",
    [PRODUCTION]: "producción",
  };

  return envNameDict[`${config.ENV_NAME}`] || "producción";
};

const ApiKeysGrid = () => {
  const {
    currentUser: { apiKey, stagingApiKey },
  } = useContext(UserContext);

  return (
    <Grid container spacing={3}>
      {config.ENV_NAME === PRODUCTION &&
        stagingApiKey &&
        renderInput("API key staging", stagingApiKey)}
      {renderInput(`API key ${getFullEnvName()}`, apiKey)}
    </Grid>
  );
};

export default ApiKeysGrid;
