import React from "react";
import { Grid, Skeleton, Divider } from "@mui/material";

const SummarySkeleton = ({ rows }: { rows: number }) => (
  <>
    <Skeleton variant="rounded" width={325} height={18} className="m-b-10" />
    {[...Array(rows)].map((_, i) => (
      <React.Fragment key={i}>
        <Grid
          container
          paddingY="20px"
          fontSize={14}
          mb={0.5}
          className="text-dark"
        >
          <Grid item xs={1}>
            <Skeleton variant="rounded" width={18} height={17} />
          </Grid>
          <Grid item xs={9}>
            <Skeleton variant="rounded" width={91} height={17} />
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Skeleton variant="rounded" width={59} height={17} />
          </Grid>
        </Grid>

        <Divider />
      </React.Fragment>
    ))}
  </>
);

export default SummarySkeleton;
