import React, { useState } from "react";
import { Typography } from "@mui/material";
import CredentialsOca from "../../forms/CredentialsOca";
import {
  ICredentialsOca,
  ICredentialsOcaErrors,
  ICredentialsStep,
} from "../../../interfaces/configuration";
import ConfirmChangesCredentials from "../ConfirmChangesCredentials";
import { ERRORS_MESSAGES } from "../../../utils/constants";
import { validateInputs } from "../../../utils/inputValidations";
import { IValidation } from "../../../interfaces/form";
import GenericAlert from "../../GenericAlert";

const {
  passwordRequired,
  cuitRequired,
  cuitInvalid,
  emailInvalid,
  emailRequired,
  accountNumberRequired,
  doorToDoorEndDateNotInThePast,
  reverseLogisticsEndDateNotInThePast,
  reverseOnlyNumbers,
  doorToDoorRequired,
  doorToDoorOnlyNumbers,
  oneDoorToDoorRequired,
  reverseRequired,
  endDateRequired,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "door_to_door_standard",
    type: "only-numbers",
    message: doorToDoorOnlyNumbers,
  },
  {
    id: "door_to_door_priority",
    type: "only-numbers",
    message: doorToDoorOnlyNumbers,
  },
  {
    id: "reverse",
    type: "only-numbers",
    message: reverseOnlyNumbers,
  },
  {
    id: "account_number",
    type: "required",
    message: accountNumberRequired,
  },
  {
    id: "reverse_end_date",
    type: "future_date",
    message: reverseLogisticsEndDateNotInThePast,
  },
  {
    id: "reverse",
    type: "required",
    message: reverseRequired,
  },
  {
    id: "reverse_end_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "door_to_door_standard_end_date",
    type: "future_date",
    message: doorToDoorEndDateNotInThePast,
  },
  {
    id: "door_to_door_priority_end_date",
    type: "future_date",
    message: doorToDoorEndDateNotInThePast,
  },
  {
    id: "door_to_door_standard",
    param: "door_to_door_standard_end_date",
    type: "required-param",
    message: doorToDoorRequired,
  },
  {
    id: "door_to_door_priority",
    param: "door_to_door_priority_end_date",
    type: "required-param",
    message: doorToDoorRequired,
  },
  {
    id: "door_to_door_standard_end_date",
    param: "door_to_door_standard",
    type: "required-param",
    message: endDateRequired,
  },
  {
    id: "door_to_door_priority_end_date",
    param: "door_to_door_priority",
    type: "required-param",
    message: endDateRequired,
  },
];

const OcaCredentials = ({
  handleCancel,
  alert,
  carrier,
  loading,
  handleSubmitCarriers,
}: ICredentialsStep) => {
  const [credentials, setCredentials] = useState({} as ICredentialsOca);
  const [errors, setErrors] = useState({} as ICredentialsOcaErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(credentials, formValidations);
    const { door_to_door_standard, door_to_door_priority } = credentials;
    const d2dContract = !!(door_to_door_standard || door_to_door_priority);
    if (!d2dContract) {
      inputErrors.door_to_door_standard = [oneDoorToDoorRequired];
      inputErrors.door_to_door_priority = [oneDoorToDoorRequired];
    }

    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      handleSubmitCarriers([
        {
          ...carrier,
          credentials,
        },
      ]);
    }
  };

  return (
    <>
      <Typography className="f-s-24 m-b-20">Credenciales de OCA</Typography>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <CredentialsOca
        values={credentials}
        setValues={setCredentials}
        errors={errors}
      />
      <ConfirmChangesCredentials
        onClose={handleCancel}
        handleSubmit={handleSubmit}
        disabled={loading}
        backText="Cambiar operador"
        confirmText="Finalizar"
      />
    </>
  );
};

export default OcaCredentials;
