import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Tooltip,
} from "@mui/material";
import { ISelectedInvitation } from "../../interfaces/invitations";
import {
  IActionsTable,
  IInvitationsTable,
  ITableColumnsByEntity,
} from "../../interfaces/table";
import {
  INTEGRATIONS_STATUSES,
  MANAGMENT_PERMISSION,
  MARKETPLACE,
  MERCHANT,
} from "../../utils/constants";
import ListPagination from "./ListPagination";
import ListHeader from "./ListHeader";
import "../../styles/tables.css";
import { usePermission } from "../../customHooks/usePermission";

const tableColumns: ITableColumnsByEntity = {
  marketplace: [{ label: "Correo electrónico" }, { label: "Estado" }],
  merchant: [{ label: "Alias" }, { label: "Correo electrónico" }],
  internal: [{ label: "Correo electrónico" }, { label: "Estado" }]
};

const TableActions = ({
  row,
  setSelectedRow,
  handleSelectedAction,
  entity,
}: IActionsTable) => (
  <TableCell key={row.email}>
    {entity === MERCHANT && (
      <Tooltip arrow title="Aceptar" placement="top">
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            setSelectedRow(row);
            handleSelectedAction("accept", row);
          }}
        >
          <span className="material-symbols-rounded">done</span>
        </IconButton>
      </Tooltip>
    )}
    <Tooltip
      arrow
      title={entity === MARKETPLACE ? "Cancelar" : "Rechazar"}
      placement="top"
    >
      <IconButton
        size="small"
        color="primary"
        onClick={() => {
          setSelectedRow(row);
          handleSelectedAction("cancel", row);
        }}
      >
        <span className="material-symbols-rounded">close</span>
      </IconButton>
    </Tooltip>
  </TableCell>
);

const ListInvitations = ({
  invitations,
  setSelectedInvitation,
  handleSelectedAction,
  handlePageChange,
  pagination: { count, page },
  entity,
}: IInvitationsTable) => {
  const { can_write } = usePermission(MANAGMENT_PERMISSION);

  const getListHeader = () => {
    const header = tableColumns[entity];
    return can_write ? header.concat({ label: "Acciones" }) : header;
  };

  return (
    <TableContainer component={Paper} className="m-t-40 br-20 bg-light">
      <Table>
        <ListHeader columns={getListHeader()} />
        <TableBody>
          {invitations.map((row: ISelectedInvitation) => {
            const { name, email, status } = row;
            return (
              <TableRow key={email}>
                {name && <TableCell className="p-l-20">{name}</TableCell>}
                <TableCell className={name ? "" : "p-l-20"}>{email}</TableCell>
                {status && (
                  <TableCell>{INTEGRATIONS_STATUSES[status]}</TableCell>
                )}{" "}
                {can_write && (
                  <TableActions
                    row={row}
                    setSelectedRow={setSelectedInvitation}
                    handleSelectedAction={handleSelectedAction}
                    entity={entity}
                  />
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ListPagination
        total={count}
        page={page}
        onChangePage={handlePageChange}
      />
    </TableContainer>
  );
};

export default ListInvitations;
