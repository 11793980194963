import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import BillingForm from "../../components/signupClient/BillingForm";
import { ISignupSteps, ISignupClientPt2 } from "../../interfaces/signup";
// TODO este paso viene cuando tengamos forma de pagos
// import PaymentForm from "../../components/signupClient/PaymentForm";

const SignupClientPt2 = () => {
  const { setGridSize, step } = useOutletContext<any>();
  const [selectedValues, setselectedValues] = useState({} as ISignupClientPt2);

  const steps: ISignupSteps[] = [
    {
      title: "Ingresa los datos de tu empresa",
      number: 3,
      component: () => (
        <BillingForm
          selectedValues={selectedValues}
          setSelectedValues={setselectedValues}
          setGridSize={setGridSize}
          // goForward={goForward}
        />
      ),
    },
    // TODO este paso viene cuando tengamos forma de pagos
    // {
    //   title: "Ingresa los datos de pago",
    //   number: 5,
    //   component: () => (
    //     <PaymentForm
    //       selectedValues={selectedValues}
    //       setSelectedValues={setselectedValues}
    //       setGridSize={setGridSize}
    //       goForward={goForward}
    //       goBack={goBack}
    //     />
    //   ),
    // },
  ];
  return (
    <Box className="p-x-card" py={3}>
      <Typography className="f-s-20">
        Paso {steps[step - 1].number} de 3
      </Typography>
      <Typography variant="h2" className="m-t-10">
        {steps[step - 1].title}
      </Typography>
      <Box>{steps[step - 1].component()}</Box>
    </Box>
  );
};

export default SignupClientPt2;
