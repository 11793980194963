import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  ICredentialsRapiboyErrors, ICredentialsRapiboyPayload,
} from "../../interfaces/configuration";
import { IValidation } from "../../interfaces/form";
import { ICredentialsModal } from "../../interfaces/modal";
import { ERRORS_MESSAGES } from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import ModalBase from "./ModalBase";
import CredentialsRapiboy from "../forms/CredentialsRapiboy";
import CredentialsDimensions from "./CredentialsDimensions";
import ConfirmChangesCredentials from "../configuration/ConfirmChangesCredentials";
import GenericForm from "../GenericForm";

const {
  cuitRequired,
  cuitInvalid,
  endDateRequired,
  endDateNotInThePast,
  widthRequired,
  heightRequired,
  depthRequired,
  weightRequired,
  widthGreatherThanZero,
  heightGreatherThanZero,
  depthGreatherThanZero,
  weightGreatherThanZero,
  tokenRequired,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "token",
    type: "required",
    message: tokenRequired,
  },
  {
    id: "end_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "end_date",
    type: "future_date",
    message: endDateNotInThePast,
  },
  {
    id: "width",
    type: "required",
    message: widthRequired,
  },
  {
    id: "height",
    type: "required",
    message: heightRequired,
  },
  {
    id: "depth",
    type: "required",
    message: depthRequired,
  },
  {
    id: "weight",
    type: "required",
    message: weightRequired,
  },
  {
    id: "height",
    type: "number",
    message: heightGreatherThanZero,
  },
  {
    id: "width",
    type: "number",
    message: widthGreatherThanZero,
  },
  {
    id: "depth",
    type: "number",
    message: depthGreatherThanZero,
  },
  {
    id: "weight",
    type: "number",
    message: weightGreatherThanZero,
  },
];

const CredentialsRapiboyModal = ({
  open,
  onClose,
  carrier,
  handleChangeOfConfig,
}: ICredentialsModal) => {
  const { dimensions, ...restCredentials } = (carrier.credentials ||
    {}) as ICredentialsRapiboyPayload;
  const initialCredentials = { ...dimensions, ...restCredentials };

  const [values, setValues] = useState(initialCredentials);
  const [errors, setErrors] = useState({} as ICredentialsRapiboyErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(values, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      const { height, width, depth, ...restValues } = values;
      const newDimensions = { height, width, depth };
      const dateExpired = new Date(values.end_date) <= new Date();

      handleChangeOfConfig({
        _id: carrier._id,
        name: carrier.name,
        disabled: carrier.disabled,
        credentials: {
          ...restValues,
          dimensions: newDimensions,
          expired: dateExpired,
          extended_week: !!values.extended_week
        },
      });
      onClose();
    }
  };

  return (
    <ModalBase open={open} handleClose={onClose} maxWidth="md">
      <Grid container>
        <Typography className="f-s-18 m-b-20">
          Ingresa los datos de tu contrato con Rapiboy
        </Typography>

        <GenericForm onSubmit={handleSubmit}>
          <CredentialsRapiboy
            values={values}
            setValues={setValues}
            errors={errors}
          />

          <CredentialsDimensions
            values={values}
            setValues={setValues}
            errors={errors}
          />
        </GenericForm>

        <ConfirmChangesCredentials
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </ModalBase>
  );
};

export default CredentialsRapiboyModal;
