import { IShipmentTrackingStatus } from "../interfaces/tracking";
import { DELIVERED } from "./constants";

export const parseDateToSentence = (date = "") => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
  };

  return new Date(date).toLocaleDateString("es-ES", dateOptions);
};

export const getDeliveredDateFromOrder = (
  status_history: IShipmentTrackingStatus[]
) => {
  const statusHistory = status_history.find(
    ({ status: statusFromHistory }) => statusFromHistory === DELIVERED
  );
  return `Tu pedido llegó el ${parseDateToSentence(statusHistory?.createdAt)}.`;
};
