import { Box, Button, IconButton, Typography } from "@mui/material";
import { IDeleteSelectedNotificationsProps } from "../../interfaces/notifications";

export const DeleteSelectedNotifications = ({
  count,
  handleDeleteNotifications,
  setNotificationsToDelete
}: IDeleteSelectedNotificationsProps) =>
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    className="w-100 h-58 selected-notifications-content">
    <Box
      display="flex"
      justifyContent="space-between"
      className="w-630">
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => setNotificationsToDelete([])}>
          <span className="material-symbols-rounded text-dark">
            close
          </span>
        </IconButton>
        <Typography>
          Notificaciones seleccionadas: {count}
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => handleDeleteNotifications()}>
        Eliminar
      </Button>
    </Box>
  </Box>;
