import React, { ChangeEvent, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ICredentialsOcaProps } from "../../interfaces/configuration";
import { CUIT_PLACEHOLDER } from "../../utils/constants";
import { SimpleFormInput } from "./SimpleFormInput";
import { showInputErrors } from "../../utils/credentials";
import { SimpleDateInput } from "./SimpleDateInput";

const CredentialsOca = ({
  values,
  setValues,
  errors,
}: ICredentialsOcaProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const baseInfo = {
    values,
    setValues,
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <SimpleFormInput
          inputTitle="Número de cuenta"
          placeholder="Ingresar número de cuenta"
          valueName="account_number"
          errors={errors.account_number}
          {...baseInfo}
          autoFocus
        />
      </Grid>
      <Grid item md={6}>
        <SimpleFormInput
          inputTitle="CUIT"
          placeholder={CUIT_PLACEHOLDER}
          valueName="cuit"
          errors={errors.cuit}
          {...baseInfo}
        />
      </Grid>
      <Grid item md={6}>
        <SimpleFormInput
          inputTitle="Correo electrónico"
          placeholder="Ingresar correo electrónico"
          valueName="email"
          errors={errors.email}
          {...baseInfo}
        />
      </Grid>
      <Grid item md={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Contraseña</Typography>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            placeholder="Ingresar contraseña"
            value={values.password}
            error={errors.password?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, password: value.trim() })
            }
            className="f-s-14"
            endAdornment={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <span className="material-symbols-rounded">
                  {showPassword ? "visibility_off" : "visibility"}
                </span>
              </IconButton>
            }
          />
          {errors.password?.length > 0 && showInputErrors(errors.password)}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography className="f-s-20 m-t-10">Tipos de contratos</Typography>
      </Grid>
      <Grid item md={6}>
        <SimpleFormInput
          inputTitle="Código de contrato puerta a puerta estándar"
          placeholder="Ingresar código de contrato puerta a puerta"
          valueName="door_to_door_standard"
          errors={errors.door_to_door_standard}
          {...baseInfo}
        />
      </Grid>
      <Grid item md={6}>
        <SimpleDateInput
          inputTitle="Fecha de expiración"
          valueName="door_to_door_standard_end_date"
          errors={errors.door_to_door_standard_end_date}
          {...baseInfo}
        />
      </Grid>
      <Grid item md={6}>
        <SimpleFormInput
          inputTitle="Código de contrato puerta a puerta prioritario"
          placeholder="Ingresar código de contrato puerta a puerta"
          valueName="door_to_door_priority"
          errors={errors.door_to_door_priority}
          {...baseInfo}
        />
      </Grid>
      <Grid item md={6}>
        <SimpleDateInput
          inputTitle="Fecha de expiración"
          valueName="door_to_door_priority_end_date"
          errors={errors.door_to_door_priority_end_date}
          {...baseInfo}
        />
      </Grid>
      <Grid item md={6}>
        <SimpleFormInput
          inputTitle="Código de contrato de logística inversa"
          placeholder="Ingresar código de logística inversa"
          errors={errors.reverse}
          valueName="reverse"
          {...baseInfo}
        />
      </Grid>
      <Grid item md={6}>
        <SimpleDateInput
          inputTitle="Fecha de expiración"
          valueName="reverse_end_date"
          errors={errors.reverse_end_date}
          {...baseInfo}
        />
      </Grid>
    </Grid>
  );
};

export default CredentialsOca;
