import React, { useState } from "react";
import { Grid } from "@mui/material";
import { IOrderDetails } from "../../interfaces/order";
import MobileLayout from "../MobileLayout";
import OrderDescription from "./OrderDescription";
import OrderPackageDescription from "./OrderPackageDescription";
import { MEDIUM, PARENT_PATH } from "../../utils/constants";
import OrderDetailsTopContent from "./OrderDetailsTopContent";
import Loader from "../Loader";
import { OrderCancelOrReturnReason } from "./OrderCancelOrReturnReason";

const OrderDetailsMobile = ({
  order,
  trackingNumber,
  alert,
  loading,
  navigate,
}: IOrderDetails) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    navigate(PARENT_PATH);
    setOpen(false);
  };
  return (
    <MobileLayout title="Ver orden" open={open} handleClose={handleClose}>
      <Grid container className="p-20">
        {loading ? (
          <Loader size={MEDIUM} />
        ) : (
          <>
            <OrderDetailsTopContent
              alert={alert}
              trackingNumber={trackingNumber}
            />
            {order && !alert.showAlert && (
              <>
                <OrderDescription order={order} isMobile />
                <OrderPackageDescription packages={order.orderInfo.packages} isMobile />
                <OrderCancelOrReturnReason order={order} isMobile/>
              </>
            )}
          </>
        )}
      </Grid>
    </MobileLayout>
  );
};

export default OrderDetailsMobile;
