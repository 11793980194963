import {
  Select,
  MenuItem,
  FormControl,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import {
  IConfigurationRule,
  ISelectRulesComponent,
} from "../../interfaces/configuration";
import {
  CONFIGURATION_PERMISSION,
  MARKETPLACE,
  MARKETPLACE_MERCHANT,
  RULES_OPTIONS,
  SELECT_RESPONSIBLE,
} from "../../utils/constants";
import { IDynamicObjectReturnString } from "../../interfaces/common";
import { usePermission } from "../../customHooks/usePermission";

/* El back nos devuelve el valor de merchantCanDisableCarriers como un booleano, pero nosotros lo parseamos a una string en el front
   Esta función nos permite interpretar el valor sin importar si lo recibimos del back o lo modificamos usando el select */
const getMerchantCanDisableCarriersValue = ({
  merchantCanDisableCarriers,
}: any) => {
  switch (merchantCanDisableCarriers) {
  case MARKETPLACE_MERCHANT:
  case true:
    return MARKETPLACE_MERCHANT;
  case MARKETPLACE:
  case false:
    return MARKETPLACE;
  default:
    return SELECT_RESPONSIBLE;
  }
};

const RuleList = ({
  selectedRules,
  handleRuleChange,
}: ISelectRulesComponent) => {
  const { can_write } = usePermission(CONFIGURATION_PERMISSION);

  const parsedSelectedRules: IDynamicObjectReturnString = {
    ...selectedRules,
    merchantCanDisableCarriers:
      getMerchantCanDisableCarriersValue(selectedRules),
  };

  return (
    <>
      {RULES_OPTIONS.map(({ name, title, options }, index) => (
        <FormControl size="small" fullWidth>
          <Typography className="m-b-10">{title}</Typography>
          <Select
            size="small"
            disabled={!can_write}
            className={`f-s-14  bg-input ${
              index === RULES_OPTIONS.length - 1 ? "" : "m-b-20"
            }`}
            value={parsedSelectedRules[name] || SELECT_RESPONSIBLE}
            onChange={({ target: { value } }: SelectChangeEvent) =>
              handleRuleChange(name, value)
            }
          >
            <MenuItem
              disabled
              key={SELECT_RESPONSIBLE}
              value={SELECT_RESPONSIBLE}
              className="f-s-14"
            >
              {SELECT_RESPONSIBLE}
            </MenuItem>
            {options.map(({ id, text }: IConfigurationRule) => (
              <MenuItem key={id} value={id} className="f-s-14">
                {text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
    </>
  );
};

export default RuleList;
