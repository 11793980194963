import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import ListHeader from "./ListHeader";
import { IExportTable, IExportRequestWithMerchant } from "../../interfaces/order";
import { downloadFromURL, mongoTimeStampToString, translateOrderStatus } from "../../utils/orders";
import { MARKETPLACE } from "../../utils/constants";

const tableColumnsMarketplace = [
  { label: "Fecha de creación" },
  { label: "Desde" },
  { label: "Hasta" },
  { label: "Operador Logístico" },
  { label: "Merchant" },
  { label: "Estado" },
  { label: "Acciones" },
];

const tableColumns = [
  { label: "Fecha de creación" },
  { label: "Desde" },
  { label: "Hasta" },
  { label: "Operador Logístico" },
  { label: "Estado" },
  { label: "Acciones" },
];

const ExportOrdersTable = ({ requests, entity }: IExportTable) => (
  <TableContainer component={Paper} className="m-t-40 br-20 bg-light">
    <Table>
      <ListHeader
        columns={
          entity === MARKETPLACE ? tableColumnsMarketplace : tableColumns
        }
      />
      <TableBody>
        {requests.map(
          (
            { from, to, carrier, path, merchant, createdAt, status, status_filter }: IExportRequestWithMerchant,
            index: number
          ) => (
            <TableRow key={index}>
              <TableCell className="p-l-20">{mongoTimeStampToString(createdAt, true)}</TableCell>
              <TableCell>{mongoTimeStampToString(from, true)}</TableCell>
              <TableCell>{mongoTimeStampToString(to, true)}</TableCell>
              <TableCell>{carrier?.name || "Todos"}</TableCell>
              {entity === MARKETPLACE && (
                <TableCell>{merchant?.name || "Todos"}</TableCell>
              )}
              <TableCell>{translateOrderStatus(status_filter) || "Todos los estados"}</TableCell>
              <TableCell>
                {path ? (
                  <Tooltip arrow title="Descargar" placement="top">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => downloadFromURL(path)}
                    >
                      <span className="material-symbols-rounded">download</span>
                    </IconButton>
                  </Tooltip>
                ) : (
                  status
                )}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ExportOrdersTable;
