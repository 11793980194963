import React from "react";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { IPlanComponentProps } from "../../interfaces/signup";
import { FREE_PLAN_SIGNUP_PATH } from "../../utils/constants";

const Plan = ({
  id,
  title,
  value,
  totalOrders,
  handlePlanSelect,
}: IPlanComponentProps) => (
  <Grid item xs={12} className="plan-card-container m-b-10">
    <Card className="br-10 text-center">
      <CardContent>
        <Typography className="f-s-20 f-s-md-36">{title}</Typography>
        <Typography className="f-s-42 f-s-md-60 color-primary">
          $ {value}
        </Typography>
        <Typography className="f-s-12">USD mensual + impuestos</Typography>
        <Typography className="f-s-16 f-s-md-20 text-bold m-t-40">
          {totalOrders} envíos por mes
        </Typography>
        <Button
          variant="contained"
          fullWidth
          className="m-t-40 f-s-14"
          href={
            handlePlanSelect
              ? undefined
              : `${FREE_PLAN_SIGNUP_PATH}${id}`
          }
          onClick={handlePlanSelect}
        >
          Elegir plan
        </Button>
      </CardContent>
    </Card>
  </Grid>
);

export default Plan;
