import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import GenericAlert from "../../components/GenericAlert";
import ExportFilters from "../../components/orders/ExportFilters";
import ExportOrdersTable from "../../components/tables/ExportOrdersTable";
import { UserContext } from "../../contexts/userContext";
import { IAlert } from "../../interfaces/alert";
import { ICarrier } from "../../interfaces/configuration";
import { IValidation } from "../../interfaces/form";
import {
  IExportOrdersValues,
  IExportOrdersErrors,
  IExportRequest,
} from "../../interfaces/order";
import {
  ERROR,
  ERRORS_MESSAGES,
  ERROR_FROM_GREATER_THAN_TO,
  GET,
  INFO,
  MEDIUM,
  NO_LABELS_MESSAGE,
  POST,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { validateInputs } from "../../utils/inputValidations";
import Loader from "../../components/Loader";

const { fromDateRequired, toDateRequired, carrierRequired } = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "carrier",
    type: "required",
    message: carrierRequired,
  },
  {
    id: "fromDate",
    type: "required",
    message: fromDateRequired,
  },
  {
    id: "toDate",
    type: "required",
    message: toDateRequired,
  },
  {
    id: "fromDate",
    type: "compare-dates",
    message: ERROR_FROM_GREATER_THAN_TO,
    param: "toDate",
  },
];

const ExportLabels = () => {
  const { selectedMarketplace, currentUser } = useContext(UserContext);
  const [values, setValues] = useState({} as IExportOrdersValues);
  const [requests, setRequests] = useState([] as IExportRequest[]);
  const [errors, setErrors] = useState({} as IExportOrdersErrors);
  const [alert, setAlert] = useState({} as IAlert);
  const [tableAlert, setTableAlert] = useState({} as IAlert);
  const [carriers, setCarriers] = useState<ICarrier[]>([]);
  const [loading, setLoading] = useState(true);

  const carriersEndpointsByEntity: any = {
    merchant: `carriers_merchants?marketplaceId=${selectedMarketplace}`,
    ecommerce: "carriers_clients",
  };
  const getCarriers = async () => {
    try {
      const carriersResponse = await fetchData({
        url: carriersEndpointsByEntity[currentUser.entity],
        method: GET,
      });
      setCarriers(carriersResponse);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };

  const getExportsFiles = async () => {
    setLoading(true);
    try {
      const results = await fetchData({
        url: `/orders/files?type=label${
          selectedMarketplace && `&marketplaceId=${selectedMarketplace}`
        }`,
        method: GET,
      });
      if (results.length === 0)
        setTableAlert({
          typeOfAlert: INFO,
          message: "No hay archivos para descargar.",
          showAlert: true,
        });
      setRequests(results);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const inputErrors = validateInputs(values, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      setTableAlert({
        typeOfAlert: INFO,
        message: "El archivo se está procesando.",
        showAlert: true,
      });
      try {
        const result = await fetchData({
          url: "/orders/labels",
          method: POST,
          body: { ...values, marketplaceId: selectedMarketplace || undefined },
        });
        const noLabelAlert: IAlert = {
          typeOfAlert: INFO,
          message: NO_LABELS_MESSAGE,
          showAlert: true,
        };
        setTableAlert(
          result === NO_LABELS_MESSAGE ? noLabelAlert : ({} as IAlert)
        );
        setValues({} as IExportOrdersValues);
      } catch (error: any) {
        setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      } finally {
        getExportsFiles();
      }
    }
  };

  useEffect(() => {
    getExportsFiles();
    getCarriers();
    setAlert({} as IAlert);
    setTableAlert({} as IAlert);
  }, [selectedMarketplace]);

  return (
    <>
      {loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            md={7}
            display="flex"
            alignItems="center"
            justifyContent="start"
          >
            <Typography className="f-s-20 text-dark" ml={1}>
              Descarga masiva de etiquetas
            </Typography>
          </Grid>
          <ExportFilters
            values={values}
            setValues={setValues}
            carriers={carriers}
            setErrors={setErrors}
            handleSubmit={handleSubmit}
            errors={errors}
            showstatusFilter={false}
          />

          {alert.showAlert && (
            <Grid item xs={12} className="m-t-10">
              <GenericAlert
                alert={alert}
                handleActionAlert={() =>
                  setAlert({ showAlert: false } as IAlert)
                }
              />
            </Grid>
          )}
          {tableAlert.showAlert && (
            <Grid item xs={12} className="m-t-10">
              <GenericAlert
                alert={tableAlert}
                handleActionAlert={() =>
                  setTableAlert({ showAlert: false } as IAlert)
                }
              />
            </Grid>
          )}

          {requests.length > 0 && (
            <ExportOrdersTable
              requests={requests}
              entity={currentUser.entity}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default ExportLabels;
