import React, { useContext, useEffect, useState } from "react";
import { fetchData } from "../../utils/dataProvider";
import {
  GET,
  INFO,
  MEDIUM,
  MERCHANT,
  ROWS_PER_PAGE,
} from "../../utils/constants";
import { Grid } from "@mui/material";
import Loader from "../../components/Loader";
import ListQuoteAudits from "../../components/tables/ListQuoteAudits";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../../components/GenericAlert";
import { UserContext } from "../../contexts/userContext";
import { IQuoteAudit } from "../../interfaces/order";
import { IPaginationParams } from "../../interfaces/table";

const QuotesAudits = () => {
  const {
    currentUser: { entity },
    selectedMarketplace,
  } = useContext(UserContext);
  const [audits, setAudits] = useState<IQuoteAudit[]>([]);
  const [alert, setAlert] = useState<IAlert>({} as IAlert);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
  } as IPaginationParams);

  const getQuoteAudits = async (newPage = 0) => {
    setLoading(true);
    const skip = newPage * ROWS_PER_PAGE;
    const useMarketplaceid =
      entity === MERCHANT ? `&marketplaceId=${selectedMarketplace}` : "";
    try {
      const {
        data: { count, audits: fetchAudits },
      } = await fetchData({
        url: `/orders/audit/quotes?limit=${ROWS_PER_PAGE}&skip=${skip}${useMarketplaceid}`,
        method: GET,
      });
      setAudits(fetchAudits);
      setPagination({ count, page: newPage });
      if (fetchAudits.length === 0)
        setAlert({
          typeOfAlert: INFO,
          message: "No hay cotizaciones para mostrar.",
          showAlert: true,
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => getQuoteAudits(newPage);

  useEffect(() => {
    getQuoteAudits();
  }, []);

  return (
    <Grid container>
      {loading ? (
        <Grid container justifyContent="center">
          <Loader size={MEDIUM} />
        </Grid>
      ) : (
        <>
          {alert.showAlert && (
            <Grid item xs={12}>
              <GenericAlert alert={alert} />
            </Grid>
          )}
          {audits && !alert.showAlert && (
            <ListQuoteAudits
              audits={audits}
              pagination={pagination}
              handlePageChange={handlePageChange}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default QuotesAudits;
