import React, { useContext } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { mongoTimeStampToString } from "../../utils/orders";
import { UserContext } from "../../contexts/userContext";
import "../../styles/subscription.css";
import { useWindowSize } from "../../customHooks/useWindowSize";
import { MEDIUM_BREAKPOINT } from "../../utils/constants";

const SubscriptionInfo = () => {
  const { currentUser } = useContext(UserContext);
  const { width } = useWindowSize();
  return (
    <>
      <Grid
        item
        container
        xs={12}
        md={7}
        justifyContent="space-between"
        alignItems="center"
        className="m-t-30"
      >
        <Typography className="f-s-20 text-dark">
          Información del plan
        </Typography>
      </Grid>
      <Grid item xs={12} md={7} className="m-t-20">
        <Card className="br-20 bg-light">
          <CardContent sx={{ padding: "0px" }} className="card-plans">
            <Box>
              <Typography className="color-primary text-bold f-s-24">
                Prueba gratuita
              </Typography>
              <Typography className="text-dark f-s-14">
                Duración 3 meses
              </Typography>
            </Box>
            {currentUser.planEndDate && (
              <Typography
                display="flex"
                alignItems="center"
                justifyContent={width > MEDIUM_BREAKPOINT ? "end" : "start"}
                className="text-dark f-s-14"
              >
                Vence el &nbsp;
                {mongoTimeStampToString(String(currentUser.planEndDate), true)}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default SubscriptionInfo;
