import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  IShipmentTrackingComponent,
  IShipmentTrackingStatus,
} from "../../interfaces/tracking";
import { DELIVERED } from "../../utils/constants";
import { getOrderStatus, mongoTimeStampToString } from "../../utils/orders";
import { getDeliveredDateFromOrder } from "../../utils/tracking";
import ListHeader from "./ListHeader";

const ShipmentTrackingTable = ({
  statusHistory,
}: IShipmentTrackingComponent) => (
  <Grid container item marginTop={6}>
    {statusHistory && (
      <TableContainer component={Paper} className="m-t-20 br-10 bg-white">
        <Table>
          <ListHeader
            columns={[
              { label: "Fecha y hora" },
              { label: "Estado" },
              { label: "Descripción" },
            ]}
          />
          <TableBody>
            {statusHistory.map(
              ({
                createdAt: statusDate,
                status,
                alias,
              }: IShipmentTrackingStatus) => {
                const orderStatus = getOrderStatus(status);
                return (
                  <TableRow key={statusDate}>
                    <TableCell className="p-l-20">
                      {mongoTimeStampToString(statusDate)}
                    </TableCell>
                    <TableCell>{alias || orderStatus?.label}</TableCell>
                    <TableCell>
                      {status === DELIVERED
                        ? getDeliveredDateFromOrder(statusHistory)
                        : orderStatus?.description}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )}
  </Grid>
);

export default ShipmentTrackingTable;
