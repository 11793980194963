import { Grid, Typography } from "@mui/material";
import React from "react";
import { IOrderTopContent } from "../../interfaces/order";
import GenericAlert from "../GenericAlert";

const OrderDetailsTopContent = ({
  alert,
  trackingNumber,
}: IOrderTopContent) => (
  <>
    <Grid
      item
      xs={12}
      md={7}
      justifyContent="space-between"
      alignItems="center"
      className="m-t-20"
    >
      <Typography className="f-s-20 text-dark">
        N° de guía {trackingNumber}
      </Typography>
    </Grid>
    {alert.showAlert && (
      <Grid item xs={12}>
        <GenericAlert alert={alert} />
      </Grid>
    )}
  </>
);

export default OrderDetailsTopContent;
