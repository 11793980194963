import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import ListHeader from "./ListHeader";
import { capitalizeOnlyFirstLetter } from "../../utils/orders";
import { IListRoles, IRole } from "../../interfaces/usersRoles";

const COLUMNS = [
  { label: "Roles" },
  { label: "Descripción" },
  { label: "Usuarios" },
];

const ListRoles = ({ roles }: IListRoles) => (
  <TableContainer component={Paper} className="m-t-20 br-10 bg-light">
    <Table>
      <ListHeader columns={COLUMNS} />
      <TableBody>
        {roles?.map(({ name, description, users }: IRole) => (
          <TableRow key={name}>
            <TableCell className="p-l-20">{name}</TableCell>
            <TableCell>{capitalizeOnlyFirstLetter(description)}</TableCell>
            <TableCell>{users.join(", ")}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ListRoles;
