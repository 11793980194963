import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Box } from "@mui/material";
import { INotificationRowProps } from "../../interfaces/notifications";
import {
  RED_DOT_URL,
  messageTypeIconEquivalencies,
} from "../../utils/constants";
import {
  getHowLongCreationWas,
  getIconUrl,
  handleRedirect,
} from "../../utils/notifications";
import { UserContext } from "../../contexts/userContext";

export const NotificationRow = ({
  setNotificationsToDelete,
  notificationsToDelete,
  notification: {
    _id,
    createdAt,
    message,
    redirect_url,
    read_at,
    message_type,
    marketplace_id,
  },
}: INotificationRowProps) => {
  const {
    setSelectedMarketplace,
    merchantIntegrations,
    currentUser: { entity },
  } = useContext(UserContext);
  const navigate = useNavigate();
  const isChecked = notificationsToDelete.includes(_id);
  const notificationBackgroundColor = read_at ? "#EDEDED" : "transparent";
  return (
    <Box
      display="flex"
      justifyContent="center"
      bgcolor={isChecked ? "#E327281A" : notificationBackgroundColor}
      className="w-100"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={`w-90 notification-list-row ${
          redirect_url ? "pointer" : ""
        }`}
        onClick={() => {
          if (redirect_url) {
            handleRedirect({
              notificationInfo: { redirect_url, marketplace_id },
              setSelectedMarketplace,
              merchantIntegrations,
              navigate,
              entity,
            });
          }
        }}
      >
        <Box display="flex" justifyContent="start" width="8px">
          {read_at ? "" : <img width="100%" src={RED_DOT_URL} />}
        </Box>
        <Box display="flex" padding="0px 8px">
          <img
            width="30px"
            className="notification-icon"
            src={getIconUrl(messageTypeIconEquivalencies[message_type])}
            alt="icono"
          />
        </Box>
        <Box width="103px" className="text-muted f-s-14">
          {getHowLongCreationWas(new Date(createdAt), new Date())}
        </Box>
        <Box className="f-s-14 notification-message">{message}</Box>
        <Box justifyContent="flex-start" className="d-flex">
          <Checkbox
            size="medium"
            color="primary"
            checked={notificationsToDelete.includes(_id)}
            onChange={({ target: { checked } }) =>
              setNotificationsToDelete(
                checked
                  ? notificationsToDelete.concat(_id)
                  : notificationsToDelete.filter((id) => id !== _id)
              )
            }
            onClick={(event) => event.stopPropagation()}
          />
        </Box>
      </Box>
    </Box>
  );
};
