import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  ICredentialsOcasa,
  ICredentialsOcasaErrors,
  ICredentialsStep,
} from "../../../interfaces/configuration";
import ConfirmChangesCredentials from "../ConfirmChangesCredentials";
import { ERRORS_MESSAGES } from "../../../utils/constants";
import { validateInputs } from "../../../utils/inputValidations";
import { IValidation } from "../../../interfaces/form";
import CredentialsOcasa from "../../forms/CredentialsOcasa";
import GenericForm from "../../GenericForm";
import GenericAlert from "../../GenericAlert";

const {
  userRequired,
  passwordRequired,
  clientCodeRequired,
  cuitRequired,
  cuitInvalid,
  doorToDoorEndDateRequired,
  reverseLogisticsEndDateRequired,
  doorToDoorEndDateNotInThePast,
  reverseLogisticsEndDateNotInThePast,
  reverseOnlyNumbers,
  doorToDoorOnlyNumbers,
  doorToDoorRequired,
  widthRequired,
  heightRequired,
  depthRequired,
  weightRequired,
  widthGreatherThanZero,
  heightGreatherThanZero,
  depthGreatherThanZero,
  weightGreatherThanZero,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "user",
    type: "required",
    message: userRequired,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "client_code",
    type: "required",
    message: clientCodeRequired,
  },
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "door_to_door",
    type: "required",
    message: doorToDoorRequired,
  },
  {
    id: "door_to_door",
    type: "only-numbers",
    message: doorToDoorOnlyNumbers,
  },
  {
    id: "reverse",
    type: "required",
    message: reverseOnlyNumbers,
  },
  {
    id: "reverse",
    type: "only-numbers",
    message: reverseOnlyNumbers,
  },
  {
    id: "reverse_end_date",
    type: "required",
    message: reverseLogisticsEndDateRequired,
  },
  {
    id: "reverse_end_date",
    type: "future_date",
    message: reverseLogisticsEndDateNotInThePast,
  },
  {
    id: "door_to_door_end_date",
    type: "required",
    message: doorToDoorEndDateRequired,
  },
  {
    id: "door_to_door_end_date",
    type: "future_date",
    message: doorToDoorEndDateNotInThePast,
  },
  {
    id: "width",
    type: "required",
    message: widthRequired,
  },
  {
    id: "height",
    type: "required",
    message: heightRequired,
  },
  {
    id: "depth",
    type: "required",
    message: depthRequired,
  },
  {
    id: "weight",
    type: "required",
    message: weightRequired,
  },
  {
    id: "height",
    type: "number",
    message: heightGreatherThanZero,
  },
  {
    id: "width",
    type: "number",
    message: widthGreatherThanZero,
  },
  {
    id: "depth",
    type: "number",
    message: depthGreatherThanZero,
  },
  {
    id: "weight",
    type: "number",
    message: weightGreatherThanZero,
  },
];

const OcasaCredentials = ({
  handleCancel,
  loading,
  alert,
  carrier,
  handleSubmitCarriers,
}: ICredentialsStep) => {
  const [credentials, setCredentials] = useState({} as ICredentialsOcasa);
  const [errors, setErrors] = useState({} as ICredentialsOcasaErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(credentials, formValidations);

    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      const d2dExpired =
        new Date(credentials.door_to_door_end_date) <= new Date();
      const rlExpired = !!(
        credentials.reverse &&
        new Date(credentials.reverse_end_date) <= new Date()
      );

      const { height, width, depth, ...restCredentials } = credentials;
      const newDimensions = { height, width, depth };
      const newCredentials = {
        ...restCredentials,
        dimensions: newDimensions,
        expired: d2dExpired || rlExpired,
      };
      handleSubmitCarriers([{
        ...carrier,
        credentials: newCredentials,
      }]);
    }
  };

  return (
    <>
      <Typography className="f-s-24 m-b-20">Credenciales de OCASA</Typography>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm onSubmit={handleSubmit}>
        <CredentialsOcasa
          values={credentials}
          setValues={setCredentials}
          errors={errors}
        />
      </GenericForm>
      <ConfirmChangesCredentials
        onClose={handleCancel}
        handleSubmit={handleSubmit}
        disabled={loading}
        backText="Cambiar operador"
        confirmText="Finalizar"
      />
    </>
  );
};

export default OcasaCredentials;
