import React from "react";
import {
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import { ADMIN_ROLE_NAME, OWNER_ROLE_NAME } from "../../utils/constants";
import { IRole } from "../../interfaces/usersRoles";

const ListUsersActions = ({
  handleSelectedAction,
  user: { id, name, email, roles, disabled }, // IUser
  createdUser, // boolean
  loggedUserIsOwner
}: any) => {
  const hideActions = !loggedUserIsOwner &&
    roles.find(({ name: roleName }: IRole) => [OWNER_ROLE_NAME, ADMIN_ROLE_NAME].includes(roleName));

  if (hideActions) return <></>;

  return (<>
    {createdUser && <Switch
      size="small"
      onClick={() =>
        handleSelectedAction("disable", { id, name, disabled: !disabled })
      }
      checked={!disabled}
    />}
    {createdUser && <Tooltip arrow title="Editar" placement="top">
      <IconButton
        size="small"
        color="primary"
        onClick={() =>
          handleSelectedAction("edit", {
            id,
            name,
            email,
            roles,
          })
        }
      >
        <span className="material-symbols-rounded">
          edit
        </span>
      </IconButton>
    </Tooltip>}
    <Tooltip arrow title="Eliminar" placement="top">
      <IconButton
        size="small"
        color="primary"
        onClick={() => {
          handleSelectedAction("delete", { id, name });
        }}
      >
        <span className="material-symbols-rounded">
          delete
        </span>
      </IconButton>
    </Tooltip>
  </>);
};

export default ListUsersActions;
