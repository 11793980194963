import React, { useEffect, useState } from "react";
import GenericAlert from "../../components/GenericAlert";
import ListInvitations from "../../components/tables/ListInvitations";
import { IAlert } from "../../interfaces/alert";
import {
  ISelectedInvitation,
  IGetInvitationsParams,
  IInvitationsProps,
  IAcceptInvitation,
  IMappedInfo,
} from "../../interfaces/invitations";
import {
  ERROR,
  GET,
  PATCH,
  INFO,
  MARKETPLACE,
  ROWS_PER_PAGE,
  SUCCESS,
  MEDIUM,
  SUCCESS_ALERT_DURATION,
  INTERNAL,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { IIntegrationResponse } from "../../interfaces/integrations";
import { IPaginationParams } from "../../interfaces/table";
import SendInvitationModal from "../../components/modals/SendInvitationModal";
import HeadOptions from "../../components/tables/HeadOptions";
import CancelInvitationModal from "../../components/modals/CancelInvitationModal";
import { IValuesByEntity } from "../../interfaces/common";
import Loader from "../../components/Loader";

const urlByEntity: IValuesByEntity = {
  marketplace: "/marketplaces",
  merchant: "/merchants",
};

const messageByEntity: IValuesByEntity = {
  marketplace: "No hay invitaciones creadas.",
  merchant: "No tienes invitaciones pendientes.",
  internal: "No hay invitaciones pendientes.",
};

const getMappedInfo = ({
  integrations,
  entity,
}: {
  integrations: IMappedInfo[];
  entity: string;
}): ISelectedInvitation[] => {
  if (entity === MARKETPLACE)
    return integrations.map(
      ({
        id,
        status,
        invitation: { id: invitationID, email },
      }: IIntegrationResponse) => ({
        id,
        status,
        email,
        invitationID,
      })
    );

  return integrations.map(
    ({
      id,
      invitation: { id: invitationID },
      marketplace: {
        client: {
          // TODO email should be one of the owner / superadmin
          entity: { name, email },
        },
      },
    }: IMappedInfo) => ({
      id,
      name,
      email,
      invitationID,
    })
  );
};

const acceptInvitation = async ({
  row: { name, invitationID },
  merchantId,
  setAlert,
  updateTable,
  setUpdateTable,
  getIntegrationsOfMerchant,
}: IAcceptInvitation) => {
  try {
    if (merchantId) {
      await fetchData({
        url: `/merchants/invitation/acept/id/${invitationID}`,
        method: PATCH,
        body: { merchantId },
      });

      setAlert({
        typeOfAlert: SUCCESS,
        message: `¡Felicidades! Haz añadido a ${name} con éxito.`,
        showAlert: true,
      });
      setUpdateTable(!updateTable);
      getIntegrationsOfMerchant();
    }
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  }
};

const getInvitations = async ({
  entity,
  setInvitations,
  setAlert,
  setPagination,
  newPage,
  url,
  setLoading = () => undefined,
}: IGetInvitationsParams) => {
  const page = newPage || 0;
  setLoading(true);
  try {
    const { count, integrations } = await fetchData({
      url,
      method: GET,
    });
    if (integrations.length > 0) {
      setInvitations(getMappedInfo({ integrations, entity }));
      setPagination({ count, page });
    } else setInvitations([]);
    setAlert({} as IAlert);
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  } finally {
    setLoading(false);
  }
};

const getInvitedClients = async ({
  setInvitations,
  setPagination,
  newPage,
  setLoading,
  setAlert,
}: any) => {
  try {
    const page = newPage || 0;
    setLoading(true);
    const { count, clients } = await fetchData({
      method: GET,
      url: `/internal/clients/pending/?skip=${newPage}&limit=${ROWS_PER_PAGE}`,
    });
    if (clients.length > 0) {
      setInvitations(clients);
      setPagination({ count, page });
    } else setInvitations([]);
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  } finally {
    setLoading(false);
  }
};

const Invitations = ({
  entity,
  merchantId,
  getIntegrationsOfMerchant,
}: IInvitationsProps) => {
  const [invitations, setInvitations] = useState([] as ISelectedInvitation[]);
  const [selectedInvitation, setSelectedInvitation] = useState(
    {} as ISelectedInvitation
  );
  const [alert, setAlert] = useState({} as IAlert);
  const [updateTable, setUpdateTable] = useState(false);
  const [openModalInvitation, setOpenModalInvitation] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [pagination, setPagination] = useState({} as IPaginationParams);
  const [loading, setLoading] = useState(true);
  const isInternalUser = entity === INTERNAL;
  const handlePageChange = (newPage: number) => {
    if (isInternalUser)
      getInvitedClients({
        setInvitations,
        setPagination,
        newPage,
        setLoading,
        setAlert,
      });
    else
      getInvitations({
        entity,
        setInvitations,
        setAlert,
        setPagination,
        newPage,
        url: `${urlByEntity[entity]}/integrations?limit=${ROWS_PER_PAGE}&skip=${newPage}`,
        setLoading,
      });
  };
  // uso row de esta forma porque selectedInvitation no traia nada, para investigar luego
  const handleSelectedAction = (option: string, row: ISelectedInvitation) =>
    option === "accept"
      ? acceptInvitation({
        row,
        merchantId,
        setAlert,
        updateTable,
        setUpdateTable,
        getIntegrationsOfMerchant,
      })
      : setOpenCancelModal(true);
  useEffect(() => {
    const newPage = pagination.page || 0;
    if (isInternalUser)
      getInvitedClients({
        setInvitations,
        setPagination,
        newPage,
        setLoading,
        setAlert,
      });
    else
      getInvitations({
        entity,
        setInvitations,
        setAlert,
        setPagination,
        url: `${urlByEntity[entity]}/integrations?limit=${ROWS_PER_PAGE}&skip=${newPage}`,
        setLoading,
      });
  }, [updateTable]);

  useEffect(() => {
    if (alert.showAlert && alert.typeOfAlert === SUCCESS)
      setTimeout(() => setAlert({} as IAlert), SUCCESS_ALERT_DURATION);
  }, [alert]);

  return (
    <>
      {loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <>
          {entity === MARKETPLACE || isInternalUser ? (
            <HeadOptions
              setOpenModal={setOpenModalInvitation}
              invitationsOrIntegrations={invitations}
              setInvitationsOrIntegrations={setInvitations}
              getInvitationsOrIntegrations={getInvitations}
              setPagination={setPagination}
              getMappedInfo={getMappedInfo}
              getInvitationOrIntegrationArguments={{
                entity,
                setInvitations,
                setAlert,
                setPagination,
              }}
              invitationOrIntegration="invitation"
              entity={entity}
              page={pagination.page}
            />
          ) : (
            <HeadOptions
              entity={entity}
              invitationOrIntegration="invitation"
              invitationsOrIntegrations={invitations}
              setInvitationsOrIntegrations={setInvitations}
              getInvitationsOrIntegrations={getInvitations}
              getInvitationOrIntegrationArguments={{
                entity,
                setInvitations,
                setAlert,
                setPagination,
              }}
              setPagination={setPagination}
              getMappedInfo={getMappedInfo}
              page={pagination.page}
            />
          )}
          {alert.showAlert && <GenericAlert alert={alert} />}
          {!(alert.typeOfAlert === ERROR && alert.showAlert) &&
            invitations.length === 0 && (
            <GenericAlert
              alert={{
                typeOfAlert: INFO,
                message: messageByEntity[entity],
                showAlert: true,
              }}
            />
          )}
          {invitations.length > 0 && (
            <ListInvitations
              invitations={invitations}
              setSelectedInvitation={setSelectedInvitation}
              handleSelectedAction={handleSelectedAction}
              handlePageChange={handlePageChange}
              pagination={pagination}
              entity={entity}
            />
          )}
        </>
      )}

      <SendInvitationModal
        open={openModalInvitation}
        setOpen={setOpenModalInvitation}
        setUpdateTable={setUpdateTable}
        updateTable={updateTable}
        setAlert={setAlert}
        isInternalUser={isInternalUser}
      />
      <CancelInvitationModal
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        integration={selectedInvitation}
        updateTable={updateTable}
        setUpdateTable={setUpdateTable}
        entity={entity}
        setAlert={setAlert}
      />
    </>
  );
};

export default Invitations;
