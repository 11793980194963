import { useContext, useState } from "react";
import {
  FormControl,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  Card,
  CardContent,
} from "@mui/material";
import {
  IInitialConfigStepWithNextButton,
  TContractOwner,
} from "../../interfaces/configuration";
import { CONTRACT_OWNER_OPTIONS, ERROR } from "../../utils/constants";
import { UserContext } from "../../contexts/userContext";
import { patchContractOwner } from "../../requests/configurationRequests";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import { LoadingButton } from "@mui/lab";

const ContractOwner = ({ handleNext }: IInitialConfigStepWithNextButton) => {
  const { refreshUserData } = useContext(UserContext);
  const [contractOwner, setContractOwner] = useState("" as TContractOwner);
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await patchContractOwner(contractOwner);
      refreshUserData();
      handleNext();
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item xs={12} lg={6} xl={5.5}>
      <Card className="initial-config-step-card bg-input br-10">
        <CardContent>
          <Typography className="f-s-24 m-b-10">Contratos</Typography>
          <Typography className="m-b-20 f-s-14">
            Selecciona la entidad responsable de los contratos logísticos. Ten
            en cuenta que esta elección será permanente y no podrá ser
            modificada en el futuro.
          </Typography>

          {alert.showAlert && <GenericAlert alert={alert} />}

          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            className="m-t-20 m-b-50"
          >
            <Typography className="f-s-14 m-b-10">
              ¿Quién carga los contratos?
            </Typography>
            <FormControl size="small">
              <InputLabel
                disableAnimation
                shrink={false}
                className="f-s-14"
                sx={{ color: "var(--text-dark) !important" }}
              >
                {!contractOwner && "Seleccionar responsable"}
              </InputLabel>
              <Select
                value={contractOwner}
                onChange={({ target: { value } }: SelectChangeEvent) =>
                  setContractOwner(value as TContractOwner)
                }
                className="f-s-14"
              >
                {CONTRACT_OWNER_OPTIONS.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={!contractOwner || loading}
              loading={loading}
              loadingPosition="end"
            >
              Continuar
            </LoadingButton>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ContractOwner;
