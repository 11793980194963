import { IValuesByEntity } from "../interfaces/common";
import { IPostCarriersChanges } from "../interfaces/configuration";
import { IGetCarriers } from "../interfaces/requests";
import { ECOMMERCE, GET, INTERNAL, MARKETPLACE, MERCHANT, POST } from "../utils/constants";
import { fetchData } from "../utils/dataProvider";

export const getCarriers = ({
  entity,
  selectedMarketplace = "",
  urlQueryParams = "",
}: IGetCarriers) => {
  const carriersEndpointsByEntity: IValuesByEntity = {
    merchant: `carriers_merchants?marketplaceId=${selectedMarketplace}${urlQueryParams}`,
    marketplace: `carriers_clients${urlQueryParams}`,
    ecommerce: `carriers_clients${urlQueryParams}`,
    internal: `internal/carriers${urlQueryParams}`
  };
  return fetchData({
    url: carriersEndpointsByEntity[entity],
    method: GET,
  });
};

export const postCarriersChanges = ({
  formData, // carriersToUpdate,
  entity,
  selectedMarketplace,
}: IPostCarriersChanges) => {

  const requestByEntity = {
    [MARKETPLACE]: "carriers_clients/integrateCarriers",
    [ECOMMERCE]: "carriers_clients/integrateCarriers",
    [MERCHANT]: `carriers_merchants/carriers/${selectedMarketplace}`,
    [INTERNAL]: "internal/carriers"
  };

  const request = requestByEntity[entity];

  return fetchData({
    url: request,
    method: POST,
    body: formData,
    headers: { "Content-Type": "multipart/form-data" }
  });
};
