import React, { useContext, useEffect } from "react";
import { AppBar, Toolbar } from "@mui/material";
import "../../styles/navbar.css";
import { INavbar } from "../../interfaces/navbar";
import { UserContext } from "../../contexts/userContext";
import {
  getLocalMarketplaceId,
  // getSelectedMarketplaceName,
  storeData,
} from "../../utils/storage";
import NavbarDesktop from "./NavbarDesktop";
// import NavbarMobile from "./NavbarMobile";
// import { useWindowSize } from "../../customHooks/useWindowSize";
import { CURRENT_MARKETPLACE, MARKETPLACE_NAME } from "../../utils/constants";
import { NotificationsContext } from "../../contexts/notificationContext";

const Navbar = ({ showContent, toggleSidebar }: INavbar) => {
  const { merchantIntegrations, setSelectedMarketplace } =
    useContext(UserContext);
  const { getBanners } = useContext(NotificationsContext);
  const currentMarketplace = getLocalMarketplaceId();
  // const { width } = useWindowSize();

  const handleChange = (marketplaceId: string) => {
    const [{ name: marketplaceName }] = merchantIntegrations.filter(
      ({ id }) => id === Number(marketplaceId)
    );
    setSelectedMarketplace(String(marketplaceId));
    storeData(CURRENT_MARKETPLACE, marketplaceId);
    storeData(MARKETPLACE_NAME, marketplaceName);
    getBanners(marketplaceId);
  };

  useEffect(() => {
    if (currentMarketplace) setSelectedMarketplace(currentMarketplace);
  }, []);

  return (
    <AppBar position="fixed" color="secondary" className="navbar-no-shadow">
      <Toolbar className="toolbar">
        {/* TODO : ARREGLAR NAVBAR MOBILE PORQUE NO PODEMOS TENER UN ESTADO CURRENTMARKETPLACENAME PARA SOLO 1 COMPONENTE */}
        {/* {width > MEDIUM_BREAKPOINT ? ( */}
        <NavbarDesktop
          toggleSidebar={toggleSidebar}
          showContent={showContent}
          handleChange={handleChange}
        />
        {/* ) : (
          <NavbarMobile
            toggleSidebar={toggleSidebar}
            showContent={showContent}
            handleChange={handleChange}
            currentMarketplaceName={currentMarketplaceName}
          />
        )} */}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
