import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/userContext";

export const usePermission = (permissionName: string) => {
  const {
    currentUser: { permissions },
  } = useContext(UserContext);
  const [permission, setPermission] = useState(
    {} as { can_read: boolean; can_write: boolean }
  );

  useEffect(() => {
    if (permissions && permissions[permissionName]) setPermission(permissions[permissionName]);
  }, [permissions]);

  return permission;
};
