import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { IShipmentTrackingStatus } from "../../interfaces/tracking";
import {
  extendStatusIfDelivered,
  getOrderStatus,
  mongoTimeStampToString,
} from "../../utils/orders";
import ListHeader from "../tables/ListHeader";
import { DELIVERED } from "../../utils/constants";
import { getDeliveredDateFromOrder } from "../../utils/tracking";
const OrderStatusDetails = ({
  status_history,
  retries,
  showReason = false,
}: {
  status_history?: IShipmentTrackingStatus[];
  retries?: number;
  showReason?: boolean;
}) => {
  const columns = [
    { label: "Fecha y hora" },
    { label: "Estado" },
    { label: "Motivo" },
    { label: "Descripción" },
  ];

  const getTranslatedOrderDetail = (status: string) => {
    const order = getOrderStatus(status);
    return order;
  };

  const getColumns = () =>
    columns.filter(({ label }: { label: string }) => {
      const ignoredColumn = showReason ? "Descripción" : "Motivo";
      return label !== ignoredColumn;
    });
  return (
    <>
      <Typography className="f-s-20 text-dark m-t-30">
        Detalle del envío
      </Typography>
      <TableContainer
        component={Paper}
        className="m-t-20 br-20  p-b-40 bg-light MuiCard-root"
      >
        <Table>
          <ListHeader columns={getColumns()} fontSize={16} />
          <TableBody>
            {status_history?.map(
              ({ status, createdAt, reason, _id, alias }) => {
                const order = getTranslatedOrderDetail(status);
                const translatedStatus = showReason ? status : order?.label;
                return (
                  <React.Fragment key={_id}>
                    <TableRow>
                      <TableCell className="f-s-16" sx={{ width: "30%" }}>
                        {mongoTimeStampToString(createdAt)}
                      </TableCell>
                      <TableCell className="f-s-16" sx={{ width: "30%" }}>
                        {alias ||
                          extendStatusIfDelivered(translatedStatus, retries)}
                      </TableCell>
                      {showReason ? (
                        <TableCell className="f-s-16" sx={{ width: "25%" }}>
                          {reason || "-"}
                        </TableCell>
                      ) : (
                        <TableCell className="f-s-16" sx={{ width: "33%" }}>
                          {status === DELIVERED
                            ? getDeliveredDateFromOrder(status_history)
                            : order?.description}
                        </TableCell>
                      )}
                    </TableRow>
                  </React.Fragment>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderStatusDetails;
