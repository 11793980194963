import React, { ChangeEvent } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { showInputErrors } from "../../utils/credentials";
import { IUserInfoForm } from "../../interfaces/usersRoles";

const UserInfoForm = ({
  errors,
  handleChange,
  userInfo,
  disableInputs,
}: IUserInfoForm) => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Nombre y apellido</Typography>
      <TextField
        fullWidth
        className="bg-input"
        size="small"
        name="name"
        placeholder="Ingresar nombre y apellido"
        value={userInfo?.name}
        disabled={disableInputs}
        error={errors.name?.length > 0}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
      {errors.name?.length > 0 && showInputErrors(errors.name)}
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Correo electrónico</Typography>
      <TextField
        fullWidth
        disabled
        className="bg-input"
        size="small"
        name="email"
        value={userInfo?.email}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Roles</Typography>
      <Autocomplete
        getOptionLabel={(option: any) => option.name}
        value={userInfo.roles[0]}
        options={userInfo.roles}
        disabled
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size="small"
          />
        )}
      />
    </Grid>
  </Grid>
);

export default UserInfoForm;
