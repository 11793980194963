import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { IConfirmationModal } from "../../interfaces/modal";
import ModalBase from "./ModalBase";

const ConfirmationModal = ({ open, onClose, onSubmit }: IConfirmationModal) => (
  <ModalBase open={open} handleClose={onClose}>
    <Grid container paddingTop={3} justifyContent="center">
      <Typography className="f-s-20 m-t-20">
        ¿Seguro de que deseas cancelar?
      </Typography>
      <Grid container item gap={2} flexWrap="nowrap" className="m-t-40">
        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={onClose}>
            Volver
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            onClick={onSubmit}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </ModalBase>
);

export default ConfirmationModal;
