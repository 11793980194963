import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SubscriptionContent from "./SubscriptionContent";
import SubscriptionInfo from "./SubscriptionInfo";
import { ISubscriptionProps } from "../../interfaces/account";
import {
  BILLING_PERMISSION,
  MERCHANT,
  PARTNERS_PLAN_NAME,
} from "../../utils/constants";
import { usePermission } from "../../customHooks/usePermission";

const SubscriptionDesktop = ({
  entity,
  planName,
  disableInputs,
  values,
  disableSubmit,
  alert,
  errors,
  handleEdit,
  handleChange,
  handleCancel,
  handleSubmit,
}: ISubscriptionProps) => {
  const { can_write } = usePermission(BILLING_PERMISSION);
  const navigate = useNavigate();
  const showSubscriptionInfo =
    entity !== MERCHANT && planName !== PARTNERS_PLAN_NAME;

  const title = showSubscriptionInfo
    ? "Suscripción y facturación"
    : "Facturación";

  const handleBackButton = () => {
    if (disableInputs) navigate(-1);
    else handleCancel();
  };

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        md={7}
        alignItems="center"
        justifyContent="start"
      >
        <IconButton onClick={handleBackButton}>
          <span className="material-symbols-rounded text-dark">
            keyboard_backspace
          </span>
        </IconButton>
        <Typography className="f-s-20 text-dark" ml={1}>
          {disableInputs ? title : "Editar datos de facturación"}
        </Typography>
      </Grid>
      {disableInputs && (
        <>
          {showSubscriptionInfo && <SubscriptionInfo />}
          <Grid
            item
            container
            xs={7}
            justifyContent="space-between"
            alignItems="center"
            className="m-t-30"
          >
            <Typography className="f-s-20 text-dark">
              Datos de facturación
            </Typography>

            {can_write && (
              <Button
                size="small"
                variant="text"
                className="f-s-14"
                onClick={handleEdit}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Editar datos de facturación
              </Button>
            )}
          </Grid>
        </>
      )}
      <SubscriptionContent
        alert={alert}
        errors={errors}
        handleChange={handleChange}
        values={values}
        disableInputs={disableInputs}
        disableSubmit={disableSubmit}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </Grid>
  );
};

export default SubscriptionDesktop;
