import {
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ICarrierRow } from "../../interfaces/configuration";
import { CLIENT_TYPES, INTERNAL } from "../../utils/constants";
import CarrierState from "./CarrierState";

const CarrierCredentialExpired = () => (
  <TableCell>
    <Typography className="text-muted f-s-14">Vencido</Typography>
  </TableCell>
);

const CarrierRow = ({
  carrier,
  carrierChanged,
  rules,
  setOpenModal,
  handleCarriersChanges,
  entity,
  can_write,
}: ICarrierRow) => {
  const {
    _id,
    name,
    weight_info,
    cities,
    disabled,
    credentials,
    enabled,
    volume_info,
    contact_info,
  } = carrier;
  const isInternal = entity === INTERNAL;
  const isDisabled = isInternal ? !enabled : disabled;
  const { canFulfillCredentials, canDisableOL, canActiveCarriers } = rules;
  const [disableValue, setDisableValue] = useState(isDisabled);

  const hasCredentials = carrierChanged?.credentials || credentials;

  const credentialsExpired = !carrierChanged?.credentials && credentials?.expired;

  const actionTextByEntity = () => {
    switch (entity) {
    case INTERNAL:
      return "Editar información";
    default:
      return `${hasCredentials ? "Editar" : "Cargar"} contrato`;
    }
  };
  return (
    <TableRow key={_id}>
      <TableCell className="p-l-20">{name}</TableCell>
      <TableCell>{weight_info?.max > 0 ? `Hasta ${weight_info.max}kg`: "Sin límites de peso"}</TableCell>
      <TableCell>{cities.join(", ")}</TableCell>

      {((can_write && canFulfillCredentials) || isInternal) && (
        <TableCell sx={{ minWidth: "120px" }}>
          <Button
            variant="text"
            sx={{ padding: 0 }}
            onClick={() =>
              setOpenModal({
                type: name,
                open: true,
              })
            }
          >
            {actionTextByEntity()}
          </Button>
        </TableCell>
      )}

      {/* NOTE: carriersCredsExpired and carrierState will be rendered regardless who entity Is logged (focus on canDisableOL rule).
          After that we decide which component would be render depending on if the credential is expired or not*/}
      {((can_write && canDisableOL) || isInternal) && (
        <>
          {credentialsExpired ? (
            <CarrierCredentialExpired />
          ) : (
            <CarrierState
              disableCarrierStatus={disableValue}
              blockedCarrierStatusToggle={
                CLIENT_TYPES.includes(entity)
                  ? !hasCredentials
                  : !hasCredentials && canFulfillCredentials
              }
              setDisableValue={setDisableValue}
              handleCarrierState={() =>
                handleCarriersChanges({
                  ...carrier,
                  ...carrierChanged,
                  _id,
                  name,
                  disabled: !disableValue,
                  credentials: hasCredentials,
                })
              }
            />
          )}
        </>
      )}
      {/* TODO PARA EVALUAR DESPUES SI ES NECESARIO
            <TableCell>
              <Typography className="text-muted f-s-14">Inactivo</Typography>
            </TableCell> */}

      {can_write && canActiveCarriers && (
        <TableCell>
          <Checkbox
            checked={!disableValue}
            onClick={() => {
              setDisableValue(!disableValue);
              handleCarriersChanges({
                _id,
                name,
                disabled: !disableValue,
                volume_info,
                weight_info,
                contact_info,
                cities
              });
            }}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default CarrierRow;
