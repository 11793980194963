export const parseCuit = (cuitToParse: string) => {
  if (!cuitToParse) return "-";

  const cuitLength = cuitToParse.length;

  const firstDigits = cuitToParse.slice(0, 2);
  const middleDigits = cuitToParse.slice(2, cuitLength - 1);
  const lastDigit = cuitToParse.slice(cuitLength - 1, cuitLength);

  return `${firstDigits}-${middleDigits}-${lastDigit}`;
};
