import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import "../styles/layout.css";

export const InternalError = () => {
  const navigate = useNavigate();
  const internalErrorOnClick = () => {
    navigate("autenticacion");
    location.reload();
  };
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
        height={"80vh"}
        xs={6}
      >
        <Grid item>
          <Typography className="f-s-32 m-t-10 text-center" color="primary">
            ¡Ups! Algo salió mal
          </Typography>
        </Grid>
        <Grid item className="m-t-10">
          <Typography className="f-s-16 m-t-10 text-center">
            Nuestro equipo está trabajando para arreglarlo.
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="f-s-16 text-center">
            Por favor, inténtalo nuevamente mas tarde.
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="f-s-16 m-t-20">
            Si necesitas ayuda adicional, no dudes en contactarnos.
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          justifyContent="center"
          className="m-t-30"
        >
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              onClick={internalErrorOnClick}
            >
              Volver al login
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
