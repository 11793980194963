import { Divider, Grid, Typography } from "@mui/material";
import { useWindowSize } from "../../customHooks/useWindowSize";
import {
  IDimension,
  IOrderPackageContent,
  IPackage,
} from "../../interfaces/order";
import { MEDIUM_BREAKPOINT } from "../../utils/constants";

const LargePackageDescription = ({
  name,
  sku,
  quantity,
}: {
  name: string;
  sku: string;
  quantity: number;
}) => (
  <>
    <Grid item md={4} className="text-bold">
      {name}
    </Grid>
    <Grid item md={4} className="text-bold">
      SKU: {sku}
    </Grid>
    <Grid item md={4} className="text-bold">
      Cantidad: {quantity}
    </Grid>
  </>
);

const ShortPackageDescription = ({
  name,
  quantity,
  sku,
}: {
  name: string;
  quantity: number;
  sku: string;
}) => (
  <Grid item container className="m-t-20">
    <Grid item xs={12} md={4}>
      <Typography className="text-bold">{name}</Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <Typography className="text-bold">SKU: {sku}</Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <Typography className="text-bold">Cantidad: {quantity}</Typography>
    </Grid>
  </Grid>
);

const OrderPackageContent = ({ packages }: IOrderPackageContent) => {
  const { width: windowWidth } = useWindowSize();
  return (
    <>
      {packages?.map(
        ({ sub_packages, sku, quantity, name }: IPackage, index: number) => {
          return (
            <>
              <Grid container className="m-b-10" key={index}>
                {windowWidth > MEDIUM_BREAKPOINT ? (
                  <LargePackageDescription
                    name={name}
                    sku={sku}
                    quantity={quantity}
                  />
                ) : (
                  <ShortPackageDescription
                    name={name}
                    sku={sku}
                    quantity={quantity}
                  />
                )}
                {sub_packages?.length &&
                  sub_packages.map(
                    (
                      { height, weight, depth, width }: IDimension,
                      bulkIndex: number
                    ) => (
                      <>
                        <Grid
                          container
                          className="m-b-10 m-t-10"
                          key={bulkIndex}
                        >
                          <Grid item xs={4}>
                            <Typography className="f-s-14">
                              Bulto por producto: {bulkIndex + 1} /{" "}
                              {sub_packages.length}
                            </Typography>
                          </Grid>
                          <Grid item xs md={4}>
                            <Typography>
                              {height} × {weight} × {depth} cm
                            </Typography>
                          </Grid>
                          <Grid item xs md={4}>
                            <Typography>{width}kg</Typography>
                          </Grid>
                        </Grid>
                        <Divider className="m-b-10 m-t-10" />
                      </>
                    )
                  )}
              </Grid>
              {index + 1 !== packages?.length && (
                <Divider className="m-b-10 m-t-10" />
              )}
            </>
          );
        }
      )}
    </>
  );
};

export default OrderPackageContent;
