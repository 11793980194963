import React, { useContext, useEffect } from "react";
import "./App.css";
// theme
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import theme from "./utils/materialTheme";
// router
import {
  Navigate,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
// routes
import { privateRoutes } from "./routes/privateRoutes";
import { publicRoutes } from "./routes/publicRoutes";
import ProtectedRoute from "./routes/protectedRoute";
// context
import { UserContext } from "./contexts/userContext";
// utils
import {
  getInitialConfigurationComplete,
  getLocalToken,
} from "./utils/storage";
import { checkPrivateRouteByPathname } from "./utils/navigation";
import {
  AUTHENTICATION_PATH,
  SENTRY_PRIORITIES,
  SENTRY_LEVELS,
  NOT_FOUND_PATH,
} from "./utils/constants";
// pages
import AcceptInvitation from "./pages/AcceptInvitation";
import NotFound from "./pages/NotFound";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import config from "./config/config";
import { InternalError } from "./pages/InternalError";
const {
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  AUTH_API_BASE_URL,
  CONFIG_API_BASE_URL,
  ENV_NAME,
} = config;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV_NAME,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        AUTH_API_BASE_URL,
        CONFIG_API_BASE_URL,
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: SENTRY_SAMPLE_RATE, // note: lower the value in production
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  const {
    logout,
    refreshUserData,
    currentUser,
    currentUser: { entity, planName, permissions },
    rules,
  } = useContext(UserContext);
  const { pathname } = useLocation();
  const token = getLocalToken();
  useEffect(() => {
    const isPrivateRoute = checkPrivateRouteByPathname(pathname);
    if (token && !entity) refreshUserData();
    else if (isPrivateRoute && !entity) logout();
  }, []);
  const initialConfigurationComplete = getInitialConfigurationComplete();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Sentry.ErrorBoundary
          fallback={<InternalError />}
          beforeCapture={(scope, error: Error, componentStack) => {
            scope.setLevel(SENTRY_LEVELS.FATAL);
            scope.setTag("priority", SENTRY_PRIORITIES.HIGH);
            console.log(error, componentStack);
          }}
        >
          <SentryRoutes>
            <Route
              element={
                <ProtectedRoute
                  token={token}
                  user={currentUser}
                  privacy="public"
                />
              }
            >
              {publicRoutes}
            </Route>
            <Route
              element={
                <ProtectedRoute
                  token={token}
                  user={currentUser}
                  privacy="private"
                />
              }
            >
              {privateRoutes({
                entity,
                planName,
                initialConfigurationComplete,
                rules,
                permissions,
              })}
            </Route>
            <Route
              path="/aceptar-invitacion/:type/:token"
              element={<AcceptInvitation />}
            />
            <Route path={`/${NOT_FOUND_PATH}`} element={<NotFound />} />
            <Route
              path="*"
              element={<Navigate to={AUTHENTICATION_PATH} replace />}
            />
          </SentryRoutes>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
