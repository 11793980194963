import React, { useState } from "react";
import ModalBase from "./ModalBase";
import { Button, Grid, Typography } from "@mui/material";
import {
  ICreateEditUserModal,
  IEditOrInviteUserParams,
} from "../../interfaces/usersRoles";
import CreateEditUsers from "../forms/CreateEditUsers";
import { IValidation } from "../../interfaces/form";
import {
  ERROR,
  ERRORS_MESSAGES,
  USER_EDIT_MODAL_TYPE,
} from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import { cleanBody } from "../../utils/text";
import { editUserRoles, inviteUser } from "../../requests/usersRequests";

const { emailInvalid, emailRequired, contactNameRequired, roleRequired } =
  ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "name",
    type: "required",
    message: contactNameRequired,
  },
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "roles",
    type: "required-list",
    message: roleRequired,
  },
];

const CreateEditUserModal = ({
  open,
  typeOfModal,
  roles,
  onClose,
  userInfo,
}: ICreateEditUserModal) => {
  const [values, setValues] = useState(
    (userInfo || { name: "", email: "", roles: [] }) as IEditOrInviteUserParams
  );
  const [errors, setErrors] = useState(
    {} as { name: string[]; email: string[]; role: string[] }
  );
  const [alertModal, setAlertModal] = useState({} as IAlert);

  const handleSubmit = async () => {
    const inputErrors = validateInputs(values, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      try {
        const cleanValues = cleanBody(values);
        const handler =
          typeOfModal === USER_EDIT_MODAL_TYPE ? editUserRoles : inviteUser;
        await handler(cleanValues);
        onClose(true);
      } catch (error: any) {
        setAlertModal({ typeOfAlert: ERROR, message: error, showAlert: true });
      }
    }
  };

  return (
    <ModalBase open={open} handleClose={onClose}>
      <Grid container>
        <div className="userErrorsContainer">
          <Typography className="f-s-18 m-b-20">
            {typeOfModal === USER_EDIT_MODAL_TYPE ? "Editar" : "Crear"} usuario
          </Typography>
          {alertModal.showAlert && (
            <div className="userErrors">
              <GenericAlert alert={alertModal} />
            </div>
          )}
        </div>
        <CreateEditUsers
          values={values}
          setValues={setValues}
          typeOfModal={typeOfModal}
          roles={roles}
          errors={errors}
        />

        <Grid container gap={2} flexWrap="nowrap" className="m-t-40">
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" onClick={() => onClose()}>
              Volver
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" onClick={handleSubmit}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default CreateEditUserModal;
