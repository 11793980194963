import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import AllNotifications from "../components/notifications/AllNotifications";
import NoReadNotifications from "../components/notifications/NoReadNotifications";
import { GoBackButton } from "../components/notifications/GoBackButton";
import {
  deleteNotifications,
  updateReadNotifications,
} from "../utils/notifications";
import { NotificationsContext } from "../contexts/notificationContext";
import { DeleteSelectedNotifications } from "../components/notifications/DeleteSelectedNotifications";
import { LIMIT } from "../utils/constants";
import "../styles/notifications.css";
import {
  INotification,
  INotificationsState,
  IRequestNotificationsFilters,
  TNotificationPage,
} from "../interfaces/notifications";
import Loader from "../components/Loader";

const NotificationsTest = () => {
  const { socket, isConnected } = useContext(NotificationsContext);
  const [value, setValue] = useState<TNotificationPage>("all");
  const [notifications, setNotifications] = useState<INotificationsState>({
    all: [],
    noRead: [],
  });
  const [notificationsToDelete, setNotificationsToDelete] = useState<string[]>(
    []
  );
  const [scroll, setScroll] = useState(true);
  const notificationsToReadRef = useRef<Set<string>>(new Set());
  const handleChange = (
    _event: SyntheticEvent,
    newValue: TNotificationPage
  ) => {
    setValue((currentValue) => (currentValue === "all" ? "noRead" : "all"));
    setScroll(!(notifications[newValue].length % LIMIT));
  };
  const filterDeletedNotifications = (notificationsList: INotification[]) =>
    notificationsList.filter(
      (notification) => !notificationsToDelete.includes(notification._id)
    );
  const handleDeleteNotifications = () => {
    deleteNotifications(socket, notificationsToDelete);
    setNotifications(({ all, noRead }) => ({
      all: all.length ? filterDeletedNotifications(all) : all,
      noRead: noRead.length ? filterDeletedNotifications(noRead) : noRead,
    }));
    setNotificationsToDelete([]);
  };

  const requestNotifications = (filters: IRequestNotificationsFilters) =>
    socket.emit("request-notifications", {
      ...filters,
      limit: LIMIT,
    });

  useEffect(() => {
    if (socket && isConnected) {
      if (!notifications[value].length)
        requestNotifications({
          skip: 0,
          noRead: value === "noRead",
          //En caso de un fetch inicial, se setea la fecha de la ultima notificacion al momento de renderizar la pantalla
          //o la fecha actual en caso de que no hayan aun notificaciones.
          lastCreatedAt: notifications.all.length
            ? notifications.all[0].createdAt
            : new Date().toString()
        });
      socket.off("list-notifications");
      socket.on(
        "list-notifications",
        ({ notifications: notificationsList }) => {
          setNotifications((currentNotifications) => ({
            ...currentNotifications,
            [value]: currentNotifications[value].concat(notificationsList),
          }));
          setScroll(notificationsList.length === LIMIT);
        }
      );
    }
  }, [socket, value, isConnected]);

  useEffect(() => {
    const { all, noRead } = notifications;
    if (all.length || noRead.length) {
      const notificationsToMarkAsRead: Set<string> = new Set();
      const commonCallBack = ({ _id, read_at }: INotification) => {
        if (!read_at) notificationsToMarkAsRead.add(_id);
      };
      all.forEach(commonCallBack);
      noRead.forEach(commonCallBack);
      notificationsToReadRef.current = notificationsToMarkAsRead;
    }
  }, [notifications]);

  useEffect(() => {
    return () => {
      const idSet = notificationsToReadRef.current;
      if (idSet.size) updateReadNotifications(socket, Array.from(idSet));
    };
  }, []);

  return (
    <Box>
      {isConnected ? (
        <>
          {notificationsToDelete.length ? (
            <DeleteSelectedNotifications
              count={notificationsToDelete.length}
              handleDeleteNotifications={handleDeleteNotifications}
              setNotificationsToDelete={setNotificationsToDelete}
            />
          ) : (
            <GoBackButton />
          )}
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            marginTop="50px"
            className="w-100"
          >
            <Box
              paddingTop="1.25rem"
              paddingBottom="1.25rem"
              className="bg-light w-630 br-10 notif-cont-box-shadow"
            >
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "fit-content",
                    marginLeft: 5,
                  }}
                >
                  <TabList onChange={handleChange}>
                    <Tab label="Todas" value="all" />
                    <Tab
                      label="No leídas"
                      value="noRead"
                      disabled={!notifications.all.length}
                    />
                  </TabList>
                </Box>
                <TabPanel value="all">
                  <AllNotifications
                    notifications={notifications?.all}
                    scroll={scroll && value === "all"}
                    notificationsToDelete={notificationsToDelete}
                    setNotificationsToDelete={setNotificationsToDelete}
                  />
                </TabPanel>
                <TabPanel value="noRead">
                  <NoReadNotifications
                    notifications={notifications?.noRead}
                    scroll={scroll && value === "noRead"}
                    notificationsToDelete={notificationsToDelete}
                    setNotificationsToDelete={setNotificationsToDelete}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Box>{" "}
        </>
      ) : (
        <Loader size="md" />
      )}
    </Box>
  );
};

export default NotificationsTest;
