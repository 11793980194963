import { TEntity } from "../interfaces/userContext";
import { MARKETPLACE, MERCHANT, PARTNERS_PLAN_NAME } from "./constants";

const accountOption = {
  icon: "admin_panel_settings",
  label: "Cuenta",
  slug: "cuenta",
};

const getBillingOption = (entity: TEntity) => {
  const isJustBilling = entity === MERCHANT;

  return {
    icon: "fact_check",
    label: isJustBilling ? "Datos de facturación" : "Suscripción y facturación",
    slug: isJustBilling ? "facturacion" : "suscripcion-y-facturacion",
  };
};

export const getNavbarOptions = (
  entity: TEntity,
  planName: string | null,
  can_write_account: boolean,
  can_write_billing: boolean
) => {
  const isMarketplaceWithPartnerPlan =
    entity === MARKETPLACE && planName === PARTNERS_PLAN_NAME;
  const commonOptions = [
    {
      icon: "account_circle",
      label: "Mis datos",
      slug: "mis-datos",
    },
    /* TODO para cuando exista la sección de ayuda
    {
      icon: "help",
      label: "Ayuda",
      slug: "ayuda",
    }, */
  ];
  if (can_write_account) commonOptions.push({ ...accountOption });
  if (can_write_billing && !isMarketplaceWithPartnerPlan)
    commonOptions.push(getBillingOption(entity));
  return commonOptions;
};
