import React from "react";
import { Drawer } from "@mui/material";
import SidebarList from "./SidebarList";
import { ISidebarMobile } from "../../interfaces/sidebar";

const SidebarMobile = ({ open, setOpen, showContent }: ISidebarMobile) => {
  const onCloseSidebar = () => setOpen(false);
  return (
    <Drawer anchor="left" open={open} onClose={onCloseSidebar}>
      <div className="sidebar-container">
        {showContent && <SidebarList onCloseSidebar={onCloseSidebar} />}
      </div>
    </Drawer>
  );
};

export default SidebarMobile;
