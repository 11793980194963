import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ICredentialsAndreaniErrors } from "../../interfaces/configuration";
import { IValidation } from "../../interfaces/form";
import { ICredentialsModal } from "../../interfaces/modal";
import { ERRORS_MESSAGES } from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import ModalBase from "./ModalBase";
import CredentialsAndreani from "../forms/CredentialsAndreani";
import GenericForm from "../GenericForm";
import ConfirmChangesCredentials from "../configuration/ConfirmChangesCredentials";

const {
  passwordRequired,
  idClientRequired,
  userRequired,
  doorToDoorRequired,
  endDateRequired,
  endDateNotInThePast,
  doorToDoorOnlyNumbers,
  reverseOnlyNumbers,
  reverseRequired,
  oneDoorToDoorRequired
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "client",
    type: "required",
    message: idClientRequired,
  },
  {
    id: "user",
    type: "required",
    message: userRequired,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "door_to_door",
    type: "required",
    message: doorToDoorRequired,
  },
  {
    id: "door_to_door",
    type: "only-numbers",
    message: doorToDoorOnlyNumbers,
  },
  {
    id: "reverse",
    type: "only-numbers",
    message: reverseOnlyNumbers,
  },
  {
    id: "reverse",
    type: "required",
    message: reverseRequired,
  },
  {
    id: "end_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "end_date",
    type: "future_date",
    message: endDateNotInThePast,
  },
];

const CredentialsAndreaniModal = ({
  open,
  onClose,
  carrier,
  handleChangeOfConfig,
}: ICredentialsModal) => {
  const [values, setValues] = useState((carrier.credentials || {}) as any);
  const [errors, setErrors] = useState({} as ICredentialsAndreaniErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(values, formValidations);
    const { b2b, b2c } = values;
    const d2dContract = !!(b2b || b2c);
    if (!d2dContract) {
      inputErrors.b2b = [oneDoorToDoorRequired];
      inputErrors.b2c = [oneDoorToDoorRequired];
    }
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      const expired = new Date(values.end_date) <= new Date();
      handleChangeOfConfig({
        _id: carrier._id,
        name: carrier.name,
        disabled: carrier.disabled,
        credentials: { ...values, expired }
      });
      onClose();
    }
  };

  return (
    <ModalBase open={open} handleClose={onClose} maxWidth="md">
      <Grid container>
        <Typography className="f-s-18 m-b-20">
          Ingresa los datos de tu contrato con Andreani
        </Typography>
        <GenericForm onSubmit={handleSubmit}>
          <CredentialsAndreani
            values={values}
            setValues={setValues}
            errors={errors}
          />
        </GenericForm>

        <ConfirmChangesCredentials
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </ModalBase>
  );
};

export default CredentialsAndreaniModal;
