import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ICredentialsModal } from "../../interfaces/modal";
import ModalBase from "./ModalBase";
import ConfirmChangesCredentials from "../configuration/ConfirmChangesCredentials";
import { IValidation } from "../../interfaces/form";
import { ERRORS_MESSAGES } from "../../utils/constants";
import GenericForm from "../GenericForm";
import {
  ICredentialsChazkiErrors,
  ICredentialsChazkiPayload,
} from "../../interfaces/configuration";
import { validateInputs } from "../../utils/inputValidations";
import CredentialsChazki from "../forms/CredentialsChazki";
import FileUpload from "../forms/FileUpload";

const {
  endDateRequired,
  endDateNotInThePast,
  tokenRequired,
  monthlyShippingsRequired,
  monthlyShippings,
  invalidMatrixFormat,
} = ERRORS_MESSAGES;

const getFormValidations = (previousInfo: any): IValidation[] => [
  {
    id: "token",
    type: "required",
    message: tokenRequired,
  },
  {
    id: "end_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "end_date",
    type: "future_date",
    message: endDateNotInThePast,
  },
  {
    id: "monthly_shippings",
    type: "required",
    message: monthlyShippingsRequired,
  },
  {
    id: "monthly_shippings",
    type: "number",
    message: monthlyShippings,
  },
  {
    id: "matrix",
    type: "file",
    previousInfo,
    message: invalidMatrixFormat,
  },
];

const CredentialsChazkiModal = ({
  open,
  onClose,
  carrier,
  handleChangeOfConfig,
}: ICredentialsModal) => {
  const initialCredentials = (
    carrier.credentials ? { ...carrier.credentials } : {}
  ) as ICredentialsChazkiPayload;

  const [values, setValues] = useState(initialCredentials);
  const [errors, setErrors] = useState({} as ICredentialsChazkiErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(
      values,
      getFormValidations(initialCredentials.matrix_file_name)
    );

    setErrors(inputErrors);

    if (!Object.keys(inputErrors).length) {
      const { token, end_date, monthly_shippings, matrix } =
        values as ICredentialsChazkiPayload;
      const dateExpired = new Date(end_date) <= new Date();

      const formData = new FormData();

      formData.append("end_date", end_date.toString());
      formData.append("token", token);
      formData.append("monthly_shippings", monthly_shippings.toString());
      formData.append("matrix", matrix);

      handleChangeOfConfig({
        _id: carrier._id,
        name: carrier.name,
        disabled: carrier.disabled,
        credentials: {
          end_date,
          token,
          monthly_shippings,
          matrix,
          expired: dateExpired,
        },
      });

      onClose();
    }
  };

  const handleUpload = (event: any) => {
    setValues((currentInfo) => ({
      ...currentInfo,
      matrix: event.target.files[0],
    }));
  };

  return (
    <ModalBase open={open} handleClose={onClose} maxWidth="md">
      <Grid container>
        <Typography className="f-s-18 m-b-20">
          Ingresa los datos de tu contrato con Chazki
        </Typography>
        <GenericForm onSubmit={handleSubmit}>
          <CredentialsChazki
            values={values}
            setValues={setValues}
            errors={errors}
          />
        </GenericForm>
        <FileUpload
          text="Importar matriz"
          indications="Cargar matriz en formato .xlsx"
          handleUpload={handleUpload}
          errors={errors.matrix}
          uploadedFileName={
            values.matrix?.name || initialCredentials.matrix_file_name
          }
        />
        <ConfirmChangesCredentials
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </ModalBase>
  );
};

export default CredentialsChazkiModal;
