import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Box, IconButton, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Integrations from "../../pages/integrations/Integrations";
import Invitations from "../../pages/integrations/Invitations";
import MerchantCarrierIntegrations from "../../pages/integrations/Carriers";
import { UserContext } from "../../contexts/userContext";
import {
  INTERNAL,
  MARKETPLACE,
  MERCHANT,
  MERCHANT_TABS_TRANSLATION,
  VALID_MERCHANT_TABS,
} from "../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";

const IntegrationsTabs = () => {
  const {
    currentUser: { entity, merchantId },
    getIntegrationsOfMerchant,
    rules: { contractOwner },
  } = useContext(UserContext);
  const [value, setValue] = useState("invitations");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get("vista") as string;
  const handleChange = (_event: SyntheticEvent, newValue: string) =>
    setValue(newValue);

  useEffect(() => {
    const translatedInitialTabName =
      MERCHANT_TABS_TRANSLATION[initialTab?.toLowerCase()];

    if (VALID_MERCHANT_TABS.includes(translatedInitialTabName))
      setValue(translatedInitialTabName);
  }, []);

  const getManagmentDict: { [key: string]: string } = {
    [MARKETPLACE]: "Merchants",
    [MERCHANT]: "Marketplaces",
    [INTERNAL]: "Clientes",
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        {entity === MERCHANT && (
          <IconButton onClick={() => navigate(-1)}>
            <span className="material-symbols-rounded text-dark">
              keyboard_backspace
            </span>
          </IconButton>
        )}
        <Typography ml={1} className="f-s-20">
          Gestión de {getManagmentDict[entity]}
        </Typography>
      </Box>

      <TabContext value={value}>
        <Box
          className="m-t-30"
          sx={{ borderBottom: 1, borderColor: "divider", width: "fit-content" }}
        >
          <TabList onChange={handleChange}>
            <Tab label="Invitaciones" value="invitations" />
            <Tab label="Administración" value="administration" />
            {entity === MARKETPLACE && contractOwner === MERCHANT && (
              <Tab label="Operadores" value="carriers" />
            )}
          </TabList>
        </Box>
        <TabPanel value="invitations">
          <Invitations
            entity={entity}
            merchantId={merchantId}
            getIntegrationsOfMerchant={getIntegrationsOfMerchant}
          />
        </TabPanel>
        <TabPanel value="administration">
          <Integrations
            entity={entity}
            getIntegrationsOfMerchant={getIntegrationsOfMerchant}
          />
        </TabPanel>
        {entity === MARKETPLACE && contractOwner === MERCHANT && (
          <TabPanel value="carriers">
            <MerchantCarrierIntegrations />
          </TabPanel>
        )}
      </TabContext>
    </>
  );
};

export default IntegrationsTabs;
