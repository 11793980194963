import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

const ClipboardAdornment = ({ value }: { value: string }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (copiedToClipboard) setTimeout(() => setCopiedToClipboard(false), 2000);
  }, [copiedToClipboard]);

  return (
    <Tooltip title="Copiado al portapapeles" open={copiedToClipboard}>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(value);
          setCopiedToClipboard(true);
        }}
        color={copiedToClipboard ? "primary" : "default"}
      >
        <span className="material-symbols-rounded">
          {copiedToClipboard ? "check" : "content_copy"}
        </span>
      </IconButton>
    </Tooltip>
  );
};

export default ClipboardAdornment;
