import { Route } from "react-router-dom";
import AuthLayout from "../components/AuthLayout";
import Login from "../pages/Login";
import ChangePassword from "../pages/password/ChangePassword";
import RecoverPassword from "../pages/password/RecoverPassword";
import SignupClientPt1 from "../pages/signup/SignupClientPt1";
import SignupClientPt2 from "../pages/signup/SignupClientPt2";
import SignupMerchant from "../pages/signup/SignupMerchant";
import SignupUser from "../pages/signup/SignupUser";

export const authRoutes = (
  <Route path="/autenticacion" element={<AuthLayout />}>
    <Route index element={<Login />} />
    <Route path="registro-merchant/:token" element={<SignupMerchant />} />
    <Route path="registro-cliente" element={<SignupClientPt1 />} />
    <Route
      path="completar-registro-cliente/:token"
      element={<SignupClientPt2 />}
    />
    <Route path="recuperar-credenciales" element={<RecoverPassword />} />
    <Route path="cambiar-credenciales/:token" element={<ChangePassword />} />
    <Route path="registro-usuario/:token" element={<SignupUser />} />
  </Route>
);
