import { SyntheticEvent, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Typography, Box, Tab } from "@mui/material";
import QuotesAudits from "../../pages/audits/QuotesAudits";
import Orders from "../../pages/orders/Orders";

const AuditsTabs = () => {
  const [value, setValue] = useState("orders");
  const handleChange = (_event: SyntheticEvent, newValue: string) =>
    setValue(newValue);

  return (
    <>
      <Typography className="f-s-20">Auditoría</Typography>
      <TabContext value={value}>
        <Box
          className="m-t-30"
          sx={{ borderBottom: 1, borderColor: "divider", width: "fit-content" }}
        >
          <TabList onChange={handleChange}>
            <Tab label="Órdenes" value="orders" />
            <Tab label="Cotizaciones" value="quotes" />
          </TabList>
        </Box>
        <TabPanel value="orders">
          <Orders showTitle={false} showFilters={false} />
        </TabPanel>
        <TabPanel value="quotes">
          <QuotesAudits />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default AuditsTabs;
