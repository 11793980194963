import React, { Fragment } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { TEntity } from "../../interfaces/userContext";
import { MARKETPLACE } from "../../utils/constants";
import SummarySkeleton from "./SummarySkeleton";
import { IMetricsOrdersSummary } from "../../interfaces/metrics";

const TotalOrdersByOLSummary = ({
  olsInfo,
  entity,
}: {
  olsInfo: IMetricsOrdersSummary[];
  entity: TEntity;
}) => (
  <>
    {olsInfo ? (
      <Grid
        container
        item
        xs
        lg={entity === MARKETPLACE ? 4 : 6}
        flexDirection="column"
        pl={2.5}
        pr={3.75}
        className="custom-border-left"
      >
        <Typography className="f-s-20" mt={5}>
          Total de envíos por operador logístico
        </Typography>

        <Grid container flexDirection="column" mb={7.5}>
          {olsInfo.map(({ name, count, _id }: IMetricsOrdersSummary) => (
            <Fragment key={_id}>
              <Grid container alignItems="center" py={2.5}>
                <Grid item xs={1}>
                  <div className="badge-orders-metrics badge-blue" />
                </Grid>
                <Grid item xs={8}>
                  <Typography className="f-s-14">{name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="f-s-14" textAlign="right">
                    {count} envíos
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ borderColor: "#DEDEDE" }} />
            </Fragment>
          ))}
        </Grid>
      </Grid>
    ) : (
      <Grid
        container
        item
        xs
        lg={entity === MARKETPLACE ? 4 : 6}
        flexDirection="column"
        pl={2.5}
        pt={5}
        pb={7.5}
        className="custom-border-left"
      >
        <SummarySkeleton rows={6} />
      </Grid>
    )}
  </>
);

export default TotalOrdersByOLSummary;
