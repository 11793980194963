import { Grid, Typography } from "@mui/material";
import React from "react";
import { IMerchantInfoModal } from "../../interfaces/modal";
import { parseCuit } from "../../utils/cuit";
import ModalBase from "./ModalBase";

const InfoMerchantModal = ({
  open,
  setOpen,
  integration: {
    name,
    cuit,
    businessName,
    ivaSituation,
    email,
    commercialAddress,
  },
}: IMerchantInfoModal) => {
  const handleClose = () => setOpen(false);

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="m-t-10 f-s-24 text-bold">{name}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className="text-bold">Alias</Typography>
          <Typography>{name}</Typography>
          <Typography className="m-t-10 text-bold">CUIT</Typography>
          <Typography>{parseCuit(cuit)}</Typography>
          <Typography className="m-t-10 text-bold">Razón Social</Typography>
          <Typography>{businessName}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className="text-bold">Condición ante el IVA </Typography>
          <Typography>{ivaSituation}</Typography>
          <Typography className="m-t-10 text-bold">Correo electrónico</Typography>
          <Typography>{email}</Typography>
          <Typography className="m-t-10 text-bold">
            Domicilio comercial
          </Typography>
          <Typography>{commercialAddress}</Typography>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default InfoMerchantModal;
