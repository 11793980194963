import { useEffect, useState } from "react";
import { TIME_DELAY } from "../utils/constants";

export const delayCall = (setSubmitting: Function) =>
  setTimeout(() => setSubmitting(false), TIME_DELAY);

export const useDebounce = (callback: Function) => {
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (submitting) {
      callback();
      delayCall(setSubmitting);
    }
  }, [submitting]);

  return { submitting, setSubmitting };
};
