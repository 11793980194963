import { createTheme } from "@mui/material";
import { common } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E32728",
      contrastText: common.white,
    },
    success: {
      main: "#0C88FC",
      contrastText: common.white,
    },
    error: {
      main: "#D90001",
      contrastText: common.white,
    },
    warning: {
      main: "#E3D027",
      contrastText: common.black,
    },
    info: {
      main: "#F96E20",
      contrastText: common.white,
    },
    secondary: {
      main: "#EBEBEB",
      contrastText: "#181818",
    },
    contrastThreshold: 3,
  },
  typography: {
    h1: {
      color: "#181818",
    },
    h2: {
      color: "#181818",
      fontSize: "1.5rem",
    },
    h3: {
      color: "#181818",
    },
    h4: {
      color: "#181818",
    },
    h5: {
      color: "#181818",
    },
    h6: {
      color: "#181818",
    },
    body1: {
      color: "#181818",
    },
    fontFamily: [
      "Lato",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontSize: 14,
            color: "#181818",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
});

export default theme;
