import React from "react";
import { Button, Grid } from "@mui/material";
import NotFoundBanner from "../images/404-banner.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reason = searchParams.get("reason");
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <img src={NotFoundBanner} className="w-100" alt="404-banner" />
      </Grid>
      {!reason && (
        <Grid item xs={3} className="m-t-40">
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Volver atrás
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default NotFound;
