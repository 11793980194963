import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../utils/dataProvider";
import { GET, MEDIUM, NOT_FOUND_PATH, PATCH } from "../utils/constants";
import Loader from "../components/Loader";
import { IDynamicRequests } from "../interfaces/common";
import { Grid } from "@mui/material";

const AcceptInvitation = () => {
  const { token, type } = useParams();
  const navigate = useNavigate();

  const methodByType: IDynamicRequests = {
    merchant: {
      method: PATCH,
      url: `/merchants/invitation/acept/token/${token}`,
    },
    user: { method: GET, url: `/users/redirect/${token}` },
  };

  const acceptInvitation = async () => {
    try {
      if (type && token && methodByType[type]) {
        const { url, method } = methodByType[type];

        const redirectTo = await fetchData({
          url,
          method,
        });

        navigate(redirectTo);
      } else navigate(`${NOT_FOUND_PATH}?reason=accept-invitation-failed`);
    } catch (error: any) {
      console.error("error: ", error);
      navigate(`${NOT_FOUND_PATH}?reason=accept-invitation-failed`);
    }
  };

  useEffect(() => {
    acceptInvitation();
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      height="100svh"
    >
      <Loader size={MEDIUM} />
    </Grid>
  );
};

export default AcceptInvitation;
