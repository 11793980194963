import { ISummaryCard } from "../../interfaces/metrics";
import { Card, CardContent, Grid, Typography } from "@mui/material";

const SummaryCard = ({ title, quantity }: ISummaryCard) => (
  <Grid item xs={6} md={4} lg={2.4}>
    <Card className="br-5 bg-light">
      <CardContent
        sx={{ paddingBlock: "15px !important", paddingInline: "20px" }}
      >
        <Typography className="f-s-14">{title}</Typography>
        <Typography className="f-s-20">{quantity}</Typography>
      </CardContent>
    </Card>
  </Grid>
);

export default SummaryCard;
