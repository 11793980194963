import React, { useState, useContext, useEffect } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { IConfigInitialRules, IRules } from "../../interfaces/configuration";
import RuleList from "./RuleList";
import { IAlert } from "../../interfaces/alert";
import {
  ERROR,
  INITIAL_CONFIGURATION_PARTIAL_PATH,
  MARKETPLACE_MERCHANT,
  SUCCESS,
  SUCCESS_ALERT_DURATION,
} from "../../utils/constants";
import { patchRules } from "../../requests/configurationRequests";
import GenericAlert from "../GenericAlert";
import { LoadingButton } from "@mui/lab";
import ConfirmChangesButtons from "./ConfirmChangesButtons";
import { UserContext } from "../../contexts/userContext";

const Rules = ({ handleNext, handleBack }: IRules) => {
  const { rules, getRules } = useContext(UserContext);
  const [selectedRules, setSelectedRules] = useState({} as IConfigInitialRules);
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const comeFromInitialConfiguration = location.pathname.includes(
    INITIAL_CONFIGURATION_PARTIAL_PATH
  );

  const handleChange = (rule: string, value: string) => {
    setSelectedRules({ ...selectedRules, [rule]: value });
    setUnsavedChanges(true);
  };

  const handleSubmit = async () => {
    setAlert({} as IAlert);
    setLoading(true);
    setUnsavedChanges(false);
    const merchantCanDisableCarriers =
      selectedRules.merchantCanDisableCarriers === MARKETPLACE_MERCHANT ||
      selectedRules.merchantCanDisableCarriers === true;
    const newRules = {
      ...selectedRules,
      merchantCanDisableCarriers,
    } as IConfigInitialRules;

    try {
      const message = await patchRules(newRules);
      setAlert({ typeOfAlert: SUCCESS, message, showAlert: true });
      setTimeout(() => setAlert({} as IAlert), SUCCESS_ALERT_DURATION);
      getRules();
      if (handleNext) handleNext();
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    getRules();
    setUnsavedChanges(false);
  };

  useEffect(() => {
    setSelectedRules(rules);
  }, [rules]);

  return (
    <>
      <Card
        className={`br-20 ${
          comeFromInitialConfiguration ? "bg-input" : "bg-light m-b-40"
        }`}
        sx={{ padding: 3, maxWidth: "560px" }}
      >
        <CardContent sx={{ paddingInline: 4.5 }}>
          <Typography className="f-s-24">Permisos</Typography>
          <div className="m-b-20">
            {alert.showAlert && <GenericAlert alert={alert} />}
          </div>

          <RuleList
            handleRuleChange={handleChange}
            selectedRules={selectedRules}
          />
          {comeFromInitialConfiguration && (
            <Grid container spacing={1} alignItems="center" className="m-t-30">
              <Grid item xs={6}>
                <Button fullWidth variant="outlined" onClick={handleBack}>
                  Volver
                </Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={Object.keys(selectedRules).length === 0 || loading}
                  loading={loading}
                  loadingPosition="end"
                >
                  Continuar
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      {/* La posición de los botones depende de si el componente se renderiza en la configuración inicial o en la sección de configuración, de ahi el uso de la variable comeFromInitialConfiguration como condicional */}
      {unsavedChanges && !comeFromInitialConfiguration && (
        <ConfirmChangesButtons
          handleConfirm={handleSubmit}
          handleCancel={handleCancel}
          confirmButtonText="Guardar cambios"
        />
      )}
    </>
  );
};

export default Rules;
