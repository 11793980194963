import { Grid, Button } from "@mui/material";
import React from "react";
import { ISaveChangesParams } from "../../interfaces/integrations";

const SaveIntegrationChanges = ({
  onSave,
  onCancel
}: ISaveChangesParams) => (
  <Grid
    container
    alignItems="center"
    justifyContent="flex-end"
    className="m-t-10"
    spacing={2}
  >
    <Grid item xs={6} md={3} lg={2}>
      <Button
        variant="outlined"
        size="small"
        fullWidth
        onClick={onCancel}
      >
        Cancelar
      </Button>
    </Grid>
    <Grid item xs={6} md={3} lg={2}>
      <Button
        size="small"
        variant="contained"
        fullWidth
        onClick={onSave}
      >
        Guardar cambios
      </Button>
    </Grid>
  </Grid>
);

export default SaveIntegrationChanges;
