import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import ListHeader from "./ListHeader";
import ListPagination from "./ListPagination";
import {
  AUDITS_PATH,
  MARKETPLACE,
  SPANISH_SERVICES,
} from "../../utils/constants";
import { IValuesArrayByEntity } from "../../interfaces/common";
import { ITableOrders, IMappedOrder, IPackage } from "../../interfaces/order";
import {
  mongoTimeStampToString,
  getOrderStatus,
  capitalizeOnlyFirstLetter,
  getChoppedText,
} from "../../utils/orders";
import ListOrdersActions from "./ListOrdersActions";
import { ITableHeaderColumn } from "../../interfaces/table";
import { getFeeString } from "../../utils/text";

const commonHeader: ITableHeaderColumn[] = [
  { label: "Operador" },
  { label: "Origen" },
  { label: "Paquetes" },
  { label: "Costo" },
  { label: "Estado" },
  { label: "Tipo" },
  { label: "Acciones" },
];
const merchantEcommerceHeader = [
  { label: "Fecha" },
  { label: "N° de guía" },
  ...commonHeader,
];
const headerByEntity: IValuesArrayByEntity = {
  marketplace: [
    { label: "Fecha" },
    { label: "N° de guía" },
    { label: "Merchant" },
    ...commonHeader,
  ],
  merchant: merchantEcommerceHeader,
  ecommerce: merchantEcommerceHeader,
};

const ListOrders = ({
  orders,
  entity,
  handlePageChange,
  handleSelectedAction,
  pagination: { count, page },
  showActions,
  rules,
}: ITableOrders) => {
  const isAudit = window.location.pathname.includes(AUDITS_PATH);
  const header = headerByEntity[entity].filter(
    ({ label }) => !(isAudit && label === "Operador")
  );

  return (
    <TableContainer component={Paper} className="m-t-40 br-20 bg-light">
      <Table>
        <ListHeader columns={header} />
        <TableBody>
          {orders.map((row: IMappedOrder, index: number) => {
            const {
              updatedAt,
              tracking_id,
              carrier,
              merchant_name,
              origin,
              packages,
              status,
              fee,
              service,
              alias,
            } = row;
            const orderStatus = getOrderStatus(status);
            const totalPackages = packages?.reduce(
              (accum: number, { quantity }: IPackage) => {
                // eslint-disable-next-line no-param-reassign
                accum += quantity;
                return accum;
              },
              0
            );

            return (
              <TableRow key={index}>
                <TableCell className="p-l-20">
                  {mongoTimeStampToString(updatedAt, true)}
                </TableCell>
                <TableCell>{tracking_id}</TableCell>
                {entity === MARKETPLACE && merchant_name && (
                  <TableCell>{getChoppedText(20, merchant_name)}</TableCell>
                )}
                {!isAudit && (
                  <TableCell>{capitalizeOnlyFirstLetter(carrier)}</TableCell>
                )}
                <TableCell>{origin && getChoppedText(25, origin)}</TableCell>
                <TableCell>{totalPackages}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {getFeeString(fee)}
                </TableCell>
                <TableCell className="fixed-column">
                  {alias || orderStatus?.label}
                </TableCell>
                <TableCell>
                  {SPANISH_SERVICES[service] ||
                    SPANISH_SERVICES[row.orderInfo.service]}
                </TableCell>
                <ListOrdersActions
                  row={row}
                  showActions={showActions}
                  handleSelectedAction={handleSelectedAction}
                  rules={rules}
                  entity={entity}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ListPagination
        total={count}
        page={page}
        onChangePage={handlePageChange}
      />
    </TableContainer>
  );
};

export default ListOrders;
