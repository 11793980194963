import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Stack,
} from "@mui/material";
import { ITableHeader, ITableHeaderColumn } from "../../interfaces/table";

const ListHeader = ({ columns, fontSize = 14 }: ITableHeader) => (
  <TableHead>
    <TableRow>
      {columns.map(({ label, helper }: ITableHeaderColumn, i: number) => (
        <TableCell
          className={`p-y-20 ${i === 0 ? "p-l-20" : ""} ${
            ["Operadores logísticos", "Peso"].includes(label) ? "w-20" : ""
          }`}
          key={label}
        >
          <Stack direction="row" spacing={1}>
            <Typography className="text-bold" fontSize={fontSize}>
              {label}
            </Typography>
            {helper && <Typography fontSize={fontSize}>{helper}</Typography>}
          </Stack>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default ListHeader;
