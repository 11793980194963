import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { IAlert } from "../../interfaces/alert";
import { IValuesByEntity } from "../../interfaces/common";
import { ICancelInvitationModal } from "../../interfaces/modal";
import {
  PATCH,
  ERROR,
  SUCCESS,
  CANCEL_INVITATION_MESSAGE,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import GenericAlert from "../GenericAlert";
import ModalBase from "./ModalBase";

const CancelInvitationModal = ({
  open,
  setOpen,
  integration: { email, invitationID },
  updateTable,
  setUpdateTable,
  entity,
  setAlert,
}: ICancelInvitationModal) => {
  const [alertDisable, setAlertDisable] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);
  const urlByEntity: IValuesByEntity = {
    marketplace: `/marketplaces_merchants/invitation/cancel/${invitationID}`,
    merchant: `/merchants/invitation/reject/${invitationID}`,
  };

  const messageByEntity: IValuesByEntity = {
    marketplace: `¿Seguro de que deseas cancelar la invitación a ${email}?`,
    merchant: `¿Seguro de que deseas rechazar la invitación de ${email}?`,
  };

  const handleClose = () => {
    setOpen(false);
    setDisableButton(false);
    setAlertDisable({} as IAlert);
  };

  const handleDisable = async () => {
    setDisableButton(true);
    try {
      await fetchData({
        url: urlByEntity[entity],
        method: PATCH,
      });

      setUpdateTable(!updateTable);
      setAlert({
        typeOfAlert: SUCCESS,
        message: CANCEL_INVITATION_MESSAGE,
        showAlert: true,
      });
      handleClose();
    } catch (error: any) {
      setAlertDisable({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };
  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <div className="w-100">
          {alertDisable.showAlert && <GenericAlert alert={alertDisable} />}
        </div>
        <Typography className="f-s-20 m-t-50 m-b-50 text-center">
          {messageByEntity[entity]}
        </Typography>

        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            Volver
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            disabled={disableButton}
            onClick={handleDisable}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default CancelInvitationModal;
