import { Box, Button, Grid, Typography } from "@mui/material";
import {
  ACCOUNT_PATH,
  CONFIGURATION_PATH,
  ENTITY_ROUTES,
  INITIAL_CONFIGURATION_COMPLETE,
  INITIAL_CONFIG_SUCCESS_MESSAGES,
} from "../../utils/constants";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import Navbar from "../../components/navbar/Navbar";
import { storeData } from "../../utils/storage";

const InitialConfigSuccess = () => {
  const {
    currentUser: { entity },
    refreshUserData,
    rules: { contractOwner },
  } = useContext(UserContext);
  const [disabledButons, setDisabledButtons] = useState(true);
  const navigate = useNavigate();

  const handleRedirect = (path: string) =>
    navigate(`${ENTITY_ROUTES[entity]}${path}`);

  const refreshAndEnable = async () => {
    await refreshUserData();
    setDisabledButtons(false);
  };

  useEffect(() => {
    refreshAndEnable();
    return () => storeData(INITIAL_CONFIGURATION_COMPLETE, "complete");
  }, []);
  return (
    <>
      <Navbar />
      <Box
        component="div"
        className="initial-config-container d-flex bg-input"
        marginTop={8}
      >
        <div className="config-success-background" />
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography className="f-s-42 color-primary">
              ¡Felicidades!
            </Typography>
            <Typography className="f-s-20 m-t-40">
              Tu usuario ha sido habilitado en Coatí.
            </Typography>
            <Typography className="f-s-20 m-t-10">
              Al acceder a la sección “Cuenta”, encontrarás tus API KEY para
              realizar pruebas.
            </Typography>
            <Typography className="f-s-20 m-t-10">
              {contractOwner && INITIAL_CONFIG_SUCCESS_MESSAGES[contractOwner]}
            </Typography>
            <Grid container item gap={2} flexWrap="nowrap" className="m-t-50">
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={disabledButons}
                  variant="outlined"
                  className="back-button"
                  onClick={() => handleRedirect(CONFIGURATION_PATH)}
                >
                  Ir a Configuración
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={disabledButons}
                  variant="contained"
                  onClick={() => handleRedirect(ACCOUNT_PATH)}
                >
                  Ir a Cuenta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InitialConfigSuccess;
