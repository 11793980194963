import React from "react";
import { FormHelperText } from "@mui/material";
import {
  ICredentialsRapiboyPayload,
  ICredentialsRapiboy,
  ICredentialsOcasaPayload,
  ICredentialsOcasa,
  ICarrier,
  ICarriersChanges,
} from "../interfaces/configuration";
import { OCA, OCASA, RAPIBOY } from "./constants";
import { TEntity } from "../interfaces/userContext";
import { postCarriersChanges } from "../requests/carrierRequests";

// TODO PASAR ESTA FUNCION A UN ARCHIVO GENERAL (SE USA EN MUCHOS COMPONENTES)
export const showInputErrors = (inputErrors: string[] = []) =>
  inputErrors.map((message: string) => (
    <FormHelperText className="color-error" key={message}>
      {message}
    </FormHelperText>
  ));

const genericCredentialsChanged = (
  credentials: any,
  newCredentials: any
): boolean => {
  const keys = Object.keys(newCredentials);

  console.info("Keys", keys);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (key === "extended_week")
      console.info(
        "Extended week log",
        credentials.extended_week,
        newCredentials.extended_week
      );

    if (
      key !== "expired" &&
      String(credentials[key]) !== String(newCredentials[key])
    )
      return true;
  }

  return false;
};

const ocasaRapiboyCredentialsChanged = (
  credentials: ICredentialsOcasaPayload | ICredentialsRapiboyPayload,
  newCredentials: ICredentialsOcasa | ICredentialsRapiboy
): boolean => {
  const parsedCredentials = {
    ...credentials,
    dimensions: credentials?.dimensions,
  };

  return genericCredentialsChanged(parsedCredentials, newCredentials);
};

export const credentialHasChanges = (
  olName: string,
  credentials: any,
  newCredentials: any
): boolean => {
  console.info("[utils/credentials.credentialHasChanges]");
  switch (olName) {
  case OCA:
    return genericCredentialsChanged(credentials, newCredentials);
  case OCASA:
  case RAPIBOY:
    return ocasaRapiboyCredentialsChanged(credentials, newCredentials);
  default:
    return false;
  }
};

export const getFormDataFromCarriersInfo = (
  carriersChanges: ICarriersChanges,
  entity: TEntity,
  selectedMarketplace?: string
) => {
  const formData = new FormData();
  const carriersToUpdate: any[] = [];

  Object.entries(carriersChanges).forEach(([key, values]) => {
    formData.append(`matrix_${key}`, values.credentials?.matrix);
    carriersToUpdate.push(values);
  });

  formData.append("carriers", JSON.stringify(carriersToUpdate));

  return postCarriersChanges({
    formData,
    selectedMarketplace,
    entity,
  });
};

export const getFormDataFromCarriersList = (
  carriersChanges: ICarrier[],
  entity: TEntity,
  selectedMarketplace?: string
) => {
  const formData = new FormData();
  const mappedCarriers = carriersChanges.map(
    ({ credentials, name, ...rest }) => {
      formData.append(`matrix_${name}`, credentials?.matrix);

      return {
        credentials,
        name,
        ...rest,
      };
    }
  );

  formData.append("carriers", JSON.stringify(mappedCarriers));

  return postCarriersChanges({
    formData,
    selectedMarketplace,
    entity,
  });
};
