import { TableCell, IconButton, Tooltip } from "@mui/material";
import { IOrdersActionsTable } from "../../interfaces/order";
import {
  AUDITS_PATH,
  FINAL_STATUS_LIST,
  MARKETPLACE,
  ORDERS_PERMISSION,
  STATUS_TO_SHOW_CANCEL_AND_LABEL_DOWNLOAD,
  STATUS_TO_SHOW_REFUND_ICON,
} from "../../utils/constants";
import { canRefundOrCancel } from "../../utils/orders";
import { usePermission } from "../../customHooks/usePermission";

const ListOrdersActions = ({
  row,
  handleSelectedAction,
  showActions,
  rules: {
    entitiesAbleToCancel,
    entitiesAbleToChangeStatus,
    entitiesAbleToReturn,
  },
  entity,
}: IOrdersActionsTable) => {
  const { can_write } = usePermission(ORDERS_PERMISSION);
  const isAudit = window.location.pathname.includes(AUDITS_PATH);

  return (
    <TableCell key={row.trackingNumber}>
      {Object.keys(row.orderInfo).length > 0 && (
        <>
          <Tooltip arrow title="Ver" placement="top">
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                handleSelectedAction(isAudit ? "view-audit" : "info", row);
              }}
            >
              <span className="material-symbols-rounded">visibility</span>
            </IconButton>
          </Tooltip>
          {!isAudit && (
            <>
              {showActions && (
                <>
                  {can_write &&
                    STATUS_TO_SHOW_CANCEL_AND_LABEL_DOWNLOAD.includes(
                      row.status
                    ) && (
                    <>
                      {row.labels && entity !== MARKETPLACE && (
                        <Tooltip
                          arrow
                          title="Descargar etiqueta"
                          placement="top"
                        >
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleSelectedAction("label", row)}
                          >
                            <span className="material-symbols-rounded">
                                sim_card_download
                            </span>
                          </IconButton>
                        </Tooltip>
                      )}
                      {!row.orderInfo?.restrictCancellation &&
                          canRefundOrCancel(entity, entitiesAbleToCancel) && (
                        <Tooltip arrow title="Cancelar" placement="top">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              handleSelectedAction("delete", row);
                            }}
                          >
                            <span className="material-symbols-rounded">
                                  close
                            </span>
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}

                  {can_write &&
                    STATUS_TO_SHOW_REFUND_ICON.includes(row.status) &&
                    canRefundOrCancel(entity, entitiesAbleToReturn) && (
                    <Tooltip arrow title="Devolver" placement="top">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          handleSelectedAction("refund", row);
                        }}
                      >
                        <span className="material-symbols-rounded">
                            refresh
                        </span>
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              <Tooltip arrow title="Historial" placement="top">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleSelectedAction("history", row);
                  }}
                >
                  <span className="material-symbols-rounded">schedule</span>
                </IconButton>
              </Tooltip>
              {entitiesAbleToChangeStatus?.includes(entity) &&
                can_write &&
                !FINAL_STATUS_LIST.includes(row.status) && (
                <Tooltip arrow title="Editar estado" placement="top">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      handleSelectedAction("edit", row);
                    }}
                  >
                    <span className="material-symbols-rounded">edit</span>
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
    </TableCell>
  );
};
export default ListOrdersActions;
