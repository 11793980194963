import { useContext } from "react";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { INavbarItem, INavbarOptionsMenu } from "../../interfaces/navbar";
import { UserContext } from "../../contexts/userContext";

const NavbarOptionsMenu = ({
  anchorElMenu,
  openMenu,
  navbarOptions,
  handleCloseMenu,
  getAvatar,
  disconnect,
  showAll
}: INavbarOptionsMenu) => {
  const navigate = useNavigate();
  const {
    currentUser: { name },
    logout,
  } = useContext(UserContext);

  const handleNavigate = (slug: string) => {
    navigate(slug);
    handleCloseMenu();
  };

  const handleLogOut = () => {
    logout();
    disconnect();
  };

  return (
    <Menu
      anchorEl={anchorElMenu}
      open={openMenu}
      onClose={handleCloseMenu}
      classes={{ paper: "navbar__options__menu" }}
    >
      {getAvatar(name, "navbar__menu__avatar")}
      <Typography className="f-s-20 m-b-20 m-t-10">{name}</Typography>
      <Divider variant="middle" flexItem light />
      {showAll && navbarOptions.map(
        ({ icon, label, slug }: INavbarItem) => (
          <MenuItem
            component="a"
            key={label}
            onClick={() => handleNavigate(slug)}
            disableRipple
          >
            <span className="material-symbols-rounded">{icon}</span>
            <p className="f-s-14">{label}</p>
          </MenuItem>
        )
      )}
      <MenuItem onClick={handleLogOut} disableRipple>
        <span className="material-symbols-rounded">logout</span>
        <p className="f-s-14">Cerrar sesión</p>
      </MenuItem>
    </Menu>
  );
};

export default NavbarOptionsMenu;
