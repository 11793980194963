import React, { useContext, useState } from "react";
import { Tab, Typography, Grid, Box } from "@mui/material";
import { UserContext } from "../../contexts/userContext";
import {
  ECOMMERCE,
  MARKETPLACE,
  MERCHANT,
  INTERNAL,
  OWNER_ROLE_NAME,
} from "../../utils/constants";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Carriers from "./Carriers";
import Rules from "../../components/configuration/RulesContainer";
import WebhookSettings from "../../components/configuration/WebhookSettings";
import { IUserRole } from "../../interfaces/userContext";

const CONFIGURATION_TABS = [
  {
    id: "carriers",
    label: "Operadores Logísticos",
    component: <Carriers />,
  },
  {
    id: "permissions",
    label: "Permisos",
    component: (
      <Grid lg={4} className="m-t-30">
        <Rules />
      </Grid>
    ),
  },
  {
    id: "webhook",
    label: "Notificación de estados",
    component: (
      <Grid lg={4} className="m-t-30">
        <WebhookSettings />
      </Grid>
    ),
  },
];

const getBlacklistedTabs = (entity: string): string[] => {
  switch (entity) {
  case ECOMMERCE:
    return ["permissions"];
  case MERCHANT:
  case INTERNAL:
    return ["webhook", "permissions"];
  case MARKETPLACE:
  default:
    return [];
  }
};

const getTabs = (entity: string, roles: IUserRole[]) => {
  const blacklist = getBlacklistedTabs(entity);

  return CONFIGURATION_TABS.filter(({ id }) => {
    /* Webhook tab should only be available for marketplace and ecommerce entities with owner role */
    if (id === "webhook" && (entity === MARKETPLACE || entity === ECOMMERCE))
      return roles.find(({ name }) => name === OWNER_ROLE_NAME);
    return !blacklist.includes(id);
  });
};

const Configuration = () => {
  const [activeTab, setActiveTab] = useState("carriers");
  const {
    currentUser: { entity, roles },
  } = useContext(UserContext);

  const tabs = getTabs(entity, roles);

  return (
    <Grid container flexDirection="column">
      <Typography className="f-s-20">Configuración</Typography>

      <TabContext value={activeTab}>
        <Box
          className="m-t-20"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "fit-content",
          }}
        >
          <TabList onChange={(_, newValue) => setActiveTab(newValue)}>
            {tabs.map(({ id, label }) => (
              <Tab key={id} value={id} label={label} />
            ))}
          </TabList>
        </Box>
        {tabs.map(({ id, component }, index) => (
          <TabPanel key={index} value={id}>
            {component}
          </TabPanel>
        ))}
      </TabContext>
    </Grid>
  );
};

export default Configuration;
