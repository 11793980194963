import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  ICredentialsHop,
  ICredentialsHopErrors,
  ICredentialsStep,
} from "../../../interfaces/configuration";
import ConfirmChangesCredentials from "../ConfirmChangesCredentials";
import { ERRORS_MESSAGES } from "../../../utils/constants";
import { validateInputs } from "../../../utils/inputValidations";
import { IValidation } from "../../../interfaces/form";
import CredentialsHop from "../../forms/CredentialsHop";
import GenericForm from "../../GenericForm";
import GenericAlert from "../../GenericAlert";

const {
  endDateRequired,
  endDateNotInThePast,
  emailRequired,
  emailInvalid,
  passwordRequired,
  hopIdRequired,
  hopSecretRequired,
  sellerCodeRequired,
  onlyNumbers,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "hop_id",
    type: "required",
    message: hopIdRequired,
  },
  {
    id: "hop_id",
    type: "only-numbers",
    message: onlyNumbers,
  },
  {
    id: "hop_secret",
    type: "required",
    message: hopSecretRequired,
  },
  {
    id: "seller_code",
    type: "required",
    message: sellerCodeRequired,
  },
  {
    id: "expired_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "expired_date",
    type: "future_date",
    message: endDateNotInThePast,
  },
];

const HopCredentials = ({
  handleCancel,
  loading,
  alert,
  carrier,
  handleSubmitCarriers,
}: ICredentialsStep) => {
  const [credentials, setCredentials] = useState({} as ICredentialsHop);
  const [errors, setErrors] = useState({} as ICredentialsHopErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(credentials, formValidations);

    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      const dateExpired = new Date(credentials.expired_date) <= new Date();

      const newCredentials = {
        ...credentials,
        expired: dateExpired,
      };
      handleSubmitCarriers([
        {
          ...carrier,
          credentials: newCredentials,
        },
      ]);
    }
  };

  return (
    <>
      <Typography className="f-s-24 m-b-20">Credenciales de HOP</Typography>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm onSubmit={handleSubmit}>
        <CredentialsHop
          values={credentials}
          setValues={setCredentials}
          errors={errors}
        />
      </GenericForm>
      <ConfirmChangesCredentials
        onClose={handleCancel}
        handleSubmit={handleSubmit}
        disabled={loading}
        backText="Cambiar operador"
        confirmText="Finalizar"
      />
    </>
  );
};

export default HopCredentials;
