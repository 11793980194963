import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import { IPlan, IPlansStep } from "../../interfaces/signup";
import Plan from "../landing/Plan";

// TODO traer listado desde api de config
// import axios from "axios";
// import { CONFIG_API_BASE_URL } from "../../utils/constants";

// TODO traer listado desde api de config
export const plans: IPlan[] = [
  {
    id: "63484d2a376afbecb35e02b7",
    title: "Básico",
    value: "10.00",
    totalOrders: "100",
  },
  {
    id: "63484d2a376afbecb35e02b8",
    title: "Estándar",
    value: "20.00",
    totalOrders: "500",
  },
  {
    id: "63484d2a376afbecb35e02b9",
    title: "Premium",
    value: "30.00",
    totalOrders: "1000",
  },
];

const Plans = ({
  setGridSize,
  goForward,
  selectedValues,
  setSelectedValues,
}: IPlansStep) => {
  const handleSelectedPlan = (planId: string) => {
    setSelectedValues({ ...selectedValues, planId });
    goForward();
  };

  // TODO traer listado desde api de config
  // const getPlans = () =>
  //   axios
  //     .get(`${CONFIG_API_BASE_URL}/api/config/plans/all`)
  //     .then((res) => console.log(res))
  //     .catch((error) => console.log(error));
  useEffect(() => {
    // getPlans();
    setGridSize(7);
  }, []);

  return (
    <Grid
      container
      rowSpacing={3}
      className="m-t-50"
      justifyContent="space-around"
    >
      {plans.map(({ title, value, totalOrders, id }: IPlan) => (
        <Plan
          key={id}
          id={id}
          title={title}
          value={value}
          totalOrders={totalOrders}
          handlePlanSelect={() => handleSelectedPlan(id)}
        />
      ))}
    </Grid>
  );
};

export default Plans;
