import React, { useContext } from "react";
import { NotificationsContext } from "../../contexts/notificationContext";
import { LIMIT } from "../../utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box } from "@mui/material";
import { NotificationRow } from "./NotificationRow";
import { INoReadNotificationsProps, IRequestNotificationsFilters } from "../../interfaces/notifications";

const NoReadNotifications = ({
  notifications,
  setNotificationsToDelete,
  notificationsToDelete,
  scroll,
}: INoReadNotificationsProps) => {
  const { socket } = useContext(NotificationsContext);

  const requestNotifications = (filters: IRequestNotificationsFilters) =>
    socket.emit("request-notifications", {
      ...filters,
      limit: LIMIT,
      noRead: true,
    });

  const handleScroll = () =>
    requestNotifications({
      skip: notifications.length,
      lastCreatedAt: notifications[0].createdAt,
    });


  return (
    <InfiniteScroll
      className="br-10"
      dataLength={notifications.length}
      next={handleScroll}
      hasMore={scroll}
      loader={<Box/>}
      scrollableTarget="infiniteScroll"
    >
      <Box paddingTop="1.25rem" paddingBottom="2rem" className="bg-light w-630">
        {notifications?.length ? (
          notifications.map((notification) => (
            <NotificationRow
              notification={notification}
              notificationsToDelete={notificationsToDelete}
              setNotificationsToDelete={setNotificationsToDelete}
            />
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="notification-unread text-muted f-s-14"
          >
            No hay nada nuevo por aquí.
          </Box>
        )}
      </Box>
    </InfiniteScroll>
  );
};

export default NoReadNotifications;
