import React from "react";
import { Grid } from "@mui/material";
import { ILoader } from "../interfaces/loader";
import loaderGif from "../images/loader.gif";

const Loader = ({ size }: ILoader) => (
  <Grid display="flex" justifyContent="center" alignItems="center">
    <img src={loaderGif} alt="loader" className={`loader-size-${size}`} />
  </Grid>
);

export default Loader;
