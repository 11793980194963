import { Fragment } from "react";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { ConfirmDisableCarriers } from "../../components/modals/ConfirmDisableCarriers";
import CredentialsChazkiModal from "../../components/modals/CredentialsChazkiModal";
import CredentialsOcaModal from "../../components/modals/CredentialsOcaModal";
import CredentialsOcasaModal from "../../components/modals/CredentialsOcasaModal";
import CredentialsRapiboyModal from "../../components/modals/CredentialsRapiboyModal";
import {
  ICarrierChanged,
  IConfigurationModals,
  IModalsState,
} from "../../interfaces/configuration";
import { ANDREANI, CHAZKI, HOP, INTERNAL, OCA, OCASA, RAPIBOY } from "../../utils/constants";
import CredentialsHopModal from "../../components/modals/CredentialsHopModal";
import CredentialsAndreaniModal from "../../components/modals/CredentialsAndreaniModal";
import EditCarrierInfoModal from "../../components/modals/EditCarrierInfoModal";

const CredentialsModal = ({
  carriers,
  carriersChanges,
  openModal: { type, open },
  setOpenModal,
  handleGetCarriers,
  handleCarriersChanges,
  handleSubmitChanges,
  entity
}: IConfigurationModals) => {
  const handleCloseModal = () => setOpenModal({ open: false } as IModalsState);
  const getCredentialInfo = (carrierName: string) => {
    const [carrier] = carriers.filter(({ name }) => name === carrierName);

    // Tomamos la informacion del carrier modificado (si existe) o la del carrier original
    return carriersChanges[carrierName] || (carrier as ICarrierChanged);
  };
  if (entity === INTERNAL && type !== "confirmCancel")
    return (
      <EditCarrierInfoModal
        open={open}
        onClose={handleCloseModal}
        carrier={getCredentialInfo(type)}
        handleCarriersChanges={handleCarriersChanges}
      />
    );

  switch (type) {
  case OCA:
    return (
      <CredentialsOcaModal
        open={open}
        onClose={handleCloseModal}
        carrier={getCredentialInfo(OCA)}
        handleChangeOfConfig={handleCarriersChanges}
      />
    );
  case OCASA:
    return (
      <CredentialsOcasaModal
        open={open}
        onClose={handleCloseModal}
        carrier={getCredentialInfo(OCASA)}
        handleChangeOfConfig={handleCarriersChanges}
      />
    );
  case RAPIBOY:
    return (
      <CredentialsRapiboyModal
        open={open}
        onClose={handleCloseModal}
        carrier={getCredentialInfo(RAPIBOY)}
        handleChangeOfConfig={handleCarriersChanges}
      />
    );
  case CHAZKI:
    return (
      <CredentialsChazkiModal
        open={open}
        onClose={handleCloseModal}
        carrier={getCredentialInfo(CHAZKI)}
        handleChangeOfConfig={handleCarriersChanges}
      />
    );
  case HOP:
    return (
      <CredentialsHopModal
        open={open}
        onClose={handleCloseModal}
        carrier={getCredentialInfo(HOP)}
        handleChangeOfConfig={handleCarriersChanges}
      />
    );
  case ANDREANI:
    return (
      <CredentialsAndreaniModal
        open={open}
        onClose={handleCloseModal}
        carrier={getCredentialInfo(ANDREANI)}
        handleChangeOfConfig={handleCarriersChanges}
      />
    );
  case "confirmCancel":
    return (
      <ConfirmationModal
        onSubmit={() => {
          handleGetCarriers();
          handleCloseModal();
        }}
        open={open}
        onClose={handleCloseModal}
      />
    );
  case "confirmDisableCarriers":
    return (
      <ConfirmDisableCarriers
        open={open}
        onClose={handleCloseModal}
        handleSubmit={handleSubmitChanges}
        carriers={Object.keys(carriersChanges)}
      />
    );
  default: {
    console.warn(
      "No se ha encontrado el modal conrrespondiente al nombre de carrier"
    );

    return <Fragment />;
  }
  }
};

export default CredentialsModal;
