import { IEditOrInviteUserParams } from "../interfaces/usersRoles";
import { DELETE, GET, PATCH, POST, ROWS_PER_PAGE } from "../utils/constants";
import { fetchData } from "../utils/dataProvider";

const baseUrl = "/users";

export const getUsers = (filter: string, page: number) =>
  fetchData({
    url: `${baseUrl}?limit=${ROWS_PER_PAGE}&skip=${page}&search=${filter}`,
    method: GET
  });

export const deleteUser = (id: number) =>
  fetchData({ url: `${baseUrl}/${id}`, method: DELETE });

export const editUser = (user: { name: string }) =>
  fetchData({ url: `${baseUrl}`, method: PATCH, body: user });

export const editUserRoles = ({ id, roles }: IEditOrInviteUserParams) =>
  fetchData({ url: `${baseUrl}/roles/${id}`, method: PATCH, body: { roles } });

export const inviteUser = ({ name, email, roles }: IEditOrInviteUserParams) =>
  fetchData({
    url: `${baseUrl}/send-user-invitation`,
    method: POST,
    body: { invitedName: name, invitedEmail: email, desiredRoles: roles },
  });

export const registerUser = (user: { email: string; password: string }, token?: string) =>
  fetchData({
    url: `/users/invitation/accept/${token}`,
    method: PATCH,
    body: { ...user, token }
  });

export const getUserInvitationInfoByToken = (token: string) =>
  fetchData({ url: `${baseUrl}/invitation/${token}`, method: GET });

export const disabledUser = (userId: number, disabled?: boolean) =>
  fetchData({ url: `${baseUrl}/disable/${userId}`, method: PATCH, body: { disabled } });
