import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  FormHelperText,
  Typography,
} from "@mui/material";
import {
  IContactFormValues,
  IContactFormErrors,
} from "../../interfaces/contact";
import { validateInputs } from "../../utils/inputValidations";
import { fetchData } from "../../utils/dataProvider";
import {
  CONTACT_MAX_CHARACTERS,
  CONTACT_SUCCESS_MESSAGE,
  ERROR,
  ERRORS_MESSAGES,
  POST,
  SUCCESS,
  SUCCESS_ALERT_DURATION,
} from "../../utils/constants";
import GenericAlert from "../GenericAlert";
import { IAlert } from "../../interfaces/alert";
import { trimValues } from "../../utils/form";
import { useDebounce } from "../../customHooks/useDebounce";
import { IValidation } from "../../interfaces/form";

const { contactNameRequired, emailRequired, emailInvalid, messageRequired } =
  ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "name",
    type: "required",
    message: contactNameRequired,
  },
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "message",
    type: "required",
    message: messageRequired,
  },
];

const defaultValues: IContactFormValues = {
  name: "",
  email: "",
  message: "",
};

const ContactForm = () => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [errors, setErrors] = useState({} as IContactFormErrors);
  const [alert, setAlert] = useState({} as IAlert);

  const postContactSubmission = async () => {
    try {
      await fetchData({
        url: "/contacts",
        method: POST,
        body: trimValues(formValues),
      });
      setAlert({
        typeOfAlert: SUCCESS,
        message: CONTACT_SUCCESS_MESSAGE,
        showAlert: true,
      });
      setFormValues(defaultValues);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };

  const handleValueChange = (inputName: string, value: string) =>
    setFormValues({ ...formValues, [inputName]: value });

  const handleSubmit = () => {
    const inputErrors = validateInputs(formValues, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) postContactSubmission();
  };

  const { setSubmitting } = useDebounce(handleSubmit);

  useEffect(() => {
    if (alert.showAlert)
      setTimeout(() => setAlert({} as IAlert), SUCCESS_ALERT_DURATION);
  }, [alert]);

  return (
    <Grid container item xs={12} lg={10} xl={8} className="m-t-40">
      {alert.showAlert && (
        <Grid item xs={12} marginBottom={2}>
          <GenericAlert alert={alert} />
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            name="name"
            value={formValues.name}
            className="bg-input"
            fullWidth
            placeholder="Nombre y apellido"
            size="small"
            error={errors.name?.length > 0}
            onChange={({ target: { name, value } }) =>
              handleValueChange(name, value)
            }
          />
          {errors.name?.length > 0 &&
            errors.name.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="email"
            value={formValues.email}
            className="bg-input"
            fullWidth
            placeholder="Correo electrónico"
            size="small"
            error={errors.email?.length > 0}
            onChange={({ target: { name, value } }) =>
              handleValueChange(name, value)
            }
          />
          {errors.email?.length > 0 &&
            errors.email.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="message"
            value={formValues.message}
            className="bg-input"
            placeholder="Mensaje"
            multiline
            rows={7}
            inputProps={{ maxLength: CONTACT_MAX_CHARACTERS }}
            fullWidth
            error={errors.message?.length > 0}
            onChange={({ target: { name, value } }) =>
              handleValueChange(name, value)
            }
          />
          {errors.message?.length > 0 &&
            errors.message.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={1} alignSelf="flex-start">
          <Typography className="text-muted">
            {formValues.message?.length || 0}/{CONTACT_MAX_CHARACTERS}
          </Typography>
        </Grid>
        <Grid item xs={3} lg={2} className="m-t-20">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            onClick={() => setSubmitting(true)}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
