import { TMetricsActions } from "../interfaces/metrics";
import {
  CLIENTS_COUNT,
  MARKETPLACE_ORDERS,
  ORDERS_SUMMARY,
  STATUS_AND_SLA,
  STATUS_ORDERS_COUNT,
  TOTAL_COUNT,
} from "../utils/constants";

export const metricsReducer = (
  state: any,
  action: { type: string; content: TMetricsActions }
) => {
  switch (action.type) {
  case ORDERS_SUMMARY:
    return {
      ...state,
      [ORDERS_SUMMARY]: action.content,
    };
  case TOTAL_COUNT:
    return {
      ...state,
      [TOTAL_COUNT]: action.content,
    };
  case STATUS_AND_SLA:
    return {
      ...state,
      [STATUS_AND_SLA]: action.content,
    };
  case STATUS_ORDERS_COUNT:
    return {
      ...state,
      [STATUS_ORDERS_COUNT]: action.content,
    };
  case MARKETPLACE_ORDERS:
    return {
      ...state,
      [MARKETPLACE_ORDERS]: action.content,
    };
  case CLIENTS_COUNT:
    return {
      ...state,
      [CLIENTS_COUNT]: action.content
    };
  case "defaultValue":
    return action.content;
  default:
    return state;
  }
};
