import { Navigate, Outlet, useLocation } from "react-router-dom";
import { IProtectedRoute } from "../interfaces/routes";
import {
  AUTHENTICATION_PATH,
  ENTITY_ROUTES,
  PRIVATE,
  PUBLIC,
} from "../utils/constants";
import { getIsLogout } from "../utils/storage";

const ProtectedRoute = ({
  user,
  token,
  children,
  privacy,
}: IProtectedRoute) => {
  const { pathname } = useLocation();
  const ignoreRedirect = getIsLogout();

  if (privacy === PRIVATE && !token) {
    const loginQuery =
      ignoreRedirect || pathname === "/" || pathname === AUTHENTICATION_PATH
        ? ""
        : `?redirectUrl=${pathname}`;

    return <Navigate to={`${AUTHENTICATION_PATH}${loginQuery}`} replace />;
  }

  if (privacy === PUBLIC && token && user.entity)
    return <Navigate to={ENTITY_ROUTES[user.entity]} replace />;

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
