import { Box, Checkbox, Typography } from "@mui/material";
import { ITermsAndConditions } from "../interfaces/signup";
import GenericLink from "./GenericLink";

const TermsAndConditions = ({
  setTermsAndConditions,
  termsAndConditions,
}: ITermsAndConditions) => (
  <Box display="flex" className="m-t-20">
    <Checkbox
      checked={termsAndConditions}
      onClick={() => setTermsAndConditions(!termsAndConditions)}
      disableRipple
      sx={{
        height: 0,
        paddingTop: 0,
        display: "flex",
        alignItems: "flex-start",
      }}
    />
    <Typography className="f-s-14">
      Acepto las
      {
        <GenericLink
          text={" Condiciones del servicio "}
          url={"https://google.com"}
          open_type={"_blank"}
          extraClass={"text-success"}
        />
      }
      y las
      {
        <GenericLink
          text={" Políticas de privacidad"}
          url={"https://google.com"}
          open_type={"_blank"}
          extraClass={"text-success"}
        />
      }
      .
    </Typography>
  </Box>
);

export default TermsAndConditions;
