import { TablePagination } from "@mui/material";
import React from "react";
import { IPaginationTable } from "../../interfaces/table";
import { ROWS_PER_PAGE } from "../../utils/constants";

const ListPagination = ({ total, page, onChangePage }: IPaginationTable) => (
  <TablePagination
    component="div"
    count={total || 0}
    rowsPerPage={ROWS_PER_PAGE}
    rowsPerPageOptions={[ROWS_PER_PAGE]}
    page={page || 0}
    onPageChange={(
      _event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => onChangePage(newPage)}
    showFirstButton
    showLastButton
    labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count > -1 ? count : `más de ${to}`}` }
  />
);

export default ListPagination;
