import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import LandingFooter from "./landing/LandingFooter";
import LandingNavbar from "./landing/LandingNavbar";
import { useEffect, useState } from "react";
import { getPlans } from "../utils/plans";
import { FREE_PLAN_NAME, PARTNERS_PLAN_NAME } from "../utils/constants";
import { IPlanFromDB } from "../interfaces/landing";

const LandingLayout = () => {
  const [plans, setPlans] = useState<IPlanFromDB[]>([]);

  const handleFetchPlans = async () => {
    const fetchedPlans = await getPlans();
    if (fetchedPlans) setPlans(fetchedPlans);
  };

  const freePlan = plans.find(
    ({ name }: { name: string }) => name === FREE_PLAN_NAME
  );

  const partnerPlan = plans.find(
    ({ name }: { name: string }) => name === PARTNERS_PLAN_NAME
  );

  useEffect(() => {
    handleFetchPlans();
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      className="bg-light landing-layout-container"
      paddingTop={{ xs: "56px", md: "64px" }}
    >
      <LandingNavbar freePlan={freePlan} />

      <Outlet context={{ partnerPlan, freePlan }} />

      <LandingFooter />
    </Grid>
  );
};

export default LandingLayout;
