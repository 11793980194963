import { Route } from "react-router-dom";
import IntegrationsTabs from "../components/tabs/IntegrationsTabs";
import UsersRolesTabs from "../components/tabs/UsersRolesTabs";
import {
  IBillingRoutes,
  IOrderRoutes,
  IRouteByPermission,
} from "../interfaces/routes";
import { TEntity } from "../interfaces/userContext";
import NotFound from "../pages/NotFound";
import AccountDetails from "../pages/account/AccountDetails";
import SubscriptionDetails from "../pages/account/SubscriptionDetails";
import Configuration from "../pages/configuration/Configuration";
// import Customization from "../pages/customization/Customization";
import ExportLabels from "../pages/orders/ExportLabels";
import ExportOrders from "../pages/orders/ExportOrders";
import OrderDetails from "../pages/orders/OrderDetails";
import OrderHistory from "../pages/orders/OrderHistory";
import Orders from "../pages/orders/Orders";
import RefundOrderDetails from "../pages/orders/RefundOrderDetails";
import {
  MERCHANT,
  PARTNERS_PLAN_NAME,
  ECOMMERCE,
  MARKETPLACE,
  ACCOUNT_PERMISSION,
  BILLING_PERMISSION,
  CONFIGURATION_PERMISSION,
  // CUSTOMIZATION_PERMISSION,
  EXPORT_PERMISSION,
  MANAGMENT_PERMISSION,
  USERS_PERMISSION,
  ORDERS_PERMISSION,
  SYS_CLIENTS_PERMISSION,
  SYS_CARRIERS_PERMISSION,
  SYS_METRICS_PERMISSION,
} from "./constants";
import { canRefundOrCancel } from "./orders";
import AuditsTabs from "../components/tabs/AuditsTabs";
import OrderAudit from "../pages/audits/OrderAudit";
import QuoteAudit from "../pages/audits/QuoteAudit";

/* const getCustomizationRoutes = () => (
  <Route path="personalizacion" element={<Customization />} />
); */

const getUsersRoutes = () => (
  <Route path="usuarios-roles" element={<UsersRolesTabs />} />
);

const getExportRoutes = () => (
  <Route path="exportar-ordenes" element={<ExportOrders />} />
);

const getConfigurationRoutes = () => (
  <Route path="configuracion" element={<Configuration />} />
);

const getAccountRoutes = () => (
  <Route path="cuenta" element={<AccountDetails />} />
);

const getBillingRoutes = ({ entity, planName }: IBillingRoutes) => (
  <Route
    path={
      entity === MERCHANT || planName === PARTNERS_PLAN_NAME
        ? "facturacion"
        : "suscripcion-y-facturacion"
    }
    element={<SubscriptionDetails />}
  />
);

const getManagmentRoutes = (entity: TEntity) => (
  <Route
    path={`gestion-${entity === MERCHANT ? "marketplaces" : "merchants"}`}
    element={<IntegrationsTabs />}
  />
);

const getSysClientsRoutes = () => (
  <Route path="gestion-clientes" element={<IntegrationsTabs />} />
);

const getSysCarriersRoutes = () => (
  <Route path="configuracion" element={<Configuration />} />
);

const getOrdersRoutes = ({ permission, rules, entity }: IOrderRoutes) => (
  <>
    <Route path="ordenes">
      <Route index element={<Orders />} />
      {entity === ECOMMERCE ||
        (Object.keys(rules).length &&
          canRefundOrCancel(entity, rules?.entitiesAbleToReturn) &&
          permission.values.can_write && (
          <Route
            path="devolucion/:trackingNumber"
            element={<RefundOrderDetails />}
          />
        ))}
      <Route path="historial/:trackingNumber" element={<OrderHistory />} />
      <Route path="datos-de-orden/:trackingNumber" element={<OrderDetails />} />
    </Route>
    {entity !== MARKETPLACE}
    <Route path="descargar-etiquetas" element={<ExportLabels />} />
    <Route path="auditoria">
      <Route index element={<AuditsTabs />} />
      <Route path="orden/:trackingNumber" element={<OrderAudit />} />
      <Route path="cotizacion/:trackingNumber" element={<QuoteAudit />} />
    </Route>
  </>
);

export const getRouteSetByPermission = ({
  permission,
  entity,
  rules,
  planName,
}: IRouteByPermission) => {
  switch (permission.name) {
  case ACCOUNT_PERMISSION:
    return getAccountRoutes();
  case BILLING_PERMISSION:
    return getBillingRoutes({ entity, planName });
  case CONFIGURATION_PERMISSION:
    return getConfigurationRoutes();
  /* Customization is disabled.
  case CUSTOMIZATION_PERMISSION:
    return getCustomizationRoutes(); */
  case EXPORT_PERMISSION:
    return getExportRoutes();
  case MANAGMENT_PERMISSION:
    return getManagmentRoutes(entity);
  case ORDERS_PERMISSION:
    return getOrdersRoutes({ entity, rules, permission });
  case USERS_PERMISSION:
    return getUsersRoutes();
  case SYS_CLIENTS_PERMISSION:
    return getSysClientsRoutes();
  case SYS_CARRIERS_PERMISSION:
    return getSysCarriersRoutes();
  case SYS_METRICS_PERMISSION:
    return getUsersRoutes();
  default:
    return <Route path="*" element={<NotFound />} />;
  }
};
