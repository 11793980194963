import React from "react";
import {
  Grid,
  Typography,
  TextField,
  FormHelperText,
  Button,
} from "@mui/material";
import GenericAlert from "../GenericAlert";
import { IRecoverPasswordForm } from "../../interfaces/recoverPassword";
import { AUTHENTICATION_PATH } from "../../utils/constants";
import GenericForm from "../GenericForm";

const RecoverPasswordForm = ({
  handleSubmit,
  emailRef,
  errors,
  alert,
  disableButton,
  navigate,
}: IRecoverPasswordForm) => (
  <>
    <Grid item xs={12}>
      <Typography className="f-s-14 m-t-20">
        Ingresa la dirección de correo electrónico asociada a tu cuenta.
      </Typography>
      {alert.showAlert && <GenericAlert alert={alert} />}
    </Grid>

    <Grid item xs={12} className="m-t-30">
      <GenericForm onSubmit={handleSubmit} disableSubmit={disableButton}>
        <Typography className="f-s-14 m-b-10">
          Ingresar correo electrónico
        </Typography>
        <TextField
          fullWidth
          inputRef={emailRef}
          size="small"
          name="billingEmail"
          placeholder="Ingresar correo electrónico"
          error={errors.email?.length > 0}
          autoFocus
        />
        {errors.email?.length > 0 &&
          errors.email?.map((message: string) => (
            <FormHelperText key={message} className="color-error">
              {message}
            </FormHelperText>
          ))}
      </GenericForm>
    </Grid>
    <Grid item xs className="m-t-100">
      <Button
        fullWidth
        variant="contained"
        disabled={disableButton}
        onClick={handleSubmit}
      >
        Confirmar
      </Button>
    </Grid>
    <Grid item xs={12} className="m-t-10">
      <Button
        fullWidth
        variant="text"
        onClick={() => navigate(AUTHENTICATION_PATH)}
      >
        Volver
      </Button>
    </Grid>
  </>
);

export default RecoverPasswordForm;
