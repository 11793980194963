import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import ModalBase from "./ModalBase";
import { validateInputs } from "../../utils/inputValidations";
import { IValidation } from "../../interfaces/form";
import { ERRORS_MESSAGES } from "../../utils/constants";
import {
  ICredentialsOcasa,
  ICredentialsOcasaErrors,
  ICredentialsOcasaPayload,
  IProductAgrement,
} from "../../interfaces/configuration";
import CredentialsOcasa from "../forms/CredentialsOcasa";
import { ICredentialsModal } from "../../interfaces/modal";
import ConfirmChangesCredentials from "../configuration/ConfirmChangesCredentials";
import GenericForm from "../GenericForm";

const {
  userRequired,
  passwordRequired,
  clientCodeRequired,
  cuitRequired,
  cuitInvalid,
  doorToDoorEndDateRequired,
  reverseLogisticsEndDateRequired,
  doorToDoorEndDateNotInThePast,
  reverseLogisticsEndDateNotInThePast,
  reverseOnlyNumbers,
  doorToDoorOnlyNumbers,
  doorToDoorRequired,
  widthRequired,
  heightRequired,
  depthRequired,
  weightRequired,
  widthGreatherThanZero,
  heightGreatherThanZero,
  depthGreatherThanZero,
  weightGreatherThanZero,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "user",
    type: "required",
    message: userRequired,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "client_code",
    type: "required",
    message: clientCodeRequired,
  },
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "door_to_door",
    type: "required",
    message: doorToDoorRequired,
  },
  {
    id: "door_to_door",
    type: "only-numbers",
    message: doorToDoorOnlyNumbers,
  },
  {
    id: "reverse",
    type: "required",
    message: reverseOnlyNumbers,
  },
  {
    id: "reverse",
    type: "only-numbers",
    message: reverseOnlyNumbers,
  },
  {
    id: "reverse_end_date",
    type: "required",
    message: reverseLogisticsEndDateRequired,
  },
  {
    id: "reverse_end_date",
    type: "future_date",
    message: reverseLogisticsEndDateNotInThePast,
  },
  {
    id: "door_to_door_end_date",
    type: "required",
    message: doorToDoorEndDateRequired,
  },
  {
    id: "door_to_door_end_date",
    type: "future_date",
    message: doorToDoorEndDateNotInThePast,
  },
  {
    id: "width",
    type: "required",
    message: widthRequired,
  },
  {
    id: "height",
    type: "required",
    message: heightRequired,
  },
  {
    id: "depth",
    type: "required",
    message: depthRequired,
  },
  {
    id: "weight",
    type: "required",
    message: weightRequired,
  },
  {
    id: "height",
    type: "number",
    message: heightGreatherThanZero,
  },
  {
    id: "width",
    type: "number",
    message: widthGreatherThanZero,
  },
  {
    id: "depth",
    type: "number",
    message: depthGreatherThanZero,
  },
  {
    id: "weight",
    type: "number",
    message: weightGreatherThanZero,
  },
];

const getDataFromAgreement = ({
  service_code,
  end_date,
  name,
}: IProductAgrement) => {
  switch (name) {
  case "DOOR_TO_DOOR":
    return { door_to_door_end_date: end_date, door_to_door: service_code };
  case "REVERSE_LOGISTICS":
    return { reverse_end_date: end_date, reverse: service_code };
  default:
    return {};
  }
};
const getProductAgreement = (product_agreement: IProductAgrement[]) => {
  let agreement_data = {};
  product_agreement?.forEach((agreement) => {
    agreement_data = { ...agreement_data, ...getDataFromAgreement(agreement) };
  });
  return agreement_data;
};

const CredentialsOcasaModal = ({
  open,
  onClose,
  carrier,
  handleChangeOfConfig,
}: ICredentialsModal) => {
  const { dimensions, product_agreement, ...restCredentials } =
    (carrier.credentials || {}) as ICredentialsOcasaPayload;
  const initialValues = {
    ...dimensions,
    ...restCredentials,
    ...getProductAgreement(product_agreement),
  };
  const [values, setValues] = useState(initialValues as ICredentialsOcasa);
  const [errors, setErrors] = useState({} as ICredentialsOcasaErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(values, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      const d2dExpired = new Date(values.door_to_door_end_date) <= new Date();
      const rlExpired = !!(
        values.reverse && new Date(values.reverse_end_date) <= new Date()
      );

      const { height, width, depth, ...restValues } = values;
      const newDimensions = { height, width, depth };

      handleChangeOfConfig({
        _id: carrier._id,
        name: carrier.name,
        disabled: carrier.disabled,
        credentials: {
          ...restValues,
          dimensions: newDimensions,
          expired: d2dExpired || rlExpired,
        },
      });
      onClose();
    }
  };
  return (
    <ModalBase open={open} handleClose={onClose} maxWidth="md">
      <Grid container>
        <Typography className="f-s-20 m-b-20">
          Ingresa los datos de tu contrato con Ocasa
        </Typography>

        <GenericForm onSubmit={handleSubmit}>
          <CredentialsOcasa
            values={values}
            setValues={setValues}
            errors={errors}
          />
        </GenericForm>

        <ConfirmChangesCredentials
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </ModalBase>
  );
};

export default CredentialsOcasaModal;
