import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { IOrderDescription } from "../../interfaces/order";
import OrderDescriptionContent from "./OrderDescriptionContent";

const OrderDescription = ({ order, isMobile, reduced = false }: IOrderDescription) => (
  <Grid item container xs={12} className="m-t-20">
    {isMobile ? (
      <OrderDescriptionContent {...order} reduced={reduced}/>
    ) : (
      <Card className="br-10 bg-light" sx={{width: "100%"}}>
        <CardContent sx={{ padding: "20px", paddingBottom: "20px !important" }}>
          <OrderDescriptionContent {...order} reduced={reduced}/>
        </CardContent>
      </Card>
    )}
  </Grid>
);

export default OrderDescription;
