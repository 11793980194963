import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme, useTheme } from "@mui/material";
import "../../styles/sidebar.css";
import SidebarList from "./SidebarList";
import { DRAWER_WIDTH } from "../../utils/constants";
import { ISidebar } from "../../interfaces/sidebar";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
  width: `calc(${theme.spacing(6)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)})`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: { theme: Theme; open: boolean }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex: "2",
  ...(open
    ? {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }
    : {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const Sidebar = ({ showContent, open }: ISidebar) => {
  const theme = useTheme();
  return (
    <Drawer variant="permanent" open={open} theme={theme}>
      <div className="sidebar-container">
        { showContent && <SidebarList /> }
      </div>
    </Drawer>
  );
};

export default Sidebar;
