import React, { ChangeEvent, useEffect, useState } from "react";
import { IAlert } from "../../interfaces/alert";
import GenericForm from "../../components/GenericForm";
import GenericAlert from "../../components/GenericAlert";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ITargetValue, IValidation } from "../../interfaces/form";
import { showInputErrors } from "../../utils/credentials";
import {
  AUTHENTICATION_PATH,
  ERROR,
  ERRORS_MESSAGES,
  PASSWORD_HELPER,
} from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import {
  getUserInvitationInfoByToken,
  registerUser,
} from "../../requests/usersRequests";
import { useNavigate, useParams } from "react-router-dom";
import { trimValues } from "../../utils/form";

const { passwordInvalid, passwordRequired, emailInvalid, emailRequired } =
  ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "password",
    type: "password",
    message: passwordInvalid,
  },
];

const SignupUser = () => {
  const { token } = useParams();
  const [user, setUser] = useState({} as { email: string; password: string });
  const [errors, setErrors] = useState({ email: [], password: [] });
  const [showPassword, setshowPassword] = useState(false);
  const [alert, setAlert] = useState({} as IAlert);
  const navigate = useNavigate();

  const setUserInfo = async (userToken: string) => {
    try {
      const { email } = await getUserInvitationInfoByToken(userToken);
      setUser({ email, password: "" });
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };

  useEffect(() => {
    if (token) setUserInfo(token);
  }, [token]);

  const handleChange = ({ name, value }: ITargetValue) =>
    setUser({
      ...user,
      [name]: value,
    });

  const handleSubmit = async () => {
    const inputErrors = validateInputs(user, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      try {
        await registerUser(trimValues(user), token);

        navigate(
          `${AUTHENTICATION_PATH}?email=${encodeURIComponent(user.email)}`
        );
      } catch (error: any) {
        setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      }
    }
  };

  return (
    <Box className="p-x-card" pt={6.75}>
      <Typography fontSize={24} className="m-b-10">
        ¡Te damos la bienvenida!
      </Typography>
      <Typography fontSize={24} className="m-b-20">
        Completa la configuración de tu cuenta
      </Typography>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm onSubmit={handleSubmit} disableSubmit={alert.showAlert}>
        <FormControl className="m-t-20" fullWidth>
          <Typography id="email-label" className="f-s-14 m-b-10">
            Correo electrónico
          </Typography>
          <OutlinedInput
            type="text"
            size="small"
            name="email"
            placeholder="Ingresar correo electrónico"
            disabled
            value={user.email}
            error={errors.email?.length > 0}
            autoFocus
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              handleChange(target)
            }
          />
          {errors.email?.length > 0 && showInputErrors(errors.email)}
        </FormControl>

        <FormControl className="m-t-20" fullWidth>
          <Typography id="password-label" className="f-s-14 m-b-10">
            Contraseña
          </Typography>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            size="small"
            name="password"
            placeholder="Ingresar contraseña"
            value={user.password}
            error={errors.password?.length > 0}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              handleChange(target)
            }
            endAdornment={
              <InputAdornment position="start">
                <IconButton
                  onClick={() => setshowPassword(!showPassword)}
                  edge="end"
                >
                  <span className="material-symbols-rounded f-s-18">
                    {showPassword ? "visibility" : "visibility_off"}
                  </span>
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{PASSWORD_HELPER}</FormHelperText>
          {errors.password?.length > 0 && showInputErrors(errors.password)}
        </FormControl>
      </GenericForm>

      <Button
        variant="contained"
        className="m-t-40 w-100"
        onClick={handleSubmit}
        disabled={alert.showAlert}
      >
        Confirmar
      </Button>
    </Box>
  );
};

export default SignupUser;
