import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  IDimension,
  IModifiedQuantities,
  IPackage,
  IPackageToRefund,
} from "../../interfaces/order";
import { IRefundPackagesTable } from "../../interfaces/table";
import { checkIfProductIsSelectedById, findPackage } from "../../utils/orders";
import RefundQuantitySelect from "../orders/RefundQuantitySelect";

const RefundPackagesTable = ({
  orderInfo,
  packagesToRefund,
  setPackagesToRefund,
}: IRefundPackagesTable) => {
  const [modifiedQuantities, setModifiedQuantities] = useState(
    {} as IModifiedQuantities
  );
  const handleProductChecked = ({
    sku,
    quantity,
  }: {
    sku: string;
    quantity: number;
  }) => {
    let modifiedProducts = [...packagesToRefund];
    const skuAlreadyExists = findPackage(sku, modifiedProducts);
    if (skuAlreadyExists) {
      modifiedProducts = modifiedProducts.filter(
        ({ sku: productSku }: { sku: string }) => productSku !== sku
      );
    } else
      modifiedProducts.push({
        sku,
        quantity: modifiedQuantities[sku] || quantity,
      });

    setPackagesToRefund([...modifiedProducts]);
  };

  const handleProductQuantityChange = (sku: string, quantity: number) => {
    // Buscar el paquete y actualizar con la cantidad modificada
    const modifiedProducts = packagesToRefund.map((item: IPackageToRefund) =>
      item.sku === sku ? { ...item, quantity } : item
    );
    setPackagesToRefund([...modifiedProducts]);
    setModifiedQuantities({ ...modifiedQuantities, [sku]: quantity });
  };

  return (
    <TableContainer component={Paper} className="m-t-20 br-20 bg-light">
      <Table className="refund-packages-table">
        <TableBody>
          {orderInfo?.packages.map((orderPackage: IPackage) => {
            const {
              _id: packageId,
              name,
              sku,
              quantity,
              sub_packages,
            } = orderPackage;
            return (
              <React.Fragment key={packageId}>
                <TableRow>
                  <TableCell className="text-bold">
                    <Checkbox
                      checked={checkIfProductIsSelectedById(
                        sku,
                        packagesToRefund
                      )}
                      onChange={() =>
                        handleProductChecked({
                          quantity,
                          sku,
                        })
                      }
                    />
                    {name}
                  </TableCell>
                  <TableCell className="text-bold">SKU {sku}</TableCell>
                  <TableCell>
                    <RefundQuantitySelect
                      packagesToRefund={packagesToRefund}
                      quantity={quantity}
                      packageId={sku}
                      handleChange={handleProductQuantityChange}
                      modifiedQuantities={modifiedQuantities}
                    />
                  </TableCell>
                </TableRow>

                {sub_packages?.length &&
                  sub_packages.map(
                    (
                      { height, weight, depth, width }: IDimension,
                      bulkIndex: number
                    ) => (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography className="f-s-14">
                              Bulto por producto: {bulkIndex + 1} /{" "}
                              {sub_packages.length}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {height} × {weight} × {depth} cm
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{width}kg</Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RefundPackagesTable;
