import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import GenericAlert from "../GenericAlert";
import {
  AUTHENTICATION_PATH,
  CUIT_PLACEHOLDER,
  ERROR,
  ERRORS_MESSAGES,
  GET,
  IVA_SITUATIONS,
  PUT,
} from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import {
  IBillingFormErrors,
  IBillingFormStep,
  ICompleteRegistrationSubmit,
  IValidateToken,
} from "../../interfaces/signup";
import { ITargetValue, IValidation } from "../../interfaces/form";
import { IAlert } from "../../interfaces/alert";
import { fetchData } from "../../utils/dataProvider";
import GenericForm from "../GenericForm";
import { showInputErrors } from "../../utils/credentials";
import { trimValues } from "../../utils/form";

const {
  emailInvalid,
  emailRequired,
  nameRequired,
  ivaSituationRequired,
  businessNameRequired,
  cuitInvalid,
  cuitRequired,
  commercialAddressRequired,
  commercialAddressHasToContainNumbers,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "name",
    type: "required",
    message: nameRequired,
  },
  {
    id: "billingEmail",
    type: "required",
    message: emailRequired,
  },
  {
    id: "billingEmail",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "ivaSituation",
    type: "required",
    message: ivaSituationRequired,
  },
  {
    id: "businessName",
    type: "required",
    message: businessNameRequired,
  },
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "commercialAddress",
    type: "required",
    message: commercialAddressRequired,
  },
  {
    id: "commercialAddress",
    type: "contain-numbers",
    message: commercialAddressHasToContainNumbers,
  },
];

const completeClientRegistration = async ({
  selectedValues,
  navigate,
  setAlert,
  token,
}: ICompleteRegistrationSubmit) => {
  try {
    await fetchData({
      url: `/clients/confirm/${token}`,
      method: PUT,
      body: trimValues(selectedValues),
    });
    navigate(`${AUTHENTICATION_PATH}?reason=signup`);
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  }
};

const validateToken = async ({
  token,
  setAlert,
  setInvalidToken,
}: IValidateToken) => {
  try {
    await fetchData({
      url: `/clients/validateToken/${token}`,
      method: GET,
    });
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    setInvalidToken(true);
  }
};

const BillingForm = ({
  selectedValues,
  setSelectedValues,
  setGridSize,
}: IBillingFormStep) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({} as IBillingFormErrors);
  const [alert, setAlert] = useState({} as IAlert);
  const [invalidToken, setInvalidToken] = useState(false);

  const handleChange = ({ name, value }: ITargetValue) =>
    setSelectedValues({
      ...selectedValues,
      [name]: value,
    });

  const handleSubmit = () => {
    const inputErrors = validateInputs(selectedValues, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0)
      completeClientRegistration({ selectedValues, navigate, setAlert, token });
  };

  useEffect(() => {
    setGridSize(4);
    validateToken({ token, setAlert, setInvalidToken });
  }, []);
  return (
    <>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm onSubmit={handleSubmit}>
        <Typography className="m-t-20 f-s-14 m-b-10">Nombre y Apellido</Typography>
        <TextField
          fullWidth
          size="small"
          name="userName"
          placeholder="Ingresar nombre y apellido"
          value={selectedValues.userName}
          error={errors.userName?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
          autoFocus
        />
        {errors.userName?.length > 0 && showInputErrors(errors.userName)}
        <Typography className="m-t-20 m-b-10 f-s-14">Alias</Typography>
        <TextField
          fullWidth
          size="small"
          name="name"
          placeholder="Ingresar alias"
          error={errors.name?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
          autoFocus
        />
        {errors.name?.length > 0 &&
          errors.name?.map((message) => (
            <FormHelperText className="color-error" key={message}>
              {message}
            </FormHelperText>
          ))}
        <FormControl className="m-t-20" fullWidth>
          <Typography className="f-s-14 m-b-10">
            Condición ante el IVA
          </Typography>
          <Select
            size="small"
            name="ivaSituation"
            error={errors.ivaSituation?.length > 0}
            onChange={({ target }: SelectChangeEvent) => handleChange(target)}
          >
            {IVA_SITUATIONS.map((name: string) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {errors.ivaSituation?.length > 0 && showInputErrors(errors.ivaSituation)}
        </FormControl>
        <Typography className="m-t-20 f-s-14 m-b-10">Razón Social</Typography>
        <TextField
          fullWidth
          size="small"
          name="businessName"
          placeholder="Razón Social"
          error={errors.businessName?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.businessName?.length > 0 && showInputErrors(errors.businessName)}
        <Typography className="m-t-20 f-s-14 m-b-10">CUIT</Typography>
        <TextField
          fullWidth
          size="small"
          name="cuit"
          placeholder={CUIT_PLACEHOLDER}
          error={errors.cuit?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.cuit?.length > 0 &&
          errors.cuit?.map((message: string) => (
            <FormHelperText className="color-error" key={message}>
              {message}
            </FormHelperText>
          ))}
        <Typography className="m-t-20 f-s-14 m-b-10">
          Domicilio Comercial
        </Typography>
        <TextField
          fullWidth
          size="small"
          name="commercialAddress"
          placeholder="Domicilio comercial"
          error={errors.commercialAddress?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.commercialAddress?.length > 0 && showInputErrors(errors.commercialAddress)}
        <Typography className="m-t-20 f-s-14 m-b-10">
          Correo electrónico de facturación
        </Typography>
        <TextField
          fullWidth
          size="small"
          name="billingEmail"
          placeholder="Ingresar correo electrónico de facturación"
          error={errors.billingEmail?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.billingEmail?.length > 0 && showInputErrors(errors.billingEmail)}
      </GenericForm>
      <Button
        variant="contained"
        className="m-t-40 w-100"
        onClick={handleSubmit}
        disabled={invalidToken}
      >
        Continuar
      </Button>
    </>
  );
};

export default BillingForm;
