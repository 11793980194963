import { Grid, Typography, TableContainer, Paper, Table } from "@mui/material";
import ListHeader from "../../tables/ListHeader";
import { IAuditHistoryTable } from "../../../interfaces/order";

const AuditHistoryTable = ({
  columns,
  renderEntries,
  title,
}: IAuditHistoryTable) => (
  <Grid item xs={12} display="flex" flexDirection="column" mb={5}>
    <Typography fontSize={24}>{title}</Typography>

    <TableContainer
      component={Paper}
      className="m-t-20 br-20  p-b-40 bg-light MuiCard-root"
    >
      <Table>
        <ListHeader columns={columns} />
        {renderEntries()}
      </Table>
    </TableContainer>
  </Grid>
);

export default AuditHistoryTable;
