import React from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import "../styles/landing.css";
import banner from "../images/landing/landing_banner.jpg";
import benefits from "../images/landing/landing_video.mp4";
import moreBenefits from "../images/landing/benefits_landing.mp4";
import SectionTitle from "../components/landing/SectionTitle";
import {
  BENEFITS,
  BENEFIT_ICONS,
  SECTION_THREE,
  SECTION_TWO,
  SECTION_ONE,
  FREE_PLAN_SIGNUP_PATH,
} from "../utils/constants";
import BenefitIcon from "../components/landing/BenefitIcon";
import { IBenefitIcon } from "../interfaces/landing";
import { useNavigate, useOutletContext } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  const { freePlan } = useOutletContext<any>();
  return (
    <>
      <Grid container>
        <img
          className="landing-banner w-100"
          src={banner}
          alt="Banner landing"
        />
      </Grid>
      <Grid id="benefits" container item className="p-x-landing p-y-landing">
        <SectionTitle title={SECTION_ONE} marginBottom={10} />
        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          alignItems="center"
          marginBottom={{ xs: 3, lg: 0 }}
        >
          <video src={benefits} autoPlay muted loop className="w-100 br-5" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingX={{ xs: 2, lg: 10 }}
        >
          {/* LIST */}
          <Typography className="f-s-16 f-s-md-24 w-100 m-b-10">
            Ahorrá tiempo y mejorá la experiencia de compra
          </Typography>
          <List className="benefits w-100">
            {BENEFITS.map((text) => (
              <ListItem key={text} disablePadding className="m-b-10">
                <ListItemIcon>
                  <span className="material-symbols-rounded color-primary">
                    check
                  </span>
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: "f-s-14 f-s-md-20" }}
                  primary={text}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid container item justifyContent="space-between" spacing={3}>
          {BENEFIT_ICONS.map(({ title, description, icon }: IBenefitIcon) => (
            <BenefitIcon
              key={icon}
              title={title}
              icon={icon}
              description={description}
            />
          ))}
        </Grid>
      </Grid>
      <Grid
        id="plans"
        container
        item
        className="bg-secondary p-x-landing p-y-landing"
        justifyContent="center"
      >
        <SectionTitle title={SECTION_TWO} marginBottom={10} />
        <Grid item lg={3}>
          <Button
            fullWidth
            onClick={() => navigate(`${FREE_PLAN_SIGNUP_PATH}${freePlan?._id}`)}
            disabled={!freePlan?._id}
            variant="contained"
          >
            Iniciar prueba gratuita
          </Button>
        </Grid>
      </Grid>
      <Grid
        id="contact"
        container
        item
        className="p-x-landing p-y-landing"
        justifyContent="center"
        marginBottom={{ xs: 3, lg: 20 }}
      >
        <SectionTitle title={SECTION_THREE} marginBottom={10} />
        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          alignItems="center"
          marginBottom={{ xs: 3, lg: 0 }}
        >
          <video
            src={moreBenefits}
            autoPlay
            muted
            loop
            className="w-100 br-5"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingX={{ xs: 2, lg: 10 }}
        >
          <Box marginBottom={{ xs: 4, lg: 6 }}>
            <Typography
              className="f-s-16 f-s-md-24 w-100 m-b-10"
              color="primary"
            >
              Tecnología
            </Typography>
            <Typography className="f-s-14 f-s-md-20">
              Múltiples herramientas en un sólo lugar. Una sola integración que
              conecta marketplaces con todos los operadores logísticos del país.
            </Typography>
          </Box>
          <Box>
            <Typography
              className="f-s-16 f-s-md-24 w-100 m-b-10"
              color="primary"
            >
              Contrato Coatí
            </Typography>
            <Typography className="f-s-12 f-s-md-14 f-s-md-20">
              Nos hacemos cargo de los contratos con los operadores logísticos
              optimizando costos y tiempos de entrega, mejorando la experiencia
              de los clientes finales.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Landing;
