import axios from "axios";
import config from "../config/config";

const { CONFIG_API_BASE_URL } = config;
export const getPlans = async () => {
  try {
    const { data: plans } = await axios.get(`${CONFIG_API_BASE_URL}/plans`);
    return plans;
  } catch (error) {
    console.error(error);
    return null;
  }
};
