import { TStorageKeys } from "../interfaces/storage";
import {
  CURRENT_MARKETPLACE,
  INITIAL_CONFIGURATION_COMPLETE,
  IS_LOGOUT,
  MARKETPLACE_NAME,
  TOKEN,
  VALID_STORAGE_KEYS,
} from "./constants";

export const storeData = (key: TStorageKeys, value: string) => {
  if (VALID_STORAGE_KEYS.includes(key)) localStorage.setItem(key, value);
};

export const getLocalToken = () => localStorage.getItem(TOKEN);

export const getInitialConfigurationComplete = () =>
  localStorage.getItem(INITIAL_CONFIGURATION_COMPLETE);

export const getLocalMarketplaceId = () =>
  localStorage.getItem(CURRENT_MARKETPLACE);

export const getSelectedMarketplaceName = () =>
  localStorage.getItem(MARKETPLACE_NAME);

export const getIsLogout = () => localStorage.getItem(IS_LOGOUT);

export const clearLocalData = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(CURRENT_MARKETPLACE);
  localStorage.removeItem(MARKETPLACE_NAME);
  localStorage.removeItem(INITIAL_CONFIGURATION_COMPLETE);
};

export const clearSelectedMarketplace = () => {
  localStorage.removeItem(CURRENT_MARKETPLACE);
  localStorage.removeItem(MARKETPLACE_NAME);
};

export const removeIsLogout = () => localStorage.removeItem(IS_LOGOUT);
