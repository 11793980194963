import {
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ERROR, GET, SPANISH_MODALITIES } from "../../utils/constants";
import { IAlert } from "../../interfaces/alert";
import { fetchData } from "../../utils/dataProvider";
import AuditWrapper from "../../components/orders/audit/AuditWrapper";
import { useParams } from "react-router-dom";
import { mongoTimeStampToString } from "../../utils/orders";
import { UserContext } from "../../contexts/userContext";
import OrderPackageDescription from "../../components/orders/OrderPackageDescription";
import ListHeader from "../../components/tables/ListHeader";
import { getFeeString } from "../../utils/text";

const QuoteAudit = () => {
  const { trackingNumber } = useParams();
  const { marketplaceIntegrations }: any = useContext(UserContext);
  const [audit, setAudit] = useState({} as any);
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(true);

  const getMerchantNameFromId = (id: number) =>
    marketplaceIntegrations.integrations.find(
      ({ merchant: { id: integrationId } }: any) => id === integrationId
    )?.merchant.entity.name;

  const getAudit = async () => {
    setLoading(true);
    try {
      const response = await fetchData({
        url: `/orders/audit/quotes/${trackingNumber}`,
        method: GET,
      });
      setAudit(response);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAudit();
  }, []);

  return (
    <AuditWrapper loading={loading} alert={alert}>
      <Grid container xs={12} lg={9}>
        <Grid item xs={12} mb={1.25}>
          <Card className="br-10 bg-light" sx={{ width: "100%" }}>
            <CardContent sx={{ padding: "20px" }}>
              <Grid container spacing={{ xs: 2, md: 0 }}>
                <Grid item xs={6} md={4}>
                  <Typography className="text-bold m-t-10" fontSize={14}>
                    Fecha
                  </Typography>
                  <Typography fontSize={14}>
                    {mongoTimeStampToString(audit.createdAt)}
                  </Typography>
                </Grid>
                {marketplaceIntegrations.integrations && (
                  <Grid item xs={12} md={4}>
                    <Typography className="text-bold m-t-10" fontSize={14}>
                      Merchant
                    </Typography>
                    <Typography fontSize={14}>
                      {getMerchantNameFromId(audit.merchant_id)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {audit?.input?.packages && (
          <OrderPackageDescription
            packages={audit.input.packages}
            isMobile={false}
          />
        )}

        <Grid item xs={12} mt={5}>
          <TableContainer
            component={Paper}
            className="br-20  p-b-40 bg-light MuiCard-root"
          >
            <Table>
              <ListHeader
                columns={[
                  { label: "Operadores cotizados" },
                  { label: "Tipos de servicio" },
                  { label: "Precio" },
                  { label: "Errores" },
                ]}
              />
              {audit?.responses?.map((response: any) => (
                <TableRow>
                  <TableCell>{response.name.toUpperCase()}</TableCell>
                  <TableCell>
                    {SPANISH_MODALITIES[response.response?.service] ||
                      response.response?.service ||
                      ""}
                  </TableCell>
                  <TableCell>
                    {getFeeString(response.response?.price) || ""}
                  </TableCell>
                  <TableCell>
                    {response.errors && (
                      <>
                        {response.errors.length}{" "}
                        {response.errors.length > 1 ? "errores" : "error"}:
                        <ul style={{ margin: 0 }}>
                          {response.errors.map(({ code }: { code: string }) => (
                            <li>{code}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </AuditWrapper>
  );
};

export default QuoteAudit;
