import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { getOrderStatus } from "../../utils/orders";
import { getColorBadge } from "../../utils/home";
import SummarySkeleton from "./SummarySkeleton";
import { MARKETPLACE } from "../../utils/constants";
import { TEntity } from "../../interfaces/userContext";
import { IMetricsOrdersCount } from "../../interfaces/metrics";

const OrdersSummary = ({
  ordersInfo,
  entity,
}: {
  ordersInfo: IMetricsOrdersCount[];
  entity: TEntity;
}) => {
  const navigate = useNavigate();

  return (
    <>
      {ordersInfo ? (
        <Grid
          container
          item
          xs
          lg={entity === MARKETPLACE ? 4 : 6}
          flexDirection="column"
          pr={2.5}
          className="summaryOlsContainer"
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mt={5}
          >
            <Typography className="f-s-20">
              Resumen de envíos en curso
            </Typography>
            <Button
              variant="text"
              sx={{ padding: 0, "&:hover": { backgroundColor: "transparent" } }}
              onClick={() => navigate("ordenes")}
            >
              Ver detalle
            </Button>
          </Grid>
          <Grid container flexDirection="column" mb={7.5}>
            {ordersInfo.map(({ name, count }: IMetricsOrdersCount) => (
              <Fragment key={name}>
                <Grid container alignItems="center" py={2.5}>
                  <Grid item xs={1}>
                    <div
                      className={`badge-orders-metrics ${getColorBadge(name)}`}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className="f-s-14">
                      {getOrderStatus(name)?.label || name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className="f-s-14" textAlign="right">
                      {count} envíos
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: "#DEDEDE" }} />
              </Fragment>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs
          lg={entity === MARKETPLACE ? 4 : 6}
          flexDirection="column"
          pr={2.5}
          pt={5}
          pb={7.5}
          className="summaryOlsContainer"
        >
          <SummarySkeleton rows={6} />
        </Grid>
      )}
    </>
  );
};

export default OrdersSummary;
