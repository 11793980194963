import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MEDIUM, PARENT_PATH } from "../../../utils/constants";
import GenericAlert from "../../GenericAlert";
import Loader from "../../Loader";
import { IAuditWrapper } from "../../../interfaces/order";

const AuditWrapper = ({
  children,
  alert,
  loading,
}: IAuditWrapper) => {
  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <Grid item xs={12}>
          <Loader size={MEDIUM} />
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} display="flex" alignItems="center" mb={2.5}>
            <IconButton onClick={() => navigate(PARENT_PATH)}>
              <span className="material-symbols-rounded text-dark">
                keyboard_backspace
              </span>
            </IconButton>
            <Typography className="f-s-20 text-dark" ml={1}>
              Ver auditoría
            </Typography>
          </Grid>

          {alert && alert.showAlert ? (
            <Grid item xs={12}>
              <GenericAlert alert={alert} />
            </Grid>
          ) : (
            <>{children}</>
          )}
        </Grid>
      )}
    </>
  );
};

export default AuditWrapper;
