import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  IActionsTable,
  IIntegrationsTable,
  IDisableActionParams,
} from "../../interfaces/table";
import { ISelectedIntegration } from "../../interfaces/integrations";
import ListPagination from "./ListPagination";
import ListHeader from "./ListHeader";
import { INTERNAL, MANAGMENT_PERMISSION, MARKETPLACE } from "../../utils/constants";
import { parseCuit } from "../../utils/cuit";
import "../../styles/tables.css";
import { usePermission } from "../../customHooks/usePermission";

const tableColumns: any = [
  { label: "Alias" },
  { label: "Correo electrónico" },
  { label: "Cuit" },
  { label: "Estado" },
  { label: "Acciones" },
];

const DisableAction = ({
  handleDisableAction,
  disabled,
  id,
  name,
  resetValue,
}: IDisableActionParams) => {
  const [disableValue, setDisableValue] = useState(!disabled);

  useEffect(() => {
    setDisableValue(!disabled);
  }, [resetValue]);

  return (
    <TableCell>
      <Switch
        size="small"
        onClick={() => {
          handleDisableAction(disableValue, id, name);
          setDisableValue(!disableValue);
        }}
        checked={disableValue}
      />
    </TableCell>
  );
};

const TableActions = ({
  row,
  can_write,
  setSelectedRow,
  handleSelectedAction,
}: IActionsTable) => (
  <TableCell key={row.email}>
    <Tooltip arrow title="Ver" placement="top">
      <IconButton
        size="small"
        color="primary"
        onClick={() => {
          setSelectedRow(row);
          handleSelectedAction("info");
        }}
      >
        <span className="material-symbols-rounded">visibility</span>
      </IconButton>
    </Tooltip>
    {can_write && (
      <Tooltip arrow title="Eliminar" placement="top">
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            setSelectedRow(row);
            handleSelectedAction("delete");
          }}
        >
          <span className="material-symbols-rounded">delete</span>
        </IconButton>
      </Tooltip>
    )}
  </TableCell>
);

const ListIntegrations = ({
  integrations,
  setSelectedIntegration,
  handleSelectedAction,
  handlePageChange,
  handleDisableAction,
  pagination: { count, page },
  entity,
  resetValue,
}: IIntegrationsTable) => {
  const { can_write } = usePermission(MANAGMENT_PERMISSION);
  const isInternalUser = entity === INTERNAL;
  return (
    <TableContainer component={Paper} className="m-t-40 br-20 bg-light">
      <Table>
        <ListHeader columns={tableColumns} />
        <TableBody>
          {integrations.map((row: ISelectedIntegration) => {
            const { id, name, email, cuit, disabled, businessName } = row;
            return (
              <TableRow key={email}>
                <TableCell className="p-l-20">{businessName||name}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{parseCuit(cuit)}</TableCell>
                {isInternalUser || (entity === MARKETPLACE && can_write) ? (
                  <DisableAction
                    id={id}
                    name={name}
                    disabled={disabled}
                    handleDisableAction={handleDisableAction}
                    resetValue={resetValue}
                  />
                ) : (
                  <TableCell>
                    {disabled ? "Deshabilitado" : "Habilitado"}
                  </TableCell>
                )}
                <TableActions
                  row={row}
                  setSelectedRow={setSelectedIntegration}
                  handleSelectedAction={handleSelectedAction}
                  can_write={can_write || isInternalUser}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ListPagination
        total={count}
        page={page}
        onChangePage={handlePageChange}
      />
    </TableContainer>
  );
};

export default ListIntegrations;
