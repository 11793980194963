import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  ICredentialsRapiboy,
  ICredentialsRapiboyErrors,
  ICredentialsStep,
} from "../../../interfaces/configuration";
import ConfirmChangesCredentials from "../ConfirmChangesCredentials";
import { ERRORS_MESSAGES } from "../../../utils/constants";
import { validateInputs } from "../../../utils/inputValidations";
import { IValidation } from "../../../interfaces/form";
import CredentialsRapiboy from "../../forms/CredentialsRapiboy";
import GenericForm from "../../GenericForm";
import CredentialsDimensions from "../../modals/CredentialsDimensions";
import GenericAlert from "../../GenericAlert";

const {
  cuitRequired,
  cuitInvalid,
  endDateRequired,
  endDateNotInThePast,
  widthRequired,
  heightRequired,
  depthRequired,
  weightRequired,
  widthGreatherThanZero,
  heightGreatherThanZero,
  depthGreatherThanZero,
  weightGreatherThanZero,
  tokenRequired,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "token",
    type: "required",
    message: tokenRequired,
  },
  {
    id: "end_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "end_date",
    type: "future_date",
    message: endDateNotInThePast,
  },
  {
    id: "width",
    type: "required",
    message: widthRequired,
  },
  {
    id: "height",
    type: "required",
    message: heightRequired,
  },
  {
    id: "depth",
    type: "required",
    message: depthRequired,
  },
  {
    id: "weight",
    type: "required",
    message: weightRequired,
  },
  {
    id: "height",
    type: "number",
    message: heightGreatherThanZero,
  },
  {
    id: "width",
    type: "number",
    message: widthGreatherThanZero,
  },
  {
    id: "depth",
    type: "number",
    message: depthGreatherThanZero,
  },
  {
    id: "weight",
    type: "number",
    message: weightGreatherThanZero,
  },
];

const RapiboyCredentials = ({
  handleCancel,
  loading,
  alert,
  carrier,
  handleSubmitCarriers,
}: ICredentialsStep) => {
  const [credentials, setCredentials] = useState({} as ICredentialsRapiboy);
  const [errors, setErrors] = useState({} as ICredentialsRapiboyErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(credentials, formValidations);

    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      const { height, width, depth, ...restCredentials } = credentials;
      const newDimensions = { height, width, depth };
      const newCredentials = {
        ...restCredentials,
        dimensions: newDimensions,
        extended_week: !!credentials.extended_week,
      };
      handleSubmitCarriers([
        {
          ...carrier,
          credentials: newCredentials,
        },
      ]);
    }
  };

  return (
    <>
      <Typography className="f-s-24 m-b-20">Credenciales de RAPIBOY</Typography>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm onSubmit={handleSubmit}>
        <CredentialsRapiboy
          values={credentials}
          setValues={setCredentials}
          errors={errors}
        />

        <CredentialsDimensions
          values={credentials}
          setValues={setCredentials}
          errors={errors}
        />
      </GenericForm>
      <ConfirmChangesCredentials
        onClose={handleCancel}
        handleSubmit={handleSubmit}
        disabled={loading}
        backText="Cambiar operador"
        confirmText="Finalizar"
      />
    </>
  );
};

export default RapiboyCredentials;
