import { Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { IAlert } from "../../interfaces/alert";
import { IValuesByEntity } from "../../interfaces/common";
import { IDeleteIntegrationModal } from "../../interfaces/modal";
import { DELETE, ERROR, MERCHANT } from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import GenericAlert from "../GenericAlert";
import ModalBase from "./ModalBase";
import { clearSelectedMarketplace } from "../../utils/storage";
const DeleteIntegrationModal = ({
  entity,
  open,
  setOpen,
  integration: { id, name, marketplaceId },
  updateTable,
  setUpdateTable,
  getIntegrationsOfMerchant,
}: IDeleteIntegrationModal) => {
  const [alertDelete, setAlertDelete] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setAlertDelete({} as IAlert);
    setDisableButton(false);
  };

  const urlByEntity: IValuesByEntity = {
    marketplace: `/marketplaces/integrations/${id}`,
    merchant: `/marketplaces_merchants/remove_marketplace/${marketplaceId}`,
    internal: `/internal/client/${id}`
  };

  const handleUnlink = async () => {
    setDisableButton(true);
    try {
      await fetchData({
        url: urlByEntity[entity],
        method: DELETE,
      });
      setUpdateTable(!updateTable);
      if (entity === MERCHANT) {
        clearSelectedMarketplace();
        getIntegrationsOfMerchant();
      }
      handleClose();
    } catch (error: any) {
      setAlertDelete({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };
  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <div className="w-100">
          {alertDelete.showAlert && <GenericAlert alert={alertDelete} />}
        </div>
        <Typography className="f-s-20 m-t-50 m-b-50 text-center">
          ¿Seguro de que deseas desvincular a {name}?
        </Typography>

        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            disabled={disableButton}
            onClick={handleUnlink}
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default DeleteIntegrationModal;
