import { IHomeDateFilter } from "../interfaces/home";
import { GET, INTERNAL_METRICS_URL, METRICS_URL } from "../utils/constants";
import { fetchData } from "../utils/dataProvider";

export const getMetrics = (
  metricsType: string,
  dateFilter: IHomeDateFilter,
  isInternal: boolean,
  selectedMarketplace?: string
) => {
  const urlHandler = isInternal ? INTERNAL_METRICS_URL : METRICS_URL;
  const url = `${urlHandler[metricsType]}?from=${dateFilter.from}&to=${
    dateFilter.to
  }${selectedMarketplace ? `&marketplaceId=${selectedMarketplace}` : ""}`;
  return fetchData({ url, method: GET });
};
