import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchData } from "../../utils/dataProvider";
import { UserContext } from "../../contexts/userContext";
import { ERROR, GET, MEDIUM, PARENT_PATH } from "../../utils/constants";
import { IOrderRaw } from "../../interfaces/order";
import { IAlert, typeOfAlerts } from "../../interfaces/alert";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Loader from "../../components/Loader";
import OrderDetailsTopContent from "../../components/orders/OrderDetailsTopContent";
import OrderDescription from "../../components/orders/OrderDescription";
import OrderStatusDetails from "../../components/orders/OrderStatusDetails";

const OrderHistory = () => {
  const [orderData, setOrderData] = useState({} as IOrderRaw);
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(true);
  const { trackingNumber } = useParams();
  const { selectedMarketplace } = useContext(UserContext);
  const navigate = useNavigate();

  const showAlert = (typeOfAlert: typeOfAlerts, message: string) =>
    setAlert({
      typeOfAlert,
      message,
      showAlert: true,
    });

  const getOrderByTrackingNumber = async () => {
    setLoading(true);
    try {
      const response = await fetchData({
        url: `/orders/${trackingNumber}${
          selectedMarketplace && `?marketplaceId=${selectedMarketplace}`
        }`,
        method: GET,
      });
      setOrderData(response);
    } catch (error: any) {
      showAlert(ERROR, error);
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => navigate(PARENT_PATH);

  useEffect(() => {
    getOrderByTrackingNumber();
  }, [selectedMarketplace]);

  return (
    <Grid container flexDirection="column">
      {loading ? (
        <Grid item xs={12}>
          <Loader size={MEDIUM} />
        </Grid>
      ) : (
        <>
          {orderData?.orderInfo && (
            <Grid item>
              <Box display="flex" alignItems="center">
                <IconButton onClick={goBack}>
                  <span className="material-symbols-rounded text-dark">
                    keyboard_backspace
                  </span>
                </IconButton>
                <Typography ml={1} className="f-s-20">
                  Historial de {orderData?.orderInfo?.carrier}
                </Typography>
              </Box>
            </Grid>
          )}
          <OrderDetailsTopContent
            alert={alert}
            trackingNumber={trackingNumber}
          />
          {Object.entries(orderData).length > 0 && !alert.showAlert && (
            <>
              <Grid item container xs={12} md={7} className="m-b-10">
                <OrderDescription order={orderData} reduced/>
              </Grid>
              <Grid item xs={12} md={7}>
                <OrderStatusDetails
                  status_history={orderData?.orderInfo?.status_history}
                  showReason
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default OrderHistory;
