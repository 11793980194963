import { ReactComponent as ExportOrdersIcon } from "../../images/export-orders.svg";
import { ISidebarIcon } from "../../interfaces/sidebar";

export const renderCustomIcon = (icon: string, active?: boolean) => {
  switch (icon) {
  case "export_orders":
    return (
      <div className="sidebar-icon-margin">
        <ExportOrdersIcon fill={active ? "#e32728" : "#181818"} />
      </div>
    );
  default:
    return <></>;
  }
};

const SidebarIcon = ({ icon, isCustomIcon, active }: ISidebarIcon) => (
  <>
    {isCustomIcon ? (
      renderCustomIcon(icon, active)
    ) : (
      <span className="material-symbols-rounded text-dark sidebar-icon-margin">
        {icon}
      </span>
    )}
  </>
);

export default SidebarIcon;
