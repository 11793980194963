import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { IOrderDetails } from "../../interfaces/order";
import OrderDescription from "./OrderDescription";
import OrderPackageDescription from "./OrderPackageDescription";
import { MEDIUM, PARENT_PATH } from "../../utils/constants";
import OrderDetailsTopContent from "./OrderDetailsTopContent";
import Loader from "../Loader";
import OrderStatusDetails from "./OrderStatusDetails";
import { OrderCancelOrReturnReason } from "./OrderCancelOrReturnReason";
const OrderDetailsDesktop = ({
  order,
  trackingNumber,
  alert,
  loading,
  navigate,
}: IOrderDetails) => (
  <Grid container>
    {loading ? (
      <Grid item xs={12}>
        <Loader size={MEDIUM} />
      </Grid>
    ) : (
      <>
        <Grid item container md={7} alignItems="center" justifyContent="start">
          <IconButton onClick={() => navigate(PARENT_PATH)}>
            <span className="material-symbols-rounded text-dark">
              keyboard_backspace
            </span>
          </IconButton>
          <Typography className="f-s-20 text-dark" ml={1}>
            Ver orden
          </Typography>
        </Grid>
        <OrderDetailsTopContent alert={alert} trackingNumber={trackingNumber} />
        {order && !alert.showAlert && (
          <Grid container xs={12} md={7}>
            <OrderDescription order={order} />
            <OrderPackageDescription packages={order.orderInfo.packages} />
            {!!order?.rejection_info?.length && (
              <OrderCancelOrReturnReason order={order} />
            )}
            <OrderStatusDetails status_history={order?.status_history} retries={order?.retries} />
          </Grid>
        )}
      </>
    )}
  </Grid>
);

export default OrderDetailsDesktop;
