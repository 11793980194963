import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DATE_FORMAT,
  ERROR_FROM_GREATER_THAN_TO,
  ERROR_TO_GREATER_THAN_FROM,
  LOCALE,
} from "../../utils/constants";
import { IExportFilters } from "../../interfaces/order";
import { compareDates } from "../../utils/orders";

const showInputErrors = (inputErrors: string[]) =>
  inputErrors.map((message: string) => (
    <FormHelperText className="color-error" key={message}>
      {message}
    </FormHelperText>
  ));

const ExportFilters = ({
  values,
  setValues,
  carriers,
  handleSubmit,
  errors,
  setErrors,
  merchants,
  showstatusFilter,
}: IExportFilters) => {
  const validateDates = (keyName: string, date: string | null) => {
    const { fromDate, toDate } = values;
    const isDateValid = compareDates(
      keyName === "fromDate" ? date : fromDate,
      keyName === "fromDate" ? toDate : date
    );
    setErrors({
      ...errors,
      invalidFrom: keyName === "fromDate" && !isDateValid,
      invalidTo: keyName === "toDate" && !isDateValid,
    });
  };
  const handleChangeDate = (dateType: string, newValue: any) => {
    validateDates(dateType, newValue);
    setValues({ ...values, [dateType]: newValue });
  };
  return (
    <Grid container spacing={2} className="m-t-10">
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={LOCALE}>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Desde"
            value={values.fromDate || null}
            onChange={(newValue: any) => {
              if (!newValue?.invalid) handleChangeDate("fromDate", newValue);
            }}
            inputFormat={DATE_FORMAT}
            renderInput={(params: any) => (
              <TextField
                fullWidth
                size="small"
                {...params}
                error={errors.invalidFrom}
                className="date-picker-svg"
              />
            )}
            disableFuture
          />
          <FormHelperText className="color-error" key="error-message">
            {errors.fromDate?.length > 0 && showInputErrors(errors.fromDate)}
            {errors.invalidFrom && ERROR_FROM_GREATER_THAN_TO}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Hasta"
            value={values.toDate || null}
            onChange={(newValue: any) => {
              if (!newValue?.invalid) handleChangeDate("toDate", newValue);
            }}
            inputFormat={DATE_FORMAT}
            renderInput={(params: any) => (
              <TextField
                fullWidth
                size="small"
                {...params}
                error={errors.invalidTo}
                className="date-picker-svg"
              />
            )}
            disableFuture
          />
          <FormHelperText className="color-error" key="error-message">
            {errors.toDate?.length > 0 && showInputErrors(errors.toDate)}
            {errors.invalidTo && ERROR_TO_GREATER_THAN_FROM}
          </FormHelperText>
        </Grid>
      </LocalizationProvider>
      {showstatusFilter && (
        <Grid item xs={12} md={6} lg={2}>
          <Autocomplete
            id="status"
            inputValue={values.status}
            options={values.statusList || []}
            noOptionsText="No se encontró el estado"
            onChange={(_, value: any, reason: string) =>
              setValues({
                ...values,
                status: reason === "clear" ? "" : value.id,
              })
            }
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" label="Estado" />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={2}>
        <Autocomplete
          getOptionLabel={(option) => option.name}
          options={carriers}
          value={values.carrier}
          noOptionsText="No se encontró el operador logístico"
          onChange={(_, value: any, reason: string) =>
            setValues({
              ...values,
              carrier:
                reason === "clear"
                  ? undefined
                  : { name: value.name, _id: value._id },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label="Operador Logístico"
              error={errors.carrier && errors.carrier.length > 0}
            />
          )}
        />
        {errors.carrier &&
          errors.carrier?.length > 0 &&
          showInputErrors(errors?.carrier)}
      </Grid>
      {merchants && merchants.length > 0 && (
        <Grid item xs={12} md={2}>
          <Autocomplete
            inputValue={values?.merchantName}
            getOptionLabel={(option) => option.merchant.entity.name}
            options={merchants}
            noOptionsText="No se encontró el merchant"
            onChange={(_, value) =>
              setValues({
                ...values,
                merchantId: value?.merchantId,
                merchantName: value?.merchant.entity.name,
              })
            }
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" label="Merchant" />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          sx={{ padding: "8px" }}
          onClick={handleSubmit}
        >
          Generar archivo
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExportFilters;
