import React from "react";
import { Grid, Card, CardContent } from "@mui/material";
import GenericAlert from "../GenericAlert";
import SaveChangesAccount from "./SaveChangesAccount";
import SubscriptionForm from "./SubscriptionForm";
import { ISubscriptionContent } from "../../interfaces/account";
import GenericForm from "../GenericForm";
import { IUserResponses } from "../../interfaces/userContext";

const SubscriptionContent = ({
  disableInputs,
  alert,
  values,
  disableSubmit,
  errors,
  handleChange,
  handleCancel,
  handleSubmit,
  isMobile,
}: ISubscriptionContent) => (
  <Grid item container className="m-b-40">
    <Grid item xs={12} md={7}>
      {alert.showAlert && <GenericAlert alert={alert} />}
    </Grid>
    <Grid item xs={12} md={7} className="m-t-20">
      {isMobile ? (
        <GenericForm onSubmit={handleSubmit} disableSubmit={disableSubmit}>
          <SubscriptionForm
            errors={errors}
            handleChange={handleChange}
            currentUser={values as IUserResponses}
            disableInputs={disableInputs}
          />
        </GenericForm>
      ) : (
        <Card
          className="br-20 bg-light"
          sx={{ padding: 3, paddingBottom: 5.75 }}
        >
          <CardContent>
            <GenericForm onSubmit={handleSubmit} disableSubmit={disableSubmit}>
              <SubscriptionForm
                errors={errors}
                handleChange={handleChange}
                currentUser={values as IUserResponses}
                disableInputs={disableInputs}
              />
            </GenericForm>
          </CardContent>
        </Card>
      )}
    </Grid>
    {!disableInputs && (
      <Grid item xs={12} md={7}>
        <SaveChangesAccount
          disableSubmit={disableSubmit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      </Grid>
    )}
  </Grid>
);

export default SubscriptionContent;
