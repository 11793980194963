import {
  Card,
  CardContent,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import AuditCarrierLogs from "./AuditCarrierLogs";
import AuditHistoryTable from "./AuditHistoryTable";
import { mongoTimeStampToString } from "../../../utils/orders";
import { IOrderAuditInfo } from "../../../interfaces/order";

const OrderAuditInfo = ({
  audit: { ordersAudit, carrierAudit, carrierName },
}: IOrderAuditInfo) => (
  <>
    <Grid item xs={12} mb={5}>
      <Card className="br-10 bg-light" sx={{ width: "100%" }}>
        <CardContent sx={{ padding: "20px" }}>
          <Grid container spacing={{ xs: 2, md: 0 }}>
            <Grid item xs={6} md={4}>
              <Typography className="text-bold m-t-10" fontSize={14}>
                Fecha
              </Typography>
              <Typography fontSize={14}>
                {mongoTimeStampToString(carrierAudit.creation.triggeredAt)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography className="text-bold m-t-10" fontSize={14}>
                Operador logístico
              </Typography>
              <Typography fontSize={14}>{carrierName}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="text-bold m-t-10" fontSize={14}>
                Número de seguimiento
              </Typography>
              <Typography fontSize={14} sx={{ wordBreak: "break-all" }}>
                {carrierAudit.tracking_id}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

    {ordersAudit?.door_to_door && (
      <AuditHistoryTable
        title="Cambio manual de estado"
        columns={[
          { label: "Fecha y hora" },
          { label: "Usuario" },
          { label: "Acción" },
        ]}
        renderEntries={() => (
          <TableRow>
            <TableCell>
              {mongoTimeStampToString(ordersAudit.door_to_door.changed_at)}
            </TableCell>
            <TableCell>{ordersAudit.door_to_door.changed_by_email}</TableCell>
            <TableCell>{ordersAudit.door_to_door.end_status}</TableCell>
          </TableRow>
        )}
      />
    )}

    {carrierAudit.webhook.length > 0 && (
      <AuditHistoryTable
        title="Webhook"
        columns={[
          { label: "Fecha y hora" },
          { label: "Operador logístico" },
          { label: "Webhook info" },
        ]}
        renderEntries={() => (
          <>
            {carrierAudit.webhook.map(({ body, calledAt }: any) => (
              <TableRow>
                <TableCell>{calledAt}</TableCell>
                <TableCell>{carrierName}</TableCell>
                <TableCell>
                  <pre>
                    <code>{JSON.stringify(body, null, 2)}</code>
                  </pre>
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
      />
    )}

    <AuditCarrierLogs
      title="Creación"
      inputValues={carrierAudit.creation.input}
      outputValues={carrierAudit.creation.output}
    />

    {carrierAudit.cancelation && (
      <AuditCarrierLogs
        title="Cancelación"
        inputValues={carrierAudit.cancelation.input}
        outputValues={carrierAudit.cancelation.output}
      />
    )}
  </>
);

export default OrderAuditInfo;
