import { useContext } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import ListHeader from "./ListHeader";
import { IListUsers, IRole, IUser } from "../../interfaces/usersRoles";
import { INFO, OWNER_ROLE_NAME } from "../../utils/constants";
import ListPagination from "./ListPagination";
import ListUsersActions from "./ListUsersActions";
import { UserContext } from "../../contexts/userContext";
import GenericAlert from "../GenericAlert";

const COLUMNS = [
  { label: "Nombre y apellido" },
  { label: "Correo electrónico" },
  { label: "Roles" },
  { label: "Estado" },
];

const ListUsers = ({
  users,
  handleSelectedAction,
  handlePageChange,
  can_write,
  page,
  count,
}: IListUsers) => {
  const { currentUser } = useContext(UserContext);
  const loggedUserIsOwner = currentUser.roles.find(
    ({ name: roleName }) => roleName === OWNER_ROLE_NAME
  );
  const getListHeader = () =>
    can_write ? COLUMNS.concat({ label: "Acciones" }) : COLUMNS;

  return count ? (
    <TableContainer component={Paper} className="m-t-20 br-10 bg-light">
      <Table>
        <ListHeader columns={getListHeader()} />
        <TableBody>
          {users?.map((user: IUser) => (
            <TableRow key={user.id}>
              <TableCell className="p-l-20">{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.roles.map(({ name: roleName }: IRole) => roleName)}
              </TableCell>
              <TableCell>{user.status}</TableCell>
              {can_write && (
                <TableCell>
                  {user.status === "invited" ? (
                    <Tooltip arrow title="Eliminar invitación" placement="top">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          handleSelectedAction("delete-invitation", {
                            id: user.id,
                          });
                        }}
                      >
                        <span className="material-symbols-rounded">delete</span>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <>
                      {user.roles.find(
                        ({ name: roleNameToFind }: IRole) =>
                          roleNameToFind !== "Owner"
                      ) && (
                        <ListUsersActions
                          handleSelectedAction={handleSelectedAction}
                          user={user}
                          createdUser={user.status !== "Invitación enviada"}
                          loggedUserIsOwner={loggedUserIsOwner}
                        />
                      )}
                    </>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ListPagination
        total={count}
        page={page}
        onChangePage={handlePageChange}
      />
    </TableContainer>
  ) : (
    <GenericAlert
      alert={{
        typeOfAlert: INFO,
        message: "No hay usuarios.",
        showAlert: true,
      }}
    />
  );
};

export default ListUsers;
