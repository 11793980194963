import axios from "axios";
import { IAxiosProps } from "../interfaces/axios";
import config from "../config/config";
import {
  UNAUTHORIZED,
  FORBIDDEN,
  NON_EXISTENT_INTEGRATION,
  MERCHANT_ERROR_INTEGRATION_PATH,
} from "./constants";
import { redirectTo } from "./navigation";
import { clearLocalData, getLocalToken } from "./storage";

const { AUTH_API_BASE_URL } = config;
const getHeaders = () => {
  const headers: any = {
    "Access-Control-Allow-Origin": true,
    "Access-Control-Allow-Headers": "Content-Type",
  };

  const token = getLocalToken();
  if (token) headers.Authorization = token;

  return headers;
};

export const fetchData = async ({
  url,
  method,
  body,
  headers = {},
}: IAxiosProps) => {
  const axiosClient = axios.create({
    baseURL: AUTH_API_BASE_URL,
    headers: { ...getHeaders(), ...headers },
  });

  try {
    const { data } = await axiosClient[method](url, body);
    return data;
  } catch (error: any) {
    const {
      response: {
        status,
        data: { error: errorMessage, message, extraInfo },
      },
    } = error;

    if (
      status === FORBIDDEN &&
      extraInfo === NON_EXISTENT_INTEGRATION &&
      location.pathname !== MERCHANT_ERROR_INTEGRATION_PATH
    )
      redirectTo(MERCHANT_ERROR_INTEGRATION_PATH);

    if (status === UNAUTHORIZED) {
      clearLocalData();
      redirectTo(`/autenticacion?reason=${errorMessage}`);
    }

    throw (
      errorMessage ||
      message ||
      "Hubo un problema, intente de nuevo en unos minutos."
    );
  }
};
