import { IBanner } from "../interfaces/alert";
import { Box, Typography } from "@mui/material";

const Banner = ({ banner }: { banner: IBanner }) => (
  <Box className="d-flex bg-error" padding="10px 40px" marginBottom="20px">
    <Typography className="text-white f-s-20">{banner.message}</Typography>
  </Box>
);

export default Banner;
