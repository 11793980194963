import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { IWehbookFormProps } from "../../interfaces/configuration";
import {
  AUTOMATIC_STATE_UPDATE,
  MANUAL_STATE_UPDATE,
  CONFIGURATION_PERMISSION,
  SELECT_MODE,
} from "../../utils/constants";
import { usePermission } from "../../customHooks/usePermission";

const WebhookForm = ({
  webhookInfo: { notificationType, webhookUrl, authentication },
  handleInfoChange,
  isInitialConfig,
}: IWehbookFormProps) => {
  const { can_write } = usePermission(CONFIGURATION_PERMISSION);
  return (
    <div className="webhookForm">
      <FormControl size="small" fullWidth>
        <Typography className="m-b-10">
          Elige cómo deseas notificar los estados
        </Typography>
        <Select
          disabled={!can_write}
          size="small"
          className="f-s-14 bg-input m-b-20"
          value={notificationType}
          onChange={({ target: { value } }) =>
            handleInfoChange("notificationType", value)
          }
        >
          {isInitialConfig && (
            <MenuItem
              disabled
              key={SELECT_MODE}
              value={SELECT_MODE}
              className="f-s-14"
            >
              {SELECT_MODE}
            </MenuItem>
          )}
          <MenuItem
            key={AUTOMATIC_STATE_UPDATE}
            value={AUTOMATIC_STATE_UPDATE}
            className="f-s-14"
          >
            {AUTOMATIC_STATE_UPDATE}
          </MenuItem>
          <MenuItem
            key={MANUAL_STATE_UPDATE}
            value={MANUAL_STATE_UPDATE}
            className="f-s-14"
          >
            {MANUAL_STATE_UPDATE}
          </MenuItem>
        </Select>
      </FormControl>

      {notificationType===AUTOMATIC_STATE_UPDATE && (
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-t-10 m-b-10">
            Por favor ingresa el webhook para la notificación de estados
          </Typography>
          <OutlinedInput
            autoFocus
            size="small"
            className="bg-input"
            disabled={!can_write}
            value={webhookUrl}
            placeholder="Ingresa la url del webhook"
            onChange={({ target: { value } }) =>
              handleInfoChange("webhookUrl", value)
            }
          />
        </FormControl>
      )}
      {notificationType===AUTOMATIC_STATE_UPDATE && (
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-t-10 m-b-10">
            Por favor ingresa la apikey para la autenticación con el webhook
          </Typography>
          <OutlinedInput
            autoFocus
            size="small"
            className="bg-input"
            disabled={!can_write}
            value={authentication}
            placeholder="Ingresa una apikey para la autenticación"
            onChange={({ target: { value } }) =>
              handleInfoChange("authentication", value)
            }
          />
        </FormControl>
      )}
    </div>
  );
};

export default WebhookForm;
