import React, { ChangeEvent } from "react";
import { Grid, Typography, TextField, FormHelperText } from "@mui/material";
import { IAccountForm } from "../../interfaces/account";
import { CUIT_PLACEHOLDER } from "../../utils/constants";

const AccountProfileForm = ({
  errors,
  handleChange,
  currentUser,
  disableInputs,
}: IAccountForm) => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Alias</Typography>
      <TextField
        fullWidth
        className="bg-input"
        size="small"
        name="name"
        placeholder="Ingresar alias"
        value={currentUser?.name}
        disabled={disableInputs}
        error={errors.name?.length > 0}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
      {errors.name?.length > 0 &&
        errors.name?.map((message: string) => (
          <FormHelperText className="color-error" key={message}>
            {message}
          </FormHelperText>
        ))}
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Correo electrónico</Typography>
      <TextField
        fullWidth
        disabled
        className="bg-input"
        size="small"
        name="email"
        placeholder="Ingresar correo electrónico"
        value={currentUser?.email}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">CUIT</Typography>
      <TextField
        fullWidth
        disabled
        className="bg-input"
        size="small"
        name="cuit"
        placeholder={CUIT_PLACEHOLDER}
        value={currentUser?.cuit}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
    </Grid>
  </Grid>
);

export default AccountProfileForm;
