export type TIntegrationsType =
  | "showInfoMerchantModal"
  | "showDeleteModal"
  | "showDisableModal"
  | "showCancelModal";

type TIntegrationsAction =
  | { type: "showInfoMerchantModal"; open: boolean }
  | { type: "showDeleteModal"; open: boolean }
  | { type: "showDisableModal"; open: boolean }
  | { type: "showCancelModal"; open: boolean };

const modalTypes = [
  "showInfoMerchantModal",
  "showDeleteModal",
  "showDisableModal",
  "showCancelModal",
];

export const integrationsModalReducer = (
  state: any,
  action: TIntegrationsAction
) =>
  modalTypes.includes(action.type)
    ? { ...state, [action.type]: action.open }
    : state;
