import React from "react";
import { IConfirmationModal, IModifyUserInfo, TModifyModalOptions } from "../../interfaces/modal";
import ModalBase from "./ModalBase";
import { Button, Grid, Typography } from "@mui/material";
import { DELETE_USER_MODAL_TYPE, DISABLE_USER_MODAL_TYPE } from "../../utils/constants";

const getModalTitle = (modalType: TModifyModalOptions, userInfo?: IModifyUserInfo): string => {
  if (!userInfo) return "";

  switch (modalType) {
  case DELETE_USER_MODAL_TYPE:
    return `¿Seguro de que deseas desvincular a ${userInfo.name}?`;
  case DISABLE_USER_MODAL_TYPE:
    return `¿Seguro de que deseas ${userInfo.disabled ? "des" : ""}habilitar a ${userInfo.name}?`;
  default: return "";
  }
};

const ConfirmDeleteUserModal = ({
  open,
  onClose,
  onSubmit,
  userInfo,
  modalType = ""
}: IConfirmationModal) => (
  <ModalBase open={open} handleClose={onClose}>
    <Grid container paddingTop={3} justifyContent="center">
      <Typography className="f-s-20 m-t-20">
        {getModalTitle(modalType, userInfo)}
      </Typography>
      <Grid container item gap={2} flexWrap="nowrap" className="m-t-40">
        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={onClose}>
            Volver
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" onClick={onSubmit}>
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </ModalBase>
);

export default ConfirmDeleteUserModal;
