import { FormHelperText } from "@mui/material";

const InputErrors = ({ errors }: { errors: string[] }) => (
  <div>
    {errors.map((message: string) => (
      <FormHelperText className="color-error" key={message}>
        {message}
      </FormHelperText>
    ))}
  </div>
);

export default InputErrors;
