import React from "react";
import ModalBase from "./ModalBase";
import { Button, Grid, Typography } from "@mui/material";
import { joinByComaAndAnd } from "../../utils/text";
interface IConfirmDisableCarriers {
  open: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  disabled?: boolean;
  carriers: string[];
}

export const ConfirmDisableCarriers = ({
  open,
  onClose,
  handleSubmit,
  disabled = false,
  carriers,
}: IConfirmDisableCarriers) => (
  <ModalBase open={open} handleClose={onClose}>
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Typography className="f-s-20 m-t-50 text-center">
        ¿Seguro de que deseas desactivar a {joinByComaAndAnd(carriers)}?
      </Typography>
      <Typography className="f-s-20 m-b-50 text-center">
        Ten en cuenta que una vez desactivado{carriers.length === 1? "": "s"}, los merchants no podrán seguir
        operando con el{carriers.length === 1? "": "los"}.
      </Typography>
      <Grid item xs={6}>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Volver
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={disabled}
        >
          Guardar cambios
        </Button>
      </Grid>
    </Grid>
  </ModalBase>
);
