import React from "react";
import { Link } from "@mui/material";
import { IGenericLink } from "../interfaces/link";

const GenericLink = ({ text, url, open_type, extraClass }: IGenericLink) => (
  <Link
    underline="none"
    className={`pointer ${extraClass}`}
    href={url}
    target={open_type}
  >
    {text}
  </Link>
);

export default GenericLink;
