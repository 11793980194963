import { Grid, Typography, Button } from "@mui/material";
import SectionTitle from "../../components/landing/SectionTitle";
import { CONTACT_TITLE_ONE, CONTACT_TITLE_TWO } from "../../utils/constants";
import ContactForm from "../../components/contact/ContactForm";

const Contact = () => (
  <>
    <Grid
      id="contact"
      container
      className="p-x-landing p-y-landing bg-input"
      justifyContent="center"
    >
      <SectionTitle title={CONTACT_TITLE_ONE} marginBottom={7} />
      <Typography className="w-100 f-s-24 m-t-20 text-center">
        Comunicate con nuestro equipo por Whatsapp +54 11 55085594
      </Typography>
      <Grid item lg={3} className="m-t-40">
        <Button
          variant="contained"
          fullWidth
          href="https://wa.link/r8uz3q"
          target="_blank"
        >
          Chateá con nosotros
        </Button>
      </Grid>
    </Grid>

    <Grid
      container
      className="p-x-landing bg-contact-section"
      justifyContent="center"
      paddingBottom={20}
      paddingTop={{ xs: 3, md: 14 }}
    >
      <SectionTitle title={CONTACT_TITLE_TWO} marginBottom={7} />
      <Typography className="w-100 f-s-24 m-t-10  m-b-20 text-center">
        Completá el siguiente formulario y nos pondremos en contacto a la
        brevedad.
      </Typography>
      <ContactForm />
    </Grid>
  </>
);
export default Contact;
