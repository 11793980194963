import { ICancelOrder, IOrderStatus } from "../interfaces/order";
import { GET, PATCH } from "../utils/constants";
import { fetchData } from "../utils/dataProvider";
import { trimValue } from "../utils/form";

export const cancelOrder = ({
  trackingNumber,
  reason,
  selectedMarketplace,
}: ICancelOrder) =>
  // TODO cambiar endpoint para cancelar order, falta del back
  fetchData({
    url: `/orders/cancel/${trackingNumber}${
      selectedMarketplace && `?marketplaceId=${selectedMarketplace}`
    }`,
    method: PATCH,
    body: { reason: trimValue(reason) },
  });

export const updateStatusAlias = (status: IOrderStatus) =>
  fetchData({
    url: "/orders/customization/alias",
    method: PATCH,
    body: { status: status.id, alias: status.label },
  });

export const getStatusAliasList = (marketplaceId?: string) =>
  fetchData({
    url: `/orders/customization/alias${
      marketplaceId ? `?marketplaceId=${marketplaceId}` : ""
    }`,
    method: GET,
  });

export const getEmailTemplateList = () =>
  fetchData({ url: "/orders/customization/email-templates", method: GET });

export const updateEmailTemplate = (body: any) =>
  fetchData({
    url: "/orders/customization/email-template",
    method: PATCH,
    body,
  });

export const updateEmailDisabledConfiguration = (body: any) =>
  fetchData({
    url: "/orders/customization/email-templates/toggle-status-email",
    method: PATCH,
    body,
  });

export const editOrderStatus = (
  trackingNumber: string | number,
  body: any,
  marketplaceId?: string
) =>
  fetchData({
    url: `/orders/${trackingNumber}${
      marketplaceId ? `?marketplaceId=${marketplaceId}` : ""
    }`,
    method: PATCH,
    body,
  });
