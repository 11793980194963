import React from "react";
import {
  Card,
  CardContent,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { IAccountAuthInfo } from "../../interfaces/account";
import { capitalizeOnlyFirstLetter } from "../../utils/orders";
import { TContractOwner } from "../../interfaces/configuration";

const ContractGrid = ({ contractOwner }: { contractOwner: TContractOwner }) => (
  <>
    <Typography className="f-s-14 m-b-10">
      ¿Quién carga los contratos?
    </Typography>
    <OutlinedInput
      disabled
      fullWidth
      size="small"
      className="bg-input"
      value={capitalizeOnlyFirstLetter(contractOwner)}
    />
  </>
);

export const AccountCredentialsManagerInfo = ({
  isMobile,
  contractOwner,
}: IAccountAuthInfo) => (
  <Grid item xs={12} md={7}>
    <Typography className="f-s-20 text-dark m-t-40">Contratos</Typography>

    <Grid item xs={12} md={7} className="m-t-20">
      {isMobile ? (
        <ContractGrid contractOwner={contractOwner} />
      ) : (
        <Card
          className="br-20 bg-light"
          sx={{
            padding: 3,
            paddingBottom: 5.75,
          }}
        >
          <CardContent>
            <ContractGrid contractOwner={contractOwner} />
          </CardContent>
        </Card>
      )}
    </Grid>
  </Grid>
);
