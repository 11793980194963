import {
  FormControl,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { showInputErrors } from "../utils/credentials";
import { IPasswordInput } from "../interfaces/form";

const PasswordInput = ({
  value,
  show,
  setShow,
  errors,
  handleChange,
  label,
  name,
  placeholder,
  helper,
}: IPasswordInput) => (
  <FormControl className="m-t-20" fullWidth>
    <Typography id="password-label" className="f-s-14 m-b-10">
      {label}
    </Typography>
    <OutlinedInput
      type={show ? "text" : "password"}
      size="small"
      name={name}
      placeholder={placeholder}
      value={value}
      error={errors?.length > 0}
      onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
        handleChange(target)
      }
      endAdornment={
        <InputAdornment position="start">
          <IconButton onClick={() => setShow(!show)} edge="end">
            <span className="material-symbols-rounded f-s-18">
              {show ? "visibility" : "visibility_off"}
            </span>
          </IconButton>
        </InputAdornment>
      }
    />
    {helper && <FormHelperText>{helper}</FormHelperText>}
    {errors?.length > 0 && showInputErrors(errors)}
  </FormControl>
);

export default PasswordInput;
