import { IMerchantIntegrationsReduceState } from "../interfaces/merchantIntegrations";
import {
  ALERT,
  LOADING,
  MERCHANTS_INTEGRATIONS,
  MERCHANT_SELECTED,
} from "../utils/constants";

export const merchantsIntegrationsReducer = (
  state: IMerchantIntegrationsReduceState,
  action: {
    content: any;
    type: string;
  }
) => {
  switch (action.type) {
  case LOADING:
    return {
      ...state,
      [LOADING]: action.content,
    };
  case MERCHANT_SELECTED:
    return {
      ...state,
      [MERCHANT_SELECTED]: action.content,
    };
  case MERCHANTS_INTEGRATIONS:
    return {
      ...state,
      [MERCHANTS_INTEGRATIONS]: action.content,
    };
  case ALERT:
    return {
      ...state,
      [ALERT]: action.content,
    };
  default:
    return state;
  }
};

export const merchantsIntegrationsDefaultValues = {
  [LOADING]: false,
  [MERCHANT_SELECTED]: "",
  [MERCHANTS_INTEGRATIONS]: [],
  [ALERT]: {},
};
