import React, { useState, useEffect, useReducer } from "react";
import Loader from "../../components/Loader";
import {
  ALERT,
  INFO,
  LOADING,
  MEDIUM,
  MERCHANTS_INTEGRATIONS,
  MERCHANT_SELECTED,
} from "../../utils/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box, Card, Grid } from "@mui/material";
import GenericAlert from "../../components/GenericAlert";
import {
  ICustomAccordionProps,
  IIntegratedCarriersInfo,
  IMerchantsIntegrationsInfo,
} from "../../interfaces/merchantIntegrations";
import SearchInput from "../../components/SearchInput";
import {
  merchantsIntegrationsReducer,
  merchantsIntegrationsDefaultValues,
} from "../../reducers/merchantsIntegrationsReducer";
import { capitalizeOnlyFirstLetter } from "../../utils/orders";
import { getMerchantIntegrationsFullInfo } from "../../requests/integrationRequests";

const getMappedInfo = ({
  merchant,
}: {
  merchant: any;
  carrierIntegrations: IIntegratedCarriersInfo[];
}) => ({
  name: merchant?.entity?.name,
  carriers: merchant?.carriers,
});

const CustomAccordion = ({
  handleChange,
  current,
  merchantName,
  carriers,
}: ICustomAccordionProps) => (
  <Accordion
    expanded={current === merchantName}
    onChange={() => handleChange(merchantName)}
    disableGutters
    elevation={0}
    square
    id="operators-accordion-main-row"
    className="opetators-accordion-border-bottom"
  >
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      expandIcon={
        <span className="material-symbols-rounded f-s-24">expand_more</span>
      }
    >
      <Typography pl={4}>{merchantName}</Typography>
    </AccordionSummary>
    {carriers?.map(({ ableToOperate, name: carrierName }) => (
      <Grid container id="operators-accordion-expanded-rows">
        <Grid item xs={6}>
          <Typography>{capitalizeOnlyFirstLetter(carrierName)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{ableToOperate ? "Activo" : "Inactivo"}</Typography>
        </Grid>
      </Grid>
    ))}
  </Accordion>
);

const CustomSearchInput = ({
  handleSearch,
}: {
  handleSearch: (_value?: string) => Promise<void>;
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchOrDelete, setSearchOrDelete] = useState(false);
  const handleSubmit = async (resetSearchValue?: string) => {
    setSearchOrDelete(!!searchValue);
    if (!resetSearchValue) {
      await handleSearch(searchValue);
    }
  };

  const handleCloseInput = () => {
    setSearchOrDelete(false);
    setSearchValue("");
    handleSearch();
  };

  return (
    <Grid container alignItems="center" className="m-t-20" spacing={2}>
      <Grid item xs={12} md={6} lg={3.8}>
        <SearchInput
          searchValue={searchValue}
          placeholder="Buscar por alias"
          handleSubmit={handleSubmit}
          handleChange={(value: string) => {
            setSearchOrDelete(false);
            setSearchValue(value);
          }}
          handleClose={handleCloseInput}
          activeSearch={searchOrDelete}
        />
      </Grid>
    </Grid>
  );
};

const MerchantCarrierIntegrations = () => {
  const [merchantsIntegrationsState, dispatch] = useReducer(
    merchantsIntegrationsReducer,
    merchantsIntegrationsDefaultValues
  );

  const handleSelect = (merchantName: string) =>
    dispatch({
      type: MERCHANT_SELECTED,
      content:
        merchantName === merchantsIntegrationsState[MERCHANT_SELECTED]
          ? ""
          : merchantName,
    });

  const getMarketplaceMerchantsIntegrations = async (searchValue = "") => {
    dispatch({ type: ALERT, content: false });
    const { integrations } = await getMerchantIntegrationsFullInfo(searchValue);
    dispatch({
      type: MERCHANTS_INTEGRATIONS,
      content: integrations.map(getMappedInfo),
    });
    if (!integrations.length)
      dispatch({
        type: ALERT,
        content: {
          showAlert: true,
          typeOfAlert: INFO,
          message: "No hay merchants para mostrar.",
        },
      });
    dispatch({ type: LOADING, content: false });
  };

  useEffect(() => {
    dispatch({ type: LOADING, content: true });
    getMarketplaceMerchantsIntegrations();
  }, []);

  return (
    <Box>
      {merchantsIntegrationsState.loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <>
          <CustomSearchInput
            handleSearch={getMarketplaceMerchantsIntegrations}
          />
          <Grid container flexDirection="column">
            <Grid item xs={5}>
              {merchantsIntegrationsState.alert.showAlert && (
                <GenericAlert alert={merchantsIntegrationsState.alert} />
              )}
              {merchantsIntegrationsState.merchantIntegrations.length >
                0 && (
                <Card
                  id="operators-accordion-card"
                  className="m-t-40 br-10 bg-light"
                >
                  <Typography
                    id="operators-accordion-header"
                    className="text-bold opetators-accordion-border-bottom"
                    pl={9}
                  >
                    Alias
                  </Typography>
                  {merchantsIntegrationsState.merchantIntegrations.map(
                    ({ name, carriers }: IMerchantsIntegrationsInfo) => (
                      <CustomAccordion
                        handleChange={handleSelect}
                        current={merchantsIntegrationsState.merchantSelected}
                        merchantName={name}
                        carriers={carriers}
                      />
                    )
                  )}
                </Card>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default MerchantCarrierIntegrations;
