import { Grid, Button } from "@mui/material";
import { IConfirmChangesCredentials } from "../../interfaces/configuration";
import { LoadingButton } from "@mui/lab";

const ConfirmChangesCredentials = ({
  onClose,
  handleSubmit,
  disabled,
  backText = "Volver",
  confirmText = "Aceptar",
}: IConfirmChangesCredentials) => (
  <Grid container gap={2} flexWrap="nowrap" className="m-t-40">
    <Grid item xs={6}>
      <Button
        fullWidth
        variant="outlined"
        disabled={disabled}
        onClick={onClose}
      >
        {backText}
      </Button>
    </Grid>
    <Grid item xs={6}>
      <LoadingButton
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        disabled={disabled}
        loading={disabled}
        loadingPosition="end"
      >
        {confirmText}
      </LoadingButton>
    </Grid>
  </Grid>
);

export default ConfirmChangesCredentials;
