import React, { useEffect, useState } from "react";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import {
  ICarrier,
  IConfigurationRules,
  IListCarriers,
  IListCarriersHeader,
} from "../../interfaces/configuration";
import ListHeader from "./ListHeader";
import { ITableHeaderColumn } from "../../interfaces/table";
import CarrierRow from "./CarrierRow";
import { usePermission } from "../../customHooks/usePermission";
import { CONFIGURATION_PERMISSION, INTERNAL } from "../../utils/constants";

const defaultHeader = [
  { label: "Operadores logísticos" },
  { label: "Peso" },
  { label: "Zonas de cobertura" },
];

const headerOptionsByRule: IListCarriersHeader = {
  canFulfillCredentials: { label: "Acciones" },
  canActiveCarriers: { label: "Acciones" },
  canDisableOL: { label: "Estado" },
};

const internalHeaders = [
  ...defaultHeader,
  { label: "Acciones" },
  { label: "Estado" },
];

const getHeaderByRules = (rules: IConfigurationRules, can_write: boolean) =>
  Object.keys(rules).reduce(
    (accum: ITableHeaderColumn[], rule: string) => {
      if (can_write && rules[rule]) accum.push(headerOptionsByRule[rule]);
      return accum;
    },
    [...defaultHeader]
  );

const getHeaders = (
  entity: string,
  rules: IConfigurationRules,
  can_write: boolean
) => {
  if (entity === INTERNAL) return internalHeaders;
  return getHeaderByRules(rules, can_write);
};

const ListCarriers = ({
  carriers,
  carriersChanges,
  rules,
  setOpenModal,
  handleCarriersChanges,
  entity,
}: IListCarriers) => {
  const [header, setHeader] = useState<ITableHeaderColumn[]>([]);
  const { can_write } = usePermission(CONFIGURATION_PERMISSION);

  useEffect(() => {
    setHeader(getHeaders(entity, rules, can_write));
  }, [can_write]);

  return (
    <TableContainer component={Paper} className="m-t-20 br-10 bg-light p-b-40">
      <Table>
        <ListHeader
          columns={can_write || entity === INTERNAL ? header : header.concat({ label: "Estado" })}
        />
        <TableBody>
          {carriers?.map((carrier: ICarrier) => (
            <CarrierRow
              carrier={carrier}
              carrierChanged={
                { ...carriersChanges[carrier.name], contract_types: undefined }
              }
              rules={rules}
              setOpenModal={setOpenModal}
              handleCarriersChanges={handleCarriersChanges}
              entity={entity}
              can_write={can_write}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListCarriers;
