import {
  MARKETPLACE_PATH,
  MERCHANT_PATH,
  ECOMMERCE_PATH,
  PATHS_TO_HIDE_CONTENT,
} from "./constants";

export const redirectTo = (path: string) => (window.location.href = path);

export const checkPrivateRouteByPathname = (pathname: string) =>
  pathname.includes(MARKETPLACE_PATH || MERCHANT_PATH || ECOMMERCE_PATH);

export const checkIfPathIsAbleToShowIcons = (pathname: string) =>
  !PATHS_TO_HIDE_CONTENT.some((path) => pathname.includes(path));
