import React from "react";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import DeleteIntegrationModal from "../../components/modals/DeleteIntegrationModal";
import DisableMerchantsModal from "../../components/modals/DisableMerchantsModal";
import InfoMerchantModal from "../../components/modals/InfoMerchantModal";
import { ISelectedIntegration } from "../../interfaces/integrations";
import { TEntity } from "../../interfaces/userContext";
import { MARKETPLACE } from "../../utils/constants";

const IntegrationsModal = ({
  integration,
  entity,
  disableMerchantProps,
  onCancelSubmit,
  deleteIntegrationProps,
  state,
  dispatch,
}: {
  integration: ISelectedIntegration;
  entity: TEntity;
  disableMerchantProps: { submitDisable(): void; merchants: string[] };
  onCancelSubmit: () => void;
  deleteIntegrationProps: {
    updateTable: boolean;
    setUpdateTable(_updateTable: boolean): void;
    getIntegrationsOfMerchant(): void;
  };
  state: any;
  dispatch: any;
}) => (
  <React.Fragment>
    <InfoMerchantModal
      integration={integration}
      open={state.showInfoMerchantModal}
      setOpen={(open: boolean) =>
        dispatch({ type: "showInfoMerchantModal", open })
      }
    />

    {entity === MARKETPLACE && (
      <DisableMerchantsModal
        {...disableMerchantProps}
        open={state.showDisableModal}
        setOpen={(open: boolean) =>
          dispatch({ type: "showDisableModal", open })
        }
      />
    )}

    <ConfirmationModal
      onSubmit={() => {
        onCancelSubmit();
        dispatch({ type: "showCancelModal", open: false });
      }}
      open={state.showCancelModal}
      onClose={() => dispatch({ type: "showCancelModal", open: false })}
    />

    <DeleteIntegrationModal
      {...deleteIntegrationProps}
      entity={entity}
      integration={integration}
      open={state.showDeleteModal}
      setOpen={(open: boolean) =>
        dispatch({ type: "showDeleteModal", open })
      }
    />
  </React.Fragment>
);

export default IntegrationsModal;
