import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ISidebarItem, ISidebarList } from "../../interfaces/sidebar";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { getSidebarOptions } from "../../utils/sidebars";
import { UserContext } from "../../contexts/userContext";
import SidebarIcon from "./SidebarIcon";

const SidebarList = ({ onCloseSidebar }: ISidebarList) => {
  const { currentUser, showSkeleton } = useContext(UserContext);
  const [options, setOptions] = useState([] as ISidebarItem[]);
  const [selected, setSelected] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = (slug: string) => {
    navigate(slug);
    if (onCloseSidebar) onCloseSidebar();
  };

  useEffect(() => {
    const [, baseUrl, childRoute] = pathname.split("/");

    setSelected(childRoute ? childRoute : `/${baseUrl}`);
  }, [pathname]);

  useEffect(() => {
    if (currentUser?.entity && currentUser?.permissions)
      setOptions(
        getSidebarOptions(currentUser.entity, currentUser.permissions)
      );
  }, [currentUser]);

  return (
    <List className="sidebar-list">
      {showSkeleton ? (
        <>
          <ListItem>
            <Skeleton variant="circular" width={25} height={25} />
          </ListItem>
          <ListItem>
            <Skeleton variant="circular" width={25} height={25} />
          </ListItem>
          <ListItem>
            <Skeleton variant="circular" width={25} height={25} />
          </ListItem>
        </>
      ) : (
        <>
          {options.length > 0 &&
            options.map(({ name, icon, isCustomIcon, slug }: ISidebarItem) => (
              <ListItem
                key={name}
                button
                disablePadding
                className={selected === slug ? "active" : ""}
                onClick={() => handleClick(slug)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SidebarIcon
                      icon={icon}
                      isCustomIcon={isCustomIcon}
                      active={selected === slug}
                    />
                  </ListItemIcon>
                  <ListItemText id="sidebar-text" primary={name} />
                </ListItemButton>
              </ListItem>
            ))}
        </>
      )}
    </List>
  );
};

export default SidebarList;
