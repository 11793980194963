import React, { createContext, useEffect, useState } from "react";
import {
  IUserContext,
  ISaveUserData,
  IUserResponses,
  IMarketplaceIntegrationsResponse,
} from "../interfaces/userContext";
import {
  CURRENT_MARKETPLACE,
  GET,
  INCOMPLETE_CONFIG,
  INITIAL_CONFIGURATION_COMPLETE,
  INTERNAL,
  IS_LOGOUT,
  MARKETPLACE,
  MARKETPLACE_NAME,
  MERCHANT,
  TOKEN,
} from "../utils/constants";
import {
  clearLocalData,
  getLocalMarketplaceId,
  getLocalToken,
  storeData,
} from "../utils/storage";
import { IMarketplaceMerchantIntegration } from "../interfaces/merchant";
import { fetchData } from "../utils/dataProvider";
import { ICarrier, IConfigInitialRules } from "../interfaces/configuration";
import { getCarriers } from "../requests/carrierRequests";
import { getIntegrationsOfMarketplace } from "../requests/integrationRequests";
import { redirectTo } from "../utils/navigation";

export const UserContext = createContext({} as IUserContext);

const UserProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<IUserResponses>(
    {} as IUserResponses
  );
  const [marketplaceIntegrations, setMarketplaceIntegrations] = useState(
    {} as IMarketplaceIntegrationsResponse
  );
  const [merchantIntegrations, setMerchantIntegrations] = useState<
    IMarketplaceMerchantIntegration[]
  >([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(
    getLocalMarketplaceId() || ""
  );
  const [rules, setRules] = useState({} as IConfigInitialRules);
  const [carriers, setCarriers] = useState<ICarrier[]>([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const localToken = getLocalToken();

  const logout = () => {
    storeData(IS_LOGOUT, "true");
    clearLocalData();
    setCurrentUser({} as IUserResponses);
    setMerchantIntegrations([]);
    setSelectedMarketplace("");
    setRules({} as IConfigInitialRules);
    setShowSkeleton(true);
    setMarketplaceIntegrations({} as IMarketplaceIntegrationsResponse);
  };

  const getIntegrationsOfMerchant = async () => {
    try {
      const { marketplaces } = await fetchData({
        url: "/merchants/marketplaces",
        method: GET,
      });

      setMerchantIntegrations(marketplaces);
      const currentMarketplace = getLocalMarketplaceId();
      if (!currentMarketplace) {
        const { id, name } = marketplaces[0];
        storeData(CURRENT_MARKETPLACE, String(id));
        storeData(MARKETPLACE_NAME, name);
        setSelectedMarketplace(String(id));
      }
    } catch (error: any) {
      // TODO aca deberia redigir a pantalla de error
    }
  };

  const handleGetIntegrationsOfMarketplace = async () => {
    const integrations = await getIntegrationsOfMarketplace();
    setMarketplaceIntegrations(integrations);
  };

  const getRules = async () => {
    try {
      const fetchedRules = await fetchData({
        url: `marketplaces/rules${
          selectedMarketplace && `?marketplaceId=${selectedMarketplace}`
        }`,
        method: GET,
      });
      setRules({ ...rules, ...fetchedRules });
    } catch (error: any) {
      // TODO manejar error
    }
  };

  const transformCarriersDependingOnEntity = (fetchedCarriers: ICarrier[]) => {
    if (currentUser?.entity !== MERCHANT) return fetchedCarriers;
    return fetchedCarriers.map(({ marketplace }: any) => marketplace);
  };

  const handleGetCarriers = async () => {
    const carriersQuery: any = {
      entity: currentUser?.entity,
      selectedMarketplace,
    };
    if (currentUser?.entity === MERCHANT)
      carriersQuery.urlQueryParams = "&getAll=true";
    try {
      const carriersResponse = await getCarriers(carriersQuery);
      setShowSkeleton(false);
      setCarriers(transformCarriersDependingOnEntity(carriersResponse));
    } catch (error: any) {
      // TODO manejar error;
    }
  };

  const saveUserData = ({ token, user }: ISaveUserData) => {
    setCurrentUser(user);
    storeData(TOKEN, token);

    if (user.entity !== MERCHANT && user.entity !== INTERNAL && !user.hasCarriers)
      storeData(INITIAL_CONFIGURATION_COMPLETE, INCOMPLETE_CONFIG);

    if (user.entity === MERCHANT) getIntegrationsOfMerchant();
    if (user.entity === MARKETPLACE) handleGetIntegrationsOfMarketplace();
  };

  const refreshUserData = async () => {
    if (!localToken) {
      logout();
      return;
    }

    try {
      const { user } = await fetchData({
        url: "/users/getUserByToken",
        method: GET,
      });
      saveUserData({ token: localToken, user });
    } catch (error) {
      clearLocalData();
    }
  };

  useEffect(() => {
    setShowSkeleton(true);
    if (selectedMarketplace || currentUser.entity === MARKETPLACE) getRules();
    if (currentUser.entity) {
      setCarriers([]);
      if (currentUser.entity !== MERCHANT || selectedMarketplace) handleGetCarriers();
    }
  }, [selectedMarketplace, currentUser]);

  useEffect(() => {
    setShowSkeleton(!!(localToken && !currentUser.entity));
  }, [localToken, currentUser]);

  useEffect(() => {
    const handleMultiTabLogout = ({ key, newValue }: any) => {
      if (key === TOKEN && !newValue) redirectTo("/autenticacion");
    };

    window.addEventListener("storage", handleMultiTabLogout);

    return () => window.removeEventListener("storage", handleMultiTabLogout);
  }, []);
  return (
    <UserContext.Provider
      value={{
        currentUser,
        marketplaceIntegrations,
        merchantIntegrations,
        saveUserData,
        refreshUserData,
        getIntegrationsOfMerchant,
        logout,
        selectedMarketplace,
        setSelectedMarketplace,
        rules,
        carriers,
        showSkeleton,
        handleGetIntegrationsOfMarketplace,
        getRules,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
