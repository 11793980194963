import React, { useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RecoverPasswordConfirmation from "../../components/password/RecoverPasswordConfirmation";
import RecoverPasswordForm from "../../components/password/RecoverPasswordForm";
import { IAlert } from "../../interfaces/alert";
import { IValidation } from "../../interfaces/form";
import { ERROR, ERRORS_MESSAGES, PATCH } from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { validateInputs } from "../../utils/inputValidations";
import { IUseRef } from "../../interfaces/recoverPassword";

const { emailInvalid, emailRequired } = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
];

const RecoverPassword = () => {
  const emailRef = useRef<IUseRef>(null);
  const [errors, setErrors] = useState({ email: [] });
  const [alert, setAlert] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const email = emailRef.current?.value.trim() as string;
    setCurrentEmail(email);
    const inputErrors = validateInputs({ email }, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0 && email) {
      setDisableButton(true);
      try {
        await fetchData({
          url: "/users/recover",
          method: PATCH,
          body: { email },
        });

        setShowSuccess(true);
        setDisableButton(false);
      } catch (error: any) {
        setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      }
    }
  };

  const formComposition = {
    emailRef,
    disableButton,
    alert,
    errors,
    handleSubmit,
    navigate,
  };

  const confirmationComposition = {
    alert,
    setAlert,
    navigate,
    email: currentEmail,
  };

  return (
    <Box className="p-x-card" pt={3}>
      <Typography className="m-t-10 f-s-20">Recupera tu contraseña</Typography>
      <Grid container spacing={2}>
        {showSuccess ? (
          <RecoverPasswordConfirmation {...confirmationComposition} />
        ) : (
          <RecoverPasswordForm {...formComposition} />
        )}
      </Grid>
    </Box>
  );
};

export default RecoverPassword;
