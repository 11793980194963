import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Typography,
  FormHelperText,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import GenericAlert from "../GenericAlert";
import { fetchData } from "../../utils/dataProvider";
import {
  AUTHENTICATION_PATH,
  CUIT_PLACEHOLDER,
  ERROR,
  ERRORS_MESSAGES,
  IVA_SITUATIONS,
  POST,
} from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import { ITargetValue, IValidation } from "../../interfaces/form";
import {
  IBillingFormErrors,
  IAccountInfoStep,
  IRegisterMerchantSubmit,
} from "../../interfaces/signup";
import { IAlert } from "../../interfaces/alert";
import { trimValues } from "../../utils/form";
import GenericForm from "../GenericForm";

const {
  emailInvalid,
  emailRequired,
  nameRequired,
  ivaSituationRequired,
  businessNameRequired,
  cuitInvalid,
  cuitRequired,
  commercialAddressRequired,
  commercialAddressHasToContainNumbers,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "name",
    type: "required",
    message: nameRequired,
  },
  {
    id: "billingEmail",
    type: "required",
    message: emailRequired,
  },
  {
    id: "billingEmail",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "ivaSituation",
    type: "required",
    message: ivaSituationRequired,
  },
  {
    id: "businessName",
    type: "required",
    message: businessNameRequired,
  },
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "commercialAddress",
    type: "required",
    message: commercialAddressRequired,
  },
  {
    id: "commercialAddress",
    type: "contain-numbers",
    message: commercialAddressHasToContainNumbers,
  },
];

const registerMerchant = async ({
  selectedValues,
  navigate,
  setAlert,
  token,
  setSubmitting,
}: IRegisterMerchantSubmit) => {
  setSubmitting(true);
  try {
    await fetchData({
      url: "/merchants/register",
      method: POST,
      body: { ...selectedValues, token },
    });
    navigate(`${AUTHENTICATION_PATH}?reason=signup`);
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  } finally {
    setSubmitting(false);
  }
};

const AccountInfoForm = (props: IAccountInfoStep) => {
  const { selectedValues, setSelectedValues, setGridSize, goBack, token } =
    props;
  const navigate = useNavigate();
  const [errors, setErrors] = useState({} as IBillingFormErrors);
  const [alert, setAlert] = useState({} as IAlert);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = ({ name, value }: ITargetValue) =>
    setSelectedValues({
      ...selectedValues,
      [name]: value,
    });
  const handleSubmit = () => {
    const inputErrors = validateInputs(selectedValues, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      registerMerchant({
        selectedValues: trimValues(selectedValues),
        navigate,
        setAlert,
        token,
        setSubmitting,
      });
    }
  };
  const showInputErrors = (inputErrors: string[]) =>
    inputErrors.map((message: string) => (
      <FormHelperText className="color-error" key={message}>
        {message}
      </FormHelperText>
    ));
  useEffect(() => setGridSize(4), []);
  return (
    <>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm onSubmit={handleSubmit} disableSubmit={submitting}>
        <Typography className="m-t-20 f-s-14 m-b-10">Nombre y Apellido</Typography>
        <TextField
          fullWidth
          size="small"
          name="userName"
          placeholder="Ingresar nombre y apellido"
          value={selectedValues.userName}
          error={errors.userName?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
          autoFocus
        />
        {errors.userName?.length > 0 && showInputErrors(errors.userName)}
        <Typography className="m-t-20 f-s-14 m-b-10">Alias</Typography>
        <TextField
          fullWidth
          size="small"
          name="name"
          placeholder="Ingresar alias"
          value={selectedValues.name}
          error={errors.name?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
          autoFocus
        />
        {errors.name?.length > 0 && showInputErrors(errors.name)}
        <FormControl className="m-t-20" fullWidth>
          <Typography className="f-s-14 m-b-10">
            Condición ante el IVA
          </Typography>
          <Select
            size="small"
            name="ivaSituation"
            value={selectedValues.ivaSituation}
            error={errors.ivaSituation?.length > 0}
            onChange={({ target }: SelectChangeEvent) => handleChange(target)}
          >
            {IVA_SITUATIONS.map((name: string) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {errors.ivaSituation?.length > 0 &&
            showInputErrors(errors.ivaSituation)}
        </FormControl>
        <Typography className="m-t-20 f-s-14 m-b-10">Razón Social</Typography>
        <TextField
          fullWidth
          size="small"
          name="businessName"
          placeholder="Razón Social"
          value={selectedValues.businessName}
          error={errors.businessName?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.businessName?.length > 0 &&
          showInputErrors(errors.businessName)}
        <Typography className="m-t-20 f-s-14 m-b-10">CUIT</Typography>
        <TextField
          fullWidth
          size="small"
          name="cuit"
          placeholder={CUIT_PLACEHOLDER}
          value={selectedValues.cuit}
          error={errors.cuit?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.cuit?.length > 0 && showInputErrors(errors.cuit)}
        <Typography className="m-t-20 f-s-14 m-b-10">
          Domicilio Comercial
        </Typography>
        <TextField
          fullWidth
          size="small"
          name="commercialAddress"
          placeholder="Domicilio comercial"
          value={selectedValues.commercialAddress}
          error={errors.commercialAddress?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.commercialAddress?.length > 0 &&
          showInputErrors(errors.commercialAddress)}
        <Typography className="m-t-20 f-s-14 m-b-10">
          Correo electrónico de facturación
        </Typography>
        <TextField
          fullWidth
          size="small"
          name="billingEmail"
          placeholder="Ingresar correo electrónico de facturación"
          value={selectedValues.billingEmail}
          error={errors.billingEmail?.length > 0}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange(target)
          }
        />
        {errors.billingEmail?.length > 0 &&
          showInputErrors(errors.billingEmail)}
      </GenericForm>
      <Button
        variant="contained"
        className="m-t-40 w-100"
        disabled={submitting}
        onClick={handleSubmit}
      >
        Finalizar
      </Button>
      <Button className="m-t-20 w-100" onClick={goBack}>
        Volver
      </Button>
    </>
  );
};

export default AccountInfoForm;
