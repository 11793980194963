import { Grid } from "@mui/material";
import SummaryCard from "./SummaryCard";
import {
  SUMMARY_CARD_TITLES,
  SUMMARY_SHIPMENTS_CARD_QUANTITY,
} from "../../utils/constants";
import CardSkeleton from "./CardSkeleton";
import { ISummaryShipments } from "../../interfaces/metrics";

const SummaryShipments = ({ shipmentsData }: ISummaryShipments) => (
  <Grid container mt={1} spacing={3}>
    {shipmentsData && Object.entries(shipmentsData).length > 0 ? (
      Object.keys(shipmentsData).map((key) => (
        <SummaryCard
          key={key}
          title={SUMMARY_CARD_TITLES[key]}
          quantity={shipmentsData[key]}
        />
      ))
    ) : (
      <>
        {[...Array(SUMMARY_SHIPMENTS_CARD_QUANTITY)].map((_, i) => (
          <CardSkeleton key={i} />
        ))}
      </>
    )}
  </Grid>
);

export default SummaryShipments;
