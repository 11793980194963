import { DateTime } from "luxon";
import { RESCHEDULED, PENDING, IN_PROCESS, IN_TRANSIT, SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE, SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW } from "./constants";

export const getInitialDates = () => {
  const today = DateTime.now();
  const { year, month, day } = today.minus({weeks: 1});
  const dayOfWeekBefore = DateTime.local(year, month, day);

  return {
    from: dayOfWeekBefore,
    to: today,
  };
};

export const getColorBadge = (statusName: string) => {
  switch (statusName) {
  case RESCHEDULED:
    return "badge-yellow";
  case PENDING:
  case IN_PROCESS:
  case IN_TRANSIT:
  case SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE:
  case SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW:
  default:
    return "badge-blue";
  }
};
