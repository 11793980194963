import { Socket } from "socket.io-client";
import {
  IHandleRedirect,
  INewNotification,
  INotification,
  INotificationsAndCount,
} from "../interfaces/notifications";
import {
  CURRENT_MARKETPLACE,
  ENTITY_ROUTES,
  MARKETPLACE_NAME,
  MAX_NOTIFICATIONS,
  MERCHANT,
} from "./constants";
import { getLocalMarketplaceId, storeData } from "./storage";
import config from "../config/config";

const { S3_BASE_URL } = config;

export const updateReadNotifications = (
  socket: Socket,
  notificationsIds: string[]
) => {
  socket.emit("update-read-notifications", {
    notificationsIds,
  });
};

const singularAndPluralTimeUnitHandler = (
  timeUnit: string,
  amountOfUnit: number
) => {
  const pluralAddition = timeUnit === "mes" ? "es" : "s";
  return `${
    Math.floor(amountOfUnit) > 1 ? `${timeUnit}${pluralAddition}` : timeUnit
  }`;
};

const getTimeMessage = (timeUnit: string, amountOfUnit: number) =>
  `Hace ${Math.floor(amountOfUnit)} ${singularAndPluralTimeUnitHandler(
    timeUnit,
    amountOfUnit
  )}`;

export const setReadNotificationsAndReturnUpdatedArray = (
  socket: Socket,
  notificationsToUpdate: INotification[]
) => {
  if (notificationsToUpdate.length) {
    const notificationsIds = notificationsToUpdate.map(({ _id }) => _id);

    socket.emit("update-read-notifications", {
      notificationsIds,
    });
  }
};

export const getHowLongCreationWas = (firstDate: Date, secondDate: Date) => {
  const minutesOfDifference =
    (secondDate.getTime() - firstDate.getTime()) / 60000;
  if (minutesOfDifference < 1) return "Hace un momento";
  if (minutesOfDifference < 60)
    return getTimeMessage("minuto", minutesOfDifference);
  if (minutesOfDifference / 60 < 24)
    return getTimeMessage("hora", minutesOfDifference / 60);
  const amountOfDaysInMonth = new Date(
    secondDate.getFullYear(),
    secondDate.getMonth() + 1,
    0
  ).getDate();
  if (minutesOfDifference / (60 * 24) < amountOfDaysInMonth)
    return getTimeMessage("día", minutesOfDifference / (60 * 24));
  return getTimeMessage(
    "mes",
    minutesOfDifference / (60 * 24 * amountOfDaysInMonth)
  );
};

export const updateNotificationsAndCountAfterMenuOpening = (
  socket: Socket,
  notificationsAndCount: INotificationsAndCount
) => {
  const notificationsToUpdate = notificationsAndCount.notifications.filter(
    ({ read_at }) => !read_at
  );
  if (notificationsToUpdate.length) {
    setReadNotificationsAndReturnUpdatedArray(socket, notificationsToUpdate);
  }
};

export const removeDotFromReadNotifications = (
  { notifications, count }: INotificationsAndCount,
  setNotificationsAndCount: any
) => {
  const numberOfUpdates = notifications.filter(({ read_at }) => !read_at);
  if (numberOfUpdates.length) {
    const updatedNotifications = notifications.map(({ read_at, ...rest }) => ({
      ...rest,
      read_at: read_at || new Date(),
    }));
    const newCount =
      count - numberOfUpdates.length > 0 ? count - numberOfUpdates.length : 0;
    setNotificationsAndCount({
      notifications: updatedNotifications,
      count: newCount,
    });
  }
};

export const placeNewNoJoinableNotification = (
  { notifications, count }: INotificationsAndCount,
  notification: INotification,
  setNotificationsAndCount: any
) => {
  const newNotifications =
    notifications.length + 1 > MAX_NOTIFICATIONS
      ? notifications.slice(
        0,
        -Math.abs(notifications.length - MAX_NOTIFICATIONS + 1)
      )
      : notifications;
  newNotifications.unshift(notification);
  setNotificationsAndCount({
    count: count + 1,
    notifications: newNotifications,
  });
};

export const getIconUrl = (iconSlug: string) =>
  `${S3_BASE_URL}/icons/${iconSlug}.svg`;

export const placeNewJoinableNotification = (
  { notifications, count }: INotificationsAndCount,
  notification: INotification,
  index: number,
  setNotificationsAndCount: any
) => {
  const newNotifications = [...notifications];
  newNotifications.splice(index, 1);
  newNotifications.unshift(notification);
  setNotificationsAndCount({
    count,
    notifications: newNotifications,
  });
};

export const updateNotifications = (
  newNotification: INewNotification,
  notificationsAndCount: INotificationsAndCount,
  setNotificationsAndCount: any
) => {
  if (!newNotification) return;
  const { notifications } = notificationsAndCount;
  const { notification, isJoinableNotification } = newNotification;
  const index = notifications.findIndex(({ _id }) => _id === notification._id);
  if (isJoinableNotification && index !== -1) {
    placeNewJoinableNotification(
      notificationsAndCount,
      notification,
      index,
      setNotificationsAndCount
    );
    return;
  }
  placeNewNoJoinableNotification(
    notificationsAndCount,
    notification,
    setNotificationsAndCount
  );
};

export const listenToNotifications = (
  socket: Socket,
  setNotificationsAndCount: any,
  setNewNotification: any
) => {
  socket.on(
    "list-notifications-menu",
    ({ notifications: remoteNotifications, count }: INotificationsAndCount) => {
      setNotificationsAndCount({
        notifications: remoteNotifications,
        count,
      });
      socket.on("single-notification", (notification: INotification) =>
        setNewNotification({ notification, isJoinableNotification: false })
      );
      socket.on("updated-notification", (notification: INotification) => {
        setNewNotification({ notification, isJoinableNotification: true });
      });
    }
  );
  socket.emit("get-initial-notifications");
};

export const handleRedirect = ({
  notificationInfo: { redirect_url, marketplace_id },
  entity,
  setSelectedMarketplace,
  merchantIntegrations,
  navigate,
}: IHandleRedirect) => {
  if (
    marketplace_id &&
    entity === MERCHANT &&
    getLocalMarketplaceId() !== String(marketplace_id)
  ) {
    const marketplaceInfo = merchantIntegrations.find(
      ({ id }) => id === marketplace_id
    );
    setSelectedMarketplace(marketplaceInfo.id);
    storeData(CURRENT_MARKETPLACE, marketplaceInfo.id);
    storeData(MARKETPLACE_NAME, marketplaceInfo.name);
  }
  navigate(`${ENTITY_ROUTES[entity]}/${redirect_url}`);
};
export const deleteNotifications = (
  socket: Socket,
  notificationsIds: string[]
) => socket.emit("delete-notifications", { notificationsIds });
