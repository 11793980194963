import React from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SimpleFormInput } from "./SimpleFormInput";
import { showInputErrors } from "../../utils/credentials";
import { IRole } from "../../interfaces/usersRoles";
import { USER_EDIT_MODAL_TYPE } from "../../utils/constants";

const CreateEditUsers = ({
  values,
  setValues,
  typeOfModal,
  roles,
  errors,
}: any) => {
  const baseInfo = {
    values,
    setValues,
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SimpleFormInput
          inputTitle="Nombre y apellido"
          placeholder="Ingresar nombre y apellido"
          valueName="name"
          disabled={typeOfModal === USER_EDIT_MODAL_TYPE}
          errors={errors.name}
          {...baseInfo}
        />
      </Grid>
      <Grid item xs={12}>
        <SimpleFormInput
          inputTitle="Correo electrónico"
          placeholder="Ingresar correo electrónico"
          valueName="email"
          disabled={typeOfModal === USER_EDIT_MODAL_TYPE}
          errors={errors.email}
          {...baseInfo}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Asignar rol</Typography>
          <Autocomplete
            getOptionLabel={(option: IRole) => option.name}
            value={values?.roles[0]}
            // multiple
            options={roles}
            noOptionsText="No se encontró el rol"
            onChange={(_, value: any) => setValues({ ...values, roles: value ? [value] : [] })}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors?.role}
                fullWidth
                size="small"
                label="Rol"
              />
            )}
          />
          {errors?.roles?.length > 0 && showInputErrors(errors.roles)}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CreateEditUsers;
