import { FormControl, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import ConfirmChangesCredentials from "../configuration/ConfirmChangesCredentials";
import ModalBase from "./ModalBase";
import { SimpleFormInput } from "../forms/SimpleFormInput";
import GenericForm from "../GenericForm";
import { showInputErrors } from "../../utils/credentials";
import {
  carrierDimensionProps,
  editCarrrierInfoProps,
  ICarrierInfo,
  ICarrierInfoErrors,
  IRawCarrierInfo,
} from "../../interfaces/modal";
import { capitalizeOnlyFirstLetter } from "../../utils/orders";
import { validateInputs } from "../../utils/inputValidations";
import { IValidation } from "../../interfaces/form";
import { ERRORS_MESSAGES } from "../../utils/constants";

const getCarrierInitialValues = (carrier: IRawCarrierInfo) => ({
  cities: carrier?.cities?.join(", "),
  email: carrier?.contact_info?.email,
  phone_number: carrier?.contact_info?.phone_number,
  minHeight: carrier?.volume_info?.height?.min,
  maxHeight: carrier?.volume_info?.height?.max,
  minWidth: carrier?.volume_info?.width?.min,
  maxWidth: carrier?.volume_info?.width?.max,
  minDepth: carrier?.volume_info?.depth?.min,
  maxDepth: carrier?.volume_info?.depth?.max,
  minWeight: carrier?.weight_info?.min,
  maxWeight: carrier?.weight_info?.max,
});
const { emailInvalid, onlyPositiveNumbers } = ERRORS_MESSAGES;

const handleDimensionChange = (
  setValues: any,
  values: any,
  value: string,
  valueName: string
) => {
  const invalidCharacters = ["-", "e", "E"];
  const containsInvalidCharacters = invalidCharacters.some(
    (
      restrictedValue: string // "-", "e" and "E" could be part of a valid number, but isnt valid in this context
    ) => value.toString().includes(restrictedValue)
  );
  if (containsInvalidCharacters) return;
  setValues({
    ...values,
    [valueName]: Number(value),
  });
};

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "minWeight",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "maxWeight",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "maxWeight",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "minWidth",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "maxWidth",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "minHeight",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "maxHeight",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "minDepth",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
  {
    id: "maxDepth",
    type: "only-numbers",
    message: onlyPositiveNumbers,
  },
];

const CarrierDimensionInfo = ({
  errors,
  values,
  setValues,
  title,
  valueName,
}: carrierDimensionProps) => (
  <Grid item xs={3}>
    <FormControl size="small" fullWidth>
      <Typography className="f-s-14 m-b-10">{title}</Typography>
      <OutlinedInput
        endAdornment="cm"
        type="number"
        placeholder="0"
        inputProps={{ min: 0 }}
        value={values[valueName]}
        error={errors[valueName]?.length > 0}
        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
          handleDimensionChange(setValues, values, value, valueName)
        }
        className="f-s-14"
      />
      {errors[valueName]?.length > 0 && showInputErrors(errors[valueName])}
    </FormControl>
  </Grid>
);

const dimensionsInputs: { title: string; valueName: string }[] = [
  { title: "Ancho mínimo", valueName: "minWidth" },
  { title: "Ancho máximo", valueName: "maxWidth" },
  { title: "Alto mínimo", valueName: "minHeight" },
  { title: "Alto máximo", valueName: "maxHeight" },
  { title: "Peso mínimo", valueName: "minWeight" },
  { title: "Peso máximo", valueName: "maxWeight" },
  { title: "Profundidad mínima", valueName: "minDepth" },
  { title: "Profundidad máxima", valueName: "maxDepth" },
];

const EditCarrierInfoModal = ({
  onClose,
  handleCarriersChanges,
  open,
  carrier,
}: editCarrrierInfoProps) => {
  const [values, setValues] = useState(
    getCarrierInitialValues(carrier) as ICarrierInfo
  );
  const [errors, setErrors] = useState({} as ICarrierInfoErrors);
  const handleSubmit = () => {
    const inputErrors = validateInputs(values, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      handleCarriersChanges({
        _id: carrier._id,
        name: carrier.name,
        cities: values?.cities?.split(", "),
        volume_info: {
          height: {
            min: values.minHeight,
            max: values.maxHeight,
          },
          depth: {
            min: values.minDepth,
            max: values.maxDepth,
          },
          width: {
            min: values.minWidth,
            max: values.maxWidth,
          },
        },
        weight_info: {
          min: values.minWeight,
          max: values.maxWeight,
        },
        contact_info: {
          phone_number: values.phone_number,
          email: values.email,
        },
      });
      onClose();
    }
  };
  return (
    <ModalBase open={open} handleClose={onClose} maxWidth="md">
      <Grid container>
        <Typography className="f-s-18 m-b-20">
          Editar datos de {capitalizeOnlyFirstLetter(carrier?.name)}
        </Typography>
        <GenericForm onSubmit={handleSubmit}>
          <Grid item className="m-t-10">
            <SimpleFormInput
              inputTitle="Zona de cobertura"
              placeholder="Ingresar zona de cobertura"
              valueName="cities"
              errors={errors.cities}
              values={values}
              setValues={setValues}
            />
          </Grid>
          <Grid item className="m-t-10">
            <SimpleFormInput
              inputTitle="Teléfono de contacto"
              placeholder="Ingresar teléfono de contacto"
              valueName="phone_number"
              errors={errors.phone_number}
              values={values}
              setValues={setValues}
            />
          </Grid>
          <Grid item className="m-t-10">
            <SimpleFormInput
              inputTitle="Email de contacto"
              placeholder="Ingresar email de contacto"
              valueName="email"
              errors={errors.email}
              values={values}
              setValues={setValues}
            />
          </Grid>
        </GenericForm>
        <>
          <Typography className="f-s-18 m-t-20 m-b-20">
            Dimensiones máximas y mínimas de los paquetes
          </Typography>

          <Grid container spacing={3}>
            {dimensionsInputs.map(({ title, valueName }) => (
              <CarrierDimensionInfo
                setValues={setValues}
                values={values}
                errors={errors}
                title={title}
                valueName={valueName}
              />
            ))}
          </Grid>
        </>
        <ConfirmChangesCredentials
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </ModalBase>
  );
};

export default EditCarrierInfoModal;
