import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Plans from "../../components/signupClient/Plans";
import AccountForm from "../../components/signupClient/AccountForm";
import EmailConfirmation from "../../components/signupClient/EmailConfirmation";
import { Box, Typography } from "@mui/material";
import { ISignupClientPt1, ISignupSteps } from "../../interfaces/signup";

const SignupClientPt1 = () => {
  // TODO crear type para outlet
  const { setGridSize, step, goBack, goForward } = useOutletContext<any>();
  const [selectedValues, setselectedValues] = useState({} as ISignupClientPt1);
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");

  const planStep = {
    title: "Selecciona el plan ideal para tu empresa",
    component: () => (
      <Plans
        selectedValues={selectedValues}
        setSelectedValues={setselectedValues}
        setGridSize={setGridSize}
        goForward={goForward}
      />
    ),
  };
  const steps: ISignupSteps[] = [
    {
      title: "Completa la configuración de tu cuenta",
      component: () => (
        <AccountForm
          selectedValues={selectedValues}
          setSelectedValues={setselectedValues}
          setGridSize={setGridSize}
          goForward={goForward}
          goBack={goBack}
        />
      ),
    },
    {
      title: "Confirmá tu correo electrónico",
      component: () => (
        <EmailConfirmation
          selectedValues={selectedValues}
          setGridSize={setGridSize}
        />
      ),
    },
  ];

  const totalSteps = plan ? steps : [planStep, ...steps];

  useEffect(() => {
    if (plan) setselectedValues({ planId: plan } as ISignupClientPt1);
  }, []);

  return (
    <Box className="p-x-card" py={3}>
      <Typography className="f-s-20">
        Paso {step} de {plan ? "3" : "4"}
      </Typography>
      <Typography variant="h2" className="m-t-10">
        {totalSteps[step - 1].title}
      </Typography>
      <Box>{totalSteps[step - 1].component()}</Box>
    </Box>
  );
};

export default SignupClientPt1;
