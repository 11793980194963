import { Card, CardContent, Grid, Typography } from "@mui/material";
import ApiKeysGrid from "./ApiKeysGrid";

const AccountAuthInfo = ({ isMobile }: { isMobile?: boolean }) => (
  <Grid
    item
    container
    xs={12}
    md={7}
    alignItems="center"
    justifyContent="start"
  >
    <Typography className="f-s-20 text-dark m-t-40">Autenticación</Typography>
    <Grid item xs={12} className="m-t-20">
      {isMobile ? (
        <ApiKeysGrid />
      ) : (
        <Card
          className="br-20 bg-light"
          sx={{ padding: 3, paddingBottom: 5.75 }}
        >
          <CardContent>
            <ApiKeysGrid />
          </CardContent>
        </Card>
      )}
    </Grid>
  </Grid>
);

export default AccountAuthInfo;
