import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import trackingImg from "../../images/landing/landing_tracking.png";
import { fetchData } from "../../utils/dataProvider";
import {
  CARRIER_MAIN_PAGE,
  DELIVERED,
  ERROR,
  GET,
  LANDING_PATH,
  MEDIUM,
} from "../../utils/constants";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import ShipmentTrackingTable from "../tables/ShipmentTrackingTable";
import ShipmentTrackingTimeline from "./ShipmentTrackingTimeline";
import Loader from "../Loader";
import { IOrderData } from "../../interfaces/tracking";
import { getDeliveredDateFromOrder } from "../../utils/tracking";
import { getOrderStatus } from "../../utils/orders";
import logo from "../../images/coati_logo.png";


const ShipmentTracking = () => {
  const [orderData, setOrderData] = useState<IOrderData>({} as IOrderData);
  const [errorAlert, setErrorAlert] = useState({} as IAlert);
  const { trackingNumber } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 870px)");

  const getOrder = async () => {
    try {
      const response = await fetchData({
        url: `/orders/${trackingNumber}`,
        method: GET,
      });
      setOrderData(response);
    } catch (error: any) {
      setErrorAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };

  const { status, orderInfo, tracking_id, status_history, alias } = orderData;
  const orderStatus = getOrderStatus(status);

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        className="header-white navbar-no-shadow"
        sx={{ paddingLeft: 3 }}
      >
        <Toolbar>
          <Link href={LANDING_PATH}>
            <img src={logo} className="w-logo logo-sm" alt="coati-logo" />
          </Link>
        </Toolbar>
      </AppBar>
      <Grid
        container
        flexDirection="column"
        flexWrap="nowrap"
        className=" p-x-landing p-y-landing"
        paddingY={{ xs: 3, lg: 20 }}
      >
        {errorAlert.showAlert && <GenericAlert alert={errorAlert} />}
        {Object.keys(orderData).length > 0 ? (
          <>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              marginBottom={{ xs: 0, lg: 5 }}
            >
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => navigate(-1)}>
                  <span className="material-symbols-rounded text-dark">
                    keyboard_backspace
                  </span>
                </IconButton>
                <Typography ml={1} className="f-s-16 f-s-md-24">
                  Seguí el estado de tu pedido
                </Typography>
              </Box>
            </Grid>
            <Grid container item>
              <Grid container item xs={12} lg={6}>
                <Box className="tracking-img">
                  <img
                    src={trackingImg}
                    alt="Seguí tu envío"
                    className="w-100"
                  />
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                justifyContent="space-evenly"
                padding={{ xs: 3, lg: 8 }}
              >
                <Typography className="f-s-16 f-s-md-24 text-bold m-b-10">
                  {status === DELIVERED && status_history
                    ? getDeliveredDateFromOrder(status_history)
                    : orderStatus?.description}
                </Typography>

                <Box display="flex" flexDirection="column" className="m-b-10">
                  <Typography className="f-s-14 f-s-md-20 text-bold">
                    Estado
                  </Typography>
                  <Typography className="f-s-14 f-s-md-20">
                    {alias || orderStatus?.label}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className="m-b-10">
                  <Typography className="f-s-14 f-s-md-20 text-bold">
                    N° de envío
                  </Typography>
                  <Typography className="f-s-14 f-s-md-20">
                    {tracking_id}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className="m-b-10">
                  <Typography className="f-s-14 f-s-md-20 text-bold">
                    Dirección de entrega
                  </Typography>
                  <Typography className="f-s-14 f-s-md-20">
                    {orderInfo?.destination}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className="m-b-10">
                  <Typography className="f-s-14 f-s-md-20 text-bold">
                    Operador Logístico
                  </Typography>
                  <Typography className="f-s-14 f-s-md-20">
                    {orderInfo?.carrier}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className="m-b-10">
                  <Link
                    href={CARRIER_MAIN_PAGE[orderInfo.carrier.toUpperCase()]}
                    className="f-s-14 f-s-md-20"
                    target="_blank"
                    underline="none"
                  >
                    Ver seguimiento detallado en{" "}
                    {orderInfo.carrier.toUpperCase()}
                  </Link>
                </Box>
              </Grid>
            </Grid>
            {status_history?.length > 0 && (
              <>
                {isMobile ? (
                  <ShipmentTrackingTimeline statusHistory={status_history} />
                ) : (
                  <ShipmentTrackingTable statusHistory={status_history} />
                )}
              </>
            )}
          </>
        ) : (
          <>{!errorAlert.showAlert && <Loader size={MEDIUM} />}</>
        )}
      </Grid>
    </>
  );
};

export default ShipmentTracking;
