import { useContext } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../images/coati_logo.png";
import { ENTITY_ROUTES, MERCHANT } from "../../utils/constants";
import { IMarketplaceMerchantIntegration } from "../../interfaces/merchant";
import NavbarOptions from "./NavbarOptions";
import { ICommonNavbarProps } from "../../interfaces/navbar";
import { UserContext } from "../../contexts/userContext";

const NavbarDesktop = ({
  toggleSidebar,
  showContent,
  handleChange,
}: ICommonNavbarProps) => {
  const {
    currentUser: { entity },
    merchantIntegrations,
    selectedMarketplace,
    showSkeleton
  } = useContext(UserContext);

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleSidebar}
        edge="start"
        className="icon-m-r"
      >
        {showContent && <span className="material-symbols-rounded">menu</span>}
      </IconButton>
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Grid item>
          <Link to={ENTITY_ROUTES[entity]}>
            <img src={logo} className="w-logo logo-sm" alt="coati-logo" />
          </Link>
        </Grid>
        {/* Marketplace Select */}
        {entity === MERCHANT &&
          showContent &&
          merchantIntegrations.length > 0 && (
          <Grid item>
            {merchantIntegrations.length === 1 ? (
              <Typography>{merchantIntegrations[0].name}</Typography>
            ) : (
              <FormControl>
                <Select
                  variant="standard"
                  disableUnderline
                  size="small"
                  value={selectedMarketplace}
                  onChange={(e) => handleChange(e.target.value)}
                  disabled={showSkeleton}
                >
                  {merchantIntegrations.map(
                    ({ id, name }: IMarketplaceMerchantIntegration) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
          </Grid>
        )}
        {/* Options */}
        <Grid item>
          <NavbarOptions />
        </Grid>
      </Grid>
    </>
  );
};

export default NavbarDesktop;
