import React from "react";
import { Grid, Skeleton } from "@mui/material";

const GraphSkeleton = ({ rows }: { rows: number }) => (
  <Grid
    container
    item
    xs={12}
    md={6}
    pt={5}
    pb={8}
    className="summaryOlsContainer"
    sx={{
      paddingRight: { xs: 3, md: 6, xl: 18 },
      paddingLeft: { xs: 0, md: 3, lg: 5 },
    }}
  >
    <Grid item xs={12} md={5} className="m-b-50">
      <Skeleton variant="rounded" width="100%" height={18} />
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" className="m-b-10">
        <Grid item xs={5.2}>
          <Skeleton variant="rounded" width="100%" height={10} />
        </Grid>
        <Grid item xs={0.5}>
          <Skeleton variant="rounded" width="100%" height={10} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width="100%" height={5} />
      </Grid>
    </Grid>
    <Grid
      container
      pt={6}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
    >
      <Grid
        item
        xs={2}
        md={3}
        lg={4}
        justifyContent="center"
        sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
      >
        <Skeleton variant="circular" width="10vw" height="10vw" />
      </Grid>
      <Grid
        item
        container
        xs={10}
        sm={12}
        lg={8}
        rowSpacing={3}
        sx={{ marginTop: { xs: 2, md: 0 }, marginLeft: { xs: 0, lg: 2 } }}
      >
        {[...Array(rows)].map((_, i) => (
          <Grid key={i} item container spacing={2}>
            <Grid item xs={1.5} sm={0.5} md={1.5} lg={1}>
              <Skeleton variant="rounded" width="100%" height={10} />
            </Grid>

            <Grid item xs={8} sm={10.5} md={7.5} lg={9.7}>
              <Skeleton variant="rounded" width="100%" height={10} />
            </Grid>
            <Grid item xs={2} sm={1} md={2} lg={1.3}>
              <Skeleton variant="rounded" width="100%" height={10} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

export default GraphSkeleton;
