import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import ModalBase from "./ModalBase";
import { validateInputs } from "../../utils/inputValidations";
import { IValidation } from "../../interfaces/form";
import { ERRORS_MESSAGES } from "../../utils/constants";
import {
  ICredentialsOca,
  ICredentialsOcaErrors,
} from "../../interfaces/configuration";
import CredentialsOca from "../forms/CredentialsOca";
import { ICredentialsModal } from "../../interfaces/modal";
import ConfirmChangesCredentials from "../configuration/ConfirmChangesCredentials";
import GenericForm from "../GenericForm";

const {
  passwordRequired,
  cuitRequired,
  cuitInvalid,
  emailInvalid,
  emailRequired,
  accountNumberRequired,
  doorToDoorEndDateNotInThePast,
  reverseLogisticsEndDateNotInThePast,
  reverseOnlyNumbers,
  doorToDoorRequired,
  doorToDoorOnlyNumbers,
  oneDoorToDoorRequired,
  reverseRequired,
  endDateRequired,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "cuit",
    type: "required",
    message: cuitRequired,
  },
  {
    id: "cuit",
    type: "cuit",
    message: cuitInvalid,
  },
  {
    id: "door_to_door_standard",
    type: "only-numbers",
    message: doorToDoorOnlyNumbers,
  },
  {
    id: "door_to_door_priority",
    type: "only-numbers",
    message: doorToDoorOnlyNumbers,
  },
  {
    id: "reverse",
    type: "only-numbers",
    message: reverseOnlyNumbers,
  },
  {
    id: "account_number",
    type: "required",
    message: accountNumberRequired,
  },
  {
    id: "reverse_end_date",
    type: "future_date",
    message: reverseLogisticsEndDateNotInThePast,
  },
  {
    id: "reverse",
    type: "required",
    message: reverseRequired,
  },
  {
    id: "reverse_end_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "door_to_door_standard_end_date",
    type: "future_date",
    message: doorToDoorEndDateNotInThePast,
  },
  {
    id: "door_to_door_priority_end_date",
    type: "future_date",
    message: doorToDoorEndDateNotInThePast,
  },
  {
    id: "door_to_door_standard",
    param: "door_to_door_standard_end_date",
    type: "required-param",
    message: doorToDoorRequired,
  },
  {
    id: "door_to_door_priority",
    param: "door_to_door_priority_end_date",
    type: "required-param",
    message: doorToDoorRequired,
  },
  {
    id: "door_to_door_standard_end_date",
    param: "door_to_door_standard",
    type: "required-param",
    message: endDateRequired,
  },
  {
    id: "door_to_door_priority_end_date",
    param: "door_to_door_priority",
    type: "required-param",
    message: endDateRequired,
  },
];

const CredentialsOcaModal = ({
  open,
  onClose,
  carrier,
  handleChangeOfConfig,
}: ICredentialsModal) => {
  const [values, setValues] = useState(
    (carrier.credentials || {}) as ICredentialsOca
  );
  const [errors, setErrors] = useState({} as ICredentialsOcaErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(values, formValidations);
    const { door_to_door_standard, door_to_door_priority } = values;
    const d2dContract = !!(door_to_door_standard || door_to_door_priority);
    if (!d2dContract) {
      inputErrors.door_to_door_standard = [oneDoorToDoorRequired];
      inputErrors.door_to_door_priority = [oneDoorToDoorRequired];
    }

    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      const d2dExpired =
        new Date(values.door_to_door_standard_end_date) <= new Date() &&
        new Date(values.door_to_door_priority_end_date) <= new Date();
      const rlExpired = !!(
        values.reverse && new Date(values.reverse_end_date) <= new Date()
      );

      handleChangeOfConfig({
        _id: carrier._id,
        name: carrier.name,
        disabled: carrier.disabled,
        credentials: { ...values, expired: d2dExpired || rlExpired },
      });
      onClose();
    }
  };
  return (
    <ModalBase open={open} handleClose={onClose} maxWidth="md">
      <Grid container>
        <Typography className="f-s-20 m-b-20">
          Ingresa los datos de tu contrato con Oca
        </Typography>
        <GenericForm onSubmit={handleSubmit}>
          <CredentialsOca
            values={values}
            setValues={setValues}
            errors={errors}
          />
        </GenericForm>

        <ConfirmChangesCredentials
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </ModalBase>
  );
};

export default CredentialsOcaModal;
