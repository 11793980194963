import React from "react";
import { Grid, Button } from "@mui/material";
import { IConfirmChangesButtons } from "../../interfaces/configuration";

const ConfirmChangesButtons = ({
  handleConfirm,
  handleCancel,
  disableConfirmButton = false,
  confirmButtonText = "Confirmar",
  cancelButtonText = "Cancelar",
  size = 4,
  containerSize = 12
}: IConfirmChangesButtons) => (
  <Grid container item xs={containerSize} className="m-t-20" columnSpacing={2} justifyContent="flex-end">
    <Grid item md={size}>
      <Button fullWidth variant="outlined" onClick={handleCancel}>
        {cancelButtonText}
      </Button>
    </Grid>
    <Grid item md={size}>
      <Button
        fullWidth
        variant="contained"
        disabled={disableConfirmButton}
        onClick={handleConfirm}
      >
        {confirmButtonText}
      </Button>
    </Grid>
  </Grid>
);

export default ConfirmChangesButtons;
