import { Grid, Button } from "@mui/material";
import React, { useState } from "react";
import {
  IHeaderOptions,
  TInvitationOrIntegration,
} from "../../interfaces/invitations";
import SearchInput from "../SearchInput";
import { INTERNAL, MANAGMENT_PERMISSION, ROWS_PER_PAGE } from "../../utils/constants";
import { TEntity } from "../../interfaces/userContext";
import { IValuesByEntity } from "../../interfaces/common";
import { usePermission } from "../../customHooks/usePermission";

const getSearchKey = (
  entity: TEntity,
  invitationOrIntegration: TInvitationOrIntegration
) => {
  if (invitationOrIntegration === "invitation")
    return entity === "marketplace" ? "email" : "name";
  return "all";
};

const getPlaceholderText = (
  entity: TEntity,
  invitationOrIntegration: TInvitationOrIntegration
) => {
  if (invitationOrIntegration === "invitation")
    return entity === "marketplace"
      ? "Buscar por correo electrónico"
      : "Buscar por alias";
  return "Buscar por alias, correo electrónico o cuit";
};

const getFetchDataUrl = (
  entity: TEntity,
  invitationOrIntegration: TInvitationOrIntegration,
  { searchKey, searchValue }: any
) => {
  if (invitationOrIntegration === "integration")
    return `/marketplaces_merchants/acceptedIntegrationsOf${
      entity === "marketplace" ? "Marketplace" : "Merchant"
    }?limit=${ROWS_PER_PAGE}&skip=${0}&options=${searchValue}`;
  return `/${entity}s/integrations?limit=${ROWS_PER_PAGE}&skip=${0}&${searchKey}=${searchValue}`;
};

const urlByEntity: IValuesByEntity = {
  marketplace: "acceptedIntegrationsOfMarketplace",
  merchant: "acceptedIntegrationsOfMerchant",
};

const getUrlByInvitationOrIntegration = (
  invitationOrIntegration: TInvitationOrIntegration,
  entity: TEntity
) =>
  invitationOrIntegration === "invitation"
    ? `/${entity}s/integrations?limit=${ROWS_PER_PAGE}&skip=${0}`
    : `/marketplaces_merchants/${
      urlByEntity[entity]
    }?limit=${ROWS_PER_PAGE}&skip=${0}`;

const entityToAddDict: { [key: string]: string } = {
  marketplace: "Merchant",
  internal: "Cliente",
};

const HeadOptions = ({
  setOpenModal,
  invitationOrIntegration,
  setInvitationsOrIntegrations,
  getInvitationsOrIntegrations,
  setPagination,
  getInvitationOrIntegrationArguments,
  getMappedInfo,
  entity,
  integrationsToDisable,
  page = 0,
}: IHeaderOptions) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchOrDelete, setSearchOrDelete] = useState(false);
  const { can_write } = usePermission(MANAGMENT_PERMISSION);
  //x means user has clicked on the cross
  const handleChangeInput = async (x?: boolean) => {
    setSearchOrDelete(true);
    if (searchValue && !x) {
      const searchKey = getSearchKey(entity, invitationOrIntegration);
      const { count, integrations } = await getInvitationsOrIntegrations({
        ...getInvitationOrIntegrationArguments,
        url: getFetchDataUrl(entity, invitationOrIntegration, {
          searchKey,
          searchValue,
        }),
      });
      if (count > 0) {
        const mappedInfoNeedsIntegrationToDisable =
          invitationOrIntegration === "integration"
            ? { integrations, entity, integrationsToDisable }
            : { integrations, entity };
        setInvitationsOrIntegrations(
          getMappedInfo(mappedInfoNeedsIntegrationToDisable)
        );
        return setPagination({ count, page });
      }
      return setInvitationsOrIntegrations([]);
    }
    const getInvitationsOrIntegrationsUrl = getUrlByInvitationOrIntegration(
      invitationOrIntegration,
      entity
    );
    return getInvitationsOrIntegrations({
      ...getInvitationOrIntegrationArguments,
      url: getInvitationsOrIntegrationsUrl,
    });
  };
  const handleCloseInput = () => {
    setSearchValue("");
    handleChangeInput(true);
    setSearchOrDelete(false);
  };
  return (
    <Grid container alignItems="center" className="m-t-20" spacing={2}>
      <Grid item xs={12} md={6} lg={3.8}>
        <SearchInput
          searchValue={searchValue}
          placeholder={getPlaceholderText(entity, invitationOrIntegration)}
          handleSubmit={handleChangeInput}
          handleChange={(value: string) => setSearchValue(value)}
          handleClose={handleCloseInput}
          activeSearch={searchOrDelete}
        />
      </Grid>

      {(can_write || entity === INTERNAL) && setOpenModal && (
        <Grid item xs={12} md={4} lg={2}>
          <Button
            className="w-100"
            variant="contained"
            onClick={() => setOpenModal(true)}
          >
            {`Añadir ${entityToAddDict[entity]}`}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default HeadOptions;
