import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import {
  IShipmentTrackingComponent,
  IShipmentTrackingStatus,
} from "../../interfaces/tracking";
import { DELIVERED } from "../../utils/constants";
import { getOrderStatus, mongoTimeStampToString } from "../../utils/orders";
import { getDeliveredDateFromOrder } from "../../utils/tracking";

const ShipmentTrackingTimeline = ({
  statusHistory,
}: IShipmentTrackingComponent) => (
  <Grid className="tracking-timeline" container item>
    <Timeline>
      {statusHistory.map(
        (
          { createdAt: statusDate, status }: IShipmentTrackingStatus,
          index: number
        ) => {
          const orderStatus = getOrderStatus(status);
          return (
            <TimelineItem
              key={statusDate}
              sx={{ ":before": { display: "none" } }}
            >
              <TimelineSeparator>
                <TimelineDot
                  color={
                    index === statusHistory.length - 1 ? "primary" : "grey"
                  }
                />
                {index < statusHistory.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography className="f-s-18 f-s-md-32 text-bold">
                  {orderStatus?.label}
                </Typography>
                <Typography className="f-s-14 f-s-md-20">
                  {mongoTimeStampToString(statusDate)}
                </Typography>
                <Typography className="f-s-14 f-s-md-20">
                  {status === DELIVERED
                    ? getDeliveredDateFromOrder(statusHistory)
                    : orderStatus?.description}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        }
      )}
    </Timeline>
  </Grid>
);

export default ShipmentTrackingTimeline;
