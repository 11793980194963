import React, { useContext, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MobileLayout from "../MobileLayout";
import AccountContent from "./AccountContent";
import { IAccountProps } from "../../interfaces/account";
import AccountAuthInfo from "./AccountAuthInfo";
import { ACCOUNT_PERMISSION, MERCHANT } from "../../utils/constants";
import { AccountCredentialsManagerInfo } from "./AccountCredentialsManagerInfo";
import { UserContext } from "../../contexts/userContext";
import { usePermission } from "../../customHooks/usePermission";

const AccountMobile = ({
  disableInputs,
  alert,
  values,
  disableSubmit,
  errors,
  handleEdit,
  handleChange,
  handleCancel,
  handleSubmit,
}: IAccountProps) => {
  const {
    currentUser: { entity },
    rules: { contractOwner },
  } = useContext(UserContext);
  const { can_write } = usePermission(ACCOUNT_PERMISSION);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    if (disableInputs) {
      navigate(-1);
      setOpen(false);
    } else handleCancel();
  };
  return (
    <MobileLayout title="Cuenta" open={open} handleClose={handleClose}>
      <Grid container className="p-20">
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          className="m-t-30"
        >
          <Typography className="f-s-20 text-dark">Datos de cuenta</Typography>
          {can_write && disableInputs && (
            <Button
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              size="small"
              variant="text"
              className="f-s-14"
              onClick={handleEdit}
            >
              Editar datos
            </Button>
          )}
        </Grid>
        <AccountContent
          alert={alert}
          errors={errors}
          handleChange={handleChange}
          values={values}
          disableInputs={disableInputs}
          disableSubmit={disableSubmit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isMobile
        />

        {disableInputs && (
          <>
            {entity !== MERCHANT && <AccountAuthInfo isMobile />}
            {contractOwner && (
              <AccountCredentialsManagerInfo
                isMobile
                contractOwner={contractOwner}
              />
            )}
          </>
        )}
      </Grid>
    </MobileLayout>
  );
};

export default AccountMobile;
