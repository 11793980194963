import { IAlert, IBanner } from "../interfaces/alert";

type TLayoutActionType =
  | { type: "openSideBar"; value: boolean }
  | {
      type: "showIcons";
      value: boolean;
    }
  | {
      type: "setAlert";
      value: IAlert;
    }
  | {
      type: "setBanner";
      value: IBanner[];
    }
  | {
      type: "setLoading";
      value: boolean;
    };
export const mainLayoutReducer = (state: any, action: TLayoutActionType) => {
  switch (action.type) {
  case "openSideBar":
    return { ...state, openSideBar: action.value };
  case "showIcons":
    return { ...state, showIcons: action.value };
  case "setAlert":
    return { ...state, alert: action.value };
  case "setBanner":
    return { ...state, banners: action.value };
  case "setLoading":
    return { ...state, loading: action.value };
  default:
    return state;
  }
};
