import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import SubscriptionInfo from "./SubscriptionInfo";
import MobileLayout from "../MobileLayout";
import SubscriptionContent from "./SubscriptionContent";
import { useNavigate } from "react-router-dom";
import { ISubscriptionProps } from "../../interfaces/account";
import { BILLING_PERMISSION, MERCHANT, PARTNERS_PLAN_NAME } from "../../utils/constants";
import { usePermission } from "../../customHooks/usePermission";

const SubscriptionMobile = ({
  entity,
  planName,
  disableInputs,
  handleEdit,
  alert,
  errors,
  handleChange,
  values,
  disableSubmit,
  handleCancel,
  handleSubmit,
}: ISubscriptionProps) => {
  const { can_write } = usePermission(BILLING_PERMISSION);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    if (disableInputs) {
      navigate(-1);
      setOpen(false);
    } else handleCancel();
  };

  const showSubscriptionInfo =
    entity !== MERCHANT && planName !== PARTNERS_PLAN_NAME;

  return (
    <MobileLayout
      title={showSubscriptionInfo ? "Suscripción y facturación" : "Facturación"}
      open={open}
      handleClose={handleClose}
    >
      <Grid container className="p-20">
        {showSubscriptionInfo && <SubscriptionInfo />}
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          className="m-t-30"
        >
          <Typography className="f-s-20 text-dark">
            Datos de facturación
          </Typography>
          {can_write && disableInputs && (
            <Button
              size="small"
              variant="text"
              className="f-s-14"
              onClick={handleEdit}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
            >
              Editar datos
            </Button>
          )}
        </Grid>
        <SubscriptionContent
          alert={alert}
          errors={errors}
          handleChange={handleChange}
          values={values}
          disableInputs={disableInputs}
          disableSubmit={disableSubmit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isMobile
        />
      </Grid>
    </MobileLayout>
  );
};

export default SubscriptionMobile;
