import React, { useState } from "react";
import { AppBar, Card, CardContent, Grid, Toolbar, Link } from "@mui/material";
import { Outlet } from "react-router-dom";
import logo from "../images/coati_logo.png";
import "../styles/layout.css";
import "../styles/navbar.css";
import { LANDING_PATH } from "../utils/constants";

const AuthLayout = () => {
  const [gridSize, setGridSize] = useState(4);
  const [step, setStep] = useState(1);
  const goForward = () => setStep(step + 1);
  const goBack = () => setStep(step - 1);
  return (
    <>
      <AppBar
        position="fixed"
        className="header-white navbar-no-shadow"
        sx={{ paddingLeft: 3 }}
      >
        <Toolbar>
          <Link href={LANDING_PATH}>
            <img src={logo} className="w-logo logo-sm" alt="coati-logo" />
          </Link>
        </Toolbar>
      </AppBar>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={10} lg={gridSize} justifyContent="center">
          <Card className="card-container br-10 card-m-t card-m-b">
            <CardContent>
              <Outlet context={{ setGridSize, step, goForward, goBack }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthLayout;
