import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/userContext";
import { ISubscriptionFormErrors } from "../../interfaces/account";
import { IAlert } from "../../interfaces/alert";
import { ITargetValue, IValidation } from "../../interfaces/form";
import { IEntityInfo } from "../../interfaces/userContext";
import {
  ERROR,
  ERRORS_MESSAGES,
  MEDIUM_BREAKPOINT,
  PATCH,
  SUCCESS,
  SUCCESS_ALERT_DURATION,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { validateInputs } from "../../utils/inputValidations";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import SubscriptionMobile from "../../components/account/SubscriptionMobile";
import SubscriptionDesktop from "../../components/account/SubscriptionDesktop";
import { useWindowSize } from "../../customHooks/useWindowSize";

const {
  emailInvalid,
  emailRequired,
  ivaSituationRequired,
  businessNameRequired,
  commercialAddressRequired,
  commercialAddressHasToContainNumbers,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "businessName",
    type: "required",
    message: businessNameRequired,
  },
  {
    id: "ivaSituation",
    type: "required",
    message: ivaSituationRequired,
  },
  {
    id: "commercialAddress",
    type: "required",
    message: commercialAddressRequired,
  },
  {
    id: "commercialAddress",
    type: "contain-numbers",
    message: commercialAddressHasToContainNumbers,
  },
  {
    id: "billingEmail",
    type: "required",
    message: emailRequired,
  },
  {
    id: "billingEmail",
    type: "email",
    message: emailInvalid,
  },
];

const trimValues = (values: IEntityInfo) => ({
  ...values,
  businessName: values.businessName?.trim(),
  commercialAddress: values.commercialAddress?.trim(),
});

const SubscriptionDetails = () => {
  const { currentUser, refreshUserData } = useContext(UserContext);
  const [values, setValues] = useState(currentUser.entityInfo);
  const [errors, setErrors] = useState({} as ISubscriptionFormErrors);
  const [alert, setAlert] = useState({} as IAlert);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [disableInputs, setDisableInputs] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { width } = useWindowSize();

  const handleChange = ({ name, value }: ITargetValue) => {
    setUnsavedChanges(true);
    setDisableSubmit(false);
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleCancel = () => {
    if (unsavedChanges) setOpenConfirmationModal(true);
    else setDisableInputs(true);
  };

  const handleCloseModal = () => setOpenConfirmationModal(false);

  const handleSubmit = async () => {
    const trimmedValues = trimValues(values);
    const inputErrors = validateInputs(trimmedValues, formValidations);
    setErrors(inputErrors);
    setUnsavedChanges(false);
    setDisableSubmit(true);
    if (Object.keys(inputErrors).length === 0)
      try {
        const message = await fetchData({
          url: "/entities/billing",
          method: PATCH,
          body: trimmedValues,
        });
        setAlert({
          typeOfAlert: SUCCESS,
          message,
          showAlert: true,
        });
        setTimeout(() => setAlert({} as IAlert), SUCCESS_ALERT_DURATION);
        refreshUserData();
      } catch (error: any) {
        setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      } finally {
        setDisableInputs(true);
      }
  };
  const handleEdit = () => {
    setDisableInputs(false);
    setAlert({} as IAlert);
  };

  const subscriptionProps = {
    entity: currentUser?.entity,
    planName: currentUser?.planName,
    values,
    errors,
    alert,
    disableInputs,
    disableSubmit,
    handleChange,
    handleEdit,
    handleCancel,
    handleSubmit,
  };

  return (
    <>
      {width > MEDIUM_BREAKPOINT ? (
        <SubscriptionDesktop {...subscriptionProps} />
      ) : (
        <SubscriptionMobile {...subscriptionProps} />
      )}
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={handleCloseModal}
        // TODO FIX THIS
        onSubmit={() => location.reload()}
      />
    </>
  );
};

export default SubscriptionDetails;
