import React, { useState, useContext } from "react";
import { IAlert } from "../../interfaces/alert";
import { ITargetValue, IValidation } from "../../interfaces/form";
import { validateInputs } from "../../utils/inputValidations";
import {
  ERROR,
  ERRORS_MESSAGES,
  MEDIUM_BREAKPOINT,
  PATCH,
  SUCCESS,
  SUCCESS_ALERT_DURATION,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { UserContext } from "../../contexts/userContext";
import { IAccountFormErrors } from "../../interfaces/account";

import ConfirmationModal from "../../components/modals/ConfirmationModal";
import AccountDesktop from "../../components/account/AccountDesktop";
import AccountMobile from "../../components/account/AccountMobile";
import { useWindowSize } from "../../customHooks/useWindowSize";
import { trimValues } from "../../utils/form";

const { nameRequired } = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "name",
    type: "required",
    message: nameRequired,
  },
];

const AccountDetails = () => {
  const { currentUser, refreshUserData } = useContext(UserContext);
  const [values, setValues] = useState({ ...currentUser, cuit: currentUser.entityInfo.cuit });
  const [errors, setErrors] = useState({} as IAccountFormErrors);
  const [alert, setAlert] = useState({} as IAlert);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [disableInputs, setDisableInputs] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { width } = useWindowSize();

  const handleChange = ({ name, value }: ITargetValue) => {
    setUnsavedChanges(true);
    setDisableSubmit(false);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCancel = () => {
    if (unsavedChanges) setOpenConfirmationModal(true);
    else setDisableInputs(true);
  };

  const handleCloseModal = () => setOpenConfirmationModal(false);

  const handleSubmit = async () => {
    const trimmedValues = trimValues(values);
    const inputErrors = validateInputs(trimmedValues, formValidations);
    setErrors(inputErrors);
    setUnsavedChanges(false);
    setDisableSubmit(true);

    if (Object.keys(inputErrors).length === 0)
      try {
        const message = await fetchData({
          url: "/entities",
          method: PATCH,
          body: trimmedValues,
        });
        setAlert({
          typeOfAlert: SUCCESS,
          message,
          showAlert: true,
        });
        setTimeout(() => setAlert({} as IAlert), SUCCESS_ALERT_DURATION);
        refreshUserData();
      } catch (error: any) {
        setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      } finally {
        setDisableInputs(true);
      }
  };

  const handleEdit = () => {
    setDisableInputs(false);
    setAlert({} as IAlert);
  };

  return (
    <>
      {width > MEDIUM_BREAKPOINT ? (
        <AccountDesktop
          entity={currentUser?.entity}
          hasCarriers={currentUser?.hasCarriers}
          values={values}
          errors={errors}
          alert={alert}
          disableInputs={disableInputs}
          disableSubmit={disableSubmit}
          handleChange={handleChange}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      ) : (
        <AccountMobile
          entity={currentUser?.entity}
          hasCarriers={currentUser?.hasCarriers}
          values={values}
          errors={errors}
          alert={alert}
          disableInputs={disableInputs}
          disableSubmit={disableSubmit}
          handleChange={handleChange}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={handleCloseModal}
        // TODO FIX THIS
        onSubmit={() => location.reload()}
      />
    </>
  );
};

export default AccountDetails;
