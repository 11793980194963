import { useContext, useEffect, useState } from "react";
import ListCarriers from "../../components/tables/ListCarriers";
import { UserContext } from "../../contexts/userContext";
import { Grid } from "@mui/material";
import {
  CONFIG_SUCCESS_MESSAGE,
  ECOMMERCE,
  ERROR,
  MARKETPLACE,
  MEDIUM,
  MERCHANT,
  SUCCESS,
  SUCCESS_ALERT_DURATION,
} from "../../utils/constants";
import { getCarriers } from "../../requests/carrierRequests";
import {
  ICarrier,
  ICarrierChanged,
  ICarriersChanges,
  IConfigurationRules,
  IGetRulesByEntity,
  IModalsState,
} from "../../interfaces/configuration";
import Loader from "../../components/Loader";
import ConfirmChangesButtons from "../../components/configuration/ConfirmChangesButtons";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../../components/GenericAlert";
import SearchCarriers from "./SearchCarriers";
import { getFormDataFromCarriersInfo } from "../../utils/credentials";
import CredentialsModal from "./CredentialsModal";

const getRulesByEntity = ({
  entity,
  contractOwner,
  merchantCanDisableCarriers,
}: IGetRulesByEntity) => {
  const rulesByEntity: { [key: string]: IConfigurationRules } = {
    [MARKETPLACE]: {
      canFulfillCredentials: contractOwner === MARKETPLACE,
      canActiveCarriers: contractOwner === MERCHANT,
      canDisableOL: contractOwner === MARKETPLACE,
    },
    [MERCHANT]: {
      canFulfillCredentials: contractOwner === MERCHANT,
      canDisableOL: merchantCanDisableCarriers,
    },
    [ECOMMERCE]: {
      canFulfillCredentials: true,
      canDisableOL: true,
    },
  };

  return contractOwner || entity === ECOMMERCE ? rulesByEntity[entity] : {};
};

const parseCarriers = (
  carriers: ICarrier[],
  rules: IConfigurationRules
): ICarrier[] =>
  carriers.map(({ disabled, credentials, ...rest }: ICarrier) => ({
    ...rest,
    credentials,
    disabled: disabled || (rules.canFulfillCredentials && !credentials),
  }));

const Carriers = () => {
  const {
    currentUser: { entity },
    rules,
    selectedMarketplace,
    refreshUserData,
  } = useContext(UserContext);
  const [carriers, setCarriers] = useState([] as ICarrier[]);
  const [carriersChanges, setCarriersChanges] = useState(
    {} as ICarriersChanges
  );
  const [configurationRules, setConfigurationRules] = useState(
    {} as IConfigurationRules
  );
  const [openModal, setOpenModal] = useState({} as IModalsState);
  const [alert, setAlert] = useState({} as IAlert);
  const [submit, setSubmit] = useState(false);

  const clearStates = () => {
    setAlert({} as IAlert);
    setCarriers([]);
    setCarriersChanges({} as ICarriersChanges);
    setSubmit(false);
  };

  const handleGetCarriers = async (searchValue = "") => {
    clearStates();
    const urlQueryParams = searchValue
      ? `${entity === MERCHANT ? "&" : "?"}carrierName=${searchValue}`
      : searchValue;
    try {
      const fetchedCarriers = await getCarriers({
        entity,
        selectedMarketplace,
        urlQueryParams,
      });
      if (!fetchedCarriers.length)
        setAlert({
          typeOfAlert: ERROR,
          message: "No se encontraron operadores logísticos.",
          showAlert: true,
        });
      setCarriers(fetchedCarriers);
    } catch (error: any) {
      setAlert({
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      });
    }
  };

  const handleSubmitChanges = async () => {
    setAlert({} as IAlert);
    setSubmit(true);
    try {
      await getFormDataFromCarriersInfo(
        carriersChanges,
        entity,
        selectedMarketplace
      );

      refreshUserData();
      setAlert({
        typeOfAlert: SUCCESS,
        message: CONFIG_SUCCESS_MESSAGE,
        showAlert: true,
      });
      setTimeout(handleGetCarriers, SUCCESS_ALERT_DURATION);
    } catch (error: any) {
      setAlert({
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      });
      setSubmit(false);
    }
  };

  const handleCarriersChanges = (carrierToUpdate: ICarrierChanged) =>
    setCarriersChanges({
      ...carriersChanges,
      [carrierToUpdate.name]: carrierToUpdate,
    });

  useEffect(() => {
    setConfigurationRules(
      getRulesByEntity({
        entity,
        contractOwner: rules.contractOwner,
        merchantCanDisableCarriers: rules.merchantCanDisableCarriers as boolean,
      })
    );
  }, [rules]);

  useEffect(() => {
    handleGetCarriers();
  }, [selectedMarketplace]);

  return (
    <Grid container flexDirection="column" className="m-t-30">
      <Grid item md={5} lg={3}>
        <SearchCarriers handleGetCarriers={handleGetCarriers} />
      </Grid>
      {alert.showAlert && <GenericAlert alert={alert} />}
      {carriers.length > 0 && (
        <>
          {openModal.open && (
            <CredentialsModal
              carriers={carriers}
              carriersChanges={carriersChanges}
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleGetCarriers={handleGetCarriers}
              handleCarriersChanges={handleCarriersChanges}
              handleSubmitChanges={handleSubmitChanges}
              entity={entity}
            />
          )}
          <Grid item xs={12} xl={7}>
            <ListCarriers
              carriers={parseCarriers(carriers, configurationRules)}
              carriersChanges={carriersChanges}
              rules={configurationRules}
              handleCarriersChanges={handleCarriersChanges}
              setOpenModal={setOpenModal}
              entity={entity}
            />
          </Grid>
          {(Object.keys(carriersChanges).length > 0 ||
            (Object.keys(carriersChanges).length > 0 && !submit)) && (
            <Grid item md={12} lg={12} xl={7}>
              <ConfirmChangesButtons
                handleConfirm={handleSubmitChanges}
                handleCancel={() =>
                  setOpenModal({ type: "confirmCancel", open: true })
                }
                disableConfirmButton={submit}
              />
            </Grid>
          )}
        </>
      )}
      {!carriers.length && !alert.showAlert && <Loader size={MEDIUM} />}
    </Grid>
  );
};

export default Carriers;
