import React, { useContext } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import ListHeader from "./ListHeader";
import { MARKETPLACE, SPANISH_SERVICES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { mongoTimeStampToString } from "../../utils/orders";
import { UserContext } from "../../contexts/userContext";
import { IListQuoteAudits } from "../../interfaces/order";
import ListPagination from "./ListPagination";

const ListQuoteAudits = ({
  audits,
  pagination,
  handlePageChange,
}: IListQuoteAudits) => {
  const {
    marketplaceIntegrations,
    currentUser: { entity },
  }: any = useContext(UserContext);
  const navigate = useNavigate();
  const COLUMNS = [
    { label: "Fecha" },
    { label: "Origen" },
    { label: "Destino" },
    { label: "Tipo" },
    { label: "Log" },
    { label: "Acciones" },
  ];

  const showMerchantColumn = entity === MARKETPLACE && marketplaceIntegrations;
  if (showMerchantColumn) COLUMNS.splice(1, 0, { label: "Merchant" });

  const getMerchantNameFromId = (id: number) =>
    marketplaceIntegrations.integrations.find(
      ({ merchant: { id: integrationId } }: any) => id === integrationId
    )?.merchant.entity.name;

  const getErrorQuantityFromResponses = (responses: any[]) =>
    responses.reduce(
      (accum: any, audit) =>
        audit.errors ? accum + audit.errors.length : accum,
      0
    );

  return (
    <TableContainer component={Paper} className="m-t-40 br-20 bg-light">
      <Table>
        <ListHeader columns={COLUMNS} />
        <TableBody>
          {audits.map((audit) => (
            <TableRow>
              <TableCell>{mongoTimeStampToString(audit.createdAt)}</TableCell>
              {showMerchantColumn && (
                <TableCell>
                  {getMerchantNameFromId(audit.merchant_id)}
                </TableCell>
              )}
              <TableCell>{audit.input.originZipCode}</TableCell>
              <TableCell>{audit.input.destinationZipCode}</TableCell>
              <TableCell>{SPANISH_SERVICES[audit.input.service]}</TableCell>
              <TableCell>
                {`${getErrorQuantityFromResponses(audit.responses)} errores`}
              </TableCell>
              <TableCell>
                <Tooltip title="Ver">
                  <IconButton
                    sx={{ padding: 0.5 }}
                    onClick={() => navigate(`cotizacion/${audit._id}`)}
                  >
                    <span className="material-symbols-rounded">visibility</span>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ListPagination
        total={pagination.count}
        page={pagination.page}
        onChangePage={handlePageChange}
      />
    </TableContainer>
  );
};

export default ListQuoteAudits;
