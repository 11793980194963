import React from "react";
import { Grid, Typography, Box, IconButton, Link } from "@mui/material";
import "../../styles/landing.css";
import logo from "../../images/coati_logo.png";
import linkedIn from "../../images/landing/linkedin_icon.svg";
import youtube from "../../images/landing/youtube_icon.svg";
//import dataFiscal from "../../images/landing/data_fiscal.png";

const LandingFooter = () => (
  <Grid
    container
    item
    component="footer"
    className="p-x-landing bg-input"
    paddingY={{ xs: 3, lg: 6 }}
    justifyContent="center"
    spacing={{ xs: 4, lg: 0 }}
  >
    <Grid item xs={12} lg={4}>
      <img src={logo} className="w-logo logo-sm m-b-10" alt="Coati logo" />
      <Typography className="f-s-14 f-s-md-16">
        ©Copyright 2024 Coatí SRL. Coatí es una marca registrada.
      </Typography>
      <Typography className="f-s-14 f-s-md-16">
        Todos los derechos reservados.
      </Typography>
    </Grid>
    <Grid item xs={12} lg={2}>
      <Box display="flex" gap={5}>
        <IconButton
          className="bg-primary footer-icon"
          href="https://youtube.com"
          target="_blank"
        >
          <img src={youtube} alt={youtube} />
        </IconButton>
        <IconButton
          className="bg-primary footer-icon"
          href="https://linkedin.com"
          target="_blank"
        >
          <img src={linkedIn} alt={linkedIn} />
        </IconButton>
      </Box>
    </Grid>
    <Grid
      container
      item
      xs={8}
      lg={5}
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent={{ xs: "center", lg: "space-around" }}
      gap={{ xs: 2 }}
    >
      <Link
        underline="none"
        href="/terminos-y-condiciones"
        className="text-bold text-dark f-s-14 f-s-md-16"
      >
        Términos y condiciones
      </Link>
      <Link
        underline="none"
        href="/politicas-de-privacidad"
        className="text-bold text-dark f-s-14 f-s-md-16"
      >
        Políticas de privacidad
      </Link>
    </Grid>
    <Grid
      item
      xs={4}
      lg={1}
      className="d-flex"
      justifyContent={{ xs: "flex-end", lg: "center" }}
    >
      {/*  <Box maxWidth={{ xs: "36px", lg: "62px" }}>
        <img src={dataFiscal} alt="Data fiscal" className="w-100" />
      </Box> */}
    </Grid>
  </Grid>
);

export default LandingFooter;
