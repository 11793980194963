import React from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserInfoContent from "./UserInfoContent";
import { IUserInfoProps } from "../../interfaces/usersRoles";

const UserInfoDesktop = ({
  disableInputs,
  handleEdit,
  handleChange,
  alert,
  errors,
  values,
  disableSubmit,
  handleCancel,
  handleSubmit,
}: IUserInfoProps) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (disableInputs) navigate(-1);
    else handleCancel();
  };

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        md={7}
        alignItems="center"
        justifyContent="start"
      >
        <IconButton onClick={handleBackButton}>
          <span className="material-symbols-rounded text-dark">
            keyboard_backspace
          </span>
        </IconButton>
        <Typography className="f-s-20 text-dark" ml={1}>
          {disableInputs ? "Mis datos" : "Editar mis datos"}
        </Typography>
      </Grid>
      {disableInputs && (
        <Grid
          item
          container
          xs={7}
          justifyContent="space-between"
          alignItems="center"
          className="m-t-30"
        >
          <Typography className="f-s-20 text-dark">Mis datos</Typography>

          <Button
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            size="small"
            variant="text"
            className="f-s-14"
            onClick={handleEdit}
          >
            Editar mis datos
          </Button>
        </Grid>
      )}
      <UserInfoContent
        alert={alert}
        errors={errors}
        handleChange={handleChange}
        values={values}
        disableInputs={disableInputs}
        disableSubmit={disableSubmit}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </Grid>
  );
};

export default UserInfoDesktop;
