import { IOrderRaw, IPackageToRefund } from "../interfaces/order";

type TRefundOrderActionType =
  | {
      type: "setOrderData";
      value: IOrderRaw;
    }
  | {
      type: "setPackagesToRefund";
      value: IPackageToRefund[];
    }
  | {
      type: "setReason";
      value: string;
    }
  | {
      type: "setHasConfirmed";
      value: boolean;
    }
  | {
      type: "setLoading";
      value: boolean;
    };

export const refundOrderReducer = (
  state: any,
  action: TRefundOrderActionType
) => {
  switch (action.type) {
  case "setOrderData":
    return {
      ...state,
      orderData: action.value,
    };
  case "setPackagesToRefund":
    return {
      ...state,
      packagesToRefund: action.value,
    };
  case "setLoading":
    return {
      ...state,
      loading: action.value,
    };
  case "setHasConfirmed":
    return {
      ...state,
      hasConfirmed: action.value,
    };
  case "setReason":
    return {
      ...state,
      reason: action.value,
    };
  default:
    return state;
  }
};
