import React, { ChangeEvent, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormHelperText,
  Button,
  IconButton,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
} from "@mui/material";
import { IAlert } from "../../interfaces/alert";
import { IValidation } from "../../interfaces/form";
import { IMerchantOrClientInvitationModal } from "../../interfaces/modal";
import {
  SUCCESS,
  ERROR,
  ERRORS_MESSAGES,
  POST,
  ECOMMERCE,
  MARKETPLACE,
} from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import GenericAlert from "../GenericAlert";
import ModalBase from "./ModalBase";
import GenericForm from "../GenericForm";
import { sendMerchantInvitations } from "../../requests/merchantInvitationRequests";
import { fetchData } from "../../utils/dataProvider";

const {
  emailInvalid,
  emailRequired,
  passwordRequired,
  passwordInvalid,
  securityCodeRequired,
  entityRequired,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
];

const internalformValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "password",
    type: "password",
    message: passwordInvalid,
  },
  {
    id: "securityCode",
    type: "required",
    message: securityCodeRequired,
  },
  {
    id: "entity",
    type: "required",
    message: entityRequired,
  },
];

const registerClientAsInternal = async ({
  email,
  password,
  entity,
  securityCode,
}: {
  email: string;
  password?: string;
  entity?: string;
  securityCode?: string;
}) => {
  const algo = await fetchData({
    url: `/internal/register/client/${entity}`,
    method: POST,
    body: { email, password, securityCode },
  });
  return algo;
};

//eslint-disable-next-line
const SendInvitationModal = ({
  open,
  setOpen,
  setUpdateTable,
  updateTable,
  setAlert,
  isInternalUser,
}: IMerchantOrClientInvitationModal) => {
  const [invitationInfo, setInvitationInfo] = useState(
    {} as {
      email: string;
      password?: string;
      entity: string;
      securityCode?: string;
    }
  );
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: [],
    password: [],
    securityCode: [],
    entity: [],
  });
  const [alertModal, setAlertModal] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setErrors({ entity: [], password: [], securityCode: [], email: [] });
    setInvitationInfo({ email: "", password: "", entity: "" });
    setDisableButton(false);
    setAlertModal({} as IAlert);
  };

  const handleSubmit = async () => {
    const validationsHandler = isInternalUser
      ? internalformValidations
      : formValidations;
    const inputErrors = validateInputs(invitationInfo, validationsHandler);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      setDisableButton(true);
      try {
        let responseMessage;
        if (isInternalUser)
          responseMessage = await registerClientAsInternal(invitationInfo);
        else {
          const { message } = await sendMerchantInvitations([
            invitationInfo.email,
          ]);
          responseMessage = message;
        }
        setAlert({
          typeOfAlert: SUCCESS,
          message: responseMessage,
          showAlert: true,
        });
        setUpdateTable(!updateTable);
        handleClose();
      } catch (error: any) {
        setAlertModal({
          typeOfAlert: ERROR,
          message: error,
          showAlert: true,
        });
      }
    }
  };
  const SELECT_ENTITY = "Ingresar el tipo de negocio";

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="m-t-10 f-s-20">
            Añadir {isInternalUser ? "Cliente" : "Merchant"}
          </Typography>
          {alertModal.showAlert && <GenericAlert alert={alertModal} />}
        </Grid>
        <Grid item xs={12} className="m-t-20">
          <GenericForm onSubmit={handleSubmit} disableSubmit={disableButton}>
            <Typography className="f-s-14 m-b-10">
              Correo electrónico
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="billingEmail"
              placeholder="Ingresar correo electrónico"
              value={invitationInfo.email}
              error={errors.email?.length > 0}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => {
                setAlertModal({ showAlert: false } as IAlert);
                setDisableButton(false);
                setInvitationInfo({ ...invitationInfo, email: value.trim() });
              }}
              autoFocus
            />
            {errors.email?.length > 0 &&
              errors.email?.map((message: string) => (
                <FormHelperText className="color-error" key={message}>
                  {message}
                </FormHelperText>
              ))}
            {isInternalUser && (
              <>
                <FormControl className="m-t-20" fullWidth>
                  <Typography className="f-s-14 m-b-10">Contraseña</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <span className="material-symbols-rounded">
                            {showPassword ? "visibility_off" : "visibility"}
                          </span>
                        </IconButton>
                      ),
                    }}
                    name="password"
                    placeholder="Ingresar contraseña"
                    value={invitationInfo.password}
                    error={errors.password?.length > 0}
                    onChange={({
                      target: { value },
                    }: ChangeEvent<HTMLInputElement>) => {
                      setAlertModal({ showAlert: false } as IAlert);
                      setDisableButton(false);
                      setInvitationInfo({
                        ...invitationInfo,
                        password: value.trim(),
                      });
                    }}
                    autoFocus
                  />
                  {errors.password?.length > 0 &&
                    errors.password?.map((message: string) => (
                      <FormHelperText className="color-error" key={message}>
                        {message}
                      </FormHelperText>
                    ))}
                </FormControl>
                <FormControl className="m-t-20" fullWidth>
                  <Typography className="f-s-14 m-b-10">
                    Tipo de negocio
                  </Typography>
                  <Select
                    size="small"
                    name="entity"
                    value={
                      invitationInfo.entity?.length
                        ? invitationInfo.entity
                        : SELECT_ENTITY
                    }
                    error={errors.entity?.length > 0}
                    onChange={({ target }: SelectChangeEvent) =>
                      setInvitationInfo({
                        ...invitationInfo,
                        entity: target.value,
                      })
                    }
                  >
                    <MenuItem disabled value={SELECT_ENTITY} className="f-s-14">
                      Ingresar el tipo de negocio
                    </MenuItem>
                    <MenuItem value={ECOMMERCE}>Ecommerce</MenuItem>
                    <MenuItem value={MARKETPLACE}>Marketplace</MenuItem>
                  </Select>
                  {errors.entity?.length > 0 &&
                    errors.entity?.map((message: string) => (
                      <FormHelperText className="color-error" key={message}>
                        {message}
                      </FormHelperText>
                    ))}
                </FormControl>
                <FormControl className="m-t-20" fullWidth>
                  <Typography className="f-s-14 m-b-10">
                    Codigo de seguridad
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    name="securityCode"
                    placeholder="Ingresar código de seguridad"
                    value={invitationInfo.securityCode}
                    error={errors.securityCode?.length > 0}
                    onChange={({
                      target: { value },
                    }: ChangeEvent<HTMLInputElement>) => {
                      setAlertModal({ showAlert: false } as IAlert);
                      setDisableButton(false);
                      setInvitationInfo({
                        ...invitationInfo,
                        securityCode: value.trim(),
                      });
                    }}
                    autoFocus
                  />
                  {errors.securityCode?.length > 0 &&
                    errors.securityCode?.map((message: string) => (
                      <FormHelperText className="color-error" key={message}>
                        {message}
                      </FormHelperText>
                    ))}
                </FormControl>
              </>
            )}
          </GenericForm>
        </Grid>
        <Grid item xs={6} className="m-t-40">
          <Button fullWidth variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} className="m-t-40">
          <Button
            fullWidth
            variant="contained"
            disabled={disableButton}
            onClick={handleSubmit}
          >
            Enviar invitación
          </Button>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default SendInvitationModal;
