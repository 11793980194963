import { Grid, Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChangePasswordConfirmation from "../../components/password/ChangePasswordConfirmation";
import ChangePasswordForm from "../../components/password/ChangePasswordForm";
import { IAlert } from "../../interfaces/alert";
import { IValidation } from "../../interfaces/form";
import { ERRORS_MESSAGES, PATCH, ERROR } from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { validateInputs } from "../../utils/inputValidations";
import { IUseRef } from "../../interfaces/recoverPassword";

const { passwordInvalid, passwordRequired, passwordConfirmation } = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "newPassword",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "newPassword",
    type: "password",
    message: passwordInvalid,
  },
  {
    id: "confirmPassword",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "confirmPassword",
    type: "password",
    message: passwordInvalid,
  },
  {
    id: "confirmPassword",
    type: "password-confirmation",
    message: passwordConfirmation,
    param: "newPassword",
  },
];

const ChangePassword = () => {
  const passwordRef = useRef<IUseRef>(null);
  const confirmPasswordRef = useRef<{ value: string }>(null);
  const [errors, setErrors] = useState({
    newPassword: [],
    confirmPassword: [],
  });
  const [alert, setAlert] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const newPassword = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;
    const inputErrors = validateInputs(
      { newPassword, confirmPassword },
      formValidations
    );
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0 && newPassword) {
      setDisableButton(true);
      try {
        await fetchData({
          url: `/users/recover/${token}`,
          method: PATCH,
          body: { newPassword },
        });

        setShowSuccess(true);
        setDisableButton(false);
      } catch (error: any) {
        setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      }
    }
  };

  const formComposition = {
    passwordRef,
    confirmPasswordRef,
    disableButton,
    alert,
    errors,
    handleSubmit,
    navigate,
  };

  return (
    <Box className="p-x-card" pt={3}>
      <Grid container spacing={2}>
        {showSuccess ? (
          <ChangePasswordConfirmation navigate={navigate} />
        ) : (
          <ChangePasswordForm {...formComposition} />
        )}
      </Grid>
    </Box>
  );
};

export default ChangePassword;
