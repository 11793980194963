import { IMarketplaceMerchantIntegration } from "../interfaces/merchant";
import { PAUSED } from "./constants";

export const integrationIsPaused = (
  integrations: IMarketplaceMerchantIntegration[],
  selectedMarketplace: string
) =>
  integrations.find(
    ({ id, status }: IMarketplaceMerchantIntegration) =>
      String(id) === selectedMarketplace && status === PAUSED
  );

// export const checkAllMerchantsAreInactive = (
//   integrations: IIntegrationResponse[]
// ) => integrations.every(({ disabled }) => disabled);
