import { createContext, useEffect, useContext, useState } from "react";
import { io } from "socket.io-client";
import config from "../config/config";
import { UserContext } from "./userContext";
import { clearLocalData, getLocalToken } from "../utils/storage";
import { INotificationsContext } from "../interfaces/notificationsContext";
import { redirectTo } from "../utils/navigation";
const { REACT_APP_NOTIFICATIONS_API_BASE_URL } = config;

export const NotificationsContext = createContext({} as INotificationsContext);

const socket = io(REACT_APP_NOTIFICATIONS_API_BASE_URL, {
  autoConnect: false,
});

const NotificationsProvider = ({ children }: any) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const { currentUser } = useContext(UserContext);
  const connect = () => {
    const token = getLocalToken();
    socket.auth = { token };
    socket.connect();
    socket.on("connection-ready", () => setIsConnected(true));
  };

  const disconnect = () => {
    setIsConnected(() => false);
    socket.disconnect();
  };

  const getBanners = (currentMarketplaceId: string) =>
    socket.emit("get-banners", currentMarketplaceId);

  useEffect(() => {
    if (Object.values(currentUser).length) {
      connect();
      socket.on("expired-token", (message) => {
        clearLocalData();
        disconnect();
        redirectTo(`/autenticacion?reason=${message}`);
      });
    }
    return () => disconnect();
  }, [currentUser]);

  return (
    <NotificationsContext.Provider
      value={{
        disconnect,
        socket,
        isConnected,
        getBanners,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
