import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  FormHelperText,
  Link,
} from "@mui/material";
import logo from "../images/coati_logo.png";
import "../styles/login.css";
import { IUserLogIn, ILoginSubmit } from "../interfaces/login";
import {
  COME_BACK_LATER_MESSAGE,
  ENTITY_ROUTES,
  ERROR,
  ERRORS_MESSAGES,
  POST,
  SIGNUP,
  SIGN_UP_SUCCESS_MESSAGE,
  SUCCESS,
  VERIFY_ACCOUNT,
} from "../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validateInputs } from "../utils/inputValidations";
import { IValidation } from "../interfaces/form";
import { IAlert } from "../interfaces/alert";
import GenericAlert from "../components/GenericAlert";
import { UserContext } from "../contexts/userContext";
import { fetchData } from "../utils/dataProvider";
import { ISaveUserData } from "../interfaces/userContext";
import { reSendEmail } from "../utils/email";
import { removeIsLogout } from "../utils/storage";
import { useDebounce } from "../customHooks/useDebounce";
import GenericForm from "../components/GenericForm";

const { emailRequired, emailInvalid, passwordRequired, passwordInvalid } =
  ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "password",
    type: "password",
    message: passwordInvalid,
  },
];

const submitLogin = async ({
  values,
  saveUserData,
  navigate,
  setAlert,
  redirectUrl,
}: ILoginSubmit) => {
  try {
    const { token, user }: ISaveUserData = await fetchData({
      url: "/users/signIn",
      method: POST,
      body: values,
    });

    saveUserData({ token, user });
    const { entity } = user;

    navigate(`${redirectUrl ? redirectUrl : ENTITY_ROUTES[entity]}`);
  } catch (error: any) {
    const isVerifyError = error === VERIFY_ACCOUNT;
    if (isVerifyError) {
      const { email } = values;
      const confirmationMessage = `Revisa el email que te enviamos a ${email} para validar tu cuenta.`;
      const verifyAccountMessage = (
        <>
          {error} <br />
          ¿No te llegó el email? Reenvíalo haciendo &nbsp;
          <Link
            className="text-bold pointer"
            onClick={() =>
              reSendEmail({ email, setAlert, confirmationMessage })
            }
          >
            click aquí.
          </Link>
        </>
      );

      setAlert({
        typeOfAlert: ERROR,
        message: verifyAccountMessage,
        showAlert: true,
      });
    } else
      setAlert({
        typeOfAlert: ERROR,
        message: typeof error === "string" ? error : COME_BACK_LATER_MESSAGE,
        showAlert: true,
      });
  }
};

const Login = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  } as IUserLogIn);
  const [errors, setErrors] = useState({ email: [], password: [] });
  const [alert, setAlert] = useState({} as IAlert);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { saveUserData } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const reason = searchParams.get("reason") as string;
  const redirectUrl = searchParams.get("redirectUrl");
  const baseEmail = searchParams.get("email") || "";

  useEffect(() => {
    removeIsLogout();
  }, []);

  useEffect(() => {
    setValues({ email: baseEmail, password: values.password });
  }, [baseEmail]);

  const handleChange = (name: string, value: string) =>
    setValues({ ...values, [name]: value.trim() });

  const handleSubmit = () => {
    const inputErrors = validateInputs(values, formValidations);
    setErrors(inputErrors);

    if (Object.keys(inputErrors).length === 0)
      submitLogin({ values, saveUserData, navigate, setAlert, redirectUrl });
  };

  const { setSubmitting } = useDebounce(handleSubmit);

  useEffect(() => {
    if (reason) {
      setAlert({
        typeOfAlert: reason === SIGNUP ? SUCCESS : ERROR,
        showAlert: true,
        message: reason === SIGNUP ? SIGN_UP_SUCCESS_MESSAGE : reason,
      });
    }
  }, [reason]);

  return (
    <Box className="login_card p-x-card">
      <img
        src={logo}
        alt="Logo coatí"
        className="login-logo"
      />

      {alert.showAlert && (
        <div className="w-100">
          <GenericAlert
            alert={alert}
            handleActionAlert={() => setAlert({ showAlert: false } as IAlert)}
          />
        </div>
      )}
      <GenericForm
        onSubmit={() => {
          setSubmitting(true);
        }}
      >
        <FormControl fullWidth size="small">
          <Typography className="f-s-14 m-t-10 m-b-10" marginBottom={1}>
            Correo electrónico
          </Typography>
          <OutlinedInput
            type="email"
            placeholder="Ingresar correo electrónico"
            className="f-s-14"
            value={values.email}
            onChange={(e) => handleChange("email", e.target.value)}
            error={errors.email?.length > 0}
            autoFocus
          />

          {errors.email?.length > 0 &&
            errors.email.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </FormControl>

        <Box
          display="flex"
          className="w-100 m-t-20"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className="f-s-14 m-b-10">Contraseña</Typography>
          <Button
            variant="text"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            onClick={() => navigate("recuperar-credenciales")}
            className="f-s-14 m-b-10"
          >
            ¿Has olvidado la contraseña?
          </Button>
        </Box>
        <FormControl fullWidth size="small">
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            placeholder="Ingresar contraseña"
            className="f-s-14"
            value={values.password}
            onChange={(e) => handleChange("password", e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <span className="material-symbols-rounded">
                    {showPassword ? "visibility" : "visibility_off"}
                  </span>
                </IconButton>
              </InputAdornment>
            }
            error={errors.password?.length > 0}
          />

          {errors.password?.length > 0 &&
            errors.password.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </FormControl>
      </GenericForm>

      <Button
        type="submit"
        variant="contained"
        fullWidth
        className="m-t-40"
        onClick={() => {
          setSubmitting(true);
        }}
      >
        Ingresar
      </Button>
    </Box>
  );
};

export default Login;
