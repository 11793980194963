import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { ISignupSteps, ISignupMerchant } from "../../interfaces/signup";
import AccountConfigForm from "../../components/signupMerchant/AccountConfigForm";
import AccountInfoForm from "../../components/signupMerchant/AccountInfoForm";

const SignupMerchant = () => {
  // TODO crear type para outlet
  const { setGridSize, step, goBack, goForward } = useOutletContext<any>();
  const [selectedValues, setselectedValues] = useState({} as ISignupMerchant);
  const { token } = useParams();
  const steps: ISignupSteps[] = [
    {
      title: "Completa la configuración de tu cuenta",
      component: () => (
        <AccountConfigForm
          selectedValues={selectedValues}
          setSelectedValues={setselectedValues}
          setGridSize={setGridSize}
          goForward={goForward}
          token={token}
        />
      ),
    },
    {
      title: "Ingresa los datos de cuenta",
      component: () => (
        <AccountInfoForm
          selectedValues={selectedValues}
          setSelectedValues={setselectedValues}
          setGridSize={setGridSize}
          goBack={goBack}
          token={token}
        />
      ),
    },
  ];

  return (
    <Box className="p-x-card" py={3}>
      <Typography className="f-s-20">Paso {step} de 2</Typography>
      <Typography variant="h2" className="m-t-10">
        {steps[step - 1].title}
      </Typography>
      <Box>{steps[step - 1].component()}</Box>
    </Box>
  );
};

export default SignupMerchant;
