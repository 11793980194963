const {
  REACT_APP_AUTH_API_BASE_URL,
  REACT_APP_CONFIG_API_BASE_URL,
  REACT_APP_ENV_NAME,
  REACT_APP_NOTIFICATIONS_API_BASE_URL,
  REACT_APP_S3_BASE_URL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_SAMPLE_RATE
} = process.env;
const config = {
  AUTH_API_BASE_URL:
    REACT_APP_AUTH_API_BASE_URL || "https://api-dev.coatiapp.com/auth",
  CONFIG_API_BASE_URL:
    REACT_APP_CONFIG_API_BASE_URL || "https://api-dev.coatiapp.com/config",
  REACT_APP_NOTIFICATIONS_API_BASE_URL:
    REACT_APP_NOTIFICATIONS_API_BASE_URL as string,
  S3_BASE_URL: REACT_APP_S3_BASE_URL,
  ENV_NAME: REACT_APP_ENV_NAME,
  SENTRY_DSN: REACT_APP_SENTRY_DSN,
  SENTRY_SAMPLE_RATE: Number(REACT_APP_SENTRY_SAMPLE_RATE)
};

export default config;
