import { IAlert } from "../interfaces/alert";
import { IUserInfo, IUserInfoErrors } from "../interfaces/usersRoles";

type TLayoutActionType =
  | { type: "values"; value: IUserInfo }
  | { type: "errors"; value: IUserInfoErrors }
  | { type: "alert"; value: IAlert }
  | { type: "unsavedChanges"; value: boolean }
  | { type: "openConfirmationModal"; value: boolean }
  | { type: "disableInputs"; value: boolean }
  | { type: "disableSubmit"; value: boolean };

export const userDetailsReducer = (state: any, action: TLayoutActionType) => {
  switch (action.type) {
  case "values":
    return { ...state, values: action.value };
  case "errors":
    return { ...state, errors: action.value };
  case "alert":
    return { ...state, alert: action.value };
  case "unsavedChanges":
    return { ...state, unsavedChanges: action.value };
  case "openConfirmationModal":
    return { ...state, openConfirmationModal: action.value };
  case "disableInputs":
    return { ...state, disableInputs: action.value };
  case "disableSubmit":
    return { ...state, disableSubmit: action.value };
  default:
    return state;
  }
};
