import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { ReactElement } from "react";

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="left" ref={ref} {...props} />;
  }
);

const MobileLayout = ({
  title,
  children,
  open,
  handleClose
}: {
  title: string;
  children: ReactElement;
  open: boolean;
  handleClose(): void;
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        className="bg-secondary navbar-no-shadow"
        sx={{ position: "relative" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <span className="material-symbols-rounded text-dark">
              keyboard_backspace
            </span>
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            { title }
          </Typography>
        </Toolbar>
      </AppBar>

      {children}
    </Dialog>
  );
};

export default MobileLayout;
