import { Typography, Divider, Box } from "@mui/material";
import React from "react";
import { ISectionTitle } from "../../interfaces/landing";

const SectionTitle = ({ title, marginBottom }: ISectionTitle) => (
  <Box className="w-100" marginBottom={{ xs: 2, lg: marginBottom }}>
    <Typography className="m-b-10 f-s-18 f-s-md-32 text-center p-x-landing">
      {title}
    </Typography>
    <Divider className="title-underline" />
  </Box>
);

export default SectionTitle;
