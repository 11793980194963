/* eslint-disable consistent-return */
import React from "react";
import { IOrderDescription } from "../../interfaces/order";
import { Card, CardContent, Grid, Typography, Divider } from "@mui/material";
import { MARKETPLACE } from "../../utils/constants";
import { capitalizeOnlyFirstLetter } from "../../utils/orders";

const OrderReasonContent = ({
  order: { orderInfo, entity, merchant_name, rejection_info },
}: any) => {
  return (
    <Grid container columnSpacing={3}>
      <>
        {rejection_info?.map((rejectionItem: any, index: number) => {
          if (rejectionItem === null) return;
          const { entity: rejectionEntity, reason, type } = rejectionItem;
          const isReturn = type === "return";
          const action = type === "return" ? "devolución" : "cancelación";
          const responsable =
            entity === MARKETPLACE ? merchant_name : rejectionEntity;
          const parsedResponsable = capitalizeOnlyFirstLetter(responsable);
          return (
            <>
              <Grid item xs={12}>
                <Typography className="f-s-20 text-bold">
                  {`Detalle de la ${action}`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="text-bold m-t-10">Motivo</Typography>
                <Typography>{reason || "-"}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="text-bold m-t-10">
                  {`${action === "devolución" ? "Devuelto" : "Cancelado"} por`}
                </Typography>
                <Typography>{parsedResponsable}</Typography>
              </Grid>
              {isReturn && (
                <Grid item xs={12}>
                  <Typography className="text-bold m-t-10">
                    N° de guía de la orden original
                  </Typography>
                  <Typography>
                    {orderInfo.reference_order_tracking_number}
                  </Typography>
                </Grid>
              )}
              {index + 1 !== rejection_info.length && (
                <Grid item xs={12}>
                  <Divider className="m-b-10 m-t-10" />
                </Grid>
              )}
            </>
          );
        })}
      </>
    </Grid>
  );
};
export const OrderCancelOrReturnReason = ({
  order,
  isMobile = false,
}: IOrderDescription) => {
  return (
    <Grid item xs={12} className="m-t-30">
      {isMobile ? (
        <OrderReasonContent order={order} />
      ) : (
        <Card className="br-20 bg-light">
          <CardContent sx={{ padding: "20px" }}>
            <OrderReasonContent order={order} />
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};
