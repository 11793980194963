import React, { ChangeEvent, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ICredentialsAndreaniProps } from "../../interfaces/configuration";
import { SimpleDateInput } from "./SimpleDateInput";
import { SimpleFormInput } from "./SimpleFormInput";

const CredentialsAndreani = ({
  values,
  setValues,
  errors,
}: ICredentialsAndreaniProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const showInputErrors = (inputErrors: string[]) =>
    inputErrors.map((message: string) => (
      <FormHelperText className="color-error" key={message}>
        {message}
      </FormHelperText>
    ));
  const baseInfo = { values, setValues };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SimpleFormInput
          inputTitle="Id de cliente"
          placeholder="Ingresar Id de cliente"
          valueName="client"
          errors={errors.client}
          {...baseInfo}
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <SimpleFormInput
          inputTitle="Usuario"
          placeholder="Ingresar usuario"
          valueName="user"
          errors={errors.user}
          {...baseInfo}
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Contraseña</Typography>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            placeholder="Ingresar contraseña"
            value={values.password}
            error={errors.password?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, password: value.trim() })
            }
            className="f-s-14"
            endAdornment={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <span className="material-symbols-rounded">
                  {showPassword ? "visibility_off" : "visibility"}
                </span>
              </IconButton>
            }
          />
          {errors.password?.length > 0 && showInputErrors(errors.password)}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography className="f-s-20 m-t-10">Tipos de contratos</Typography>
      </Grid>
      <Grid item xs={6}>
        <SimpleFormInput
          inputTitle="Código de contrato puerta a puerta b2b"
          placeholder="Ingresar código de contrato puerta a puerta b2b"
          valueName="b2b"
          errors={errors.b2b}
          {...baseInfo}
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <SimpleFormInput
          inputTitle="Código de contrato puerta a puerta b2c"
          placeholder="Ingresar código de contrato puerta a puerta b2c"
          valueName="b2c"
          errors={errors.b2c}
          {...baseInfo}
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <SimpleFormInput
          inputTitle="Código de contrato de logística inversa"
          placeholder="Ingresar código de logística inversa"
          valueName="reverse"
          errors={errors.reverse}
          {...baseInfo}
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <SimpleDateInput
          inputTitle="Fecha de expiración"
          valueName="end_date"
          errors={errors.end_date}
          {...baseInfo}
        />
      </Grid>
    </Grid>
  );
};

export default CredentialsAndreani;
