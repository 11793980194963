import { Grid, Typography, Button } from "@mui/material";
import React from "react";
import { IChangePasswordConfirmation } from "../../interfaces/recoverPassword";
import { AUTHENTICATION_PATH } from "../../utils/constants";

const ChangePasswordConfirmation = ({
  navigate,
}: IChangePasswordConfirmation) => (
  <>
    <Grid item xs={12}>
      <Typography className="m-t-10 f-s-20">Contraseña actualizada</Typography>
      <Typography className="f-s-14 m-t-20">
        La contraseña ha sido actualizada correctamente.
      </Typography>
    </Grid>

    <Grid item xs className="m-t-100">
      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate(AUTHENTICATION_PATH)}
      >
        Volver al inicio
      </Button>
    </Grid>
  </>
);

export default ChangePasswordConfirmation;
