import React from "react";
import { TextField, FormHelperText } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  DATE_FORMAT,
  ERROR_TO_GREATER_THAN_FROM,
  ERROR_FROM_GREATER_THAN_TO,
  LOCALE,
} from "../../utils/constants";
import { showInputErrors } from "../../utils/credentials";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { IFilterDatePicker } from "../../interfaces/home";

const FilterDatePicker = ({
  label,
  name,
  handleDateChange,
  filter,
  errors,
}: IFilterDatePicker) => (
  <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={LOCALE}>
    <DatePicker
      label={label}
      disableFuture={name === "to"}
      value={filter[name] || null}
      onChange={(newValue: any) => {
        if (!newValue?.invalid) handleDateChange(name, newValue);
      }}
      inputFormat={DATE_FORMAT}
      renderInput={(params: any) => (
        <TextField
          fullWidth
          size="small"
          {...params}
          className="date-picker-svg"
        />
      )}
    />
    {errors[name]?.length > 0 && showInputErrors(errors[name])}
    {errors[`invalid${name}`] && (
      <FormHelperText className="color-error" key="error-message">
        {name === "from"
          ? ERROR_FROM_GREATER_THAN_TO
          : ERROR_TO_GREATER_THAN_FROM}
      </FormHelperText>
    )}
  </LocalizationProvider>
);
export default FilterDatePicker;
