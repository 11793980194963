import React, { useState } from "react";
import {
  Alert,
  AppBar,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import "../../styles/landing.css";
import SectionTitle from "./SectionTitle";
import { useNavigate } from "react-router-dom";
import { validateInputs } from "../../utils/inputValidations";
import { IValidation } from "../../interfaces/form";
import {
  ERROR,
  ERRORS_MESSAGES,
  GET,
  LANDING_PATH,
  TRACKING_TIPS,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { IAlert } from "../../interfaces/alert";
import { useDebounce } from "../../customHooks/useDebounce";
import GenericForm from "../GenericForm";
import logo from "../../images/coati_logo.png";


const { orderSearchQueryRequired } = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "search",
    type: "required",
    message: orderSearchQueryRequired,
  },
  // ESTA VALIDACION SE PUEDE USAR MAS ADELANTE
  // {
  //   id: "search",
  //   type: "order",
  //   message: orderSearchQueryInvalid,
  // },
];

const ShipmentTracking = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [errors, setErrors] = useState({ search: [] });
  const [errorAlert, setErrorAlert] = useState({
    typeOfAlert: ERROR,
    message: "",
    showAlert: false,
  } as IAlert);
  const navigate = useNavigate();

  const getOrder = async () => {
    try {
      const response = await fetchData({
        url: `/orders/${searchValue}`,
        method: GET,
      });

      if (response.tracking_id) navigate(response.tracking_id);
    } catch (error: any) {
      setErrorAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };

  const handleSubmit = () => {
    const inputErrors = validateInputs(
      { search: searchValue },
      formValidations
    );
    setErrors(inputErrors);

    if (Object.keys(inputErrors).length === 0) getOrder();
  };

  const { setSubmitting } = useDebounce(handleSubmit);

  return (
    <>
      <AppBar
        position="fixed"
        className="header-white navbar-no-shadow"
        sx={{ paddingLeft: 3 }}
      >
        <Toolbar>
          <Link href={LANDING_PATH}>
            <img src={logo} className="w-logo logo-sm" alt="coati-logo" />
          </Link>
        </Toolbar>
      </AppBar>
      <Grid
        container
        className="p-x-landing p-y-landing"
        flexDirection="column"
        alignItems="center"
        flexWrap="nowrap"
        gap={2}
        marginBottom={{ xs: 3, lg: 20 }}
      >
        <Grid item>
          <SectionTitle
            title="¿Querés saber dónde está tu envío?"
            marginBottom={6}
          />
        </Grid>

        <Grid item lg={4} className="w-100">
          <Grid item xs={12} display="flex" className="tracking-input m-b-40">
            <GenericForm
              onSubmit={() => {
                setSubmitting(true);
              }}
            >
              <FormControl fullWidth>
                <OutlinedInput
                  placeholder="Ingresá el número de envío"
                  size="small"
                  className="bg-input"
                  value={searchValue}
                  error={errors.search?.length > 0}
                  onChange={(event) => setSearchValue(event.target.value)}
                  autoFocus
                />
                {errors.search?.length > 0 &&
                  errors.search.map((message: string) => (
                    <FormHelperText className="color-error" key={message}>
                      {message}
                    </FormHelperText>
                  ))}
              </FormControl>
            </GenericForm>

            <Button
              onClick={() => {
                setSubmitting(true);
              }}
              variant="contained"
            >
              <span className="material-symbols-rounded">search</span>
            </Button>
          </Grid>
          {errorAlert.showAlert && (
            <Alert
              className="m-t-10 alertIconerror tracking-error"
              severity="error"
            >
              {errorAlert.message}
            </Alert>
          )}
        </Grid>

        <Grid item lg={4.5}>
          <Card className="bg-input tracking-card br-5" sx={{ padding: 2 }}>
            <CardContent>
              <Typography className="f-s-14 f-s-md-20 m-b-20">
                Información importante
              </Typography>
              {TRACKING_TIPS.map((tip) => (
                <ListItem key={tip} disablePadding className="m-b-10">
                  <ListItemIcon>
                    <span className="material-symbols-rounded color-primary">
                      check
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: "f-s-12 f-s-md-14" }}
                    primary={tip}
                  />
                </ListItem>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ShipmentTracking;
