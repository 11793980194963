import React from "react";
import { Grid, Box, Typography, Card } from "@mui/material";
import { IRefundCard } from "../../interfaces/order";

const RefundInfoCard = ({ orderInfo, external_id = "-" }: IRefundCard) => {
  const { destination, carrier } = orderInfo;

  const renderColumn = (
    title: string,
    content: string,
    clarification?: string
  ) => (
    <Box display="flex" flexDirection="column">
      <Typography className="f-s-14" marginBottom={1}>
        <strong>{title}</strong> {clarification || ""}
      </Typography>
      <Typography className="f-s-14">{content}</Typography>
    </Box>
  );

  return (
    <Grid
      component={Card}
      className="m-t-20 br-20 bg-light"
      item
      container
      xs={12}
      justifyContent="space-between"
      alignItems="center"
      sx={{ padding: 2.5 }}
    >
      <Grid item xs={6}>
        {renderColumn(
          "Destino",
          destination,
          "(donde recibirás la devolución)"
        )}
      </Grid>
      <Grid item xs={3}>
        {renderColumn("Operador logístico", carrier)}
      </Grid>
      <Grid item xs={3}>
        {renderColumn("ID externo", external_id)}
      </Grid>
    </Grid>
  );
};

export default RefundInfoCard;
