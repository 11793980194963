import React from "react";
import { Menu, Typography, Divider, Button } from "@mui/material";
import Notification from "./Notification";
import { INotification } from "../interfaces/notifications";

const ListNotification = ({
  handleCloseMenu,
  openNotifications,
  anchorElNotifications,
  notifications,
  entity,
  navigate
}: any) => (
  <div className="menu-item-hover">
    <Menu
      anchorEl={anchorElNotifications}
      open={openNotifications}
      onClose={handleCloseMenu}
      PaperProps={{
        style: { width: 429 },
        sx: { transform: "translateX(-42px) !important" },
      }}
      MenuListProps={{ sx: { py: 0, mb: 1 } }}
      className={
        notifications.length > 8 ? "m-t-10 list-eight-notifications" : "m-t-10"
      }
    >
      <div>
        <div
          className="d-flex space-between m-t-5 m-l-5 notifications-menu-header"
        >
          <Typography
            className="f-s-20 text-bold"
            sx={{ letterSpacing: ".1px" }}
          >
            Notificaciones
          </Typography>
          <Button
            sx={{ padding: 0, "&:hover": { backgroundColor: "transparent" } }}
            onClick={() => {
              navigate("notificaciones");
              handleCloseMenu();
            }}
          >
            Ver todo
          </Button>
        </div>
        <div>
          <Divider
            className="m-t-10 notifications-menu-header-divider"
          />
        </div>
        <div className="d-flex direction-column space-evenly">
          {notifications.length ? (
            notifications.map((notification: INotification) => (
              <React.Fragment key={notification._id}>
                <Notification notification={notification} entity={entity} />

                <Divider
                  variant="fullWidth"
                  className="m-x-20"
                />
              </React.Fragment>
            ))
          ) : (
            <>
              <span className="text-muted f-s-14 text-center m-y-40">
                No hay nada nuevo por aquí.
              </span>
              <Divider variant="fullWidth" />
            </>
          )}
        </div>
      </div>
    </Menu>
  </div>
);

export default ListNotification;
