import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { ISummaryMerchant } from "../../interfaces/metrics";
import SummarySkeleton from "./SummarySkeleton";

const SummaryMerchant = ({ summary }: ISummaryMerchant) => (
  <Grid
    container
    item
    flexDirection="column"
    xs={12}
    md={4}
    mt={5}
    mb={7.5}
    px={2.5}
    className="summaryOlsContainer"
  >
    {summary ? (
      <>
        <Typography className="f-s-20">Total de envíos por merchant</Typography>
        {summary.map(({ _id, merchantOrders }, index) => (
          <>
            <Grid
              container
              paddingY="20px"
              fontSize={14}
              className="text-dark"
              mb={0.5}
            >
              <Grid item xs={1}>
                {index + 1}
              </Grid>
              <Grid item xs={9}>
                {_id}
              </Grid>
              <Grid item xs={2} textAlign="right">
                {merchantOrders} envíos
              </Grid>
            </Grid>

            <Divider sx={{ borderColor: "#DEDEDE" }} />
          </>
        ))}
      </>
    ) : (
      <SummarySkeleton rows={6} />
    )}
  </Grid>
);

export default SummaryMerchant;
