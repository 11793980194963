export const trimValue = (value: string) => value.trim();

export const trimValues = (values: any) => {
  const valuesArray = Object.entries(values);

  const trimmedValuesArray = valuesArray.map((valueArray: any[]) => [
    valueArray[0],
    typeof valueArray[1] === "string" ? valueArray[1].trim() : valueArray[1],
  ]);

  return Object.fromEntries(trimmedValuesArray);
};

export const disableCurrentDate = (date: any) => {
  const today = new Date();

  return date < today;
};
