import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ICredentialsModal } from "../../interfaces/modal";
import ModalBase from "./ModalBase";
import ConfirmChangesCredentials from "../configuration/ConfirmChangesCredentials";
import { IValidation } from "../../interfaces/form";
import { ERRORS_MESSAGES } from "../../utils/constants";
import GenericForm from "../GenericForm";
import {
  ICredentialsHopErrors,
  ICredentialsHopPayload,
} from "../../interfaces/configuration";
import { validateInputs } from "../../utils/inputValidations";
import CredentialsHop from "../forms/CredentialsHop";

const {
  endDateRequired,
  endDateNotInThePast,
  emailRequired,
  emailInvalid,
  passwordRequired,
  hopIdRequired,
  hopSecretRequired,
  sellerCodeRequired,
  onlyNumbers,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "hop_id",
    type: "required",
    message: hopIdRequired,
  },
  {
    id: "hop_id",
    type: "only-numbers",
    message: onlyNumbers,
  },
  {
    id: "hop_secret",
    type: "required",
    message: hopSecretRequired,
  },
  {
    id: "seller_code",
    type: "required",
    message: sellerCodeRequired,
  },
  {
    id: "expired_date",
    type: "required",
    message: endDateRequired,
  },
  {
    id: "expired_date",
    type: "future_date",
    message: endDateNotInThePast,
  },
];

const CredentialsHopModal = ({
  open,
  onClose,
  carrier,
  handleChangeOfConfig,
}: ICredentialsModal) => {
  const initialCredentials = (
    carrier.credentials ? { ...carrier.credentials } : {}
  ) as ICredentialsHopPayload;

  const [values, setValues] = useState(initialCredentials);
  const [errors, setErrors] = useState({} as ICredentialsHopErrors);

  const handleSubmit = () => {
    const inputErrors = validateInputs(values, formValidations);

    setErrors(inputErrors);
    if (!Object.keys(inputErrors).length) {
      const { expired_date } = values as ICredentialsHopPayload;
      const dateExpired = new Date(expired_date) <= new Date();

      handleChangeOfConfig({
        _id: carrier._id,
        name: carrier.name,
        disabled: carrier.disabled,
        credentials: {
          ...values,
          expired_date,
          expired: dateExpired,
        },
      });

      onClose();
    }
  };

  return (
    <ModalBase open={open} handleClose={onClose} maxWidth="md">
      <Grid container>
        <Typography className="f-s-18 m-b-20">
          Ingresa los datos de tu contrato con Chazki
        </Typography>
        <GenericForm onSubmit={handleSubmit}>
          <CredentialsHop
            values={values}
            setValues={setValues}
            errors={errors}
          />
        </GenericForm>
        <ConfirmChangesCredentials
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </ModalBase>
  );
};

export default CredentialsHopModal;
