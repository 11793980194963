import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  IconButton,
  Avatar,
  Skeleton,
  Typography,
  Badge,
  Box,
} from "@mui/material";
import NavbarOptionsMenu from "./NavbarOptionsMenu";
import {
  MERCHANT,
  STORES_MANAGEMENT_PATH,
  MEDIUM_BREAKPOINT,
  INCOMPLETE_CONFIG,
  ACCOUNT_PERMISSION,
  BILLING_PERMISSION,
  MANAGMENT_PERMISSION,
} from "../../utils/constants";
import defaultImage from "../../images/default-image.png";
import MenuMobile from "./MenuMobile";
import { useWindowSize } from "../../customHooks/useWindowSize";
import { UserContext } from "../../contexts/userContext";
import { NotificationsContext } from "../../contexts/notificationContext";
import ListNotification from "../../pages/ListNotifications";
import {
  listenToNotifications,
  removeDotFromReadNotifications,
  updateNotifications,
  updateNotificationsAndCountAfterMenuOpening,
} from "../../utils/notifications";
import CustomTooltip from "../CustomTooltip";
import {
  INewNotification,
  INotificationsAndCount,
} from "../../interfaces/notifications";
import { getInitialConfigurationComplete } from "../../utils/storage";
import { INavbarItem } from "../../interfaces/navbar";
import { usePermission } from "../../customHooks/usePermission";
import { getNavbarOptions } from "../../utils/navbarOptions";

// Returns the initial of the first name
const getInitials = (name: string) => name.split(" ")[0][0].toUpperCase();

const getAvatar = (name: string, className?: string) =>
  name ? (
    <Avatar className={`bg-primary ${className}`} alt="Profile initials avatar">
      {getInitials(name)}
    </Avatar>
  ) : (
    <Avatar src={defaultImage} className={className} alt="No name avatar" />
  );

const customTooltip = (
  <>
    <Typography className="f-s-14 text-bold">
      Gestión de Marketplaces
    </Typography>
    <Typography className="f-s-14">
      En esta sección podrás gestionar las invitaciones y administrar todos los
      marketplaces.
    </Typography>
  </>
);

const handleCloseNotifications = (
  handleCloseMenu: any,
  notificationsAndCount: INotificationsAndCount,
  setNotificationsAndCount: any
) => {
  handleCloseMenu();
  removeDotFromReadNotifications(
    notificationsAndCount,
    setNotificationsAndCount
  );
};

const getBadgeSlotProps = (count: number) => ({
  badge: {
    className: count >= 100 ? "notification-badge-component-props" : "",
  },
});
const NavbarOptions = () => {
  const {
    currentUser: { entity, planName, name },
    showSkeleton,
  } = useContext(UserContext);
  const { disconnect, socket, isConnected } = useContext(NotificationsContext);
  const { can_write: can_write_account } = usePermission(ACCOUNT_PERMISSION);
  const { can_write: can_write_billing } = usePermission(BILLING_PERMISSION);
  const { can_write: can_write_managment } =
    usePermission(MANAGMENT_PERMISSION);
  const [anchorElNotifications, setAnchorElNotifications] =
    useState<null | HTMLElement>(null);
  const openNotifications = Boolean(anchorElNotifications);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [notificationsAndCount, setNotificationsAndCount] =
    useState<INotificationsAndCount>({ notifications: [], count: 0 });
  const [newNotification, setNewNotification] = useState<INewNotification>();
  const [navbarOptions, setNavbarOptions] = useState([] as INavbarItem[]);
  const { pathname } = useLocation();
  const openMenu = Boolean(anchorElMenu);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const configComplete = getInitialConfigurationComplete();

  useEffect(() => {
    if (isConnected)
      listenToNotifications(
        socket,
        setNotificationsAndCount,
        setNewNotification
      );
  }, [isConnected]);
  useEffect(() => {
    if (newNotification?.notification)
      updateNotifications(
        newNotification,
        notificationsAndCount,
        setNotificationsAndCount
      );
  }, [newNotification]);
  // TODO:  NavbarOptions component is rendering twice. we'll see why is happenning in the future.
  useEffect(() => {
    const optionsByNavbar = getNavbarOptions(
      entity,
      planName,
      can_write_account,
      can_write_billing
    );
    setNavbarOptions(optionsByNavbar);
  }, [entity, can_write_account, can_write_billing]);
  const handleCloseMenu = () => {
    setAnchorElNotifications(null);
    setAnchorElMenu(null);
  };
  return (
    <Grid item display="flex" alignItems="center" className="navbar__options">
      {width > MEDIUM_BREAKPOINT && (
        <Box mr={2.5}>
          {can_write_managment && entity === MERCHANT && (
            <CustomTooltip title={customTooltip}>
              <IconButton
                sx={{ marginRight: 1.5 }}
                className={
                  pathname === STORES_MANAGEMENT_PATH
                    ? "color-primary"
                    : "text-dark"
                }
                onClick={() => navigate("gestion-marketplaces")}
              >
                <span className="material-symbols-rounded">add_business</span>
              </IconButton>
            </CustomTooltip>
          )}
          <Badge
            badgeContent={notificationsAndCount.count}
            color="primary"
            overlap="circular"
            slotProps={getBadgeSlotProps(notificationsAndCount.count)}
          >
            <CustomTooltip
              title={
                <Typography className="f-s-14 text-bold">
                  Notificaciones
                </Typography>
              }
            >
              <IconButton
                className={
                  notificationsAndCount.count < 10
                    ? "text-dark notifications-badge"
                    : "text-dark"
                }
                onClick={({ currentTarget }) => {
                  updateNotificationsAndCountAfterMenuOpening(
                    socket,
                    notificationsAndCount
                  );
                  setAnchorElNotifications(currentTarget);
                }}
              >
                <span className="material-symbols-rounded">notifications</span>
              </IconButton>
            </CustomTooltip>
          </Badge>
        </Box>
      )}
      {showSkeleton ? (
        <Skeleton variant="circular" width={40} height={40} />
      ) : (
        <IconButton
          onClick={({ currentTarget }) => {
            return width > MEDIUM_BREAKPOINT
              ? setAnchorElMenu(currentTarget)
              : setOpenMenuMobile(!openMenu);
          }}
        >
          {getAvatar(name)}
        </IconButton>
      )}
      {entity && (
        <>
          {width > MEDIUM_BREAKPOINT ? (
            <NavbarOptionsMenu
              navbarOptions={navbarOptions}
              anchorElMenu={anchorElMenu}
              openMenu={openMenu}
              handleCloseMenu={handleCloseMenu}
              getAvatar={getAvatar}
              disconnect={disconnect}
              showAll={configComplete !== INCOMPLETE_CONFIG}
            />
          ) : (
            <MenuMobile
              navbarOptions={navbarOptions}
              open={openMenuMobile}
              setOpen={setOpenMenuMobile}
              getAvatar={getAvatar}
              showAll={configComplete !== INCOMPLETE_CONFIG}
            />
          )}
        </>
      )}
      <ListNotification
        anchorElNotifications={anchorElNotifications}
        openNotifications={openNotifications}
        handleCloseMenu={() =>
          handleCloseNotifications(
            handleCloseMenu,
            notificationsAndCount,
            setNotificationsAndCount
          )
        }
        notifications={notificationsAndCount.notifications}
        entity={entity}
        navigate={navigate}
      />
    </Grid>
  );
};

export default NavbarOptions;
