import { IFile, IValidation } from "../interfaces/form";
import {
  REQUIRED_SUBSTRING,
  VALID_INITIAL_CUIT_DIGITS,
  VALID_TRACKING_NUMBER_LENGTHS,
} from "./constants";
import { compareDates } from "./orders";

const validateEmail = (email: string) => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password: string) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#{}:;=~-])[A-Za-z\d@$!%*?&.#{}:;=~-]{8,}$/;
  return re.test(String(password));
};

const passwordConfirmation = (password: string, passwordToCompare: string) =>
  password === passwordToCompare;

const validateRequired = (value: string | object): boolean => {
  switch (typeof value) {
  case "object":
    return !!value;
  case "number":
    return value !== undefined && !Number.isNaN(value);
  default:
    return value?.length > 0;
  }
};

const validateRequiredList = (value: any[]): boolean =>
  Array.isArray(value) && value.length > 0;

const validateCuit = (cuit: any): boolean => {
  if (cuit?.length !== 11) return false;

  let acumulado = 0;
  const digitos = cuit.split("");

  const initialDigits = `${digitos[0]}${digitos[1]}`;

  if (!VALID_INITIAL_CUIT_DIGITS.includes(initialDigits)) return false;

  const digito = parseInt(digitos.pop());

  for (let i = 0; i < digitos.length; i++) {
    acumulado += digitos[9 - i] * (2 + (i % 6));
  }

  let verif = 11 - (acumulado % 11);
  if (verif === 11) {
    verif = 0;
  } else if (verif === 10) {
    verif = 9;
  }

  return digito === verif;
};

const validateTrackingNumberLength = (trackingNumber: string): boolean =>
  VALID_TRACKING_NUMBER_LENGTHS.includes(trackingNumber?.length);

const validateNumberRequired = (value: number): boolean => value > 0;

const validateDateIsFuture = (value: Date | string): boolean => {
  if (!value) return true;
  return new Date(value) > new Date();
};

const validateDateIsGreaterThan = (
  value: string | null,
  dateToCompare: string | null
): boolean => {
  if (!value) return true;
  return compareDates(value, dateToCompare);
};

export const validateOnlyNumbers = (value: string): boolean => {
  const re = /^[0-9]+$/;
  return value ? re.test(value) : true;
};

export const validateContainNumbers = (value: string): boolean => {
  const re = /\d/;
  return value ? re.test(value) : true;
};

// TODO revisar funcion porque no parece funcionar correctamente
export const validateRequiredParam = (value: any, paramValue: any): boolean =>
  paramValue ? !!value : true;

export const validateFile = (value: IFile) => !!value?.name;

const validateCase = (
  value: any,
  type: string,
  paramValue?: any,
  previousInfo?: any
): boolean => {
  switch (type) {
  case "required":
    return validateRequired(value);
  case "email":
    return validateEmail(value);
  case "password":
    return validatePassword(value);
  case "password-confirmation":
    return passwordConfirmation(value, paramValue);
  case "cuit":
    return validateCuit(value);
  case "order":
    return validateTrackingNumberLength(value);
  case "number":
    return validateNumberRequired(value);
  case "only-numbers":
    return validateOnlyNumbers(value);
  case "contain-numbers":
    return validateContainNumbers(value);
  case "future_date":
    return validateDateIsFuture(value);
  case "compare-dates":
    return validateDateIsGreaterThan(value, paramValue);
  case "required-param":
    return validateRequiredParam(value, paramValue);
  case "file":
    return (previousInfo && !value) || validateFile(value);
  case "required-list":
    return validateRequiredList(value);
  default:
    return false;
  }
};

const typesWithParams = [
  "password-confirmation",
  "compare-dates",
  "required-param",
];

export const validateInputs = (submitData: any, validations: IValidation[]) => {
  const inputsToValidate: any = {};
  validations.forEach(
    ({ message, id, type, param, previousInfo }: IValidation) => {
      const isValid = validateCase(
        submitData[id],
        type,
        typesWithParams.includes(type) ? submitData[param] : param,
        previousInfo
      );

      if (!isValid) {
        if (type === "required") inputsToValidate[id] = [message];
        else if (inputsToValidate[id]) {
          const requiredTypeErrorAlreadyExists = inputsToValidate[id].some(
            (errorText: string) => errorText.includes(REQUIRED_SUBSTRING)
          );
          inputsToValidate[id] = requiredTypeErrorAlreadyExists
            ? inputsToValidate[id]
            : inputsToValidate[id].concat(message);
        } else inputsToValidate[id] = [message];
      }
    }
  );
  return inputsToValidate;
};
