import { IResendEmail } from "../interfaces/common";
import {
  POST,
  SUCCESS,
  ERROR,
  DISABLED_RESEND_EMAIL_TIMEOUT,
  SUCCESS_ALERT_DURATION,
} from "./constants";
import { fetchData } from "./dataProvider";
import { IAlert } from "../interfaces/alert";

export const reSendEmail = ({
  email,
  setAlert,
  confirmationMessage,
}: IResendEmail) => {
  try {
    fetchData({
      url: "/users/forwardEmail",
      method: POST,
      body: { email },
    });
    setAlert({
      typeOfAlert: SUCCESS,
      message:
        confirmationMessage || "El correo electrónico se ha enviado con éxito.",
      showAlert: true,
    });
  } catch (error: any) {
    setAlert({
      typeOfAlert: ERROR,
      message: error,
      showAlert: true,
    });
  }
};

export const setDisabledFalse = (setDisabled: (disabled: boolean) => void) =>
  setTimeout(() => setDisabled(false), DISABLED_RESEND_EMAIL_TIMEOUT);

export const handleResendEmail = (
  setDisabled: (disabled: boolean) => void,
  email: string,
  setAlert: (alert: IAlert) => void
) => {
  setDisabled(true);
  reSendEmail({ email, setAlert });
  setTimeout(() => setAlert({} as IAlert), SUCCESS_ALERT_DURATION);
  setDisabledFalse(setDisabled);
};
