import { FormControlLabel, Switch, TableCell, Typography } from "@mui/material";
import { ICarrierStateProps } from "../../interfaces/configuration";

const CarrierState = ({
  disableCarrierStatus,
  blockedCarrierStatusToggle,
  handleCarrierState,
  setDisableValue,
}: ICarrierStateProps) => (
  <TableCell sx={{ minWidth: "100px" }}>
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={!disableCarrierStatus}
          disabled={blockedCarrierStatusToggle}
          onClick={() => {
            setDisableValue(!disableCarrierStatus);
            handleCarrierState();
          }}
        />
      }
      label={
        <Typography className="f-s-14">
          {disableCarrierStatus ? "Inactivo" : "Activo"}
        </Typography>
      }
    />
  </TableCell>
);

export default CarrierState;
