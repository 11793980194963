import { FormControl, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import { showInputErrors } from "../../utils/credentials";
import { ICredentialsDimensionsProps } from "../../interfaces/configuration";

const CredentialsDimensions = ({
  values,
  setValues,
  errors,
}: ICredentialsDimensionsProps) => {
  return (
    <>
      <Typography className="f-s-18 m-t-20 m-b-20">
        Dimensiones máximas de los paquetes
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth>
            <Typography className="f-s-14 m-b-10">Alto</Typography>
            <OutlinedInput
              endAdornment="cm"
              type="number"
              placeholder="0"
              inputProps={{min: 0}}
              value={values?.height}
              error={errors.height?.length > 0}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) =>
                setValues({
                  ...values,
                  height: Number(value),
                })
              }
              className="f-s-14"
            />
            {errors.height?.length > 0 && showInputErrors(errors.height)}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth>
            <Typography className="f-s-14 m-b-10">Ancho</Typography>
            <OutlinedInput
              endAdornment="cm"
              type="number"
              placeholder="0"
              inputProps={{min: 0}}
              value={values?.width}
              error={errors.width?.length > 0}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) =>
                setValues({
                  ...values,
                  width: Number(value),
                })
              }
              className="f-s-14"
            />
            {errors.width?.length > 0 && showInputErrors(errors.width)}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth>
            <Typography className="f-s-14 m-b-10">Profundidad</Typography>
            <OutlinedInput
              endAdornment="cm"
              type="number"
              placeholder="0"
              inputProps={{min: 0}}
              value={values?.depth}
              error={errors.depth?.length > 0}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) =>
                setValues({
                  ...values,
                  depth: Number(value),
                })
              }
              className="f-s-14"
            />
            {errors.depth?.length > 0 && showInputErrors(errors.depth)}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth>
            <Typography className="f-s-14 m-b-10">Peso</Typography>
            <OutlinedInput
              endAdornment="kg"
              type="number"
              placeholder="0"
              inputProps={{min: 0}}
              value={values?.weight}
              error={errors.weight?.length > 0}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) =>
                setValues({ ...values, weight: Number(value) })
              }
              className="f-s-14"
            />
            {errors.weight?.length > 0 && showInputErrors(errors.weight)}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default CredentialsDimensions;
