import React, { useState } from "react";
import MobileLayout from "../MobileLayout";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import UserInfoContent from "./UserInfoContent";
import { IUserInfoProps } from "../../interfaces/usersRoles";

const UserInfoMobile = ({
  disableInputs,
  handleEdit,
  handleChange,
  alert,
  errors,
  values,
  disableSubmit,
  handleCancel,
  handleSubmit,
}: IUserInfoProps) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    if (disableInputs) {
      navigate(-1);
      setOpen(false);
    } else handleCancel();
  };
  return (
    <MobileLayout title={disableInputs ? "Mis datos" : "Editar mis datos"} open={open} handleClose={handleClose}>
      <Grid container className="p-20">
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          className="m-t-30"
        >
          <Typography className="f-s-20 text-dark">Mis datos</Typography>
          {disableInputs && (
            <Button
              size="small"
              variant="text"
              className="f-s-14"
              onClick={handleEdit}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
            >
              Editar mis datos
            </Button>
          )}
        </Grid>
        <UserInfoContent
          alert={alert}
          errors={errors}
          handleChange={handleChange}
          values={values}
          disableInputs={disableInputs}
          disableSubmit={disableSubmit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isMobile
        />
      </Grid>
    </MobileLayout>
  );
};

export default UserInfoMobile;
