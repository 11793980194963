import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
  Link,
} from "@mui/material";
import GenericAlert from "../GenericAlert";
import { validateInputs } from "../../utils/inputValidations";
import {
  ECOMMERCE,
  ERROR,
  ERRORS_MESSAGES,
  MARKETPLACE,
  LANDING_PATH,
  POST,
  VERIFY_ACCOUNT,
  PARTNERS_PLAN_NAME,
  PASSWORD_HELPER,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { IAccountStep, IRegisterClientSubmit } from "../../interfaces/signup";
import { ITargetValue, IValidation } from "../../interfaces/form";
import { IAlert } from "../../interfaces/alert";
import { useNavigate, useSearchParams } from "react-router-dom";
import { reSendEmail } from "../../utils/email";
import { showInputErrors } from "../../utils/credentials";
import PasswordInput from "../PasswordInput";
import TermsAndConditions from "../TermsAndConditions";
import GenericForm from "../GenericForm";
import { trimValues } from "../../utils/form";

const {
  passwordInvalid,
  passwordRequired,
  emailInvalid,
  emailRequired,
  entityRequired,
  securityCodeRequired,
} = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "password",
    type: "password",
    message: passwordInvalid,
  },
  {
    id: "entity",
    type: "required",
    message: entityRequired,
  },
];

const partnerValidations: IValidation[] = [
  ...formValidations,
  { id: "securityCode", type: "required", message: securityCodeRequired },
];

const registerClient = async ({
  selectedValues,
  goForward,
  setAlert,
}: IRegisterClientSubmit) => {
  try {
    await fetchData({
      url: `/entities/register/client/${selectedValues.entity}`,
      method: POST,
      body: selectedValues,
    });

    goForward();
  } catch (error: any) {
    console.log(error);
    const isVerifyError = error === VERIFY_ACCOUNT;
    if (isVerifyError) {
      const email = selectedValues.email;
      const confirmationMessage = `Revisa el email que te enviamos a ${email} para validar tu cuenta.`;
      const verifyAccountMessage = (
        <>
          Ya existe una cuenta con este email. <br />
          {error}. <br />
          ¿No te llegó el email? Reenvíalo haciendo &nbsp;
          <Link
            className="text-bold pointer"
            onClick={() =>
              reSendEmail({ email, setAlert, confirmationMessage })
            }
          >
            click aquí.
          </Link>
        </>
      );

      setAlert({
        typeOfAlert: ERROR,
        message: verifyAccountMessage,
        showAlert: true,
      });
    } else
      setAlert({
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      });
  }
};

const baseErrors = {
  email: [],
  password: [],
  entity: [],
  securityCode: [],
};

const AccountForm = ({
  setGridSize,
  goForward,
  goBack,
  selectedValues,
  setSelectedValues,
}: IAccountStep) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [errors, setErrors] = useState(baseErrors);
  const [alert, setAlert] = useState({} as IAlert);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan");
  const planName = searchParams.get("name");

  const handleChange = ({ name, value }: ITargetValue) =>
    setSelectedValues({
      ...selectedValues,
      [name]: value,
    });

  const handleSubmit = () => {
    const inputErrors = validateInputs(
      selectedValues,
      planName === PARTNERS_PLAN_NAME ? partnerValidations : formValidations
    );
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0)
      registerClient({
        selectedValues: trimValues(selectedValues),
        goForward,
        setAlert
      });
  };

  const verifyIfCameFromLandingOrNotAndRedirect = () => {
    // If has plan, then comes from landing and go back to landing
    if (planId) navigate(LANDING_PATH);
    // If not, then comes from signin and its going back to plans
    goBack();
  };

  useEffect(() => setGridSize(4), []);
  return (
    <>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm onSubmit={handleSubmit} disableSubmit={!termsAndConditions}>
        <FormControl className="m-t-20" fullWidth>
          <Typography id="email-label" className="f-s-14 m-b-10">
            Correo electrónico
          </Typography>
          <OutlinedInput
            size="small"
            name="email"
            placeholder="Ingresar correo electrónico"
            value={selectedValues.email}
            error={errors.email?.length > 0}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              handleChange(target)
            }
            autoFocus
          />
          {errors.email?.length > 0 && showInputErrors(errors.email)}
        </FormControl>
        <PasswordInput
          value={selectedValues.password}
          show={showPassword}
          setShow={setShowPassword}
          errors={errors?.password}
          handleChange={handleChange}
          name="password"
          label="Contraseña"
          placeholder="Ingresar contraseña"
          helper={PASSWORD_HELPER}
        />
        <FormControl className="m-t-20" fullWidth>
          <Typography className="f-s-14 m-b-10">Tipo de negocio</Typography>
          <Select
            size="small"
            name="entity"
            value={selectedValues.entity}
            error={errors.entity?.length > 0}
            onChange={({ target }: SelectChangeEvent) => handleChange(target)}
          >
            <MenuItem value={ECOMMERCE}>Ecommerce</MenuItem>
            <MenuItem value={MARKETPLACE}>Marketplace</MenuItem>
          </Select>

          {errors.entity?.length > 0 && showInputErrors(errors.entity)}
        </FormControl>
        {planName === PARTNERS_PLAN_NAME && (
          <PasswordInput
            value={selectedValues.securityCode}
            show={showSecurityCode}
            setShow={setShowSecurityCode}
            errors={errors?.securityCode}
            handleChange={handleChange}
            name="securityCode"
            label="Código de seguridad"
            placeholder="Ingresar el código de seguridad de Coatí"
          />
        )}
      </GenericForm>
      <TermsAndConditions
        setTermsAndConditions={setTermsAndConditions}
        termsAndConditions={termsAndConditions}
      />
      <Button
        variant="contained"
        className="m-t-40 w-100"
        onClick={handleSubmit}
        disabled={!termsAndConditions}
      >
        Continuar
      </Button>
      <Button
        variant="text"
        className="m-t-20 w-100"
        onClick={verifyIfCameFromLandingOrNotAndRedirect}
      >
        Volver
      </Button>
    </>
  );
};

export default AccountForm;
