import SectionTitle from "../../components/landing/SectionTitle";
import {
  CONTACTO_URL,
  FREE_PLAN_SIGNUP_PATH,
  PARTNERS_TITLE,
} from "../../utils/constants";
import { Grid, Typography, Link } from "@mui/material";
import partnersImage from "../../images/landing/landing_partners.png";
import { useNavigate, useOutletContext } from "react-router-dom";

const Partners = () => {
  const navigate = useNavigate();
  const { partnerPlan } = useOutletContext<any>();
  return (
    <Grid
      container
      item
      className="p-x-landing p-y-landing"
      justifyContent="center"
      marginBottom={{ xs: 10, lg: 20 }}
    >
      <SectionTitle title={PARTNERS_TITLE} marginBottom={10} />
      <Grid
        item
        container
        alignItems="center"
        columnSpacing={{ xs: 5, lg: 15 }}
        rowSpacing={{ xs: 5, lg: 0 }}
      >
        <Grid item xs={12} lg={6} textAlign="center">
          <img src={partnersImage} className="w-100 br-10" />
        </Grid>
        <Grid item container flexDirection="column" xs={12} lg={6} gap={2.5}>
          <Typography className="f-s-20 f-s-md-24 color-primary">
            ¡En coatí estamos cambiando la forma de hacer envíos!
          </Typography>
          <Typography className="f-s-14 f-s-md-20">
            Conectamos ecommerce y marketplaces con múltiples operadores
            logísticos optimizando y mejorando la experiencia de compra de sus
            clientes.
          </Typography>
          <Typography className="f-s-14 f-s-md-20">
            ¡Comunicate con nosotros desde nuestra sección de{" "}
            <Link href={CONTACTO_URL}>contacto</Link> y descubrí cómo podemos
            ayudarte a mejorar tus envíos y hacer crecer tu negocio!
          </Typography>
          <Typography className="f-s-14 f-s-md-20">
            Si ya formas parte de nuestro ecosistema de partners ingresá
            haciendo{" "}
            <Link
              onClick={() =>
                navigate(
                  `${FREE_PLAN_SIGNUP_PATH}${partnerPlan?._id}&name=${partnerPlan?.name}`
                )
              }
              className="pointer"
            >
              clic aquí
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Partners;
