import React from "react";
import {
  IOrdersFilters,
  IOrderRawWithMerchantName,
  IPackageToRefund,
} from "../interfaces/order";
import {
  ABLE_TO_REFUND_OR_CANCEL,
  ECOMMERCE,
  LOCALE,
  ORDER_STATUS,
  SP_DELIVERED,
  STATUS_TRANSLATOR,
} from "./constants";
import {
  TEntitiesAbleToExecuteAction,
  TEntity,
} from "../interfaces/userContext";

// Previous version left an extra space between day and
// hour (since it splitted by "," instead of ", ") and
// also did not work in firefox
const getDayAndTime = (date: string): string[] =>
  date.split(date.includes(",") ? ", " : " ");

export const mongoTimeStampToString = (
  date: string,
  trimTime?: boolean
): string => {
  if (!date) return "";

  const asString = new Date(date).toLocaleString(LOCALE);
  const [day, time] = getDayAndTime(asString);
  const [hour, minutes] = time.split(":");

  if (!trimTime) return `${day} ${hour}:${minutes}`;

  return day;
};

export const downloadFromURL = (url: string) => window.open(url, "_blank");

export const getOrderStatus = (idToFind: string) =>
  ORDER_STATUS.find(({ id }: { id: string }) => id === idToFind);

export const translateStatus = (status: string) =>
  getOrderStatus(status.toLowerCase());

export const getFiltersQuery = (filters: IOrdersFilters) => {
  let query = "";

  Object.keys(filters).forEach((keyName) => {
    if (filters[keyName]) query = `${query}&${keyName}=${filters[keyName]}`;
  });
  return query;
};

export const getMappedInfo = (orders: IOrderRawWithMerchantName[]) =>
  orders.map(
    ({
      _id,
      merchant,
      merchant_name,
      createdAt,
      tracking_id,
      carrier: carrierFromOrder,
      service,
      status,
      status_history,
      orderInfo: {
        trackingNumber,
        carrier: carrierFromInfo,
        origin,
        destination,
        packages,
        labels,
        fee,
      },
      updatedAt,
      orderInfo,
      alias,
    }) => ({
      _id,
      createdAt,
      tracking_id,
      merchant,
      merchant_name,
      carrier: carrierFromInfo || carrierFromOrder,
      origin,
      destination,
      packages,
      labels,
      status,
      status_history,
      fee,
      service,
      trackingNumber,
      orderInfo,
      updatedAt,
      alias,
    })
  );

export const getArrayOfNumbers = ({ length }: { length: number }) =>
  Array.from({ length }, (_, i) => i + 1);

export const checkIfPackageIsSelectedById = (
  packageId: string,
  packages: IPackageToRefund[]
) => !!packages.find(({ sku }) => sku === packageId);

export const checkIfProductIsSelectedById = (
  id: string,
  packages: IPackageToRefund[]
) => !!packages.find(({ sku: productId }) => productId === id);

export const findPackage = (packageId: string, packages: IPackageToRefund[]) =>
  packages.filter(({ sku }) => sku === packageId)[0];

export const removePackageToRefund = (
  _id: string,
  packages: IPackageToRefund[],
  setPackagesToRefund: React.Dispatch<React.SetStateAction<IPackageToRefund[]>>
) => {
  const filteredPackages = packages.filter(({ sku }) => sku !== _id);
  setPackagesToRefund(filteredPackages);
};

export const compareDates = (date: any, dateToCompare: any) => {
  if (!date || !dateToCompare) return true;

  return new Date(dateToCompare) >= new Date(date);
};

export const capitalizeOnlyFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getFallenServicesMessage = (fallenServices: string[]) => {
  const uncapitalizedFallenServices = fallenServices.map((service) =>
    capitalizeOnlyFirstLetter(service)
  );
  const multipleServices = fallenServices.length > 1;
  const lastService = multipleServices && uncapitalizedFallenServices.pop();
  return `${multipleServices ? "Los servicios" : "El servicio"} de ${
    multipleServices
      ? `${uncapitalizedFallenServices.join(", ")} y ${lastService} presentan`
      : `${uncapitalizedFallenServices[0]} presenta`
  } interrupciones en el sistema. Las órdenes serán actualizadas a la brevedad.`;
};

export const canRefundOrCancel = (
  entity: TEntity,
  entitiesAbleToReturnOrCancel: TEntitiesAbleToExecuteAction
) =>
  entity === ECOMMERCE ||
  ABLE_TO_REFUND_OR_CANCEL[entity].includes(entitiesAbleToReturnOrCancel);

export const getChoppedText = (totalCharacters: number, text: string) => {
  if (text.length > totalCharacters)
    return `${text.substring(0, totalCharacters)}...`;
  return text;
};

export const translateOrderStatus = (status?: string): string => {
  if (!status) return "";

  return STATUS_TRANSLATOR[status] || "";
};

const getRetriesText = (retries?: number) => {
  switch (retries) {
  case 1:
    return " en segunda visita";
  case 2:
    return " en tercera visita";
  default:
    return " en primera visita";
  }
};

export const extendStatusIfDelivered = (label?: string, retries?: number) =>
  label === SP_DELIVERED ? label.concat(getRetriesText(retries)) : label;
