import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ERROR, GET } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { IAlert } from "../../interfaces/alert";
import { fetchData } from "../../utils/dataProvider";
import OrderAuditInfo from "../../components/orders/audit/OrderAuditInfo";
import AuditWrapper from "../../components/orders/audit/AuditWrapper";
import { IOrderAudit } from "../../interfaces/order";

const OrderAudit = () => {
  const { trackingNumber } = useParams();
  const [audit, setAudit] = useState({} as IOrderAudit);
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(true);

  const getAudit = async () => {
    setLoading(true);
    try {
      const response = await fetchData({
        url: `/orders/audit/orders/${trackingNumber}`,
        method: GET,
      });
      setAudit(response);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAudit();
  }, []);

  return (
    <AuditWrapper loading={loading} alert={alert}>
      <Grid container item xs={12} lg={9}>
        <OrderAuditInfo audit={audit} />
      </Grid>
    </AuditWrapper>
  );
};

export default OrderAudit;
