import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import GenericAlert from "../../components/GenericAlert";
import CancelOrderModal from "../../components/modals/CancelOrderModal";
import FiltersHeader from "../../components/orders/FiltersHeader";
import ListOrders from "../../components/tables/ListOrders";
import { UserContext } from "../../contexts/userContext";
import { IAlert } from "../../interfaces/alert";
import {
  IOrder,
  IGetOrdersParams,
  IOrdersFilters,
  IMappedOrder,
} from "../../interfaces/order";
import { IPaginationParams } from "../../interfaces/table";
import {
  ERROR,
  GET,
  INFO,
  MEDIUM,
  MERCHANT,
  ROWS_PER_PAGE,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import {
  downloadFromURL,
  getFallenServicesMessage,
  getFiltersQuery,
  getMappedInfo,
} from "../../utils/orders";
import { integrationIsPaused } from "../../utils/integrations";
import Loader from "../../components/Loader";
import EditOrderModal from "../../components/modals/EditOrderModal";
import { getStatusAliasList } from "../../requests/orderRequests";

const getOrders = async ({
  entity,
  setOrders,
  setAlert,
  setPagination,
  setLoading = () => undefined,
  selectedMarketplace,
  filters,
  newPage = 0,
}: IGetOrdersParams) => {
  const skip = newPage * ROWS_PER_PAGE;
  const useMarketplaceid =
    entity === MERCHANT ? `&marketplaceId=${selectedMarketplace}` : "";
  const hasFilters =
    Object.keys(filters).length > 0 ? getFiltersQuery(filters) : "";
  const url = `/orders?limit=${ROWS_PER_PAGE}&skip=${skip}${useMarketplaceid}${hasFilters}`;

  try {
    setAlert({ showAlert: false } as IAlert);
    const { count, orders, fallenServices } = await fetchData({
      url,
      method: GET,
    });

    if (orders.length) {
      setOrders(getMappedInfo(orders));
      setPagination({ count, page: newPage });
    } else {
      setAlert({
        typeOfAlert: INFO,
        message: "No hay órdenes para mostrar.",
        showAlert: true,
      });
      setOrders([]);
    }

    if (fallenServices.length)
      setAlert({
        typeOfAlert: INFO,
        message: getFallenServicesMessage(fallenServices),
        showAlert: true,
      });
  } catch (error: any) {
    setAlert({
      typeOfAlert: ERROR,
      message: error,
      showAlert: true,
    });
  } finally {
    setLoading(false);
  }
};

const Orders = ({ showTitle = true, showFilters = true }) => {
  const {
    currentUser: { entity },
    selectedMarketplace,
    merchantIntegrations,
    rules,
  } = useContext(UserContext);
  const [orders, setOrders] = useState([] as IMappedOrder[]);
  const [selectedOrder, setSelectedOrder] = useState({} as IOrder);
  const [pagination, setPagination] = useState({} as IPaginationParams);
  const [alert, setAlert] = useState({} as IAlert);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [filters, setFilters] = useState({} as IOrdersFilters);
  const [loading, setLoading] = useState(true);
  const [loaderPage, setLoaderPage] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [aliases, setAliases] = useState([] as any);
  const navigate = useNavigate();

  const fetchAliases = async (marketplaceId?: string) => {
    const aliasDict = await getStatusAliasList(marketplaceId);
    setAliases(aliasDict);
  };

  const getOrderCall = (newPage?: number) => {
    setLoaderPage(true);
    setAlert({} as IAlert);
    return getOrders({
      entity,
      setOrders,
      setAlert,
      setPagination,
      setLoading,
      selectedMarketplace,
      filters,
      newPage,
    });
  };

  const handlePageChange = (newPage: number) =>
    getOrderCall(newPage).finally(() => setLoaderPage(false));

  const handleSelectedAction = (action: string, order: IOrder) => {
    switch (action) {
    case "info":
      navigate(`datos-de-orden/${order.trackingNumber}`);
      break;
    case "delete":
      setSelectedOrder(order);
      setOpenCancelModal(true);
      break;
    case "label":
      downloadFromURL(order.labels);
      break;
    case "refund":
      navigate(`devolucion/${order?.trackingNumber}`);
      break;
    case "history":
      navigate(`historial/${order?.trackingNumber}`);
      break;
    case "edit":
      setSelectedOrder(order);
      setOpenEditModal(true);
      break;
    case "view-audit":
      navigate(`orden/${order.trackingNumber}`);
      break;
    default:
      console.warn("Accion inválida");
      break;
    }
  };

  const handleChangeFilter = (name: string, value: string | null) =>
    setFilters({ ...filters, [name]: value });

  useEffect(() => {
    getOrderCall().finally(() => setLoaderPage(false));
  }, [filters]);

  useEffect(() => {
    if (entity === MERCHANT && merchantIntegrations.length > 0) {
      if (Object.keys(filters).length) setFilters({} as IOrdersFilters);

      setShowActions(
        !integrationIsPaused(merchantIntegrations, selectedMarketplace)
      );
    }
    fetchAliases(selectedMarketplace);
  }, [merchantIntegrations, selectedMarketplace]);

  return (
    <>
      {loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <>
          {showTitle && <Typography className="f-s-20">Órdenes</Typography>}
          {showFilters && <FiltersHeader
            handleChangeFilter={handleChangeFilter}
            setAlert={setAlert}
            aliases={aliases}
          />}
          {alert.showAlert && <GenericAlert alert={alert} />}
          {loaderPage && <Loader size={MEDIUM} />}
          {!loaderPage && orders.length > 0 && (
            <ListOrders
              orders={orders}
              entity={entity}
              pagination={pagination}
              handlePageChange={handlePageChange}
              handleSelectedAction={handleSelectedAction}
              showActions={showActions}
              rules={rules}
            />
          )}
        </>
      )}

      <CancelOrderModal
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        order={selectedOrder}
        setFilters={setFilters}
        selectedMarketplace={selectedMarketplace}
      />
      <EditOrderModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        order={selectedOrder}
        setFilters={setFilters}
        selectedMarketplace={selectedMarketplace}
        aliases={aliases}
      />
    </>
  );
};

export default Orders;
