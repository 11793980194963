import { ChangeEvent, useState } from "react";
import { ISearchCarrierValue } from "../../interfaces/configuration";
import { ISearchUsers } from "../../interfaces/usersRoles";
import { useDebounce } from "../../customHooks/useDebounce";
import { Button, FormControl, OutlinedInput } from "@mui/material";

const SearchUsers = ({ handleGetUsers }: ISearchUsers) => {
  const [searchValue, setSearchValue] = useState({} as ISearchCarrierValue);

  const { setSubmitting } = useDebounce(() => {
    if (searchValue.content || searchValue.content === "") {
      setSearchValue({ ...searchValue, activeSearch: true });
      handleGetUsers(searchValue.content);
    }
  });

  const { setSubmitting: setSubmittingClose }: any = useDebounce(() => {
    setSearchValue({ content: "", activeSearch: false });
    handleGetUsers(searchValue.content);
  });

  const handleClick = () => {
    const handler = searchValue.activeSearch
      ? setSubmittingClose
      : setSubmitting;

    handler(true);

    if (searchValue.activeSearch)
      setSearchValue({ ...searchValue, content: "" });
  };

  return (
    <FormControl size="small" fullWidth>
      <OutlinedInput
        placeholder="Buscar por nombre o correo electrónico"
        className="f-s-14"
        value={searchValue.content}
        onKeyUp={(e) => e.key === "Enter" && setSubmitting(true)}
        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
          setSearchValue({ ...searchValue, content: value });
        }}
        endAdornment={
          <Button
            className="search-input-adornment"
            onClick={handleClick}
          >
            <span className="material-symbols-rounded text-muted f-s-24">
              {searchValue.activeSearch ? "close" : "search"}
            </span>
          </Button>
        }
      />
    </FormControl>
  );
};

export default SearchUsers;
