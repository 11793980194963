import React, { useContext } from "react";
import { Typography, Link } from "@mui/material";
import { INotification } from "../interfaces/notifications";
import { messageTypeIconEquivalencies, RED_DOT_URL } from "../utils/constants";
import {
  getHowLongCreationWas,
  getIconUrl,
  handleRedirect,
} from "../utils/notifications";
import { UserContext } from "../contexts/userContext";
import { useNavigate } from "react-router-dom";

const Notification = ({
  notification: {
    message,
    createdAt,
    read_at,
    message_type,
    redirect_url,
    marketplace_id,
  },
  entity,
}: {
  notification: INotification;
  entity: string;
}) => {
  const { setSelectedMarketplace, merchantIntegrations } =
    useContext(UserContext);
  const navigate = useNavigate();
  const currentPath = window.location.pathname.split("/").pop();

  const handleNotificationClick = () => {
    if (redirect_url) {
      if (redirect_url === currentPath) {
        location.reload();
      } else {
        handleRedirect({
          notificationInfo: { redirect_url, marketplace_id },
          setSelectedMarketplace,
          merchantIntegrations,
          navigate,
          entity,
        });
      }
    }
  };

  return (
    <Link
      underline="none"
      onClick={handleNotificationClick}
      className={redirect_url ? "pointer" : ""}
    >
      <div className="p-y-8 d-flex menu-item-hover">
        <div className="flex-1-5 m-x-20">
          <img
            src={getIconUrl(messageTypeIconEquivalencies[message_type])}
            // alt="message_type"
            className="m-t-3"
          />
        </div>
        <div className="flex-7-5">
          <Typography className="f-s-14">{message}</Typography>
          <Typography className="color-primary f-s-10">
            {getHowLongCreationWas(new Date(createdAt), new Date())}
          </Typography>
        </div>
        <div className="flex-1 d-grid m-r-20">
          {!read_at && (
            <img
              src={RED_DOT_URL}
              alt="unread_notification"
              className="m-l-30 m-t-2"
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export default Notification;
