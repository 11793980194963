import React, { useState } from "react";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { IAlert } from "../../interfaces/alert";
import { ERROR } from "../../utils/constants";
import GenericAlert from "../GenericAlert";
import ModalBase from "./ModalBase";
import { ICancelOrderModal } from "../../interfaces/modal";
import { trimValue } from "../../utils/form";
import { cancelOrder } from "../../requests/orderRequests";
import { IOrdersFilters } from "../../interfaces/order";

const CancelOrderModal = ({
  open,
  setOpen,
  order: { trackingNumber },
  setFilters,
  selectedMarketplace,
}: ICancelOrderModal) => {
  const [alertDisable, setAlertDisable] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);
  const [reason, setReason] = useState<string>("");

  const shouldDisableConfirmationButton =
    disableButton || !trimValue(reason).length;

  const handleClose = () => {
    setOpen(false);
    setDisableButton(false);
    setAlertDisable({} as IAlert);
    setReason("");
  };

  const handleDisable = async () => {
    setDisableButton(true);
    try {
      await cancelOrder({ trackingNumber, selectedMarketplace, reason });
      setOpen(false);
      setDisableButton(false);
      setFilters({} as IOrdersFilters);
      setReason("");
    } catch (error: any) {
      setAlertDisable({
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      });
    }
  };
  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container spacing={1} alignItems="center">
        <div className="w-100">
          {alertDisable.showAlert && <GenericAlert alert={alertDisable} />}
        </div>
        <Typography className="f-s-20 m-t-20">
          ¿Seguro de que deseas cancelar la orden n°{trackingNumber}?
        </Typography>

        <Grid container className="m-t-20 m-b-40">
          <TextField
            InputProps={{ className: "br-10" }}
            multiline
            fullWidth
            rows={4}
            placeholder="Por favor, ingresa en detalle el motivo de la cancelación."
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            Volver
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            disabled={shouldDisableConfirmationButton}
            onClick={handleDisable}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default CancelOrderModal;
