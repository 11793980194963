import React, { useRef } from "react";
import { AppBar, Toolbar, Grid, Link } from "@mui/material";
import logo from "../../images/coati_logo.png";
import "../../styles/landing.css";
import LandingNavMenu from "./LandingNavMenu";
import { ILandingNavbar } from "../../interfaces/landing";

const NotLandingMenu: string[] = ["/docs"];
const renderLandingMenu = (pathname: string) =>
  !NotLandingMenu.includes(pathname);

const LandingNavbar = ({ freePlan }: ILandingNavbar) => {
  const menuNav = useRef(null);

  return (
    <AppBar position="fixed" className="bg-input p-x-landing" ref={menuNav}>
      <Toolbar>
        <Grid container justifyContent="space-between">
          <Link href="/">
            <img src={logo} className="w-logo logo-sm" alt="Coati logo" />
          </Link>

          {renderLandingMenu(location.pathname) && (
            <LandingNavMenu menuRef={menuNav} freePlan={freePlan} />
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default LandingNavbar;
