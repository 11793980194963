import React, { ChangeEvent } from "react";
import { FormControl, Grid, OutlinedInput, Typography } from "@mui/material";
import { ICredentialsChazkiProps } from "../../interfaces/configuration";
import { SimpleDateInput } from "./SimpleDateInput";
import InputErrors from "./InputErrors";

const CredentialsChazki = ({
  values,
  setValues,
  errors,
}: ICredentialsChazkiProps) => (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <FormControl size="small" fullWidth>
        <Typography className="f-s-14 m-b-10">Token</Typography>
        <OutlinedInput
          placeholder={"Ingresar token"}
          value={values.token}
          error={errors.token?.length > 0}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setValues({ ...values, token: value.trim() })
          }
          className="f-s-14"
        />
        {errors.token?.length > 0 && <InputErrors errors={errors.token} />}
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl size="small" fullWidth>
        <Typography className="f-s-14 m-b-10">
          Cantidad de envíos mensuales
        </Typography>
        <OutlinedInput
          placeholder={"Ingresar la cantidad de envíos mensuales"}
          value={values.monthly_shippings}
          error={errors.monthly_shippings?.length > 0}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setValues({ ...values, monthly_shippings: value })
          }
          className="f-s-14"
        />
        {errors.monthly_shippings?.length > 0 && (
          <InputErrors errors={errors.monthly_shippings} />
        )}
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <SimpleDateInput
        inputTitle="Fecha de expiración del contrato"
        valueName="end_date"
        values={values}
        errors={errors.end_date}
        setValues={setValues}
      />
    </Grid>

    <Grid item xs={12}>
      <Typography className="f-s-20 m-t-10">Matriz logística</Typography>
    </Grid>
  </Grid>
);

export default CredentialsChazki;
