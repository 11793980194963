import React, { ChangeEvent, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ICredentialsHopProps } from "../../interfaces/configuration";
import { SimpleDateInput } from "./SimpleDateInput";
import InputErrors from "./InputErrors";

const CredentialsHop = ({
  values,
  setValues,
  errors,
}: ICredentialsHopProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Email</Typography>
          <OutlinedInput
            placeholder={"Ingresar email"}
            value={values.email}
            error={errors.email?.length > 0}
            onChange={({
              target: { value },
            }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, email: value.trim() })
            }
            className="f-s-14"
          />
          {errors.email?.length > 0 && <InputErrors errors={errors.email} />}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Contraseña</Typography>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            placeholder="Ingresar contraseña"
            value={values.password}
            error={errors.password?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, password: value.trim() })
            }
            className="f-s-14"
            endAdornment={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <span className="material-symbols-rounded">
                  {showPassword ? "visibility_off" : "visibility"}
                </span>
              </IconButton>
            }
          />
          {errors.password?.length > 0 && (
            <InputErrors errors={errors.password} />
          )}
        </FormControl>
        {errors.password?.length > 0 && (
          <InputErrors errors={errors.password} />
        )}
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Código secreto</Typography>
          <OutlinedInput
            placeholder={"Ingresar código secreto"}
            value={values.hop_secret}
            error={errors.hop_secret?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, hop_secret: value.trim() })
            }
            className="f-s-14"
          />
          {errors.hop_secret?.length > 0 && (
            <InputErrors errors={errors.hop_secret} />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">ID de cliente HOP</Typography>
          <OutlinedInput
            placeholder={"Ingresar id de cliente"}
            value={values.hop_id}
            error={errors.hop_id?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, hop_id: value.trim() })
            }
            className="f-s-14"
          />
          {errors.hop_id?.length > 0 && <InputErrors errors={errors.hop_id} />}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Código de vendedor</Typography>
          <OutlinedInput
            placeholder={"Ingresar código de vendedor"}
            value={values.seller_code}
            error={errors.seller_code?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, seller_code: value.trim() })
            }
            className="f-s-14"
          />
          {errors.seller_code?.length > 0 && (
            <InputErrors errors={errors.seller_code} />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <SimpleDateInput
          inputTitle="Fecha de expiración del contrato"
          valueName="expired_date"
          values={values}
          errors={errors.expired_date}
          setValues={setValues}
        />
      </Grid>
    </Grid>
  );
};

export default CredentialsHop;
