import React from "react";
import { Grid, Button } from "@mui/material";
import { ISaveChangesProfile } from "../../interfaces/account";

const SaveChangesAccount = ({
  disableSubmit,
  handleCancel,
  handleSubmit,
}: ISaveChangesProfile) => (
  <Grid
    container
    alignItems="center"
    justifyContent="flex-end"
    className="m-t-10"
    spacing={2}
  >
    <Grid item xs={6} md={3}>
      <Button fullWidth variant="outlined" size="small" onClick={handleCancel}>
        Cancelar
      </Button>
    </Grid>
    <Grid item xs={6} md={3}>
      <Button
        fullWidth
        size="small"
        variant="contained"
        disabled={disableSubmit}
        onClick={handleSubmit}
      >
        Guardar cambios
      </Button>
    </Grid>
  </Grid>
);

export default SaveChangesAccount;
