import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { IAlert } from "../../interfaces/alert";
import {
  ERROR,
  FINAL_DOOR_TO_DOOR_STATUS,
  FINAL_REVERSE_LOGISTICS_STATUS,
} from "../../utils/constants";
import GenericAlert from "../GenericAlert";
import ModalBase from "./ModalBase";
import { IEditOrderModal } from "../../interfaces/modal";
import {
  editOrderStatus,
} from "../../requests/orderRequests";
import { IOrdersFilters, IOrderStatus } from "../../interfaces/order";
import { getOrderStatus } from "../../utils/orders";

const replaceStatusByAlias = (
  setter: React.Dispatch<React.SetStateAction<IOrderStatus[]>>,
  service: string,
  aliases: any
) =>
  setter(() =>
    (service === "REVERSE_LOGISTICS"
      ? FINAL_REVERSE_LOGISTICS_STATUS
      : FINAL_DOOR_TO_DOOR_STATUS
    ).map(({ label, id, ...rest }) => ({
      ...rest,
      id,
      label: aliases[id] || label,
    }))
  );

const EditOrderModal = ({
  open,
  setOpen,
  order: { tracking_id, service },
  setFilters,
  selectedMarketplace,
  aliases
}: IEditOrderModal) => {

  const [selectedStatus, setSelectedStatus] = useState<{
    translation?: string;
    status?: string;
  }>();

  const [alertDisable, setAlertDisable] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);
  const [statusList, setStatusList] = useState<IOrderStatus[]>([]);

  const handleClose = () => {
    setOpen(false);
    setDisableButton(false);
    setAlertDisable({} as IAlert);
    setSelectedStatus({
      translation: "",
      status: "",
    });
  };

  const handleChange = (_: any, value: any, reason: any) => {
    setAlertDisable({} as IAlert);
    setDisableButton(false);
    setSelectedStatus(
      reason === "clear"
        ? {
          status: "",
          translation: "",
        }
        : {
          status: value?.id,
          translation: getOrderStatus(value.id)?.label,
        }
    );
  };

  const handleConfirm = async () => {
    setDisableButton(true);
    try {
      await editOrderStatus(tracking_id, {
        status: selectedStatus?.status,
      }, selectedMarketplace);
      setOpen(false);
      setDisableButton(false);
      setFilters({} as IOrdersFilters);
      setSelectedStatus({
        translation: "",
        status: "",
      });
    } catch (error: any) {
      setAlertDisable({
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      });
    }
  };

  useEffect(() => {
    replaceStatusByAlias(setStatusList, service, aliases);
  }, [selectedMarketplace, service]);

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container spacing={1} alignItems="center">
        <div className="w-100">
          {alertDisable.showAlert && <GenericAlert alert={alertDisable} />}
        </div>
        <Typography className="f-s-20 m-t-20">Editar estado</Typography>

        <Grid container className="m-t-20 m-b-40">
          <Typography className="m-b-10">
            Selecciona el estado final del envío
          </Typography>
          <Autocomplete
            className="w-100"
            id="status"
            inputValue={selectedStatus?.translation}
            options={statusList}
            noOptionsText="No se encontró el estado"
            onChange={handleChange}
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" label="Estado" />
            )}
          />
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Volver
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              disabled={disableButton}
              onClick={handleConfirm}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default EditOrderModal;
