import React, { useContext, useReducer } from "react";
import { UserContext } from "../../contexts/userContext";
import { useWindowSize } from "../../customHooks/useWindowSize";
import {
  ERROR,
  ERRORS_MESSAGES,
  MEDIUM_BREAKPOINT,
} from "../../utils/constants";
import UserInfoDesktop from "../../components/account/UserInfoDesktop";
import UserInfoMobile from "../../components/account/UserInfoMobile";
import { ITargetValue, IValidation } from "../../interfaces/form";
import { IAlert } from "../../interfaces/alert";
import { trimValues } from "../../utils/form";
import { validateInputs } from "../../utils/inputValidations";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {
  IUserDetailsState,
  IUserInfoErrors,
} from "../../interfaces/usersRoles";
import { editUser } from "../../requests/usersRequests";
import { IUserResponses } from "../../interfaces/userContext";
import { userDetailsReducer } from "../../reducers/userDetailsReducer";

const { nameRequired } = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "name",
    type: "required",
    message: nameRequired,
  },
];

const getUserDetailsBaseStates = ({
  name,
  email,
  roles,
}: IUserResponses): IUserDetailsState => ({
  values: {
    name,
    email,
    roles,
  },
  errors: {} as IUserInfoErrors,
  alert: {} as IAlert,
  unsavedChanges: false,
  openConfirmationModal: false,
  disableInputs: true,
  disableSubmit: true,
});

const UserDetails = () => {
  const { currentUser, refreshUserData } = useContext(UserContext);
  const [userDetailsStates, dispatch] = useReducer(
    userDetailsReducer,
    getUserDetailsBaseStates(currentUser)
  );
  const { width } = useWindowSize();

  const handleChange = ({ name: keyName, value }: ITargetValue) => {
    dispatch({ type: "unsavedChanges", value: true });
    dispatch({ type: "disableSubmit", value: false });
    dispatch({
      type: "values",
      value: { ...userDetailsStates.values, [keyName]: value },
    });
  };

  const handleCancel = () => {
    if (userDetailsStates.unsavedChanges)
      dispatch({ type: "openConfirmationModal", value: true });
    else dispatch({ type: "disableInputs", value: true });
  };

  const handleCloseModal = () =>
    dispatch({ type: "openConfirmationModal", value: false });

  const handleSubmit = async () => {
    const trimmedValues = trimValues(userDetailsStates.values);
    const inputErrors = validateInputs(trimmedValues, formValidations);
    dispatch({ type: "errors", value: inputErrors });
    dispatch({ type: "unsavedChanges", value: false });
    dispatch({ type: "disableSubmit", value: true });

    if (Object.keys(inputErrors).length === 0)
      try {
        await editUser({ name: userDetailsStates.values.name });
        refreshUserData();
      } catch (error: any) {
        dispatch({
          type: "alert",
          value: { typeOfAlert: ERROR, message: error, showAlert: true },
        });
      } finally {
        dispatch({ type: "disableInputs", value: true });
      }
  };

  const handleEdit = () => {
    dispatch({ type: "disableInputs", value: false });
    dispatch({ type: "alert", value: {} as IAlert });
  };

  const userInfoProps = {
    values: userDetailsStates.values,
    errors: userDetailsStates.errors,
    alert: userDetailsStates.alert,
    disableInputs: userDetailsStates.disableInputs,
    disableSubmit: userDetailsStates.disableSubmit,
    handleChange,
    handleEdit,
    handleCancel,
    handleSubmit,
  };

  return (
    <>
      {width > MEDIUM_BREAKPOINT ? (
        <UserInfoDesktop {...userInfoProps} />
      ) : (
        <UserInfoMobile {...userInfoProps} />
      )}
      <ConfirmationModal
        open={userDetailsStates.openConfirmationModal}
        onClose={handleCloseModal}
        // TODO FIX THIS - Mich
        // lo que mich quiso decir con este expresivo comentario es que hay que añadir
        // un estado aparte para guardar los cambios del usuario en vez de modificar
        // los datos iniciales. de esa forma, tenemos una forma de revertir los cambios
        // si el usuario decide (y confirma) cancelar la edición. - Teo
        onSubmit={() => location.reload()}
      />
    </>
  );
};

export default UserDetails;
