import React, { useContext, useReducer, useState } from "react";
import { UserContext } from "../contexts/userContext";
import HomeHeader from "../components/home/HomeHeader";
import MetricsContainer from "../components/metrics/MetricsContainer";
import SummaryOL from "../components/metrics/SummaryOL";
import OrdersSummary from "../components/metrics/OrdersSummary";
import SummaryMerchant from "../components/metrics/SummaryMerchant";
import {
  ERROR,
  INTERNAL,
  MARKETPLACE,
  METRICS_ENTITY_CONTROL,
} from "../utils/constants";
import SummaryShipments from "../components/metrics/SummaryShipments";
import TotalOrdersByOLSummary from "../components/metrics/TotalOrdersByOLSummary";
import { metricsReducer } from "../reducers/metricsReducer";
import { getMetrics } from "../requests/metricsRequests";
import GraphSkeleton from "../components/metrics/GraphSkeleton";
import { IMetricsStatusAndSLA } from "../interfaces/metrics";
import { IHomeDateFilter } from "../interfaces/home";
import { IAlert } from "../interfaces/alert";
import GenericAlert from "../components/GenericAlert";

const metricsDefaultValues = {
  ordersSummary: null,
  totalCount: null,
  statusAndSLA: null,
  statusOrdersCount: null,
  marketplaceOrders: null,
  clientsCount: null,
};

const Home = () => {
  const {
    currentUser: { entity },
  } = useContext(UserContext);
  const [alert, setAlert] = useState({} as IAlert);
  const [metricsState, metricsDispatch] = useReducer(
    metricsReducer,
    metricsDefaultValues
  );

  const getMetricsByType = async (
    metricsType: string,
    dateFilter: IHomeDateFilter,
    selectedMarketplace?: string
  ) => {
    try {
      const result = await getMetrics(
        metricsType,
        dateFilter,
        entity === INTERNAL,
        selectedMarketplace
      );
      metricsDispatch({ type: metricsType, content: result });
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };

  const handleGetMetrics = (
    dateFilter: IHomeDateFilter,
    selectedMarketplace?: string
  ) => {
    metricsDispatch({
      type: "defaultValue",
      content: metricsDefaultValues,
    });

    const metricRequestsByEntity = METRICS_ENTITY_CONTROL[entity];

    metricRequestsByEntity.forEach((metricsType: string) =>
      getMetricsByType(metricsType, dateFilter, selectedMarketplace)
    );
  };

  return (
    <>
      <HomeHeader
        metricsState={metricsState}
        handleGetMetrics={handleGetMetrics}
      />
      {alert.showAlert && <GenericAlert alert={alert} />}
      <SummaryShipments
        shipmentsData={
          entity === INTERNAL
            ? metricsState.clientsCount
            : metricsState.totalCount
        }
      />
      <MetricsContainer title="Métricas de envíos">
        <>
          {entity !== INTERNAL && (
            <OrdersSummary
              ordersInfo={metricsState.statusOrdersCount}
              entity={entity}
            />
          )}
        </>
        <>
          {entity === MARKETPLACE && (
            <SummaryMerchant summary={metricsState.marketplaceOrders} />
          )}
        </>
        <TotalOrdersByOLSummary
          olsInfo={metricsState.ordersSummary}
          entity={entity}
        />
      </MetricsContainer>
      <>
        {entity !== INTERNAL && (
          <MetricsContainer title="Dashboard de envíos">
            {metricsState.statusAndSLA ? (
              <>
                {metricsState.statusAndSLA.map(
                  (ol_info: IMetricsStatusAndSLA) => (
                    <SummaryOL {...ol_info} />
                  )
                )}
              </>
            ) : (
              <>
                {[...Array(2)].map((_, i) => (
                  <GraphSkeleton key={i} rows={5} />
                ))}
              </>
            )}
          </MetricsContainer>
        )}
      </>
    </>
  );
};

export default Home;
