import React from "react";
import { Alert, IconButton } from "@mui/material";
import { IAlertProps } from "../interfaces/alert";

const GenericAlert = ({
  alert: { typeOfAlert, message },
  handleActionAlert,
}: IAlertProps) => (
  <Alert
    className={`m-t-10 alertIcon${typeOfAlert}`}
    severity={typeOfAlert}
    action={
      handleActionAlert && (
        <IconButton aria-label="close" size="small" onClick={handleActionAlert}>
          <span className="material-symbols-rounded">close</span>
        </IconButton>
      )
    }
  >
    {message}
  </Alert>
);

export default GenericAlert;
