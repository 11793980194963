import React, { ChangeEvent, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  FormControl,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Tooltip,
  TableHead,
} from "@mui/material";
import {
  ERROR,
  ERRORS_MESSAGES,
  SUCCESS,
  TIME_DELAY,
} from "../../utils/constants";
import { validateInputs } from "../../utils/inputValidations";
import { showInputErrors } from "../../utils/credentials";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import { sendMerchantInvitations } from "../../requests/merchantInvitationRequests";
import { IInitialConfigStepWithNextAndBackButton } from "../../interfaces/configuration";
import { LoadingButton } from "@mui/lab";
import { IValidation } from "../../interfaces/form";

const { emailInvalid } = ERRORS_MESSAGES;
const formValidation: IValidation[] = [
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
];

const MerchantInvitations = ({
  handleNext,
  handleBack,
}: IInitialConfigStepWithNextAndBackButton) => {
  const [merchantEmail, setMerchantEmail] = useState("");
  const [listMerchants, setListMerchants] = useState([] as string[]);
  const [errors, setErrors] = useState({} as { email: string[] });
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(false);

  const handleAddMerchant = () => {
    const inputError = validateInputs({ email: merchantEmail }, formValidation);
    setErrors(inputError);
    if (Object.keys(inputError).length === 0) {
      setListMerchants([merchantEmail, ...listMerchants]);
      setMerchantEmail("");
    }
  };

  const handleDeleteEmail = (emailToDelete: string) => {
    const filteredEmails = listMerchants.filter(
      (emailInList: string) => emailInList !== emailToDelete
    );

    setListMerchants(filteredEmails);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { message } = await sendMerchantInvitations(listMerchants);
      setAlert({ typeOfAlert: SUCCESS, message, showAlert: true });
      setTimeout(handleNext, TIME_DELAY);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      setLoading(false);
    }
  };

  return (
    <Card className="br-20 bg-input" sx={{ padding: 3, maxWidth: "615px" }}>
      <CardContent>
        <Typography className="f-s-24">Merchants</Typography>
        <Typography className="f-s-14">
          Invita a los merchants para que formen parte de tu plataforma. Al
          terminar este proceso podrás añadir otros en la sección de “Gestión de
          merchants”.
        </Typography>

        <Box display="flex" className="tracking-input m-t-30">
          <FormControl>
            <OutlinedInput
              autoFocus
              sx={{
                minWidth: "446px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
                  borderWidth: 1,
                },
              }}
              onKeyUp={(e) => e.key === "Enter" && handleAddMerchant()}
              size="small"
              className="bg-input"
              value={merchantEmail}
              placeholder="Ingresa el correo electrónico"
              error={errors.email?.length > 0}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => setMerchantEmail(value)}
            />
            {errors.email?.length > 0 && showInputErrors(errors.email)}
          </FormControl>

          <Button
            variant="contained"
            onClick={handleAddMerchant}
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              maxHeight: 40,
            }}
          >
            Añadir merchant
          </Button>
        </Box>

        {alert.showAlert && <GenericAlert alert={alert} />}

        {listMerchants.length > 0 && (
          <TableContainer className="m-t-30 bg-input">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="p-y-20 p-l-20 text-bold">
                    Correo electronico
                  </TableCell>
                  <TableCell className="p-y-20 text-bold" align="center">
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listMerchants.map((email) => (
                  <TableRow>
                    <TableCell>{email}</TableCell>
                    <TableCell align="center">
                      <Tooltip arrow title="Cancelar" placement="top">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => handleDeleteEmail(email)}
                        >
                          <span className="material-symbols-rounded f-s-20">
                            close
                          </span>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Grid container className="m-t-50" gap={2} flexWrap="nowrap">
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" onClick={handleBack}>
              Volver
            </Button>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              disabled={listMerchants.length === 0 || loading}
              onClick={handleSubmit}
              loading={loading}
              loadingPosition="end"
            >
              Continuar
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MerchantInvitations;
