import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { IEmailConfirmationStep } from "../../interfaces/signup";
import { useNavigate } from "react-router-dom";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import { setDisabledFalse, handleResendEmail } from "../../utils/email";
import { AUTHENTICATION_PATH } from "../../utils/constants";

const EmailConfirmation = ({
  setGridSize,
  selectedValues: { email },
}: IEmailConfirmationStep) => {
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState({} as IAlert);
  const navigate = useNavigate();

  useEffect(() => {
    setGridSize(4);
    setDisabledFalse(setDisabled);
  }, []);
  return (
    <>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <Typography className="f-s-14 m-t-40">
        Revisá el email que te enviamos a {email} para validar tu cuenta.
      </Typography>

      <Button
        variant="contained"
        className="m-t-40 w-100"
        disabled={disabled}
        onClick={() => handleResendEmail(setDisabled, email, setAlert)}
      >
        No me llegó ningún e-mail
      </Button>
      <Button
        variant="text"
        className="m-t-20 w-100"
        onClick={() => navigate(AUTHENTICATION_PATH)}
      >
        Volver al inicio
      </Button>
    </>
  );
};

export default EmailConfirmation;
