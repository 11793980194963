import React, { useState, useEffect, useContext } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import {
  ERRORS_MESSAGES,
  ERROR_FROM_GREATER_THAN_TO,
  ERROR_TO_GREATER_THAN_FROM,
  MERCHANT,
} from "../../utils/constants";
import { compareDates } from "../../utils/orders";
import {
  IHomeDateFilter,
  IHomeFilterErrors,
  IHomeHeader,
} from "../../interfaces/home";
import { IValidation } from "../../interfaces/form";
import { validateInputs } from "../../utils/inputValidations";
import { DateTime } from "luxon";
import FilterDatePicker from "./FilterDatePicker";
import { getInitialDates } from "../../utils/home";
import { UserContext } from "../../contexts/userContext";

const { fromDateRequired, toDateRequired } = ERRORS_MESSAGES;

const dateValidations: IValidation[] = [
  {
    id: "from",
    type: "required",
    message: fromDateRequired,
  },
  {
    id: "to",
    type: "required",
    message: toDateRequired,
  },
  {
    id: "from",
    type: "compare-dates",
    message: ERROR_FROM_GREATER_THAN_TO,
    param: "to",
  },
  {
    id: "from",
    type: "compare-dates",
    message: ERROR_TO_GREATER_THAN_FROM,
    param: "to",
  },
];

const HomeHeader = ({ metricsState, handleGetMetrics }: IHomeHeader) => {
  const {
    currentUser: { entity },
    selectedMarketplace,
  } = useContext(UserContext);
  const [filter, setFilter] = useState<IHomeDateFilter>(getInitialDates);
  const [errors, setErrors] = useState({} as IHomeFilterErrors);
  const [disableButton, setDisableButton] = useState(true);

  const handleDateChange = (keyName: string, date: DateTime) => {
    setFilter({ ...filter, [keyName]: date });

    const fromValue = keyName === "from" ? date : filter.from;
    const toValue = keyName === "from" ? filter.to : date;

    const isDateValid = compareDates(fromValue, toValue);

    setErrors({
      ...errors,
      invalidfrom: keyName === "from" && !isDateValid,
      invalidto: keyName === "to" && !isDateValid,
    });
  };

  const handleSubmit = () => {
    const inputErrors = validateInputs(filter, dateValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      handleGetMetrics(filter, selectedMarketplace);
      setDisableButton(true);
    }
  };

  useEffect(() => {
    setDisableButton(false);
  }, [metricsState]);

  useEffect(() => {
    if (selectedMarketplace) {
      // reset values
      const initialDates = getInitialDates();
      setFilter(initialDates);
      handleGetMetrics(initialDates, selectedMarketplace);
    }
  }, [selectedMarketplace]);

  useEffect(() => {
    if (entity !== MERCHANT) handleGetMetrics(filter);
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={2} lg={6}>
          <Typography className="f-s-20">Inicio</Typography>
        </Grid>
        <Grid item container xs={10} lg={6} justifyContent="end">
          <Grid item lg={4}>
            <FilterDatePicker
              label={"Desde"}
              name={"from"}
              handleDateChange={handleDateChange}
              filter={filter}
              errors={errors}
            />
          </Grid>
          <Grid item lg={4} ml={2}>
            <FilterDatePicker
              label={"Hasta"}
              name={"to"}
              handleDateChange={handleDateChange}
              filter={filter}
              errors={errors}
            />
          </Grid>
          <Grid item lg={1.5} ml={2}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              disabled={disableButton}
              sx={{ padding: "8px" }}
              onClick={handleSubmit}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider className="m-t-20" />
    </>
  );
};

export default HomeHeader;
