import React from "react";
import { Grid } from "@mui/material";
import { MEDIUM } from "../../utils/constants";
import ListRoles from "../../components/tables/ListRoles";
import Loader from "../../components/Loader";
import GenericAlert from "../../components/GenericAlert";
import { IRole } from "../../interfaces/usersRoles";
import SearchRoles from "../../components/usersRoles/SearchRoles";
import { IAlert } from "../../interfaces/alert";

const RolesLoader = () => (
  <Grid item xs={12}>
    <Loader size={MEDIUM} />
  </Grid>
);

const Roles = ({
  roles,
  alert,
  handleGetRoles,
}: {
  roles: IRole[];
  alert: IAlert;
  handleGetRoles(_searchValue: string): void;
}) => (
  <Grid container className="m-t-30">
    <Grid item md={5} lg={3}>
      <SearchRoles handleGetRoles={handleGetRoles} />
    </Grid>
    {alert.showAlert && (
      <Grid item xs={12}>
        <GenericAlert alert={alert} />
      </Grid>
    )}
    {roles.length > 0 && (
      <Grid item xs={10}>
        <ListRoles roles={roles} />
      </Grid>
    )}
    {!roles.length && !alert.showAlert && <RolesLoader />}
  </Grid>
);

export default Roles;
