import { Card, CardContent, Grid, Skeleton } from "@mui/material";

const CardSkeleton = () => (
  <Grid item xs={6} md={4} lg={2.4}>
    <Card className="br-5 bg-light">
      <CardContent
        sx={{ paddingBlock: "15px !important", paddingInline: "20px" }}
      >
        <Grid container flexDirection="column">
          <Grid item xs={7} className="m-b-10">
            <Skeleton variant="rounded" width="100%" height={11} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="rounded" width="100%" height={20} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default CardSkeleton;
