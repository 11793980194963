import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { IMetricsContainer } from "../../interfaces/metrics";
import "../../styles/metrics.css";

const MetricsContainer = ({ children, title }: IMetricsContainer) => (
  <Grid container justifyContent="center" alignItems="center">
    <Box className="bg-light br-10 w-100 m-t-40 metrics-container">
      <Typography className="f-s-20">{title}</Typography>
      <Divider className="m-t-20" />
      <Grid container flexDirection="row">
        {children}
      </Grid>
    </Box>
  </Grid>
);

export default MetricsContainer;
