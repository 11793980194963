import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IAlert } from "../../interfaces/alert";
import { IOrderRaw } from "../../interfaces/order";
import { GET, ERROR, MEDIUM_BREAKPOINT } from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { useWindowSize } from "../../customHooks/useWindowSize";
import OrderDetailsDesktop from "../../components/orders/OrderDetailsDesktop";
import OrderDetailsMobile from "../../components/orders/OrderDetailsMobile";
import { UserContext } from "../../contexts/userContext";

const OrderDetails = () => {
  const { selectedMarketplace } =
    useContext(UserContext);
  const [order, setOrder] = useState({} as IOrderRaw);
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(true);
  const { trackingNumber } = useParams();
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const getOrder = async () => {
    try {
      const response = await fetchData({
        url: `/orders/${trackingNumber}${
          selectedMarketplace && `?marketplaceId=${selectedMarketplace}`
        }`,
        method: GET,
      });

      setOrder(response);
    } catch (error: any) {
      setAlert({
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      {width > MEDIUM_BREAKPOINT ? (
        <OrderDetailsDesktop
          order={order}
          alert={alert}
          loading={loading}
          navigate={navigate}
          trackingNumber={trackingNumber}
        />
      ) : (
        <OrderDetailsMobile
          order={order}
          alert={alert}
          loading={loading}
          navigate={navigate}
          trackingNumber={trackingNumber}
        />
      )}
    </>
  );
};

export default OrderDetails;
