import React, { ChangeEvent, useState } from "react";
import { Button, FormControl, OutlinedInput } from "@mui/material";
import { useDebounce } from "../../customHooks/useDebounce";
import {
  ISearchCarrierValue,
  ISearchCarriers,
} from "../../interfaces/configuration";

const SearchCarriers = ({ handleGetCarriers }: ISearchCarriers) => {
  const [searchValue, setSearchValue] = useState({} as ISearchCarrierValue);

  const { setSubmitting } = useDebounce(() => {
    if (searchValue.content) {
      setSearchValue({ ...searchValue, activeSearch: true });
      handleGetCarriers(searchValue.content);
    }
  });

  const { setSubmitting: setSubmittingClose } = useDebounce(() => {
    setSearchValue({ content: "", activeSearch: false });
    handleGetCarriers();
  });

  return (
    <FormControl size="small" fullWidth>
      <OutlinedInput
        placeholder="Buscar por operador logístico"
        className="f-s-14"
        value={searchValue.content}
        onKeyUp={(e) => e.key === "Enter" && setSubmitting(true)}
        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
          setSearchValue({ ...searchValue, content: value });
        }}
        endAdornment={
          <Button
            className="search-input-adornment"
            onClick={() =>
              searchValue.activeSearch
                ? setSubmittingClose(true)
                : setSubmitting(true)
            }
          >
            <span className="material-symbols-rounded text-muted f-s-24">
              {searchValue.activeSearch ? "close" : "search"}
            </span>
          </Button>
        }
      />
    </FormControl>
  );
};

export default SearchCarriers;
