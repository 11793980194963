import React from "react";
import { Tooltip } from "@mui/material";

const CustomTooltip = ({
  title,
  children,
}: {
  title: string | React.ReactElement;
  children: any;
}) => {
  return (
    <Tooltip
      title={title}
      arrow
      classes={{
        tooltip: "bg-input text-dark m-t-5 tooltip",
        arrow: "text-white",
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
