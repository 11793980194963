import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { IOrderPackageDescription } from "../../interfaces/order";
import OrderPackageContent from "./OrderPackageContent";

const OrderPackageDescription = ({
  packages,
  isMobile,
}: IOrderPackageDescription) => (
  <Grid item xs={12} mt={3.75}>
    <Typography className="f-s-20 text-dark m-b-20">Paquetes</Typography>
    {isMobile ? (
      <OrderPackageContent packages={packages} />
    ) : (
      <Card className="br-20 bg-light">
        <CardContent sx={{ padding: "20px" }}>
          <OrderPackageContent packages={packages} />
        </CardContent>
      </Card>
    )}
  </Grid>
);

export default OrderPackageDescription;
