import {
  Typography,
  FormHelperText,
  FormControl,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import React, { ChangeEvent, useEffect, useState } from "react";
import { IAlert } from "../../interfaces/alert";
import { ITargetValue, IValidation } from "../../interfaces/form";
import { IAccountConfigStep } from "../../interfaces/signup";
import {
  ERROR,
  ERRORS_MESSAGES,
  GET,
  PASSWORD_HELPER,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { validateInputs } from "../../utils/inputValidations";
import GenericAlert from "../GenericAlert";
import GenericForm from "../GenericForm";

const { passwordInvalid, passwordRequired, emailInvalid, emailRequired } =
  ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "email",
    type: "required",
    message: emailRequired,
  },
  {
    id: "email",
    type: "email",
    message: emailInvalid,
  },
  {
    id: "password",
    type: "required",
    message: passwordRequired,
  },
  {
    id: "password",
    type: "password",
    message: passwordInvalid,
  },
];

const AccountConfigForm = ({
  selectedValues,
  setSelectedValues,
  setGridSize,
  goForward,
  token,
}: IAccountConfigStep) => {
  const [showPassword, setshowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: [], password: [] });
  const [alert, setAlert] = useState({} as IAlert);
  // TÉRMINOS Y CONDICIONES OCULTADOS TEMPORALMENTE - ver https://app.clickup.com/t/86b18e9jk
  // const [termsAndConditions, setTermsAndConditions] = useState(false);
  const handleChange = ({ name, value }: ITargetValue) =>
    setSelectedValues({
      ...selectedValues,
      [name]: value,
    });

  const handleSubmit = () => {
    const inputErrors = validateInputs(selectedValues, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) goForward();
  };

  const getMerchantEmailFromInvitationToken = async () => {
    try {
      const merchantEmail = await fetchData({
        url: `/marketplaces_merchants/getEmailFromInvitationToken/${token}`,
        method: GET,
      });
      // by default user needs to having preloaded billingEmail with the email that appears on invitation
      setSelectedValues({
        ...selectedValues,
        email: merchantEmail,
        billingEmail: merchantEmail,
      });
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };
  useEffect(() => {
    setGridSize(4);
    getMerchantEmailFromInvitationToken();
  }, []);
  return (
    <>
      {alert.showAlert && <GenericAlert alert={alert} />}
      <GenericForm
        onSubmit={handleSubmit}
        disableSubmit={alert.showAlert}
      >
        <FormControl className="m-t-20" fullWidth>
          <Typography id="email-label" className="f-s-14 m-b-10">
            Correo electrónico
          </Typography>
          <OutlinedInput
            type="text"
            size="small"
            name="email"
            placeholder="Ingresar correo electrónico"
            value={selectedValues.email}
            error={errors.email?.length > 0}
            disabled
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              handleChange(target)
            }
            autoFocus
          />
          {errors.email?.length > 0 &&
            errors.email.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </FormControl>

        <FormControl className="m-t-20" fullWidth>
          <Typography id="password-label" className="f-s-14 m-b-10">
            Contraseña
          </Typography>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            size="small"
            name="password"
            placeholder="Ingresar contraseña"
            value={selectedValues.password}
            error={errors.password?.length > 0}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              handleChange(target)
            }
            endAdornment={
              <InputAdornment position="start">
                <IconButton
                  onClick={() => setshowPassword(!showPassword)}
                  edge="end"
                >
                  <span className="material-symbols-rounded f-s-18">
                    {showPassword ? "visibility" : "visibility_off"}
                  </span>
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{PASSWORD_HELPER}</FormHelperText>
          {errors.password?.length > 0 &&
            errors.password.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </FormControl>
      </GenericForm>

      {/* <TermsAndConditions
        setTermsAndConditions={setTermsAndConditions}
        termsAndConditions={termsAndConditions}
      /> */}

      <Button
        variant="contained"
        className="m-t-40 w-100"
        onClick={handleSubmit}
        disabled={alert.showAlert}
      >
        Continuar
      </Button>
    </>
  );
};

export default AccountConfigForm;
