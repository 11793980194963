import { useContext, useEffect, useReducer, Dispatch } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Navbar from "./navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import {
  MERCHANT,
  MEDIUM_BREAKPOINT,
  MARKETPLACE,
  NOTIFICATIONS_PATH,
  INTERNAL,
} from "../utils/constants";
import "../styles/layout.css";
import { UserContext } from "../contexts/userContext";
import { IAlert, IBanner } from "../interfaces/alert";
import { getSelectedMarketplaceName } from "../utils/storage";
import GenericAlert from "./GenericAlert";
import SidebarMobile from "./Sidebar/SidebarMobile";
import { useWindowSize } from "../customHooks/useWindowSize";
import { checkIfPathIsAbleToShowIcons } from "../utils/navigation";
import Banner from "./Banner";
import { mainLayoutReducer } from "../reducers/mainLayoutReducer";
import { IMainLayoutState } from "../interfaces/layout";
import { NotificationsContext } from "../contexts/notificationContext";
import { Socket } from "socket.io-client";

const listenBannerNotifications = (
  socket: Socket,
  dispatch: Dispatch<any>,
  selectedMarketplaceId: boolean | string
) => {
  socket.on(
    "list-banners",
    ({
      banners,
    }: {
      banners: {
        message_type: string;
        message: string;
        marketplace_id?: number;
      }[];
    }) => {
      // const parsedBanners = banners.map(({ message_type, message }) => ({
      //   _id: message_type,
      //   message,
      // }));
      const parsedBanners = banners.reduce(
        (
          bannersAccum: { _id: string; message: string }[],
          { message_type, message, marketplace_id }
        ) => {
          if (
            !selectedMarketplaceId ||
            marketplace_id === Number(selectedMarketplaceId)
          )
            return bannersAccum.concat({
              _id: message_type,
              message,
            });
          return bannersAccum;
        },
        []
      );
      dispatch({
        type: "setBanner",
        value: parsedBanners,
      });
    }
  );
};

const showBanners = (banners: IBanner[]) =>
  banners.map((banner: IBanner) => <Banner banner={banner} />);

const mainLayoutStates: IMainLayoutState = {
  openSideBar: false,
  showIcons: true,
  alert: {} as IAlert,
  banners: [] as IBanner[],
  loading: false,
};

const marketplaceName = getSelectedMarketplaceName() as string;

const MainLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const {
    merchantIntegrations,
    selectedMarketplace,
    currentUser: { entity, hasCarriers },
    showSkeleton,
  } = useContext(UserContext);
  const { socket, isConnected, getBanners } = useContext(NotificationsContext);
  const [layoutStates, dispatch] = useReducer(
    mainLayoutReducer,
    mainLayoutStates
  );

  const handleSidebarToggle = () =>
    dispatch({ type: "openSideBar", value: !layoutStates.openSideBar });

  const handleCloseSidebar = () =>
    dispatch({ type: "openSideBar", value: false });

  useEffect(() => {
    dispatch({
      type: "showIcons",
      value: checkIfPathIsAbleToShowIcons(pathname),
    });
    const goToInitialConfiguration =
      entity && entity !== MERCHANT && entity !== INTERNAL && !hasCarriers;
    if (goToInitialConfiguration)
      navigate(
        `/configuracion-inicial-${
          entity === MARKETPLACE ? "tienda" : "ecommerce"
        }`
      );
    if (!checkIfPathIsAbleToShowIcons(pathname)) handleCloseSidebar();
  }, [hasCarriers, pathname]);

  useEffect(() => {
    if (entity === MERCHANT) dispatch({ type: "setBanner", value: [] });
  }, [selectedMarketplace, merchantIntegrations, marketplaceName]);

  useEffect(() => {
    const isMerchant = entity === MERCHANT;
    if (isConnected) {
      listenBannerNotifications(
        socket,
        dispatch,
        isMerchant && selectedMarketplace
      );
      getBanners(selectedMarketplace);
    }
  }, [isConnected, selectedMarketplace]);

  return (
    <Box className="d-flex">
      <Navbar
        showContent={layoutStates.showIcons}
        toggleSidebar={handleSidebarToggle}
      />
      {width > MEDIUM_BREAKPOINT ? (
        <Sidebar
          showContent={layoutStates.showIcons}
          open={layoutStates.openSideBar}
        />
      ) : (
        <SidebarMobile
          showContent={layoutStates.showIcons}
          open={layoutStates.openSideBar}
          setOpen={() =>
            dispatch({ type: "openSideBar", value: !layoutStates.openSideBar })
          }
        />
      )}
      <Box
        component="div"
        className={`main-container ${
          pathname.endsWith(NOTIFICATIONS_PATH) ? "override-margin-bottom" : ""
        }`}
        marginTop={8}
      >
        {layoutStates.banners.length > 0 &&
          !showSkeleton &&
          showBanners(layoutStates.banners)}
        {!showSkeleton && (
          <Box
            sx={{
              paddingInline: pathname.endsWith(NOTIFICATIONS_PATH)
                ? { xs: "0", md: "0" }
                : { xs: "1.5rem", md: "2.5rem" },
              height: "100%",
            }}
          >
            {layoutStates.alert.showAlert && (
              <Box marginBottom={3}>
                <GenericAlert alert={layoutStates.alert} />
              </Box>
            )}
            <Outlet context={[dispatch, showSkeleton]} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;
