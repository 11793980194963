import { Button } from "@mui/material";
import { IFileUploaderProps } from "../../interfaces/modal";
import "../../styles/form.css";
import InputErrors from "./InputErrors";

const FileUpload = ({
  text,
  indications,
  handleUpload,
  errors,
  uploadedFileName,
}: IFileUploaderProps) => (
  <div className="fileUpload">
    <Button variant="contained" component="label">
      {text}
      <input type="file" onChange={handleUpload} hidden />
    </Button>
    {errors?.length > 0 && <InputErrors errors={errors} />}
    {uploadedFileName && <p>{uploadedFileName}</p>}
    {indications && <span>{indications}</span>}
  </div>
);

export default FileUpload;
