import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import GenericAlert from "../GenericAlert";
import { IRecoverPasswordConfirmation } from "../../interfaces/recoverPassword";
import { setDisabledFalse, handleResendEmail } from "../../utils/email";
import { AUTHENTICATION_PATH } from "../../utils/constants";

const RecoverPasswordConfirmation = ({
  alert,
  setAlert,
  navigate,
  email,
}: IRecoverPasswordConfirmation) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabledFalse(setDisabled);
  }, []);

  return (
    <>
      <Grid item xs={12}>
        {alert.showAlert && <GenericAlert alert={alert} />}
        <Typography className="f-s-14 m-t-20">
          Verifica la bandeja de entrada de {email} para restablecer tu
          contraseña.
        </Typography>
      </Grid>

      <Grid item xs className="m-t-100">
        <Button
          fullWidth
          variant="contained"
          disabled={disabled}
          onClick={() => handleResendEmail(setDisabled, email, setAlert)}
        >
          No me llegó ningún e-mail
        </Button>
      </Grid>
      <Grid item xs={12} className="m-t-10">
        <Button
          fullWidth
          variant="text"
          onClick={() => navigate(AUTHENTICATION_PATH)}
        >
          Volver al inicio
        </Button>
      </Grid>
    </>
  );
};

export default RecoverPasswordConfirmation;
