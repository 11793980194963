import { Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { IAlert } from "../../interfaces/alert";
import { IDisableIntegrationModal } from "../../interfaces/modal";
import { joinByComaAndAnd } from "../../utils/text";
import GenericAlert from "../GenericAlert";
import ModalBase from "./ModalBase";

const DisableMerchantsModal = ({
  open,
  setOpen,
  submitDisable,
  merchants,
}: IDisableIntegrationModal) => {
  const [alertDelete, setAlertDelete] = useState({} as IAlert);
  const [disableButton, setDisableButton] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAlertDelete({} as IAlert);
    setDisableButton(false);
  };

  const handleSubmit = () => {
    submitDisable();
    handleClose();
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <div className="w-100">
          {alertDelete.showAlert && <GenericAlert alert={alertDelete} />}
        </div>
        <Typography className="f-s-20 m-t-50 m-b-50 text-center">
          ¿Seguro de que deseas pausar a {joinByComaAndAnd(merchants)}? Si
          realizas esta acción
          {merchants.length === 1
            ? " el merchant no podrá"
            : " los merchants no podrán"}{" "}
          continuar operando.
        </Typography>

        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            disabled={disableButton}
            onClick={handleSubmit}
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default DisableMerchantsModal;
