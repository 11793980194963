import { Route } from "react-router-dom";
import ShipmentTrackingSearch from "../components/landing/ShipmentTrackingSearch";
import ShipmentTracking from "../components/landing/ShipmentTracking";
import { authRoutes } from "./authRoutes";
import LandingLayout from "../components/LandingLayout";
import Contact from "../pages/contact/Contact";
import Landing from "../pages/Landing";
import Partners from "../pages/partners/Partners";

export const publicRoutes = (
  <>
    <Route path="/health" element={<>Backoffice online!</>} />
    <Route path="/" element={<LandingLayout />}>
      <Route index element={<Landing />} />
      <Route path="contacto" element={<Contact />} />
      <Route path="partners" element={<Partners />} />
      <Route path="seguimiento-de-envios" element={<ShipmentTrackingSearch />} />
      <Route path="seguimiento-de-envios/:trackingNumber" element={<ShipmentTracking />} />
      <Route path="terminos-y-condiciones" element={<>Términos y condiciones</>} />
      <Route path="politicas-de-privacidad" element={<>Políticas de privacidad</>} />
    </Route>
    {authRoutes}
  </>
);
