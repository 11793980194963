import { useContext } from "react";
import { Box, Divider, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMenuMobile, INavbarItem } from "../../interfaces/navbar";
import { MERCHANT } from "../../utils/constants";
import MobileLayout from "../MobileLayout";
import { UserContext } from "../../contexts/userContext";

const MenuMobile = ({ open, setOpen, getAvatar, navbarOptions, showAll }: IMenuMobile) => {
  const navigate = useNavigate();
  const {
    currentUser: { entity, name },
    logout,
  } = useContext(UserContext);

  const handleNavigate = (slug: string) => {
    navigate(slug);
    setOpen(false);
  };

  const handleClose = () => setOpen(false);
  return (
    <MobileLayout title="Menú" open={open} handleClose={handleClose}>
      <>
        <Box className="text-center m-t-20">
          <>
            {getAvatar(name, "navbar__menu__avatar")}
            <Typography className="f-s-20 m-b-20 m-t-10">{name}</Typography>
          </>
        </Box>
        <Divider variant="middle" flexItem light />
        {entity === MERCHANT && (
          <MenuItem
            component="a"
            onClick={() => handleNavigate("gestion-marketplaces")}
          >
            <span className="material-symbols-rounded">add_business</span>
            <Typography ml={1} className="f-s-14">
              Gestión de Marketplaces
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          component="a"
          onClick={() => console.log("show notifications")}
        >
          <span className="material-symbols-rounded">notifications</span>
          <Typography ml={1} className="f-s-14">
            Notificaciones
          </Typography>
        </MenuItem>
        {showAll && navbarOptions.map(
          ({ icon, label, slug }: INavbarItem) => (
            <MenuItem
              component="a"
              key={label}
              onClick={() => handleNavigate(slug)}
            >
              <span className="material-symbols-rounded">{icon}</span>
              <Typography ml={1} className="f-s-14">
                {label}
              </Typography>
            </MenuItem>
          )
        )}
        <MenuItem onClick={() => logout()}>
          <span className="material-symbols-rounded">logout</span>
          <Typography ml={1} className="f-s-14">
            Cerrar sesión
          </Typography>
        </MenuItem>
      </>
    </MobileLayout>
  );
};

export default MenuMobile;
