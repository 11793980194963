import React, { SyntheticEvent, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import Users from "../../pages/roles_and_permissions/Users";
import Roles from "../../pages/roles_and_permissions/Roles";
import { IAlert } from "../../interfaces/alert";
import { IRole } from "../../interfaces/usersRoles";
import { ERROR } from "../../utils/constants";
import { getRoles } from "../../requests/roleRequests";

const UsersRolesTabs = () => {
  const [value, setValue] = useState("users");
  const [roles, setRoles] = useState([] as IRole[]);
  const [alert, setAlert] = useState({} as IAlert);
  const handleChange = (_event: SyntheticEvent, newValue: string) =>
    setValue(newValue);

  const handleGetRoles = async () => {
    try {
      const fetchedRoles = await getRoles();
      setRoles(fetchedRoles);
    } catch (error: any) {
      setAlert({
        typeOfAlert: ERROR,
        message: error,
        showAlert: true,
      });
    }
  };

  useEffect(() => {
    handleGetRoles();
  }, []);

  return (
    <>
      <Typography className="f-s-20">Usuarios y roles</Typography>
      <TabContext value={value}>
        <Box
          className="m-t-30"
          sx={{ borderBottom: 1, borderColor: "divider", width: "fit-content" }}
        >
          <TabList onChange={handleChange}>
            <Tab label="Usuarios" value="users" />
            <Tab label="Roles" value="roles" />
          </TabList>
        </Box>
        <TabPanel value="users">
          <Users roles={roles} />
        </TabPanel>
        <TabPanel value="roles">
          <Roles roles={roles} alert={alert} handleGetRoles={handleGetRoles} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default UsersRolesTabs;
