import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import GenericAlert from "../GenericAlert";
import GenericForm from "../GenericForm";
import SaveChangesAccount from "./SaveChangesAccount";
import UserInfoForm from "./UserInfoForm";
import { IUserInfoContent } from "../../interfaces/usersRoles";

const UserInfoContent = ({
  alert,
  errors,
  handleChange,
  values,
  disableInputs,
  disableSubmit,
  handleCancel,
  handleSubmit,
  isMobile,
}: IUserInfoContent) => {
  const userInfoFormProps = {
    errors,
    handleChange,
    userInfo: values,
    disableInputs,
  };

  return (
    <>
      <Grid item xs={12} md={7}>
        {alert.showAlert && <GenericAlert alert={alert} />}
      </Grid>
      <Grid item xs={12} md={7} className="m-t-20">
        {isMobile ? (
          <GenericForm onSubmit={handleSubmit} disableSubmit={disableSubmit}>
            <UserInfoForm {...userInfoFormProps} />
          </GenericForm>
        ) : (
          <Card
            className="br-20 bg-light"
            sx={{ padding: 3, paddingBottom: 5.75 }}
          >
            <CardContent>
              <GenericForm
                onSubmit={handleSubmit}
                disableSubmit={disableSubmit}
              >
                <UserInfoForm {...userInfoFormProps} />
              </GenericForm>
            </CardContent>
          </Card>
        )}
      </Grid>
      {!disableInputs && (
        <Grid item xs={12} md={7}>
          <SaveChangesAccount
            disableSubmit={disableSubmit}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
          />
        </Grid>
      )}
    </>
  );
};

export default UserInfoContent;
