import { IGenericForm } from "../interfaces/form";

const GenericForm = ({
  children,
  disableSubmit = false,
  onSubmit,
  ...props
}: IGenericForm) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form {...props} className="w-100" onSubmit={handleSubmit}>
      {children}
      <button type="submit" hidden disabled={disableSubmit} />
    </form>
  );
};

export default GenericForm;
