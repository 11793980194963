import React from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { disableCurrentDate } from "../../utils/form";
import { showInputErrors } from "../../utils/credentials";
import { DATE_FORMAT, LOCALE } from "../../utils/constants";
import { ISimpleFormInputProps } from "../../interfaces/configuration";

export const SimpleDateInput = ({
  inputTitle,
  valueName,
  values,
  errors,
  setValues,
}: ISimpleFormInputProps) => (
  <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={LOCALE}>
    <FormControl fullWidth>
      <Typography className="f-s-14 m-b-10">{inputTitle}</Typography>
      <DatePicker
        disablePast
        value={values[valueName] || null}
        shouldDisableDate={disableCurrentDate}
        onChange={(newValue: any) => {
          if (!newValue?.invalid)
            setValues({ ...values, [valueName]: newValue });
        }}
        inputFormat={DATE_FORMAT}
        renderInput={(params: any) => (
          <TextField
            fullWidth
            size="small"
            {...params}
            error={errors?.length > 0}
            className="date-picker-svg datePickerHeight"
          />
        )}
      />
      {errors?.length > 0 && showInputErrors(errors)}
    </FormControl>
  </LocalizationProvider>
);
