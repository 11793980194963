import React, { useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
} from "@mui/material";
import { IChangePasswordForm } from "../../interfaces/recoverPassword";
import GenericAlert from "../GenericAlert";
import { AUTHENTICATION_PATH, PASSWORD_HELPER } from "../../utils/constants";

const ChangePasswordForm = ({
  passwordRef,
  confirmPasswordRef,
  errors,
  alert,
  disableButton,
  handleSubmit,
  navigate,
}: IChangePasswordForm) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <Typography className="m-t-10 f-s-20">
          Recupera tu contraseña
        </Typography>
        {alert.showAlert && <GenericAlert alert={alert} />}
      </Grid>

      <Grid item xs={12}>
        <FormControl className="m-t-20" fullWidth>
          <Typography id="password-label" className="f-s-14 m-b-10">Ingresar nueva contraseña</Typography>
          <OutlinedInput
            inputRef={passwordRef}
            type={showPassword ? "text" : "password"}
            size="small"
            name="password"
            placeholder="Ingresar nueva contraseña"
            error={errors.newPassword?.length > 0}
            endAdornment={
              <InputAdornment position="start">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <span className="material-symbols-rounded f-s-18">
                    {showPassword ? "visibility" : "visibility_off"}
                  </span>
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{PASSWORD_HELPER}</FormHelperText>
          {errors.newPassword?.length > 0 &&
            errors.newPassword.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Typography id="password-label" className="f-s-14 m-b-10">Confirmar contraseña</Typography>
          <OutlinedInput
            inputRef={confirmPasswordRef}
            type={showConfirmPassword ? "text" : "password"}
            size="small"
            name="confirmValue"
            placeholder="Confirmar contraseña"
            error={errors.confirmPassword?.length > 0}
            endAdornment={
              <InputAdornment position="start">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  <span className="material-symbols-rounded f-s-18">
                    {showConfirmPassword ? "visibility" : "visibility_off"}
                  </span>
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.confirmPassword?.length > 0 &&
            errors.confirmPassword.map((message: string) => (
              <FormHelperText className="color-error" key={message}>
                {message}
              </FormHelperText>
            ))}
        </FormControl>
      </Grid>

      <Grid item xs className="m-t-40">
        <Button
          fullWidth
          variant="contained"
          disabled={disableButton}
          onClick={handleSubmit}
        >
          Confirmar
        </Button>
      </Grid>
      <Grid item xs={12} className="m-t-10">
        <Button
          fullWidth
          variant="text"
          onClick={() => navigate(AUTHENTICATION_PATH)}
        >
          Volver al inicio
        </Button>
      </Grid>
    </>
  );
};

export default ChangePasswordForm;
