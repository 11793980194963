import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  IconButton,
  Link,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import {
  API_DOCS_URL,
  AUTHENTICATION_PATH,
  FREE_PLAN_SIGNUP_PATH,
  LANDING_NAVIGATION_ANCHORS,
} from "../../utils/constants";
import "../../styles/landing.css";
import { ILandingNavMenu } from "../../interfaces/landing";
import { useNavigate } from "react-router-dom";

const LandingNavMenu = ({ menuRef, freePlan }: ILandingNavMenu) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const { current } = menuRef;
  const currentPath = location.pathname;

  return (
    <>
      <Box
        className="landing-nav"
        component="nav"
        display="flex"
        alignItems="center"
        gap={5}
      >
        {LANDING_NAVIGATION_ANCHORS.map(({ label, href }) => (
          <Link
            key={label}
            href={href}
            className={`${
              currentPath === href ? "color-primary" : "text-dark"
            } f-s-12 f-s-md-14`}
            underline="none"
            target={href === API_DOCS_URL ? "_blank" : "_self"}
          >
            {label}
          </Link>
        ))}
        <Button
          variant="contained"
          onClick={() => navigate(`${FREE_PLAN_SIGNUP_PATH}${freePlan?._id}`)}
          disabled={!freePlan?._id}
        >
          Iniciar prueba gratuita
        </Button>
        <Button
          className="login-btn"
          variant="outlined"
          component={Link}
          href={AUTHENTICATION_PATH}
        >
          Ingresar
        </Button>
      </Box>

      <IconButton
        className="landing-menu-icon"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setOpenMenu(true)}
      >
        <span className="material-symbols-rounded text-dark">menu</span>
      </IconButton>

      <Menu
        open={openMenu}
        anchorEl={current}
        classes={{ paper: "w-100 landing-menu p-menu" }}
        onClose={() => setOpenMenu(false)}
        transitionDuration={100}
      >
        <Typography
          className="color-primary text-bold m-b-20"
          sx={{ fontSize: "20px" }}
        >
          Te damos la bienvenida
        </Typography>
        <Divider className="m-b-20" />
        <Grid container>
          <Link
            underline="none"
            href="/seguimiento-de-envios"
            className="text-dark f-s-12 f-s-md-14 m-b-20"
          >
            Seguí tu envío
          </Link>
        </Grid>
        <Divider className="m-b-20" />
        <Button
          variant="contained"
          component={Link}
          href={AUTHENTICATION_PATH}
          fullWidth
          className="f-s-12"
        >
          Ingresar
        </Button>
      </Menu>
    </>
  );
};

export default LandingNavMenu;
