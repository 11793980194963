import React, { useContext, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Grid, TextField, FormHelperText, Autocomplete } from "@mui/material";
import {
  IGetListOfMerchants,
  IOrderStatus,
  IOrdersFiltersHeader,
  IParsedMerchantIntegration,
} from "../../interfaces/order";
import { DateTimeValidationError } from "@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation";
import {
  DATE_FORMAT,
  ERROR,
  ERROR_FROM_GREATER_THAN_TO,
  ERROR_TO_GREATER_THAN_FROM,
  GET,
  LOCALE,
  MARKETPLACE,
  ORDER_STATUS,
} from "../../utils/constants";
import { UserContext } from "../../contexts/userContext";
import SearchInput from "../SearchInput";
import { compareDates, getOrderStatus } from "../../utils/orders";
import { fetchData } from "../../utils/dataProvider";
import { IAcceptedIntegration } from "../../interfaces/integrations";

const parseMerchantsFromIntegrations = (integrations: IAcceptedIntegration[]) =>
  integrations.map(
    ({
      merchant: {
        id,
        entity: { name },
      },
    }: IAcceptedIntegration) => ({ id, label: name })
  );

const getListOfMerchants = async ({
  setMerchants,
  setAlert,
}: IGetListOfMerchants) => {
  try {
    const { integrations } = await fetchData({
      url: "/marketplaces_merchants/acceptedIntegrationsOfMarketplace",
      method: GET,
    });
    const parsedMerchants = parseMerchantsFromIntegrations(integrations);
    setMerchants(parsedMerchants);
  } catch (error: any) {
    setAlert({
      typeOfAlert: ERROR,
      message: error,
      showAlert: true,
    });
  }
};

const replaceStatusByAlias = (aliases: any, setter: any) => setter(ORDER_STATUS.map(({ label, id, ...rest }) => ({
  ...rest,
  id,
  label: aliases[id] || label,
})));
const FiltersHeader = ({
  handleChangeFilter,
  setAlert,
  aliases
}: IOrdersFiltersHeader) => {
  const {
    currentUser: { entity },
  } = useContext(UserContext);
  const [statusList, setStatusList] = useState<IOrderStatus[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [picker, setPicker] = useState({ fromDate: null, toDate: null });
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [merchants, setMerchants] = useState<IParsedMerchantIntegration[]>([]);
  const [errors, setErrors] = useState({
    from: false,
    to: false,
    invalidFrom: false,
    invalidTo: false,
  });
  const validateDates = (keyName: string, date: string | null) => {
    setErrors({ ...errors, invalidFrom: false, invalidTo: false });
    const isDateValid = compareDates(
      keyName === "fromDate" ? date : picker.fromDate,
      keyName === "fromDate" ? picker.toDate : date
    );
    if (isDateValid) handleChangeFilter(keyName, date);
    else
      setErrors({
        ...errors,
        invalidFrom: keyName === "fromDate",
        invalidTo: keyName === "toDate",
      });
  };
  const handleSubmit = () => {
    handleChangeFilter("search", searchValue);
    setActiveSearch(true);
  };
  const handleChange = (value: string) => {
    setSearchValue(value);
    setActiveSearch(false);
  };
  const handleClose = () => {
    handleChangeFilter("search", undefined);
    setSearchValue("");
    setActiveSearch(false);
  };
  const handleChangeDate = (dateType: string, newValue: any) => {
    validateDates(dateType, newValue);
    setPicker({ ...picker, [dateType]: newValue });
  };
  useEffect(() => {
    setSelectedStatus("");
    setPicker({ fromDate: null, toDate: null });
    setSearchValue("");
    replaceStatusByAlias(aliases, setStatusList);
  }, []);
  useEffect(() => {
    if (entity === MARKETPLACE) getListOfMerchants({ setMerchants, setAlert });
  }, []);
  return (
    <Grid container className="m-t-10" spacing={2}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={LOCALE}>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Desde"
            value={picker.fromDate}
            disableFuture
            inputFormat={DATE_FORMAT}
            onChange={(newValue: any) => {
              if (!newValue?.invalid) handleChangeDate("fromDate", newValue);
            }}
            onError={(reason: DateTimeValidationError) => {
              setErrors({ ...errors, from: !!reason });
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                size="small"
                {...params}
                className="date-picker-svg"
              />
            )}
          />
          <FormHelperText className="color-error" key="error-message">
            {errors.from && "Fecha inválida"}
            {errors.invalidFrom && ERROR_FROM_GREATER_THAN_TO}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Hasta"
            value={picker.toDate}
            disableFuture
            inputFormat={DATE_FORMAT}
            onChange={(newValue: any) => {
              if (!newValue?.invalid) handleChangeDate("toDate", newValue);
            }}
            onError={(reason: DateTimeValidationError) =>
              setErrors({ ...errors, to: !!reason })
            }
            renderInput={(params) => (
              <TextField
                fullWidth
                size="small"
                {...params}
                className="date-picker-svg"
              />
            )}
          />
          <FormHelperText className="color-error" key="error-message">
            {errors.to && "Fecha inválida"}
            {errors.invalidTo && ERROR_TO_GREATER_THAN_FROM}
          </FormHelperText>
        </Grid>
      </LocalizationProvider>
      <Grid item xs={12} md={6} lg={2}>
        <Autocomplete
          id="status"
          inputValue={selectedStatus}
          options={statusList}
          noOptionsText="No se encontró el estado"
          onChange={(_, value: any, reason: string) => {
            handleChangeFilter("status", value?.id);
            setSelectedStatus(
              reason === "clear" ? "" : getOrderStatus(value.id)?.label
            );
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth size="small" label="Estado" />
          )}
        />
      </Grid>
      {entity === MARKETPLACE && (
        <Grid item xs={12} md={6} lg={2}>
          <Autocomplete
            id="merchants"
            options={merchants}
            noOptionsText="No se encontró el merchant"
            onChange={(_, value) => handleChangeFilter("merchantId", value?.id)}
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" label="Merchant" />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6} lg={3} xl={2.5} className="search-min-width">
        <SearchInput
          searchValue={searchValue}
          placeholder="Buscar por n° de guía, dni o id externo"
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleClose={handleClose}
          activeSearch={activeSearch}
        />
      </Grid>
    </Grid>
  );
};

export default FiltersHeader;
