import { Dialog, Box, IconButton } from "@mui/material";
import { IModal } from "../../interfaces/modal";
import "../../styles/modal.css";

const ModalBase = ({
  open,
  handleClose,
  children,
  keepMounted,
  maxWidth = "sm",
}: IModal) => (
  <Dialog
    keepMounted={keepMounted}
    open={open}
    fullWidth
    maxWidth={maxWidth}
    classes={{ paper: "hide-scrollbar" }}
    onClose={() => {
      if (!keepMounted && handleClose) handleClose();
    }}
    id="modal"
  >
    <Box sx={{ flexGrow: 1 }} className="p-x-40 m-t-30 m-b-40">
      {!keepMounted && handleClose && (
        <IconButton onClick={handleClose} className="close-icon">
          <span className="material-symbols-rounded text-dark f-s-20">
            close
          </span>
        </IconButton>
      )}

      {children}
    </Box>
  </Dialog>
);

export default ModalBase;
