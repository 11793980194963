import React, { useContext, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Box, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import {
  ECOMMERCE_STEPS,
  INITIAL_CONFIG_STEPS,
  MARKETPLACE,
  ECOMMERCE,
  MEDIUM,
} from "../../utils/constants";
import { IStepsByEntity } from "../../interfaces/configuration";
import { UserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import ContractOwner from "../../components/configuration/ContractOwner";
import Carriers from "../../components/configuration/Carriers";
import MerchantInvitations from "../../components/configuration/MerchantInvitations";
import Rules from "../../components/configuration/RulesContainer";
import WebhookSettings from "../../components/configuration/WebhookSettings";

const InitialConfiguration = () => {
  const {
    currentUser: { entity },
  } = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () =>
    setCurrentStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);

  const navHandlers = { handleBack, handleNext };

  const initialConfigStepsComponents = [
    <ContractOwner handleNext={handleNext} />,
    <MerchantInvitations {...navHandlers} />,
    <WebhookSettings {...navHandlers} />,
    <Rules {...navHandlers} />,
    <Carriers handleBack={handleBack} />,
  ];

  const ecommerceStepsComponents = [
    <WebhookSettings {...navHandlers} />,
    <Carriers handleBack={handleBack} />,
  ];

  const stepsByEntity: IStepsByEntity = {
    [MARKETPLACE]: {
      steps: INITIAL_CONFIG_STEPS,
      components: initialConfigStepsComponents,
    },
    [ECOMMERCE]: {
      steps: ECOMMERCE_STEPS,
      components: ecommerceStepsComponents,
    },
  };

  return (
    <>
      <Navbar />
      {entity ? (
        <>
          <Box className="initial-config-container d-flex" mt={8}>
            <Grid container>
              <Grid
                item
                xs={3.5}
                p={5}
                className="initial-config-steps-container"
              >
                <Typography className="color-primary f-s-36 m-b-20">
                  ¡Te damos la bienvenida!
                </Typography>
                <Typography className="f-s-24">
                  Empecemos con la configuración inicial.
                </Typography>
                <Stepper
                  activeStep={currentStep}
                  orientation="vertical"
                  className="m-t-50"
                >
                  {stepsByEntity[entity].steps.map((step: string) => (
                    <Step key={step}>
                      <StepLabel>{step}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid
                item
                xs={8.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingY={3}
              >
                {stepsByEntity[entity].components[currentStep]}
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Loader size={MEDIUM} />
      )}
    </>
  );
};

export default InitialConfiguration;
