import React, { useContext, useReducer } from "react";
import {
  Grid,
  Typography,
  FormControl,
  Button,
  Autocomplete,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
import {
  ICarrier,
  IInitialConfigStepWithBackButton,
} from "../../interfaces/configuration";
import { UserContext } from "../../contexts/userContext";
import {
  ECOMMERCE,
  ERROR,
  HOP,
  MARKETPLACE,
  OCA,
  OCASA,
  RAPIBOY,
} from "../../utils/constants";
import OcaCredentials from "./credentials/OcaCredentials";
import RapiboyCredentials from "./credentials/RapiboyCredentials";
import OcasaCredentials from "./credentials/OcasaCredentials";
import { IAlert } from "../../interfaces/alert";
import GenericAlert from "../GenericAlert";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { carriersReducer } from "../../reducers/carriersReducer";
import { getFormDataFromCarriersList } from "../../utils/credentials";
import HopCredentials from "./credentials/HopCredentials";

const carrierStates = {
  selectedCarriers: [],
  showCredentialForm: false,
  alert: {} as IAlert,
  loading: false,
};

const getCredentialComponentDependingOnSelectedCarrier = (
  { name }: { name: string },
  configuration: any
) => {
  switch (name) {
  case OCA:
    return <OcaCredentials {...configuration} />;
  case RAPIBOY:
    return <RapiboyCredentials {...configuration} />;
  case OCASA:
    return <OcasaCredentials {...configuration} />;
  case HOP:
    return <HopCredentials {...configuration} />;
  default:
    return <></>;
  }
};

const Carriers = ({ handleBack }: IInitialConfigStepWithBackButton) => {
  const {
    currentUser: { entity },
    carriers,
    rules: { contractOwner },
  } = useContext(UserContext);
  const [states, dispatch] = useReducer(carriersReducer, carrierStates);
  const { selectedCarriers, showCredentialForm, alert, loading } = states;
  const navigate = useNavigate();
  const singleCarrierMode =
    contractOwner === MARKETPLACE || entity === ECOMMERCE;
  const initialConfigSuccessPath = `/configuracion-inicial-${
    entity === MARKETPLACE ? "tienda" : "ecommerce"
  }-exitosa`;

  const changeSelectedCarriersValue = (value: any, reason: string) => {
    if (!value || reason === "clear") {
      dispatch({ type: "setSelectedCarriers", value: [] });
      return;
    }
    dispatch({ type: "setSelectedCarriers", value });
  };

  const handleSubmitCarriers = async (carriersToUpdate: ICarrier[]) => {
    dispatch({ type: "setLoading", value: true });
    try {
      await getFormDataFromCarriersList(carriersToUpdate, entity);

      navigate(initialConfigSuccessPath);
    } catch (error: any) {
      dispatch({
        type: "setAlert",
        value: { typeOfAlert: ERROR, message: error, showAlert: true },
      });
    } finally {
      dispatch({ type: "setLoading", value: false });
    }
  };

  const handleCancel = () =>
    dispatch({ type: "setShowCredentialForm", value: false });

  const handleSelectCarriers = () =>
    singleCarrierMode
      ? dispatch({ type: "setShowCredentialForm", value: true })
      : handleSubmitCarriers(selectedCarriers);

  const getCarrierCredentialsForm = () => {
    const carrier = selectedCarriers[0];
    const configuration = {
      handleSubmitCarriers,
      alert,
      carrier,
      loading,
      handleCancel,
    };
    return getCredentialComponentDependingOnSelectedCarrier(
      carrier,
      configuration
    );
  };

  const setAsEnabled = (value: ICarrier) => ({ ...value, disabled: false });

  const getAddedCarriers = (
    value: ICarrier[] | null,
    singleValue?: ICarrier
  ) => {
    // This is horrible. Value can be a carrier list or null, single value can be
    // a carrier or undefined. If it is single mode single value must be used. So
    // if it is single mode and single value is undefined the parsed value is
    // undefined, if it is not it becomes an array. If it is not single mode, it
    // can be null or a list, so it is used as it is. So the parsed value can be
    // undefined, null (both falsey) or an usable list. That way we can compare if
    // toParse is truthy or if it is a real list.
    const toParse = singleCarrierMode ? singleValue && [singleValue] : value;

    return toParse && toParse.map(setAsEnabled);
  };

  return (
    <Grid item xs={12} lg={6} xl={showCredentialForm ? 9 : 5.5}>
      <Card className="initial-config-step-card bg-input br-10">
        <CardContent>
          {showCredentialForm ? (
            getCarrierCredentialsForm()
          ) : (
            <>
              <Typography className="f-s-24">
                {singleCarrierMode
                  ? "Seleccionar operador logístico"
                  : "Seleccionar operadores logísticos"}
              </Typography>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                className="m-t-20 m-b-50"
              >
                <Typography className="f-s-14">
                  {singleCarrierMode
                    ? "Seleccioná y cargá la credencial de tu primer operador logístico. Al terminar este proceso podrás añadir otros en la sección de configuración."
                    : "Elige los operadores logísticos con los que deseas trabajar. Al terminar este proceso podrás añadir otros en la sección de configuración."}
                </Typography>
                {alert.showAlert && <GenericAlert alert={alert} />}
                <FormControl size="small" className="m-t-20">
                  <Autocomplete
                    size="small"
                    multiple={!singleCarrierMode}
                    limitTags={3}
                    options={carriers}
                    noOptionsText="No se encontró el operador logístico."
                    value={
                      singleCarrierMode ? selectedCarriers[0] : selectedCarriers
                    }
                    disabled={!carriers.length}
                    getOptionLabel={(carrier) => carrier.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Seleccionar operador${
                          singleCarrierMode ? "" : "es"
                        }`}
                      />
                    )}
                    onChange={(_, value, reason, details) => {
                      // If it is null, null is used. If it is a list the list is used,
                      // otherwise it is made into a list (to simplify types)
                      const parsedValue =
                        !value || Array.isArray(value) ? value : [value];
                      const addedCarrier = getAddedCarriers(
                        parsedValue,
                        details?.option
                      );
                      changeSelectedCarriersValue(addedCarrier, reason);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={handleBack} fullWidth>
                    Volver
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={handleSelectCarriers}
                    disabled={!selectedCarriers.length || loading}
                    loading={loading}
                    loadingPosition="end"
                  >
                    {singleCarrierMode ? "Cargar credencial" : "Finalizar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Carriers;
