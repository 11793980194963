import { Route, Navigate } from "react-router-dom";
import {
  CLIENT_TYPES,
  ENTITY_ROUTES,
  ENTITY_ROUTES_SP,
  INCOMPLETE_CONFIG,
} from "../utils/constants";
import Home from "../pages/Home";
import AccountDetails from "../pages/account/AccountDetails";
import NotificationComponent from "../pages/Notifications";
import NotFound from "../pages/NotFound";
import InitialConfiguration from "../pages/configuration/InitialConfiguration";
import InitialConfigSuccess from "../pages/configuration/InitialConfigSuccess";
import MainLayout from "../components/MainLayout";
import { IPrivateRoutes } from "../interfaces/routes";
import { getRouteSetByPermission } from "../utils/routes";
import UserDetails from "../pages/account/UserDetails";

export const privateRoutes = ({
  entity,
  rules,
  planName,
  initialConfigurationComplete,
  permissions,
}: IPrivateRoutes) => (
  <>
    {CLIENT_TYPES.includes(entity) &&
      initialConfigurationComplete === INCOMPLETE_CONFIG && (
      <>
        <Route
          path={`configuracion-inicial-${ENTITY_ROUTES_SP[entity]}`}
          element={<InitialConfiguration />}
        />
        <Route
          path={`configuracion-inicial-${ENTITY_ROUTES_SP[entity]}-exitosa`}
          element={<InitialConfigSuccess />}
        />
      </>
    )}
    {entity && permissions ? (
      <>
        <Route path={ENTITY_ROUTES[entity]} element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="cuenta" element={<AccountDetails />} />
          <Route path="mis-datos" element={<UserDetails />} />
          <Route path="notificaciones" element={<NotificationComponent />} />
          {Object.entries(permissions).map(([name, values]) =>
            getRouteSetByPermission({
              permission: { name, values },
              entity,
              rules,
              planName,
            })
          )}
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to={ENTITY_ROUTES[entity]} replace />}
        />
      </>
    ) : (
      <Route path="*" element={<MainLayout />} />
    )}
  </>
);
