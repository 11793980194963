import {
  Grid,
  FormControl,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormHelperText,
  TextField,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { ISubscriptionForm } from "../../interfaces/account";
import { CUIT_PLACEHOLDER, IVA_SITUATIONS } from "../../utils/constants";

const SubscriptionForm = ({
  errors,
  handleChange,
  currentUser,
  disableInputs,
}: ISubscriptionForm) => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Razón Social</Typography>
      <TextField
        fullWidth
        className="bg-input"
        size="small"
        name="businessName"
        placeholder="Razón Social"
        value={currentUser?.businessName}
        disabled={disableInputs}
        error={errors.businessName?.length > 0}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
      {errors.businessName?.length > 0 &&
        errors.businessName?.map((message: string) => (
          <FormHelperText className="color-error" key={message}>
            {message}
          </FormHelperText>
        ))}
    </Grid>

    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <Typography className="f-s-14 m-b-10">Condición ante el IVA</Typography>
        <Select
          size="small"
          name="ivaSituation"
          className="bg-input"
          value={currentUser?.ivaSituation || undefined}
          disabled={disableInputs}
          error={errors.ivaSituation?.length > 0}
          onChange={({ target }: SelectChangeEvent) => handleChange(target)}
        >
          {IVA_SITUATIONS.map((name: string) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {errors.ivaSituation?.length > 0 &&
          errors.ivaSituation?.map((message: string) => (
            <FormHelperText className="color-error" key={message}>
              {message}
            </FormHelperText>
          ))}
      </FormControl>
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">CUIT</Typography>
      <TextField
        fullWidth
        disabled
        className="bg-input"
        size="small"
        name="cuit"
        placeholder={CUIT_PLACEHOLDER}
        value={currentUser?.cuit}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Domicilio Comercial</Typography>
      <TextField
        fullWidth
        className="bg-input"
        size="small"
        name="commercialAddress"
        placeholder="Domicilio comercial"
        value={currentUser?.commercialAddress}
        disabled={disableInputs}
        error={errors.commercialAddress?.length > 0}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
      {errors.commercialAddress?.length > 0 &&
        errors.commercialAddress?.map((message: string) => (
          <FormHelperText className="color-error" key={message}>
            {message}
          </FormHelperText>
        ))}
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography className="f-s-14 m-b-10">Correo electrónico de facturación</Typography>
      <TextField
        fullWidth
        className="bg-input"
        size="small"
        name="billingEmail"
        placeholder="Ingresar correo electrónico"
        value={currentUser?.billingEmail}
        disabled={disableInputs}
        error={errors.billingEmail?.length > 0}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
          handleChange(target)
        }
      />
      {errors.billingEmail?.length > 0 &&
        errors.billingEmail?.map((message: string) => (
          <FormHelperText className="color-error" key={message}>
            {message}
          </FormHelperText>
        ))}
    </Grid>
  </Grid>
);

export default SubscriptionForm;
