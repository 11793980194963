import { IAlert } from "../interfaces/alert";
import { ICarrier, ICarriersReducerStates } from "../interfaces/configuration";

type TCarriersActionType =
  | {
      type: "setSelectedCarriers";
      value: ICarrier[];
    }
  | {
      type: "setShowCredentialForm";
      value: boolean;
    }
  | {
      type: "setAlert";
      value: IAlert;
    }
  | {
      type: "setLoading";
      value: boolean;
    };

export const carriersReducer = (
  state: ICarriersReducerStates,
  action: TCarriersActionType
) => {
  switch (action.type) {
  case "setSelectedCarriers":
    return {
      ...state,
      selectedCarriers: action.value,
    };
  case "setShowCredentialForm":
    return {
      ...state,
      showCredentialForm: action.value,
    };
  case "setAlert":
    return {
      ...state,
      alert: action.value,
    };
  case "setLoading":
    return {
      ...state,
      loading: action.value,
    };
  default:
    return state;
  }
};
