import React, { ChangeEvent } from "react";
import { Button, FormControl, OutlinedInput } from "@mui/material";
import { ISearchInputComponent } from "../interfaces/search";
import { useDebounce } from "../customHooks/useDebounce";
import "../styles/filters.css";

const SearchInput = ({
  searchValue,
  handleChange,
  handleSubmit,
  placeholder,
  handleClose,
  activeSearch,
}: ISearchInputComponent) => {
  const { setSubmitting } = useDebounce(handleSubmit);
  const { setSubmitting: setSubmittingClose } = useDebounce(handleClose);

  return (
    <FormControl size="small" fullWidth>
      <OutlinedInput
        onKeyUp={(e) => e.key === "Enter" && setSubmitting(true)}
        endAdornment={
          <Button
            onClick={() =>
              activeSearch ? setSubmittingClose(true) : setSubmitting(true)
            }
            className="search-input-adornment"
          >
            <span className="material-symbols-rounded text-muted f-s-24">
              {activeSearch ? "close" : "search"}
            </span>
          </Button>
        }
        value={searchValue || ""}
        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
          handleChange(value.trim())
        }
        placeholder={placeholder}
        className="f-s-14"
      />
    </FormControl>
  );
};

export default SearchInput;
