import { IAlert } from "../interfaces/alert";
import { IModalsState } from "../interfaces/configuration";
import { IModifyUserInfo, TModifyModalOptions } from "../interfaces/modal";

type TLayoutActionType = { type: "alert", value: IAlert }
  | { type: "users", value: any[] }
  | { type: "count", value: number }
  | { type: "selectedUser", value: any }
  | { type: "openModal", value: IModalsState }
  | { type: "userToModify", value: IModifyUserInfo }
  | { type: "typeOfModal", value: TModifyModalOptions }
  | { type: "filter", value: string }
  | { type: "page", value: number }
  | { type: "loading", value: boolean };

export const userPageReducer = (state: any, action: TLayoutActionType) => {
  switch (action.type) {
  case "alert":
    return { ...state, alert: action.value };
  case "users":
    return { ...state, users: action.value };
  case "count":
    return { ...state, count: action.value };
  case "selectedUser":
    return { ...state, selectedUser: action.value };
  case "openModal":
    return { ...state, openModal: action.value };
  case "userToModify":
    return { ...state, userToModify: action.value };
  case "typeOfModal":
    return { ...state, typeOfModal: action.value };
  case "filter":
    return { ...state, filter: action.value };
  case "page":
    return { ...state, page: action.value };
  case "loading":
    return { ...state, loading: action.value };
  default:
    return state;
  }
};
