import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";

export const GoBackButton = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className="h-58">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className="p-l-30">
        <IconButton onClick={() => navigate(-1)}>
          <span className="material-symbols-rounded text-dark">
            keyboard_backspace
          </span>
        </IconButton>
        <Typography fontSize="20px">Notificaciones</Typography>
      </Box>
    </Box>);
};

