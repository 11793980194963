import { Box, Grid } from "@mui/material";
import React from "react";
import { IBenefitIcon } from "../../interfaces/landing";

const BenefitIcon = ({ title, icon }: IBenefitIcon) => (
  <Grid className="benefit-icons" item xs={4} md={3} lg={1}>
    {/* TODO habilitar tooltip cuando viole lo disponga */}
    {/* <Tooltip
        title={
          <>
            <Typography className="f-s-14 text-center text-white">
              {description}
            </Typography>
          </>
        }
        arrow
        placement="top"
        classes={{ tooltip: "bg-primary tooltip", arrow: "color-primary" }}
      > */}
    <Box className="benefit-icon text-center">
      <span className="material-symbols-rounded color-primary">{icon}</span>
      <p className="m-t-5 f-s-14">{title}</p>
    </Box>
    {/* </Tooltip> */}
  </Grid>
);

export default BenefitIcon;
