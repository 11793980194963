import React, { useContext, useEffect, useReducer, useState } from "react";
import { fetchData } from "../../utils/dataProvider";
import GenericAlert from "../../components/GenericAlert";
import ListIntegrations from "../../components/tables/ListIntegrations";
import {
  ERROR,
  GET,
  INFO,
  INTERNAL,
  MARKETPLACE,
  MEDIUM,
  OWNER_ROLE_NAME,
  PUT,
  ROWS_PER_PAGE,
} from "../../utils/constants";
import { IAlert } from "../../interfaces/alert";
import {
  IGetIntegrationsParams,
  IIntegrationsToDisable,
  ISelectedIntegration,
  IDisableIntegrationsParams,
  IIntegrationsProps,
} from "../../interfaces/integrations";
import { IPaginationParams } from "../../interfaces/table";
import SaveIntegrationChanges from "../../components/tables/SaveIntegrationChanges";
import HeadOptions from "../../components/tables/HeadOptions";
import { IMappedInfo } from "../../interfaces/invitations";
import { IValuesByEntity } from "../../interfaces/common";
import Loader from "../../components/Loader";
import { integrationsModalReducer } from "../../reducers/integrationsReducers";
import IntegrationsModal from "./IntegrationsModals";
import { UserContext } from "../../contexts/userContext";
import { IUser } from "../../interfaces/usersRoles";

const urlByEntity: IValuesByEntity = {
  marketplace: "acceptedIntegrationsOfMarketplace",
  merchant: "acceptedIntegrationsOfMerchant",
};

const removeIfExistInArray = (
  integrationsTodisable: IIntegrationsToDisable[],
  idToEvaluate: number
) => {
  const index = integrationsTodisable.findIndex(
    ({ id }) => id === idToEvaluate
  );

  if (index >= 0) integrationsTodisable.splice(index, 1);

  return integrationsTodisable;
};

const getEmailFromIntegration = (integration: IMappedInfo, entity: string) => {
  let email = "";
  if (entity === MARKETPLACE) {
    const {
      merchant: {
        entity: { email: merchantEmail },
      },
    } = integration;
    email = merchantEmail;
  } else {
    const {
      marketplace: {
        client: {
          entity: { users },
        },
      },
    } = integration;
    const ownerEmail = users.find(({ roles }: IUser) =>
      roles.some(({ name }) => name === OWNER_ROLE_NAME)
    )?.email;
    email = ownerEmail || "-";
  }
  return email;
};

const getMappedInfo = ({
  integrations,
  entity,
  integrationsToDisable,
}: {
  integrations: IMappedInfo[];
  entity: string;
  integrationsToDisable: IIntegrationsToDisable[];
}): ISelectedIntegration[] =>
  integrations.map((integration: IMappedInfo) => {
    const { id, disabled } = integration;
    const email = getEmailFromIntegration(integration, entity);
    const { name, commercialAddress, ivaSituation, businessName, cuit } =
      entity === MARKETPLACE
        ? integration.merchant.entity
        : integration.marketplace.client.entity;

    const isInEditList = integrationsToDisable.find(
      ({ id: idToDisable }: IIntegrationsToDisable) => idToDisable === id
    );
    return {
      id,
      disabled: isInEditList ? isInEditList.disabled : disabled,
      email,
      cuit,
      name,
      businessName,
      ivaSituation,
      commercialAddress,
      marketplaceId: integration.marketplace?.id || 0,
    };
  });

const disableIntegrations = async ({
  integrations,
  setAlert,
  isInternalUser
}: IDisableIntegrationsParams) => {
  try {
    const url = isInternalUser ? "/internal/updateClientStatus" : "/marketplaces/integrations";
    const dataToUpdate = isInternalUser ? { clients: integrations } : { integrations };
    await fetchData({
      url,
      method: PUT,
      body: dataToUpdate as any,
    });
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  }
};

const getIntegrations = async ({
  entity,
  setIntegrations,
  setAlert,
  setPagination,
  newPage = 0,
  integrationsToDisable,
  url,
  setLoading = () => undefined,
}: IGetIntegrationsParams) => {
  setLoading(true);
  try {
    const { count, integrations } = await fetchData({
      url,
      method: GET,
    });
    if (integrations.length) {
      setIntegrations(
        getMappedInfo({ integrations, entity, integrationsToDisable })
      );
      setPagination({ count, page: newPage });
      setAlert({} as IAlert);
    } else {
      setIntegrations([]);
      setAlert({
        typeOfAlert: INFO,
        message: "No hay invitaciones aceptadas.",
        showAlert: true,
      });
    }
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  } finally {
    setLoading(false);
  }
};

const getClientIntegrations = async ({
  setIntegrations,
  setPagination,
  newPage,
  setLoading,
  setAlert,
}: any) => {
  try {
    const page = newPage || 0;
    setLoading(true);
    const { count, clients } = await fetchData({
      method: GET,
      url: `/internal/clients/accepted/?skip=${newPage}&limit=${ROWS_PER_PAGE}`,
    });
    if (clients.length > 0) {
      setIntegrations(clients);
      setPagination({ count, page });
    } else setIntegrations([]);
  } catch (error: any) {
    setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
  } finally {
    setLoading(false);
  }
};

const getMerchantsToBeDisabledNames = (merchants: IIntegrationsToDisable[]) =>
  merchants.reduce(
    (accum: string[], { disabled, name }: any) =>
      disabled ? accum.concat(name) : accum,
    []
  );

const Integrations = ({
  entity,
  getIntegrationsOfMerchant,
}: IIntegrationsProps) => {
  const [integrations, setIntegrations] = useState(
    [] as ISelectedIntegration[]
  );
  const [selectedIntegration, setSelectedIntegration] = useState(
    {} as ISelectedIntegration
  );
  const [alert, setAlert] = useState({} as IAlert);
  const [updateTable, setUpdateTable] = useState(false);
  const [modalState, modalDispatch] = useReducer(integrationsModalReducer, {
    showInfoMerchantModal: false,
    showDeleteModal: false,
    showDisableModal: false,
    showCancelModal: false,
  });
  const [integrationsToDisable, setIntegrationsToDisable] = useState(
    [] as IIntegrationsToDisable[]
  );
  const [pagination, setPagination] = useState({} as IPaginationParams);
  const [loading, setLoading] = useState(true);
  const [resetValue, setResetValue] = useState(false);
  const { handleGetIntegrationsOfMarketplace } = useContext(UserContext);
  const isInternalUser = entity === INTERNAL;
  const handlePageChange = (newPage: number) => {
    if (isInternalUser)
      getClientIntegrations({
        setIntegrations,
        setPagination,
        newPage,
        setLoading,
        setAlert,
      });
    else
      getIntegrations({
        entity,
        setIntegrations,
        setAlert,
        setPagination,
        newPage,
        integrationsToDisable,
        url: `/marketplaces_merchants/${urlByEntity[entity]}?limit=${ROWS_PER_PAGE}&skip=${newPage}`,
        setLoading,
      });
  };
  const handleDisableAction = (
    disableValue: boolean,
    id: number,
    name: string
  ) =>
    setIntegrationsToDisable((currentArray: IIntegrationsToDisable[]) => {
      const result = removeIfExistInArray(currentArray, id);

      return [...result, { id, disabled: disableValue, name }];
    });

  const handleSelectedAction = (option: string) =>
    modalDispatch({
      type: option === "delete" ? "showDeleteModal" : "showInfoMerchantModal",
      open: true,
    });

  useEffect(() => {
    const newPage = pagination.page || 0;
    handlePageChange(newPage);
  }, [updateTable]);

  const handleSubmitIntegrationsDisabling = async () => {
    setLoading(true);
    await disableIntegrations({
      integrations: integrationsToDisable,
      setAlert,
      isInternalUser
    });
    await handlePageChange(pagination.page);
    if (entity === MARKETPLACE)
      handleGetIntegrationsOfMarketplace();
    setIntegrationsToDisable([]);
  };

  const handleIntegrationsSaveAtTable = () =>
    entity === MARKETPLACE &&
    getMerchantsToBeDisabledNames(integrationsToDisable).length
      ? modalDispatch({ type: "showDisableModal", open: true })
      : handleSubmitIntegrationsDisabling();

  return (
    <>
      {loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <>
          <HeadOptions
            invitationOrIntegration="integration"
            getInvitationsOrIntegrations={getIntegrations}
            getInvitationOrIntegrationArguments={{
              entity,
              setIntegrations,
              setAlert,
              setPagination,
              integrationsToDisable,
            }}
            invitationsOrIntegrations={integrations}
            getMappedInfo={getMappedInfo}
            setPagination={setPagination}
            setInvitationsOrIntegrations={setIntegrations}
            entity={entity}
            integrationsToDisable={integrationsToDisable}
            page={pagination.page}
          />
          {alert.showAlert && <GenericAlert alert={alert} />}
          {integrations.length > 0 && (
            <ListIntegrations
              integrations={integrations}
              setSelectedIntegration={setSelectedIntegration}
              handleSelectedAction={handleSelectedAction}
              handlePageChange={handlePageChange}
              handleDisableAction={handleDisableAction}
              pagination={pagination}
              entity={entity}
              resetValue={resetValue}
            />
          )}

          {integrationsToDisable.length > 0 && (
            <SaveIntegrationChanges
              onSave={handleIntegrationsSaveAtTable}
              onCancel={() =>
                modalDispatch({ type: "showCancelModal", open: true })
              }
            />
          )}
        </>
      )}

      <IntegrationsModal
        state={modalState}
        dispatch={modalDispatch}
        integration={selectedIntegration}
        entity={entity}
        disableMerchantProps={{
          merchants: getMerchantsToBeDisabledNames(integrationsToDisable),
          submitDisable: handleSubmitIntegrationsDisabling,
        }}
        onCancelSubmit={() => {
          setIntegrationsToDisable([]);
          setResetValue(!resetValue);
        }}
        deleteIntegrationProps={{
          updateTable,
          setUpdateTable,
          getIntegrationsOfMerchant,
        }}
      />
    </>
  );
};

export default Integrations;
